import { FTINType } from "@trolley/common-frontend";
import { Divider, GreyBox, Paragraph, Space, Text } from "components";
import React from "react";
import { useIntl } from "utils/context";
import { getFilteredFtinsByCountry } from "utils/helpers";

/**
 * Accessibility role term /defition
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/definition_role
 */

interface Props {
  country: string;
  ftinType: string;
}
export default function TinHelp({ country, ftinType }: Props) {
  const { formatMessage } = useIntl();
  const fTinsByCountry = getFilteredFtinsByCountry();
  const fTins: FTINType = fTinsByCountry[country] ? fTinsByCountry[country] : {};
  if (!fTins[ftinType]) return <></>;
  const { localName, name, examples } = fTins[ftinType];
  if ((!localName && !name) || !examples || examples.length === 0) return <></>;

  return (
    <GreyBox role="definition" border={false}>
      <Paragraph style={{ marginBottom: 0 }}>
        <Text role="term" strong inline>
          {formatMessage({ id: "components.ftinHelp.ftinForm" }, { ftinName: localName ?? name })}
        </Text>

        <Divider transparent size="small" />

        <Space direction="column" align="start" style={{ textAlign: "left" }}>
          {examples?.map((ex: string, index: number) => (
            <Text>{`"${ex}"${index < examples.length - 1 ? `, ${formatMessage({ id: "common.or" })}` : ""}`}</Text>
          ))}
        </Space>
      </Paragraph>
    </GreyBox>
  );
}
