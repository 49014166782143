import { SelectProps as Props, Select as SelectAnt } from "antd";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import React, { useContext, useState } from "react";

import Icon from "components/Icon";
import { classnames, createUseStyle, css } from "style/classname";
import FormItem from "components/Form2/Item";

export { SelectValue } from "antd/lib/select";

export interface SelectProps<ValueType = any> extends Omit<Props<ValueType>, "clearIcon" | "virtual"> {
  readOnly?: boolean; // not a real attribute. it's for stylistic and accessibility purpose.
}

export default function Select<T>(props: SelectProps<T>) {
  const {
    disabled: customDisabled,
    className,
    showSearch,
    allowClear,
    onChange,
    onSelect,
    readOnly,
    "aria-label": ariaLabel,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const { label } = FormItem.useFormItemInstance();
  const disabledContext = useContext(DisabledContext);
  const disabled = customDisabled ?? disabledContext;
  const styledSelect = useStyledSelect({ readOnly, disabled });

  return (
    <SelectAnt
      virtual={false} // for accessibility
      // disabled={disabled} // do not use this field for accessibility
      aria-disabled={disabled || readOnly} // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-disabled
      aria-label={ariaLabel ?? label}
      className={classnames(styledSelect, className)}
      popupMatchSelectWidth={false}
      open={open}
      onDropdownVisibleChange={(open) => {
        if (!disabled && !readOnly) {
          setOpen(open);
        }
      }}
      allowClear={disabled || readOnly ? false : allowClear}
      showSearch={disabled || readOnly ? false : showSearch}
      suffixIcon={disabled || readOnly ? null : <Icon type={open && showSearch ? "search" : "angles-up-down"} />}
      removeIcon={<Icon type="xmark" />}
      menuItemSelectedIcon={<Icon type="check" />}
      onChange={(...args) => {
        if (!disabled && !readOnly) {
          onChange?.(...args);
        }
      }}
      onSelect={(...args) => {
        if (!disabled && !readOnly) {
          onSelect?.(...args);
        }
      }}
      {...rest}
    />
  );
}

Select.OptGroup = SelectAnt.OptGroup;

const useStyledSelect = createUseStyle<{ readOnly?: boolean; disabled: boolean | undefined }>(
  ({ theme, readOnly, disabled }) =>
    css`
      &.${theme.prefixCls}-select:not(.${theme.prefixCls}-select-customize-input) {
        border-radius: ${theme.borderRadius}px; // Bug fix for ant select
        ${readOnly &&
        !disabled &&
        `
        .${theme.prefixCls}-select-selector {
            padding-left: 0;
            padding-right: 0;
            border-color: transparent !important;
            background-color: transparent;
          }
        `}

        ${disabled &&
        `
        cursor: not-allowed;
        .${theme.prefixCls}-select-selector {
          color: ${theme.colorTextDisabled};
          background-color: ${theme.colorBgContainerDisabled};
          border: 1px solid ${theme.colorBorderSecondary} !important;
        }
    `}
      }
    `(),
);
