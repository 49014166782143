import { countryHasPostalCode, formatCountry, getRegionLabel, UsUploadTaxFormType } from "@trolley/common-frontend";
import { Form, Grid } from "components";
import { isLegalAgeDateValidator, isPastDateValidator } from "components/Form/DatePicker";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import { useIntl } from "utils/context";
import { getIrsRule } from "pages/TaxSection/variables";
import { default as info } from "locale/en/containers/info.json";
import React, { useState } from "react";
import { notifySuccess } from "store/actions/notifications";
import { useRecipient } from "store/hooks/recipient";
import { stringifyAddress } from "utils/helpers";
import CopyProfileAddress from "../CopyProfileAddress";
import CopyProfileName from "../CopyProfileName";

export default function OtherUploadForm() {
  const recipient = useRecipient();
  const [showCopyProfileName, setShowCopyProfileName] = useState<boolean | undefined>();
  const [showCopyRecipientAddress, setShowCopyRecipientAddress] = useState<boolean | undefined>();
  const { formatMessage } = useIntl();

  return (
    <>
      <Grid>
        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "firstName"]}
            label={formatMessage({ id: "containers.info.firstName.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.firstName.required",
                }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            {(control, meta, { setFieldsValue }) => (
              <>
                <Form.Input
                  {...control}
                  name="firstName"
                  onFocus={(e) => {
                    if (
                      !e.target.value &&
                      showCopyProfileName === undefined &&
                      (recipient?.firstName || recipient?.lastName)
                    ) {
                      setShowCopyProfileName(true);
                    }
                  }}
                />
                <CopyProfileName
                  label={formatMessage({ id: "containers.tax.nameAsShown" })}
                  name={[recipient?.firstName, recipient?.lastName]
                    .filter((v) => v)
                    .join(" ")
                    .trim()}
                  visible={!!showCopyProfileName}
                  onClose={() => {
                    setShowCopyProfileName(false);
                  }}
                  onOk={() => {
                    setFieldsValue({
                      data: {
                        firstName: recipient?.firstName,
                        lastName: recipient?.lastName,
                      },
                    });
                    notifySuccess(formatMessage({ id: "containers.tax.nameCopied" }));
                    setShowCopyProfileName(false);
                  }}
                />
              </>
            )}
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "lastName"]}
            label={formatMessage({ id: "containers.info.lastName.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.lastName.required",
                }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            <Form.Input name="lastName" />
          </Form.Field>
        </Grid.Item>

        <Form.Control dependencies={[["data", "uploadKind"]]}>
          {({ value }) => {
            return (
              value?.data?.uploadKind === UsUploadTaxFormType.W8ECI && (
                <Grid.Item xs={24}>
                  <Form.Field
                    name={["data", "dob"]}
                    label={formatMessage({ id: "containers.info.dob.title" })}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: "containers.info.dob.required",
                        }),
                      },
                      isLegalAgeDateValidator(),
                    ]}
                  >
                    <Form.DatePicker name="dob" />
                  </Form.Field>
                </Grid.Item>
              )
            );
          }}
        </Form.Control>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "mailingAddress"]}
            label={formatMessage({ id: "containers.tax.mailingAddress" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.street1.required",
                }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            {(control, meta, { setFieldsValue }) => (
              <>
                <Form.Input
                  {...control}
                  name="mailingAddress"
                  onFocus={(e) => {
                    if (!e.target.value && showCopyRecipientAddress === undefined && recipient?.address?.country) {
                      setShowCopyRecipientAddress(true);
                    }
                  }}
                />
                <CopyProfileAddress
                  label={formatMessage({ id: "containers.tax.mailingAddress" })}
                  address={[
                    [recipient?.address.street1, recipient?.address.street2].filter((v) => v).join(" "),
                    stringifyAddress({
                      city: recipient?.address.city,
                      region: getRegionLabel(recipient?.address.region, recipient?.address.country),
                      postalCode: recipient?.address.postalCode,
                    }),
                    formatCountry(recipient?.address.country, formatMessage),
                  ]}
                  visible={!!showCopyRecipientAddress}
                  onClose={() => {
                    setShowCopyRecipientAddress(false);
                  }}
                  onOk={() => {
                    if (recipient) {
                      setFieldsValue({
                        data: {
                          mailingAddress: [recipient?.address.street1, recipient?.address.street2]
                            .filter((v) => v)
                            .join(" "),
                          mailingCity: recipient?.address.city,
                          mailingRegion: recipient?.address.region,
                          mailingPostalCode: recipient?.address.postalCode,
                          mailingCountry: recipient?.address.country,
                        },
                      });
                      notifySuccess(formatMessage({ id: "containers.tax.addressCopied" }));
                    }
                    setShowCopyRecipientAddress(false);
                  }}
                />
              </>
            )}
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "mailingCity"]}
            label={formatMessage({ id: "containers.tax.mailingCity" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.city.required" }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            <Form.Input name="mailingCity" />
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "mailingCountry"]}
            label={formatMessage({ id: "containers.tax.mailingCountry" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.country.required",
                }),
              },
            ]}
          >
            <Form.SelectCountry type="all" name="mailingCountry" />
          </Form.Field>
        </Grid.Item>

        <Form.Control dependencies={[["data", "mailingCountry"]]}>
          {({ value }) => {
            const mailingCountry = value?.data?.mailingCountry;

            const regionLabelId = `containers.info.region${
              mailingCountry && info.region[mailingCountry] ? `.${mailingCountry}` : ""
            }`;

            const postalLabelId = `containers.info.postalCode${
              mailingCountry && info.postalCode[mailingCountry] ? `.${mailingCountry}` : ""
            }`;

            return (
              <>
                <Grid.Item xs={24} sm={{ flex: "1 0 50%" }}>
                  <Form.Field
                    name={["data", "mailingRegion"]}
                    label={formatMessage(
                      { id: "containers.tax.mailingRegionOrPostal" },
                      {
                        regionOrPostal: formatMessage({
                          id: `${regionLabelId}.title` as any,
                        }),
                      },
                    )}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: `${regionLabelId}.required` as any,
                        }),
                      },
                      isRegionInCountryValidator(["data", "mailingCountry"], formatMessage),
                    ]}
                  >
                    <Form.SelectRegion name="mailingRegion" country={mailingCountry} />
                  </Form.Field>
                </Grid.Item>

                {countryHasPostalCode(mailingCountry) && (
                  <Grid.Item xs={24} sm={8}>
                    <Form.Field
                      name={["data", "mailingPostalCode"]}
                      label={formatMessage(
                        { id: "containers.tax.mailingRegionOrPostal" },
                        {
                          regionOrPostal: formatMessage({
                            id: `${postalLabelId}.title` as any,
                          }),
                        },
                      )}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: `${postalLabelId}.required` as any,
                          }),
                        },
                        getIrsRule(formatMessage),
                      ]}
                    >
                      <Form.Input name="mailingPostalCode" />
                    </Form.Field>
                  </Grid.Item>
                )}
              </>
            );
          }}
        </Form.Control>
      </Grid>

      <Grid>
        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "taxPayerUsId"]}
            label={formatMessage({ id: "containers.tax.usTin" })}
            rules={[
              {
                async validator(_: any, value: string) {
                  if (value && !value.includes("*") && value.replace(/\D/g, "").length < 9) {
                    throw new Error(formatMessage({ id: "containers.tax.usTinError" }));
                  }
                },
              },
            ]}
          >
            <Form.Input name="taxPayerUsId" placeholder="XX-XXXXXXXX or XXX-XX-XXXX" />
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "taxPayerForeignId"]}
            label={formatMessage({ id: "containers.tax.fTin" })}
            normalize={(v) => String(v || "").replace(/\s/g, "")}
            rules={[getIrsRule(formatMessage)]} // deal with this text
          >
            <Form.Input name="taxPayerForeignId" />
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name="signedAt"
            label={formatMessage({ id: "containers.tax.dateOfSignature" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.tax.dateOfSignatureRequired",
                }),
              },
              isPastDateValidator(),
            ]}
          >
            <Form.DatePicker name="signedAt" />
          </Form.Field>
        </Grid.Item>
      </Grid>
    </>
  );
}
