import { DependencyList, useMemo } from "react";
import useDeepCompareMemoize, { checkDeps } from "./useDeepCompareMemoize";

/**
 * `useDeepCompareMemo` will only recompute the memoized value when one of the
 * `deps` has changed.
 *
 * Usage note: only use this if `deps` are objects or arrays that contain
 * objects. Otherwise you should just use React.useMemo.
 *
 */
export default function useDeepCompareMemo<T>(factory: () => T, dependencies: DependencyList) {
  if (process.env.NODE_ENV !== "production") {
    checkDeps(dependencies, "useDeepCompareMemo");
  }

  return useMemo(factory, useDeepCompareMemoize(dependencies));
}
