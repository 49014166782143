import { default as React, ReactNode } from "react";
import css, { createUseStyle } from "style/classname";

interface Props {
  value: ReactNode | undefined;
}

export default function Hint({ value }: Props) {
  const styledHint = useStyledHint();
  if (value) {
    return <div className={styledHint}>{value}</div>;
  }

  return null;
}

const useStyledHint = createUseStyle(({ theme }) =>
  css`
    color: ${theme.colorTextSecondary};
    font-size: ${theme.fontSizeSM}px;
    line-height: ${theme.lineHeightSM};
  `(),
);
