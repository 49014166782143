/* istanbul ignore file */
import * as request from "services/request";
import store from "store";
import { standardDispatch, OpCode } from "store/dispatcher";
import * as types from "@trolley/common-frontend";

export interface W8UsWithholdingTable extends types.TaxForm.W8UsWithholdingTable {}

export function loadWithholdingTable() {
  const { withholdingTable } = store.getState();

  if (withholdingTable.fetchStatus.data === undefined) {
    standardDispatch(OpCode.LOADING, "withholdingTable");
    request
      .POST<{ withholdingTable: W8UsWithholdingTable }>(`/v1/tax/w8UsWithholdingTable`)
      .then((res) => {
        standardDispatch(OpCode.DATA, "withholdingTable", {
          data: res.body.withholdingTable,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "withholdingTable", { errors });
      });
  }
}
