import React, { InputHTMLAttributes, ReactElement, forwardRef, useState } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import { InputProps as NewInputProps } from "../Form2/Input";

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "prefix" | "type"> {
  wrapperClassName?: string;
  prefix?: ReactElement;
  suffix?: ReactElement;
  type?: NewInputProps["type"];
}

export default forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { wrapperClassName, className, prefix, suffix, onFocus, type = "text", ...inputProps } = props;
  const [prefixRef, setPrefixRef] = useState<HTMLElement | null>();
  const [suffixRef, setSuffixRef] = useState<HTMLElement | null>();
  const styledInput = useStyledInput({
    ...inputProps,
    prefixWidth: prefixRef?.offsetWidth || 0,
    suffixWidth: suffixRef?.offsetWidth || 0,
  });

  return (
    <div className={classnames("input-wrapper", styledInput, wrapperClassName)}>
      {prefix &&
        React.cloneElement(
          prefix,
          {
            ...prefix.props,
            ref: setPrefixRef,
            className: classnames(prefix.props?.className, "input-prefix"),
          },
          prefix.props.children,
        )}
      {suffix &&
        React.cloneElement(
          suffix,
          {
            ...suffix.props,
            ref: setSuffixRef,
            className: classnames(suffix.props?.className, "input-suffix"),
          },
          suffix.props.children,
        )}
      <input
        {...inputProps}
        className={classnames("input-field", className)}
        ref={ref}
        type={type}
        onFocus={
          onFocus ||
          ((e) => {
            e?.target?.select?.(); // select all on focus
          })
        }
      />
    </div>
  );
});

const useStyledInput = createUseStyle<InputProps & { prefixWidth: number; suffixWidth: number }>(
  ({ theme, animation, ...props }) =>
    css`
      position: relative;
      width: 100%;
      input {
        &::placeholder {
          color: ${theme.colorTextQuaternary};
        }
        appearance: none;
        transition: border 0.2s ease, color 0.2s ease;
        display: block;
        border-radius: ${theme.borderRadius}px;
        height: ${theme.controlHeight}px;
        background-color: ${theme.colorBgContainer};
        color: ${(theme.Input as any)?.colorText};
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        &:not(:focus) {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:focus,
        &:hover,
        &:active {
          outline: none;
          &:not([readonly], [disabled]) {
            border-color: ${theme.colorPrimary};
          }
        }
        &[readonly] {
          background-color: transparent;
          padding: 0;
          border: none;
        }
        &[disabled] {
          background-color: ${theme.colorBgContainerDisabled};
          color: ${theme.colorTextDisabled};
          cursor: not-allowed;
        }
      }
      .input-prefix,
      .input-suffix {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
      .input-prefix {
        left: 0;
        padding-left: ${props.readOnly ? 0 : 8}px;
        & ~ input {
          padding-left: ${props.prefixWidth + 8}px;
        }
      }
      .input-suffix {
        right: 0;
        padding-right: ${props.readOnly ? 0 : 8}px;
        & ~ input {
          padding-right: ${props.suffixWidth + 8}px;
        }
      }
    `(),
);
