import { allMappedCountries, getPostalCodeProps, IncompleteReason } from "@trolley/common-frontend";
import React from "react";

import { Grid, Text } from "components";
import Form from "components/Form";
import common from "locale/en/common.json";
import info from "locale/en/containers/info.json";
import { getPostalLocaleId } from "pages/Info";
import { Recipient, updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useIframeConfig } from "store/hooks/config";
import { useRecipient } from "store/hooks/recipient";
import { useTheme } from "style/classname";
import { FormatMessage, useIntl } from "utils/context";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

interface Props {
  fields?: ("country" | "street1" | "street2" | "region" | "city" | "postalCode")[];
  countrySet?: "all" | "address";
  value?: Partial<Recipient["address"]>;
}

export default function AddressInput(props: Props & InputPopupProps) {
  const { submitted, label, value, ...rest } = props;
  const { formatMessage } = useIntl();
  const recipient = useRecipient();

  async function onSubmit(address: any) {
    if (recipient) {
      if (props.onSubmit) {
        await props.onSubmit({ address });
      } else {
        await updateRecipient(recipient.id, { address });
      }
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  const address = value || recipient.address;

  return (
    <LockedInput
      name="address"
      defaultValue={address}
      onSave={onSubmit}
      FieldComponent={AddressFieldsInput}
      fieldComponentProps={rest}
      rules={[isValidAddress(rest.fields as Props["fields"], formatMessage)]}
      label={label || formatMessage({ id: "containers.info.street1.title" })}
      submitted={submitted}
    />
  );
}

interface AddressFieldsProps extends Omit<Props, "value"> {
  name?: string;
  value?: Partial<Recipient["address"]>;
  disabled?: boolean;
  onChange?(e: any): void;
}

function isValidAddress(fields: Props["fields"], formatMessage: FormatMessage) {
  return {
    async validator(_: any, values: any) {
      if (values) {
        if (!fields || fields.includes("country")) {
          if (!values.country) {
            throw new Error(
              formatMessage({
                id: "containers.info.country.required",
                defaultMessage: info.country.required,
              }),
            );
          }
        }

        if (!fields || fields.includes("street1")) {
          if (!values.street1) {
            throw new Error(
              formatMessage({
                id: "containers.info.street1.required",
                defaultMessage: info.street1.required,
              }),
            );
          }
        }

        if (!fields || fields.includes("city")) {
          if (!values.city) {
            throw new Error(
              formatMessage({
                id: "containers.info.city.required",
                defaultMessage: info.city.required,
              }),
            );
          }
        }

        if (!fields || fields.includes("region")) {
          const countryRegions = allMappedCountries[values.country]?.regions;

          if (countryRegions && !values.region) {
            throw new Error(
              formatMessage({
                id: "containers.info.region.required",
                defaultMessage: info.region.required,
              }),
            );
          }

          if (countryRegions && !countryRegions.some((r) => [r.name, r.shortCode].includes(values.region))) {
            throw new Error(
              formatMessage({
                id: "common.invalidValue",
                defaultMessage: common.invalidValue,
              }),
            );
          }
        }

        if (
          (!fields || fields.includes("postalCode")) &&
          getPostalCodeProps(values.country).hasPostalCode &&
          !values.postalCode
        ) {
          throw new Error(
            formatMessage({
              id: "containers.info.postalCode.required",
              defaultMessage: info.postalCode.required,
            }),
          );
        }
      }
    },
  };
}

function AddressFieldsInput({
  name,
  value = {},
  disabled,
  onChange,
  fields,
  countrySet = "address",
}: AddressFieldsProps) {
  const { formatMessage } = useIntl();
  const config = useIframeConfig();
  const recipient = useRecipient();
  const theme = useTheme();
  const postalCodeProps = getPostalCodeProps(value.country, formatMessage);

  function getFieldErrorStyle(field: keyof IncompleteReason) {
    if (recipient?.inactiveReasons?.[field]) {
      return {
        backgroundColor: theme.colorErrorBg,
        borderRadius: "4px",
        padding: "8px",
      };
    }

    return undefined;
  }

  return (
    <Grid padding="small">
      {(!fields || fields.includes("country")) && (
        <Grid.Item xs={24} style={getFieldErrorStyle("countryCode")}>
          <Form.SelectCountry
            aria-label={formatMessage({ id: "components.formCountry.select" })}
            disabled={disabled}
            name="country"
            type={countrySet}
            selectableCountries={countrySet === "address" ? config.enabledCountries : undefined}
            value={value.country || undefined}
            onChange={(e) => {
              const update = { ...value, country: e.target.value };
              if (!fields || fields.includes("region")) {
                // reset region when country changes
                update.region = "";
              }
              onChange?.(update);
            }}
          />
        </Grid.Item>
      )}
      {/* STREET 1 */}
      {(!fields || fields.includes("street1")) && (
        <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("street1")}>
          <Form.Input
            disabled={disabled}
            aria-label={formatMessage({ id: "containers.info.street1.title" })}
            placeholder={formatMessage({ id: "containers.info.street1.title" })}
            name="street1"
            value={value.street1}
            onChange={(e) => {
              onChange?.({ ...value, street1: e.target.value });
            }}
          />
        </Grid.Item>
      )}
      {/* STREET 2 */}
      {(!fields || fields.includes("street2")) && (
        <Grid.Item xs={24} sm={12}>
          <Form.Input
            disabled={disabled}
            aria-label={formatMessage({ id: "containers.info.street2.title" })}
            placeholder={formatMessage({ id: "containers.info.street2.title" })}
            name="street2"
            value={value.street2}
            onChange={(e) => {
              onChange?.({ ...value, street2: e.target.value });
            }}
          />
        </Grid.Item>
      )}
      {/* CITY */}
      {(!fields || fields.includes("city")) && (
        <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("city")}>
          <Form.Input
            disabled={disabled}
            placeholder={formatMessage({ id: "containers.info.city.title" })}
            aria-label={formatMessage({ id: "containers.info.city.title" })}
            name="city"
            value={value.city}
            onChange={(e) => {
              onChange?.({ ...value, city: e.target.value });
            }}
          />
        </Grid.Item>
      )}
      {/* REGION */}
      {(!fields || fields.includes("region")) && value.country && allMappedCountries[value.country]?.regions && (
        <Grid.Item xs={24} sm={12} md={{ flex: "5 0 120px" }} style={getFieldErrorStyle("regionCode")}>
          <Form.SelectRegion
            disabled={disabled}
            name="region"
            aria-label={formatMessage({ id: "components.formRegion.select" })}
            country={value.country}
            value={value.region || undefined}
            onChange={(e) => {
              onChange?.({ ...value, region: e.target.value });
            }}
          />
        </Grid.Item>
      )}
      {/* POSTAL CODE */}
      {(!fields || fields?.includes("postalCode")) && postalCodeProps.hasPostalCode && value.country && (
        <Grid.Item
          xs={24}
          sm={12}
          md={{
            flex: "1 0 110px",
          }}
          style={getFieldErrorStyle("postalCode")}
        >
          <Form.Input
            placeholder={formatMessage({
              id: getPostalLocaleId(value.country, "title"),
            })}
            name="postalCode"
            aria-label={formatMessage({ id: getPostalLocaleId(value.country, "title") })}
            disabled={disabled}
            value={value.postalCode}
            onChange={(e) => {
              onChange?.({ ...value, postalCode: e.target.value });
            }}
          />
          {postalCodeProps.hint && (
            <Text type="secondary" size="small">
              {postalCodeProps.hint}
            </Text>
          )}
        </Grid.Item>
      )}
    </Grid>
  );
}
