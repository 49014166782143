import { GreyBox, Modal, Text } from "components";
import { useIntl } from "utils/context";
import React from "react";

interface Props {
  label: string;
  name: string | undefined;
  visible: boolean;
  onClose(): void;
  onOk(): void;
}

export default function CopyProfileName(props: Props) {
  const { label, name, visible, onClose, onOk } = props;
  const { formatMessage } = useIntl();

  return name ? (
    <Modal
      size="small"
      open={visible}
      onCancel={onClose}
      onOk={onOk}
      okText={formatMessage({ id: "containers.tax.copyProfileName.ok" })}
      title={formatMessage({ id: "containers.tax.copyProfileName.title" })}
    >
      <GreyBox>
        <Text strong type="label">
          {label}
        </Text>
        <Text>{name}</Text>
      </GreyBox>
    </Modal>
  ) : null;
}
