import { CountryCode, allMappedCountries, getPostalCodeProps } from "@trolley/common-frontend";
import { Container, Divider, Form2, Icon, Typography } from "components";
import React from "react";
import { css } from "style/classname";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  street1: string;
  street2: string;
  city: string;
  country: string;
  region: string;
  postalCode: string;
}

export default function UpdatePrimaryAddress({ taxProfileWizard, onContinue }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const [form] = Form2.useForm<IFormData>();
  const initialValues: IFormData = {
    street1: taxProfileWizard?.address?.street1 ?? "",
    street2: taxProfileWizard?.address?.street2 ?? "",
    city: taxProfileWizard?.address?.city ?? "",
    country: taxProfileWizard?.address?.country ?? "",
    region: taxProfileWizard?.address?.region ?? "",
    postalCode: taxProfileWizard?.address?.postalCode ?? "",
  };
  const countryValue = Form2.useWatch("country", form) as CountryCode;

  function onSubmit(values: IFormData) {
    onContinue({
      address: { ...values },
    });
  }
  const postalCodeProps = getPostalCodeProps(countryValue);

  return (
    <Form2
      form={form}
      initialValues={initialValues}
      validateTrigger="onChange"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.updatePrimaryAddressTitle" })}
            </Typography.Title>
          </legend>
          <Divider transparent size="large" />
          <Form2.Item
            name="street1"
            label={formatMessage({ id: "containers.info.street1.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.street1.required" }),
              },
            ]}
            className={styledItem}
          >
            <Form2.Input type="text" />
          </Form2.Item>
          <Form2.Item
            name="street2"
            label={formatMessage({ id: "containers.info.street2.title" })}
            className={styledItem}
          >
            <Form2.Input type="text" />
          </Form2.Item>
          <Form2.Item
            name="city"
            label={formatMessage({ id: "containers.info.city.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.city.required" }),
              },
            ]}
            className={styledItem}
          >
            <Form2.Input type="text" />
          </Form2.Item>
          <Form2.Item
            name="country"
            label={formatMessage({ id: "containers.info.country.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.country.required" }),
              },
            ]}
            className={styledItem}
          >
            <Form2.SelectCountry
              type="all"
              onChange={() => {
                form.setFieldValue("region", "");
              }}
            />
          </Form2.Item>
          {!!allMappedCountries[countryValue]?.regions.length && (
            <Form2.Item
              name="region"
              label={formatMessage({ id: "containers.info.region.title" })}
              className={styledItem}
            >
              <Form2.SelectRegion country={countryValue} />
            </Form2.Item>
          )}
          {postalCodeProps.hasPostalCode && (
            <Form2.Item
              name="postalCode"
              label={formatMessage({
                id:
                  countryValue === CountryCode.US
                    ? "containers.info.postalCode.US.title"
                    : "containers.info.postalCode.title",
              })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id:
                      countryValue === CountryCode.US
                        ? "containers.info.postalCode.US.required"
                        : "containers.info.postalCode.required",
                  }),
                },
                {
                  async validator(_: any, v: any) {
                    if (v && countryValue === CountryCode.US && !postalCodeProps.isPostalCode?.(v)) {
                      throw new Error(formatMessage({ id: "containers.info.postalCode.US.valid" }));
                    }
                  },
                },
              ]}
              extra={postalCodeProps.hint}
              className={styledItem}
            >
              <Form2.Input type="text" />
            </Form2.Item>
          )}
        </fieldset>
        <Divider transparent size="large" />
        <Form2.SubmitButton text={formatMessage({ id: "common.continue" })} suffix={<Icon type="arrow-right" />} />
      </Container>
    </Form2>
  );
}

const styledItem = css`
  margin-bottom: 16px;
`();
