/**
 * Omit fields that contain masked values such as credit card numbers "**** **** **** 5445"
 * this function will also check deep object, and will ignore arrays
 *
 * @param obj: object to check
 * @returns partial of the original object
 */
export default function omitMaskedValues<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([field, val]) => val !== undefined && (typeof val !== "string" || !val.includes("*")))
      .map(([field, val]) => {
        if (val && typeof val === "object" && !Array.isArray(val)) {
          return [field, omitMaskedValues(val)];
        }

        return [field, val];
      }),
  ) as T;
}
