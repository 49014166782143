import Form from "components/Form";
import React from "react";
import { useIntl } from "utils/context";
import { updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { CountryCode } from "@trolley/common-frontend";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function CitizenshipsInput({ submitted }: InputPopupProps) {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();

  async function onSubmit(value: CountryCode) {
    if (recipient) {
      await updateRecipient(recipient.id, {
        citizenships: [value],
      });
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  return (
    <LockedInput<React.ComponentProps<typeof Form.SelectCountry>>
      name="citizenships"
      defaultValue={recipient.citizenships?.[0]}
      onSave={onSubmit}
      FieldComponent={Form.SelectCountry}
      fieldComponentProps={{
        type: "all",
      }}
      label={formatMessage({
        id: "containers.accountSummary.ticket.citizenships",
      })}
      submitted={submitted}
    />
  );
}
