import { CountryCode, RecipientType } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";

import { Button, Card, Container, Divider, Flag, Form2, Icon, Space, Typography } from "components";
import { useIntl } from "utils/context";
import EnterTINForm, { ITINFormData } from "./EnterTINForm";
import { WizardPageProps } from ".";
import { getTinTypeLabel } from "utils/helpers";

export default function EnterTINNumber({ taxProfileWizard, onContinue, hideBackButton }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const [tinNumbers, setTinNumbers] = useState<
    {
      tinCountry: string;
      tinNumber: string;
      tinType?: string | null;
      otherTinDescription?: string | undefined;
    }[]
  >([]);
  const [isAddingTIN, setIsAddingTIN] = useState(true);

  useEffect(() => {
    setTinNumbers([...(taxProfileWizard.tinNumbers ?? [])]);
    if (taxProfileWizard.tinNumbers && taxProfileWizard.tinNumbers.length > 0) {
      setIsAddingTIN(false);
    }
  }, [taxProfileWizard]);

  useEffect(() => {
    if (hideBackButton) {
      hideBackButton(isAddingTIN && tinNumbers.length > 0);
    }
  }, [isAddingTIN, tinNumbers, hideBackButton]);

  useEffect(() => {
    if (tinNumbers.length === 0 && !isAddingTIN) {
      setIsAddingTIN(true);
    }
  }, [tinNumbers, isAddingTIN]);

  function onNoTIN() {
    onContinue({
      tinNumbers: [],
    });
  }

  function onSaveTINForm(tinData: ITINFormData) {
    setTinNumbers((tinNumbers) => [
      ...tinNumbers,
      {
        ...tinData,
        tinType: tinData.tinType !== "other" ? tinData.tinType : null,
      },
    ]);
    setIsAddingTIN(false);
  }

  function onCloseTINForm() {
    setIsAddingTIN(false);
  }

  function onSubmit() {
    if (tinNumbers.length > 0 && !isAddingTIN) {
      onContinue({
        tinNumbers: [...tinNumbers],
      });
    }
  }

  function removeTIN(tinIndex: number) {
    setTinNumbers((tinNumbers) => [...tinNumbers.slice(0, tinIndex), ...tinNumbers.slice(tinIndex + 1)]);
  }

  return (
    <>
      {!isAddingTIN && (
        <Form2 validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
          <Container padding="none">
            {!isAddingTIN && (
              <Card
                type="inner"
                title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.title" })}
              >
                <table>
                  <tbody>
                    <tr>
                      <th>
                        {formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.tinType" })}
                      </th>
                      <th>
                        {formatMessage({
                          id: "containers.taxProfile.wizard.summarySection.taxIdentification.tinLabel",
                        })}
                      </th>
                    </tr>
                    {tinNumbers.map(({ tinCountry, tinNumber, tinType, otherTinDescription }, tinIndex) => (
                      <tr key={tinNumber}>
                        <td>
                          <Typography.Text type="label">
                            <Space>
                              <Flag code={tinCountry as CountryCode} />
                              {tinType ? getTinTypeLabel(tinCountry, tinType) : "Other"}
                            </Space>
                          </Typography.Text>
                        </td>
                        <td>{tinNumber}</td>
                        <td>
                          <Button
                            type="text"
                            aria-label={formatMessage({ id: "common.delete" })}
                            icon="trash"
                            size="small"
                            onClick={() => removeTIN(tinIndex)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card>
            )}
            <Divider transparent size="xlarge" />
            {!isAddingTIN && tinNumbers.length > 0 && (
              <Form2.Item>
                <Button
                  onClick={() => {
                    setIsAddingTIN(true);
                  }}
                >
                  <Icon type="plus" left />
                  {formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberAdd" })}
                </Button>
              </Form2.Item>
            )}
            <Divider transparent size="large" />
            {!isAddingTIN && (
              <Form2.SubmitButton
                disabled={false}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            )}
          </Container>
        </Form2>
      )}
      {isAddingTIN && (
        <EnterTINForm
          onSaveForm={onSaveTINForm}
          onCloseForm={onCloseTINForm}
          isBusiness={taxProfileWizard.confirmedRecipientType === RecipientType.BUSINESS}
          tinNumbersCount={tinNumbers.length}
          onNoTIN={onNoTIN}
          taxProfileWizard={taxProfileWizard}
        />
      )}
    </>
  );
}
