import { Ticket, TicketDocumentRequest, TicketDocumentType } from "@trolley/common-frontend";
import React, { useState } from "react";

import { Button, Form, Grid, Text, Title, UploadButton } from "components";
import { useForm } from "components/Form";
import { UploadFile } from "components/UploadButton";
import { uploadTicketDocuments } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { IntlMessageKeys, useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";

const ALLOWED_MIME_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/heic", "image/heif"];
const MAX_FILE_LIMIT = 5;
const FILE_SIZE_LIMIT_MB = 5;

function DocumentExamples({ documentType }: { documentType: TicketDocumentType }) {
  const { formatMessage } = useIntl();

  switch (documentType) {
    case TicketDocumentType.ADDRESS:
      const addressItemTranslations: IntlMessageKeys[] = [
        "containers.accountSummary.ticket.documentRequest.address.documents.utility",
        "containers.accountSummary.ticket.documentRequest.address.documents.statement",
        "containers.accountSummary.ticket.documentRequest.address.documents.driverLicense",
        "containers.accountSummary.ticket.documentRequest.address.documents.other",
      ];

      return (
        <ul>
          {addressItemTranslations.map((item) => (
            <li key={item}>
              <Text>{formatMessage({ id: item })}</Text>
            </li>
          ))}
        </ul>
      );
    case TicketDocumentType.IDENTITY:
      const identityItemTranslations: IntlMessageKeys[] = [
        "containers.accountSummary.ticket.documentRequest.identity.documents.passport",
        "containers.accountSummary.ticket.documentRequest.identity.documents.nationalId",
        "containers.accountSummary.ticket.documentRequest.identity.documents.driverLicense",
      ];

      return (
        <ul>
          {identityItemTranslations.map((item) => (
            <li key={item}>
              <Text>{formatMessage({ id: item })}</Text>
            </li>
          ))}
        </ul>
      );
    default:
      return null;
  }
}

export function DocumentRequestEntry({
  documentRequest,
  ticket,
}: {
  documentRequest: TicketDocumentRequest;
  ticket: Ticket;
}) {
  const [form] = useForm();
  const [fileStash, setFileStash] = useState<UploadFile[]>([]);
  const { isMobile } = useWindowSize();
  const { formatMessage } = useIntl();
  const recipient = useRecipient();
  const [status, setStatus] = useState<undefined | "saving" | "saved">();

  const { documentType, fileUploads } = documentRequest;
  const isProvided = documentRequest.fileUploads.length >= MAX_FILE_LIMIT;
  const isLimitReached = documentRequest.fileUploads.length + fileStash.length >= MAX_FILE_LIMIT;

  async function onSubmit() {
    if (fileStash.length && recipient) {
      try {
        setStatus("saving");
        await uploadTicketDocuments(ticket, documentType, fileStash, recipient.id);
        setFileStash([]);
        setStatus("saved");
      } catch (error) {
        setStatus(undefined);
      }
    }
  }

  const documentTypeMessage = formatMessage({
    id: `containers.accountSummary.ticket.documentRequest.${documentType}.title`,
  });

  return (
    <Form form={form} onFinish={onSubmit} style={{ marginBottom: "24px" }}>
      <Grid alignItems="middle" padding="large">
        <fieldset>
          <legend style={{ margin: 0 }}>
            <Title level={2} style={{ margin: 0 }}>
              {documentTypeMessage}
            </Title>
          </legend>
          <Grid.Item xs={24} sm={{ flex: "20" }}>
            <Text padded>
              {formatMessage({ id: `containers.accountSummary.ticket.documentRequest.${documentType}.description` })}
            </Text>

            <DocumentExamples documentType={documentType} />

            {!isProvided && !isLimitReached && (
              <UploadButton
                onSelectFile={(uploadFile) => setFileStash((stash) => [...stash, uploadFile])}
                hideFileName
              />
            )}

            {fileStash.length > 0 && (
              <>
                <Text type="label" padded>
                  {formatMessage({ id: "containers.accountSummary.ticket.pending" })}
                </Text>

                {fileStash.map((uploadFile) => (
                  <Form.Field
                    key={uploadFile.uid}
                    name={uploadFile.uid}
                    showEmptyError={false}
                    rules={[
                      () => ({
                        async validator() {
                          if (!ALLOWED_MIME_TYPES.includes(uploadFile.type)) {
                            throw new Error(
                              formatMessage({ id: "containers.accountSummary.ticket.errors.unsupportedFileFormat" }),
                            );
                          }
                          if (uploadFile.size >= FILE_SIZE_LIMIT_MB * 1024 * 1024) {
                            throw new Error(
                              formatMessage(
                                { id: "containers.accountSummary.ticket.errors.fileSizeLimit" },
                                { fileSizeMB: FILE_SIZE_LIMIT_MB },
                              ),
                            );
                          }
                        },
                      }),
                    ]}
                    style={{ marginBottom: "0px", marginTop: "4px" }}
                  >
                    <Form.Input
                      value={uploadFile.name}
                      suffix={
                        <Button
                          type="text"
                          size="small"
                          icon="circle-xmark"
                          iconProps={{
                            color: "grey",
                            theme: "solid",
                          }}
                          aria-label={formatMessage({ id: "common.delete" })}
                          onClick={() => setFileStash((stash) => [...stash.filter((x) => x !== uploadFile)])}
                        />
                      }
                    />
                  </Form.Field>
                ))}
              </>
            )}

            {fileUploads.length > 0 && (
              <>
                <Text type="label" padded>
                  {formatMessage({ id: "containers.accountSummary.ticket.uploaded" })}
                </Text>

                {fileUploads.map((fileUpload) => (
                  <Form.Input key={fileUpload.id} value={fileUpload.fileName} style={{ marginTop: "4px" }} />
                ))}
              </>
            )}
          </Grid.Item>
        </fieldset>
        <Grid.Item alignSelf="flex-end" align="right" flex="1 50px">
          <Button
            type="primary"
            htmlType="submit"
            loading={status === "saving"}
            disabled={fileStash.length === 0}
            block={!isMobile}
          >
            {isProvided
              ? formatMessage({ id: "containers.accountSummary.ticket.provided" })
              : `${formatMessage({
                  id: "common.save",
                })} ${documentTypeMessage}`}
          </Button>
        </Grid.Item>
      </Grid>
    </Form>
  );
}
