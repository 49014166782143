import React from "react";
import { Divider, GreyBox, Modal } from "components";
import { getTaxFormLabel, UsUpload } from "store/actions/taxForms";
import dayjs from "dayjs";
import { useIntl } from "utils/context";
import { getLatestSubmission } from "./variables";
import { useTaxForms } from "store/hooks/taxForms";

interface Props {
  onClose(): void;
  onOk(): void;
  visible: boolean;
  formType: "W-9" | "W-8BEN" | "W-8BEN-E";
}

export default function SubmissionPopup({ visible, onClose, onOk, formType }: Props) {
  const { formatMessage } = useIntl();
  const { data: taxForms } = useTaxForms();
  const latestSubmission = getLatestSubmission(taxForms);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      onOk={onOk}
      okText={formatMessage({ id: "containers.tax.submitForm" })}
      title={formatMessage({ id: "containers.tax.submittingForm" }, { form: formType })}
    >
      <div>
        <span>{formatMessage({ id: "containers.tax.cantEdit" })}</span>
        <Divider transparent />
        {latestSubmission && (
          <GreyBox>
            <b>
              {formatMessage(
                { id: "containers.tax.previousForm" },
                {
                  form: getTaxFormLabel((latestSubmission.data as UsUpload)?.uploadKind || latestSubmission.kind),
                  date: dayjs(latestSubmission.signedAt || "").format("LL"),
                },
              )}
            </b>
          </GreyBox>
        )}
      </div>
    </Modal>
  );
}
