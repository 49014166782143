import { CountryCode } from "@trolley/common-frontend";
import * as PhoneNumber from "libphonenumber-js";
import info from "../../../locale/en/containers/info.json";
import { FormatMessage, IntlShape } from "../../context";

export function phoneNumberValidator(
  formatMessage: IntlShape["formatMessage"] | FormatMessage,
  allowedCountries?: CountryCode[],
) {
  return {
    async validator(rule: any, fullPhoneNumber: string) {
      if (fullPhoneNumber) {
        try {
          const parsed = PhoneNumber.parsePhoneNumberWithError(fullPhoneNumber);

          if (!parsed.country || !parsed.isValid()) {
            throw new Error(
              formatMessage(
                {
                  id: "containers.info.phone.valid",
                  defaultMessage: info.phone.valid,
                },
                { format: "" },
              ),
            );
          }

          if (allowedCountries?.length && !allowedCountries.includes(parsed.country as CountryCode)) {
            throw new Error(
              formatMessage({
                id: "containers.info.phone.validCountry",
                defaultMessage: info.phone.validCountry,
              }),
            );
          }
        } catch (error) {
          if (error instanceof PhoneNumber.ParseError) {
            throw new Error(
              formatMessage(
                {
                  id: "containers.info.phone.valid",
                  defaultMessage: info.phone.valid,
                },
                { format: "" },
              ),
            );
          }

          throw error;
        }
      }
    },
  };
}

export function getCountryDialCode(country: CountryCode | PhoneNumber.CountryCode | null | undefined) {
  if (country) {
    const dialCode = PhoneNumber.getCountryCallingCode(country as PhoneNumber.CountryCode);

    if (dialCode) {
      return `+${dialCode}`;
    }
  }

  return "";
}

export function parsePhoneNumber(
  value: string | null | undefined,
  defaultCountry?: PhoneNumber.CountryCode | undefined,
) {
  try {
    const parsed = PhoneNumber.parsePhoneNumber(value ?? "", defaultCountry);
    if (parsed.countryCallingCode) {
      const internationalFormat = parsed.formatInternational();
      const prefix = `+${parsed.countryCallingCode}`;
      const lineNumber = internationalFormat.substring(prefix.length).trim();
      /**
       * National phone format if you were to call within the country. It will sometimes include a "trunk prefix".
       * https://en.wikipedia.org/wiki/Trunk_prefix
       */
      const nationalFormat = parsed.formatNational();
      const possibleCountries = parsed.getPossibleCountries();

      return {
        prefix,
        country: parsed.country || possibleCountries.find((c) => c === defaultCountry) || possibleCountries[0],
        lineNumber,
        internationalFormat,
        nationalFormat,
      };
    }
  } catch {
    // unable to parse due to number being incomplete
  }

  /**
   * FALLBACK parsing
   */
  const internationalFormat = PhoneNumber.formatIncompletePhoneNumber(value ?? "", defaultCountry);
  const prefix =
    /^\+\d+/.test(value ?? "") && defaultCountry ? `+${PhoneNumber.getCountryCallingCode(defaultCountry)}` : "";
  const lineNumber = internationalFormat.substring(prefix.length).trim();

  return {
    country: defaultCountry,
    prefix,
    lineNumber,
    internationalFormat,
    nationalFormat: lineNumber,
  };
}
