import Form from "components/Form";
import { countryPhoneValidator } from "components/Form/InputPhone";
import info from "locale/en/containers/info.json";
import React from "react";
import { useIntl } from "utils/context";
import { updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function PhoneInput({ submitted }: InputPopupProps) {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();

  async function onSubmit(phone: string) {
    if (recipient) {
      await updateRecipient(recipient.id, {
        address: {
          phone,
        },
      });
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  return (
    <LockedInput
      name="phone"
      defaultValue={recipient.address.phone}
      onSave={onSubmit}
      FieldComponent={Form.InputPhone}
      label={formatMessage({ id: "containers.info.phone.title" })}
      submitted={submitted}
      rules={[
        {
          required: true,
          message: formatMessage({
            id: "containers.info.phone.required",
            defaultMessage: info.phone.required,
          }),
        },
        countryPhoneValidator(formatMessage),
      ]}
    />
  );
}
