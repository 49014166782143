import { Space } from "components";
import { Props as ContainerProps } from "components/Container";
import React, { ReactNode } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useWindowSize } from "utils/hooks";

interface Props {
  padding?: ContainerProps["padding"];
  className?: string;
  main?: ReactNode;
  secondary?: ReactNode;
  extra?: ReactNode;
}

export default function Footer({ padding = "medium", className, main, secondary, extra }: Props) {
  const styledFooter = useStyledFooter();
  const { isMobile } = useWindowSize();

  return main || secondary || extra ? (
    <Space
      size="small"
      direction={isMobile ? "column" : "row-reverse"}
      justify="space-between"
      align={isMobile ? "stretch" : "start"}
      className={styledFooter}
    >
      {secondary || !isMobile ? (
        <Space direction="row-reverse" className={classnames("footer", className)} size="small">
          {main}
          {secondary}
        </Space>
      ) : (
        <>
          {main}
          {secondary}
        </>
      )}
      {extra}
    </Space>
  ) : null;
}

const useStyledFooter = createUseStyle(({ theme }) =>
  css`
    margin-top: ${theme.paddingXL}px;
    ${theme.screenDown("md")} {
      .${theme.prefixCls}-btn {
        width: 100%;
      }
    }
  `(),
);
