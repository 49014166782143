import { CountryCode, getCountryGovIdTypes } from "@trolley/common-frontend";
import { Button, Divider, Form, Grid, Icon, Text, Tooltip } from "components";
import components from "locale/en/components.json";
import info from "locale/en/containers/info.json";
import React from "react";
import { Recipient } from "store/actions/recipient";
import { useRecipientConfig } from "store/hooks/recipientConfig";
import { createUseStyle, css } from "style/classname";
import { FormatMessage, IntlShape, useIntl } from "utils/context";
import { entriesGroupBy } from "utils/helpers";
import { useWindowSize } from "utils/hooks";

interface Props {
  value?: any;
}

type GovernmentId = {
  country: CountryCode | null;
  type: string | null | undefined;
  value: string | null | undefined;
};

type LocalGovernmentId = {
  id: string;
  country: CountryCode | undefined;
  type: string | undefined;
  value: string | undefined;
};

interface GovIdFieldsProps extends Props {
  idType?: string;
  defaultCountry: CountryCode;
  value?: Recipient["governmentIds"];
  disabled?: boolean;
  onChange?(e: any): void;
  recipient: Pick<Recipient, "type" | "address">;
}

export function govermentIdsValidator(formatMessage: IntlShape["formatMessage"] | FormatMessage) {
  return {
    async validator(rule: any, govIds: GovernmentId[]) {
      if (Array.isArray(govIds)) {
        if (govIds.some((id) => !id.country || id.type === undefined || !id.value)) {
          throw new Error(
            formatMessage({
              id: "containers.info.governmentIds.incomplete",
              defaultMessage: info.governmentIds.incomplete,
            }),
          );
        } else {
          const [countryTypeKey] =
            entriesGroupBy(govIds, (gov) => `${gov.country || ""}_${gov.type || ""}`).find(([k, v]) => v.length > 1) ||
            [];
          if (countryTypeKey) {
            throw new Error(
              formatMessage({
                id: "containers.info.governmentIds.invalidMultipleType",
                defaultMessage: info.governmentIds.invalidMultipleType,
              }),
            );
          }
        }
      }
    },
  };
}

function computeUniqueId(govId: GovernmentId, i: number) {
  return `${govId.country}_${govId.type}_${i}`;
}

function encodeGovId(govIds: GovernmentId[]): LocalGovernmentId[] {
  return govIds.map((gov, i) => ({
    id: computeUniqueId(gov, i),
    country: gov.country || undefined,
    type: gov.type === null ? "other" : gov.type,
    value: gov.value || undefined,
  }));
}

function decodeGovId(govIds: LocalGovernmentId[]): GovernmentId[] {
  return govIds.map(({ country, type, value }) => ({
    country: country || null,
    type: type === "other" ? null : type,
    value,
  }));
}

export default function GovernmentIds({
  idType,
  defaultCountry,
  value = [],
  disabled,
  onChange,
  recipient,
}: GovIdFieldsProps) {
  const { data: recipientConfig } = useRecipientConfig();
  const { isMobile } = useWindowSize();
  const { formatMessage } = useIntl();
  const styledGovInputs = useStyledGovInputs();
  const ids = encodeGovId(value);

  function onChangeGovermentId(innerIds: LocalGovernmentId[]) {
    onChange?.(decodeGovId(innerIds));
  }

  function renderId(record: LocalGovernmentId, index: number) {
    const countryGovIds = record.country ? getCountryGovIdTypes(record.country) : [];
    const selectedGovId = countryGovIds.find((g) => g.value === record.type);
    const isMasked = disabled || !!record.value?.includes("*");
    const defaultTypeOptions = [
      {
        disabled: true,
        value: "", // placeholder value. disabled option
        label: formatMessage({
          id: "components.governmentIds.type",
          defaultMessage: components.governmentIds.type,
        }),
      },
      ...countryGovIds.map((v) => ({ value: v.value, label: v.value })),
      {
        value: "passport",
        label: formatMessage({
          id: "components.governmentIds.passport",
          defaultMessage: components.governmentIds.passport,
        }),
      },
      {
        value: "other",
        label: formatMessage({
          id: "components.governmentIds.other",
          defaultMessage: components.governmentIds.other,
        }),
      } as any,
    ].filter((type) => !idType || !type.value || idType === type.value);

    const recipientCountryGovIds =
      record.country === recipient.address.country && recipientConfig?.documentTypes?.[recipient.type];

    const typeOptions = recipientCountryGovIds || defaultTypeOptions;

    return (
      <Grid wrap={false} alignItems="middle" padding={["large", "xsmall"]} key={record.id} className={styledGovInputs}>
        <Grid.Item flex={isMobile ? "62px" : "4"}>
          <Form.SelectCountry
            onChange={(e) => {
              onChangeGovermentId(
                ids.map((govId) =>
                  govId.id === record.id
                    ? {
                        ...govId,
                        country: e.target.value as CountryCode,
                        type: idType, // reset type
                      }
                    : govId,
                ),
              );
            }}
            disabled={isMasked}
            type="address"
            value={record.country}
          />
        </Grid.Item>
        {!idType && (
          <Grid.Item flex="1 100px">
            <Form.Select
              disabled={isMasked || !record.country}
              onChange={(e) => {
                onChangeGovermentId(
                  ids.map((govId) => (govId.id === record.id ? { ...govId, type: e.target.value } : govId)),
                );
              }}
              value={record.type as any}
              options={typeOptions}
            />
          </Grid.Item>
        )}
        <Grid.Item flex="6">
          <Form.Input
            onChange={(e) => {
              const value = String(e.target.value ?? "").replace(/[^-\w\s.&/]/gi, "");
              e.target.value = value;
              onChangeGovermentId(ids.map((gov) => (gov.id === record.id ? { ...gov, value } : gov)));
            }}
            value={record.value}
            disabled={isMasked || !record.type || !record.country}
            placeholder={
              selectedGovId?.label ||
              formatMessage({
                id: "components.governmentIds.value",
                defaultMessage: components.governmentIds.value,
              })
            }
            prefix={
              selectedGovId && (selectedGovId.tooltip || record.value) ? (
                <Icon
                  type="question-circle"
                  size="small"
                  tooltip={
                    <>
                      {selectedGovId.label}
                      {selectedGovId.tooltip && (
                        <Text type="secondary" size="small">
                          {selectedGovId.tooltip}
                        </Text>
                      )}
                    </>
                  }
                />
              ) : (
                <span />
              )
            }
          />
        </Grid.Item>
        {!disabled && (
          <Grid.Item>
            <Tooltip title={formatMessage({ id: "common.delete" })}>
              <Button
                type="text"
                icon="trash"
                size="middle"
                iconProps={{ theme: "solid" }}
                aria-label={formatMessage({ id: "common.delete" })}
                onClick={() => {
                  onChangeGovermentId(ids.filter((govId) => govId.id !== record.id));
                }}
              />
            </Tooltip>
          </Grid.Item>
        )}
      </Grid>
    );
  }

  function onAddNewId() {
    onChange?.([...value, { country: defaultCountry, type: idType, value: undefined }]);
  }

  const visibleIds = ids.filter((id) => !idType || id.type === idType);

  return (
    <>
      {visibleIds.map(renderId)}
      {visibleIds.length > 0 && <Divider transparent size="small" />}
      {!disabled &&
        (visibleIds.length === 0 ? (
          <Button onClick={onAddNewId} icon="plus" size="middle" block>
            {formatMessage({
              id: "components.governmentIds.addID",
              defaultMessage: components.governmentIds.addID,
            })}
          </Button>
        ) : (
          <Button
            type="link"
            icon="plus"
            onClick={onAddNewId}
            style={{ padding: "4px 0", display: !!idType ? "none" : "block" }}
          >
            {formatMessage({
              id: "components.governmentIds.addAnotherID",
              defaultMessage: components.governmentIds.addAnotherID,
            })}
          </Button>
        ))}
    </>
  );
}

const useStyledGovInputs = createUseStyle(({ theme }) =>
  css`
    ${theme.screenUp("md")} {
      .icon.fa-times-circle {
        opacity: 0;
        transition: opacity 0.175s ease;
      }
      &:hover {
        .icon.fa-times-circle {
          opacity: 1;
        }
      }
    }
  `(),
);
