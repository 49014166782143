import { Popover as AntPopover, PopoverProps } from "antd";
import Button, { ButtonProps } from "components/Button";
import Divider from "components/Divider";
import Space from "components/Space";
import React, { ReactNode, useState } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";

interface Props extends Omit<PopoverProps, "open" | "onOpenChange" | "trigger" | "popupVisible" | "ref" | "content"> {
  description?: ReactNode;
  okText?: string;
  loading?: boolean;
  okButtonProps?: Omit<ButtonProps, "children" | "onClick" | "ref">;
  onConfirm: ButtonProps["onClick"];
  cancelText?: string;
  cancelButtonProps?: Omit<ButtonProps, "children">;
}

export default function Popconfirm(props: Props) {
  const { formatMessage } = useIntl();
  const styledPop = useStyledPop();
  const {
    description,
    loading,
    okText = formatMessage({ id: "common.yes" }),
    okButtonProps: customOkButtonProps,
    onConfirm,
    cancelText = formatMessage({ id: "common.no" }),
    cancelButtonProps: customCancelButtonProps,
    overlayClassName,
    children,
    ...rest
  } = props;
  const okButtonProps: ButtonProps = {
    size: "small",
    type: "primary",
    ...customOkButtonProps,
    style: { minWidth: "80px", ...customOkButtonProps?.style },
    children: okText,
  };
  const cancelButtonProps: ButtonProps = {
    size: "small",
    children: cancelText,
    ...customCancelButtonProps,
    style: { minWidth: "60px", ...customCancelButtonProps?.style },
  };
  const [open, setOpen] = useState(false);
  // const ref = useRef<any>();

  return (
    <AntPopover
      open={open}
      trigger={["click"]}
      onOpenChange={setOpen}
      overlayClassName={classnames(styledPop, overlayClassName)}
      // getPopupContainer={() => ref.current}
      {...rest}
      content={
        <>
          {description}
          <Divider transparent />
          <Space size="small" direction="row-reverse">
            <Button {...okButtonProps} aria-label={okText} onClick={onConfirm} autoFocus loading={loading} />
            <Button
              {...cancelButtonProps}
              aria-label={cancelText}
              onClick={() => {
                setOpen(false);
              }}
            />
          </Space>
        </>
      }
    >
      {children}
      {/* <span ref={ref} /> */}
    </AntPopover>
  );
}

const useStyledPop = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-popover {
      width: 300px;
      max-width: 94vw;
      z-index: ${(theme.zIndexPopupBase || 10000) + 75};
      .${theme.prefixCls}-popover-inner {
        padding: ${theme.paddingLG}px;
        .${theme.prefixCls}-popover-title {
          font-size: ${theme.fontSizeXL}px;
        }
      }
    }
  `(),
);
