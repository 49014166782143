/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from "react";
import { resetNotification } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";

import { App } from "antd";

const DURATION = 3;

export default function Notifier() {
  const notifications = useShallowSelector((state) => state.notifications);
  const { message } = App.useApp();

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((n) => {
        switch (n.type) {
          case "warning":
            message.warning?.(n.message, DURATION);
            break;
          case "error":
            message.error?.(n.message, DURATION);
            break;
          case "info":
            message.info?.(n.message, DURATION);
            break;
          default:
            message.success?.(n.message, DURATION);
        }
      });

      resetNotification();
    }
  }, [notifications]);

  return null;
}
