import DisabledContext from "antd/lib/config-provider/DisabledContext";
import dayjs from "dayjs";
import { Rule } from "rc-field-form/lib/interface";
import React, { useContext } from "react";

import { Form2 as Form, Grid } from "components";
import { FormatMessage, IntlShape, useIntl } from "utils/context";

interface Props {
  value?: string;
  disabled?: boolean;
  onChange?(e: any): void;
  displayInputMonth?: boolean;
  monthWidth?: React.CSSProperties["width"];
}

export function isPastDateValidator(formatMessage?: IntlShape["formatMessage"]): Rule {
  return {
    async validator(rule: any, value: any) {
      const date = dayjs(value, "YYYY-MM-DD", true); // strict validation required
      if (value) {
        if (!date.isValid() || new Date(value).toString() === "Invalid Date") {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.validDate" }) || "Select a valid date");
        }
        if (!date.isBefore(dayjs(), "day")) {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.earlierDate" }) || "Select an earlier date");
        }
      }
    },
  };
}

export function isLegalAgeDateValidator(formatMessage?: IntlShape["formatMessage"] | FormatMessage): Rule {
  return {
    async validator(_: any, value: any) {
      const date = dayjs(value, "YYYY-MM-DD", true); // strict validation required
      if (value) {
        if (!date.isValid() || new Date(value).toString() === "Invalid Date") {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.validDate" }) || "Select a valid date");
        }
        if (dayjs().diff(date, "years") < 10) {
          throw new Error(
            formatMessage?.({
              id: "components.formSelectDate.date10YearsAgo",
            }) || "Select an earlier date",
          );
        }
      }
    },
  };
}

export default function DatePicker(props: Props) {
  const { disabled: customDisabled, value, onChange, displayInputMonth, monthWidth } = props;
  const { label: itemLabel } = Form.Item.useFormItemInstance();
  const contextDisabled = useContext(DisabledContext);
  const disabled = contextDisabled ?? customDisabled;
  const { formatMessage } = useIntl();
  const [, year, month, date] = String(value || "").match(/^(\d*)-(\d{0,2})-(\d{0,2})/) || [value, "", "", ""];

  const inputMonth = (
    <Form.Input
      type="text"
      inputMode="numeric"
      value={month}
      onChange={(e) => {
        const newMonth = String(e.target.value || "")
          .replace(/[^0-9]/g, "")
          .slice(0, 2);

        onChange?.(year || newMonth || date ? [year, newMonth, date].join("-") : "");
      }}
      onBlur={() => {
        const newMonth = String(parseInt(month.replace(/[^0-9]/g, ""), 10)).padStart(2, "0");
        onChange?.(year || newMonth || date ? [year, newMonth, date].join("-") : "");
      }}
      disabled={disabled}
      placeholder="MM"
      aria-label={formatMessage({ id: "components.formSelectDate.months.select" })}
    />
  );

  const selectMonth = (
    <Form.Select
      value={month || undefined} // necessary to display placeholder, when value is empty or null, antd doesn't display the placeholder
      onChange={(newMonth) => {
        onChange?.(year || newMonth || date ? [year, newMonth, date].join("-") : "");
      }}
      aria-label={formatMessage({ id: "components.formSelectDate.months.select" })}
      disabled={disabled}
      placeholder={
        formatMessage({
          id: "components.formSelectDate.months.select",
          defaultMessage: "MM",
        }) as string
      }
      style={{ width: monthWidth ?? 180 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => ((option?.label as string) ?? "").toLowerCase().includes(input.toLowerCase())}
      options={[
        {
          value: "01",
          label: formatMessage({
            id: "components.formSelectDate.months.january",
          }),
        },
        {
          value: "02",
          label: formatMessage({
            id: "components.formSelectDate.months.february",
          }),
        },
        {
          value: "03",
          label: formatMessage({
            id: "components.formSelectDate.months.march",
          }),
        },
        {
          value: "04",
          label: formatMessage({
            id: "components.formSelectDate.months.april",
          }),
        },
        {
          value: "05",
          label: formatMessage({
            id: "components.formSelectDate.months.may",
          }),
        },
        {
          value: "06",
          label: formatMessage({
            id: "components.formSelectDate.months.june",
          }),
        },
        {
          value: "07",
          label: formatMessage({
            id: "components.formSelectDate.months.july",
          }),
        },
        {
          value: "08",
          label: formatMessage({
            id: "components.formSelectDate.months.august",
          }),
        },
        {
          value: "09",
          label: formatMessage({
            id: "components.formSelectDate.months.september",
          }),
        },
        {
          value: "10",
          label: formatMessage({
            id: "components.formSelectDate.months.october",
          }),
        },
        {
          value: "11",
          label: formatMessage({
            id: "components.formSelectDate.months.november",
          }),
        },
        {
          value: "12",
          label: formatMessage({
            id: "components.formSelectDate.months.december",
          }),
        },
      ]}
    />
  );

  const content = (
    <Grid padding="small" wrap={false} style={{ width: "100%" }}>
      <Grid.Item flex="100px">
        <Form.Input
          type="text"
          inputMode="numeric"
          value={year || ""}
          onChange={(e) => {
            const newYear = String(e.target.value || "")
              .replace(/[^0-9]/g, "")
              .slice(0, 4);

            onChange?.(newYear || month || date ? [newYear, month, date].join("-") : "");
          }}
          placeholder="YYYY"
          aria-label={formatMessage({ id: "components.formSelectDate.year" })}
          disabled={disabled}
        />
      </Grid.Item>
      <Grid.Item flex="70px">{!!displayInputMonth ? inputMonth : selectMonth}</Grid.Item>
      <Grid.Item flex="70px">
        <Form.Input
          type="text"
          inputMode="numeric"
          value={date || ""}
          onChange={(e) => {
            const newDay = String(e.target.value || "")
              .replace(/[^0-9]/g, "")
              .slice(0, 2);

            onChange?.(year || month || newDay ? [year, month, newDay].join("-") : "");
          }}
          onBlur={() => {
            const newDay = date ? String(parseInt(date.replace(/[^0-9]/g, ""), 10)).padStart(2, "0") : "";
            onChange?.(year || month || newDay ? [year, month, newDay].join("-") : "");
          }}
          disabled={disabled}
          placeholder="DD"
          aria-label={formatMessage({ id: "components.formSelectDate.daySelect" })}
        />
      </Grid.Item>
    </Grid>
  );

  return itemLabel ? (
    <fieldset role="group">
      <legend className="sr-only">{itemLabel}</legend>
      {content}
    </fieldset>
  ) : (
    content
  );
}
