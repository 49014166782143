import { PayoutMethodType } from "@trolley/common-frontend";
import React from "react";

import { Form } from "components";
import { govermentIdsValidator } from "components/Form/GovernmentIds";
import info from "locale/en/containers/info.json";
import { Recipient, updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { useRecipientConfig } from "store/hooks/recipientConfig";
import { useIntl } from "utils/context";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

interface Props extends InputPopupProps {
  value: Recipient["governmentIds"];
  label?: string;
  idType?: string;
}

export default function GovernmentIDInput(props: Props) {
  const { submitted, ...rest } = props;
  const { formatMessage } = useIntl();
  const recipient = useRecipient();
  const { data: recipientConfig } = useRecipientConfig();

  if (!recipient) {
    return null;
  }

  async function onSubmit(governmentIds: Recipient["governmentIds"]) {
    if (recipient) {
      await updateRecipient(recipient.id, { governmentIds });

      loadTicketsWithDelay(recipient.id, true);
    }
  }

  const country =
    recipient?.accounts.find((a) => a.primary && a.type === PayoutMethodType.BANKTRANSFER)?.country ||
    recipient?.address.country;
  const visibleIds = recipient.governmentIds.filter((govId) => !props.idType || govId.type === props.idType);

  return (
    <LockedInput
      name="governmentIds"
      defaultValue={recipient.governmentIds}
      forceDefaultOnMount
      onSave={onSubmit}
      FieldComponent={Form.GovernmentIds}
      fieldComponentProps={{
        ...rest,
        defaultCountry: country,
        recipient,
        recipientConfig,
      }}
      rules={[
        {
          async validator(_, value: Recipient["governmentIds"]) {
            if (!props.idType) {
              if (!value?.length) {
                throw new Error(
                  formatMessage({
                    id: "containers.info.governmentIds.required",
                    defaultMessage: info.governmentIds.required,
                  }),
                );
              }
            } else if (!value?.some((id) => id.type === props.idType)) {
              // IF idType is set, gov IDS should have at least 1 fo that type
              throw new Error(
                formatMessage({
                  id: "containers.info.governmentIds.required",
                  defaultMessage: info.governmentIds.required,
                }),
              );
            }
          },
        },
        govermentIdsValidator(formatMessage),
      ]}
      label={
        props.label ||
        formatMessage({
          id: "containers.info.governmentIds.title",
          defaultMessage: info.governmentIds.title,
        })
      }
      submitted={visibleIds.length > 0 ? submitted : undefined}
    />
  );
}
