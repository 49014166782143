import BigNumber from "bignumber.js";
import Icon from "components/Icon";
import React from "react";
import { useTheme } from "style/classname";

interface Props {
  order: number;
  completed: boolean;
  size?: number;

  /**
   * Percent Completion
   * null = not in progress, it should show either the order or completed
   */
  percent: number | null;
}

export default function StepperIcon(props: Props) {
  const { completed, order, size = 32, percent = 0 } = props;
  const center = size / 2;
  const pieRadius = (size / 2) * 0.4;
  const diameter = new BigNumber(pieRadius).times(2).times(Math.PI);
  const theme = useTheme();
  const sizePx = `${size}px`;

  if (typeof percent === "number") {
    const percentage = Math.min(Math.max(0, percent), 1); // make sure it's between 0 and 1

    return (
      <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`} style={{ display: "block" }}>
        <defs>
          <clipPath id="insideCircleOnly">
            <circle cx={center} cy={center} r={pieRadius} />
          </clipPath>
        </defs>
        <circle r={size / 2} cx={center} cy={center} fill={theme.colorPrimary} />
        <circle
          r={(size / 2) * 0.6}
          cx={center}
          cy={center}
          fill="transparent"
          id="ring"
          stroke="white"
          strokeWidth={size / 16}
        />
        <circle
          clipPath="url(#insideCircleOnly)"
          r={pieRadius}
          cx={center}
          cy={center}
          fill="transparent"
          stroke="white"
          strokeWidth={pieRadius * 2} // because stokes are filled on inner and outer
          strokeDasharray={`
          ${diameter.times(percentage).toNumber()}
          ${diameter.times(1 - percentage).toNumber()}
        `} // diameter
          transform={`rotate(-90) translate(-${size})`}
        />
      </svg>
    );
  }

  if (completed) {
    return <Icon type="check-circle" theme="solid" color="success" fixedWidth={false} style={{ fontSize: sizePx }} />;
  }

  return (
    <div
      style={{
        height: sizePx,
        width: sizePx,
        borderRadius: "50%",
        border: `2px solid ${theme.colorBorder}`,
        lineHeight: 1.8,
        fontSize: `${theme.fontSizeLG}px`,
        color: theme.colorTextSecondary,
        textAlign: "center",
      }}
    >
      {order}
    </div>
  );
}
