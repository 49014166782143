import React, { useEffect, useMemo, useState } from "react";

import { Button, Loader, LinkButton } from "components";
import { PATHS } from "pages/App/routes";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { PRODUCT_MODULES, useIntl } from "utils/context";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import {
  asyncLoadIdvSessionUrl,
  completeIdVerification,
  startIdVerification,
  cancelIdVerification,
} from "store/actions/idVerification";
import { KybStatus, RecipientType, VerificationStatus, WidgetMerchant } from "@trolley/common-frontend";
import { emitEvent, WidgetEvent } from "utils/helpers";
import { Recipient } from "store/actions/recipient";
import { useWindowSize } from "utils/hooks";

interface Props {
  reVerification?: boolean;
  placement?: "right" | "center" | "left";
  linkType?: boolean;
}

export function isVerificationInProgress(merchant: WidgetMerchant | null, recipient?: Recipient) {
  if (recipient?.type === RecipientType.BUSINESS) {
    if (merchant?.trust?.requireDSA) {
      return recipient.kybStatus === KybStatus.PENDING;
    }

    return recipient.idvStatus === VerificationStatus.PENDING;
  }

  if (recipient?.type === RecipientType.INDIVIDUAL) {
    return recipient?.idvStatus === VerificationStatus.PENDING;
  }

  return false;
}

function getButtonText(verificationInProgress: boolean, reVerification: boolean) {
  if (verificationInProgress) {
    return "containers.trust.continueVerification";
  }

  if (reVerification) {
    return "containers.trust.retakeVerification";
  }

  return "containers.accountSummary.buttons.completeVerification";
}

const oneMobileDvh = 5.96;
const veriffModalHeight = 722;

export default function IdVerificationButton({ reVerification, placement, linkType }: Props) {
  const [isVeriffFrameOpen, setIsVeriffFrameOpen] = useState(false);
  const [veriffFrameHeight, setVeriffFrameHeight] = useState(0);
  const recipient = useRecipient();
  const merchant = useMerchant();

  const verificationInProgress = useMemo(() => isVerificationInProgress(merchant, recipient), [merchant, recipient]);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isVeriffFrameOpen) {
      const { isMobile, supportsHover } = windowSize;
      const getVeriffHeight = () => {
        if (!supportsHover) {
          // check if it's a touch mobile device
          return oneMobileDvh * 100;
        }
        if (isMobile) {
          // only checks mobile width < 768px
          return window.innerHeight;
        }

        return veriffModalHeight;
      };
      const veriffHeight = getVeriffHeight();
      if (veriffHeight !== veriffFrameHeight) {
        const height = veriffHeight + (veriffHeight !== window.innerHeight ? 40 : 0);
        // emitEvent
        emitEvent({
          event: WidgetEvent.DOCUMENT_HEIGHT,
          document: {
            height,
          },
          data: height,
        });
        setVeriffFrameHeight(veriffHeight);
      }
    }
    if (!isVeriffFrameOpen && veriffFrameHeight > 0) {
      setVeriffFrameHeight(0);
    }
  }, [windowSize, isVeriffFrameOpen]);

  const showKyb =
    !!merchant?.features?.dsa && merchant?.trust?.requireDSA && recipient?.type === RecipientType.BUSINESS;
  const profileIncompleteForKYB =
    showKyb &&
    (!recipient.brn ||
      !recipient.name ||
      !recipient.address.region ||
      !recipient.address.street1 ||
      !recipient.address.country);

  const { formatMessage } = useIntl();
  if (!recipient) {
    return <Loader />;
  }

  function onEvent(msg: MESSAGES) {
    switch (msg) {
      case MESSAGES.CANCELED:
        cancelIdVerification();
        setIsVeriffFrameOpen(false);
        break;
      case MESSAGES.STARTED:
        startIdVerification();
        break;
      case MESSAGES.FINISHED:
        completeIdVerification();
        setIsVeriffFrameOpen(false);
        break;
    }
  }

  async function onClick() {
    if (recipient) {
      const data = await asyncLoadIdvSessionUrl();
      if (!data) {
        return;
      }

      createVeriffFrame({
        url: data,
        lang: recipient.language,
        onEvent: onEvent,
      });

      setIsVeriffFrameOpen(true);

      // Dispatch event when iframe is loaded
      emitEvent({
        event: WidgetEvent.MODULE_LOADED,
        module: [PRODUCT_MODULES.TRUST],
      });
    }
  }

  if (linkType) {
    return showKyb ? (
      <LinkButton type="link" to={PATHS.KYB_UPLOAD}>
        {formatMessage({
          id: getButtonText(verificationInProgress, reVerification ?? false),
        })}
      </LinkButton>
    ) : (
      <Button type="link" onClick={onClick}>
        {formatMessage({
          id: getButtonText(verificationInProgress, reVerification ?? false),
        })}
      </Button>
    );
  }

  return showKyb ? (
    <LinkButton
      type="primary"
      icon={reVerification ? "plus" : "arrow-up-right-from-square"}
      size="large"
      disabled={profileIncompleteForKYB}
      to={PATHS.KYB_UPLOAD}
      placement={placement}
    >
      {formatMessage({
        id: getButtonText(verificationInProgress, reVerification ?? false),
      })}
    </LinkButton>
  ) : (
    <Button
      type="primary"
      icon={reVerification ? "plus" : "arrow-up-right-from-square"}
      size="large"
      onClick={onClick}
      placement={placement}
    >
      {formatMessage({
        id: getButtonText(verificationInProgress, reVerification ?? false),
      })}
    </Button>
  );
}
