import React from "react";
import { CurrencyCode } from "@trolley/common-frontend";
import { useIntl } from "utils/context";
import BigNumber from "bignumber.js";

type Props = {
  value: BigNumber | string | number | null;
  currency: CurrencyCode | null | undefined;
  showCurrency?: boolean;
  emptyText?: string;
};

export default function CurrencyDisplay({ value, currency, showCurrency = true, emptyText = " - " }: Props) {
  const intl = useIntl();
  const number = Number(value);
  if (Number.isNaN(number)) {
    return <span>{emptyText}</span>;
  }

  const renderedCurrency = showCurrency && currency && (
    <span style={{ fontSize: "0.86em" }}> {String(currency).toLocaleUpperCase()}</span>
  );
  try {
    const currencyStr = intl.formatNumber(number, {
      style: "currency",
      currency: currency || window.navigator.language,
      currencyDisplay: "code",
    });

    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {currencyStr
          .replace(/[a-z]{3}\s?/gi, "") // remove currency code
          .trim()}
        {renderedCurrency}
      </span>
    );
  } catch (err) {
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {value}
        {renderedCurrency}
      </span>
    );
  }
}
