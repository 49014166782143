import { GET } from "services/request";

import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";

import { isLoaded } from "./actionUtils";
import { BaseError, RecipientLite } from "@trolley/common-frontend";

export interface RecipientConfigQuery {
  id: string; // recipient GUID
}

type DocumentTypes = { value: string; label: string };
type JsonSchema = { required: boolean };

export type RecipientConfig = {
  schema?: Partial<Record<keyof RecipientLite, JsonSchema>>;
  documentTypes?: { individual: DocumentTypes[]; business: DocumentTypes[] };
};

export function loadRecipientConfig(id: string, force?: boolean) {
  const { recipientConfig } = store.getState();

  if (force || !isLoaded(recipientConfig.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipientConfig", { id });

    GET<RecipientConfig>(`/v1/recipients/${id}/config`)
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "recipientConfig", {
          id,
          data: body,
        });
      })
      .catch((errors) => {
        if (errors?.find((err: BaseError) => err.code === "not_found")) {
          // when we get a 404, it's an expected error. therefore we should save the value as null. the fetchStatus will therefore be set to LOADED
          standardDispatch(OpCode.DATA, "recipientConfig", {
            id,
            data: null,
          });
        } else {
          standardDispatch(OpCode.ERROR, "recipientConfig", { id, errors });
        }
      });
  }

  return id;
}
