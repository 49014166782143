import { TaxDeliveryType } from "@trolley/common-frontend";
import { Button, Container, Divider, Form, Grid, Icon, Paragraph, Title } from "components";
import { useForm } from "components/Form";
import dayjs from "dayjs";
import { PATHS } from "pages/App/routes";
import SignatureBox from "pages/TaxSection/SignatureBox";
import TaxFooter from "pages/TaxSection/TaxFooter";
import { getCertificationTrueRequired, getIrsRule, getLatestSubmission } from "pages/TaxSection/variables";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { notifyError } from "store/actions/notifications";
import { TaxDataW8BENE, signAndSubmitTaxForm } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForms } from "store/hooks/taxForms";
import { useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";
import SubmissionPopup from "../SubmissionPopup";

interface Props {
  taxId: string;
  taxData: TaxDataW8BENE;
  loading?: boolean;
  pageIndex: number;
  pageTotal: number;
}

export default function Certification(props: Props) {
  const [form] = useForm();
  const history = useHistory();
  const { taxId, taxData, loading, pageIndex, pageTotal } = props;
  const recipient = useRecipient();
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const { isMobile } = useWindowSize();
  const { data: taxForms } = useTaxForms();
  const { formatMessage } = useIntl();
  const latestSubmission = getLatestSubmission(taxForms);
  const entity = <strong>{taxData.organizationName}</strong>;
  const certificationTrueRequired = getCertificationTrueRequired(formatMessage);

  return (
    <Form
      form={form}
      initialValues={{ ...taxData, certificationDate: dayjs().format("LL") }}
      onFinish={() => {
        setShowConfirmSubmit(true);
      }}
    >
      <Container>
        <Helmet>
          <title>{formatMessage({ id: "containers.tax.certification" })}</title>
        </Helmet>
        <Title>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN-E" })}</Title>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({ id: "containers.tax.certification" }),
              step: pageIndex + 1,
              totalSteps: pageTotal,
            },
          )}
        </Title>
        <Paragraph>{formatMessage({ id: "containers.tax.w8.underPenalties" })}</Paragraph>

        <Form.Field name="isOwnerOfIncome" rules={[certificationTrueRequired]} showEmptyError={false}>
          <Form.Checkbox name="isOwnerOfIncome">
            {formatMessage({ id: "containers.tax.w8.isOwnerOfIncomeE" }, { entity })}
          </Form.Checkbox>
        </Form.Field>

        <Form.Field name="isNotUsEntity" rules={[certificationTrueRequired]} showEmptyError={false}>
          <Form.Checkbox name="isNotUsEntity">
            {formatMessage({ id: "containers.tax.w8.isNotUsEntityE" }, { entity })}
          </Form.Checkbox>
        </Form.Field>

        <Form.Field name="isNotUsConnected" rules={[certificationTrueRequired]} showEmptyError={false}>
          <Form.Checkbox name="isNotUsConnected">
            {formatMessage({ id: "containers.tax.w8.isNotUsConnected.income" })}
            <ol type="a">
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsConnected.notConnected",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsConnected.connected",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsConnected.partnerShare",
                })}
              </li>
            </ol>
          </Form.Checkbox>
        </Form.Field>

        <Form.Field name="isExemptForeignPerson" rules={[certificationTrueRequired]} showEmptyError={false}>
          <Form.Checkbox name="isExemptForeignPerson">
            {formatMessage(
              { id: "containers.tax.w8.isExemptForeignPerson" },
              {
                a: (c) => (
                  <Button
                    type="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.irs.gov/instructions/iw8bene"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {c}
                    <Icon type="external-link" right size="small" />
                  </Button>
                ),
              },
            )}
          </Form.Checkbox>
        </Form.Field>

        <Divider transparent size="large" />
        <p>{formatMessage({ id: "containers.tax.w8.furthermoreE" })}</p>
        <p>
          <strong>{formatMessage({ id: "containers.tax.w8.agreeToSubmit" })}</strong>
        </p>
        <p>{formatMessage({ id: "containers.tax.w8.consentNotRequired" })}</p>
        <p>{formatMessage({ id: "containers.tax.formConfirmation" }, { name: "", form: "W-8BEN-E" })}</p>
      </Container>
      <Divider transparent />
      <SignatureBox>
        <Grid>
          <Grid.Item xs={24} sm={16}>
            <Form.Field
              name="certificationName"
              label={formatMessage({
                id: "containers.tax.signatureWithFullName",
              })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.tax.signatureRequired",
                  }),
                },
                getIrsRule(formatMessage),
              ]}
            >
              <Form.Input name="certificationName" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={8}>
            <Form.Field name="certificationDate" label="Date">
              <Form.Input name="certificationDate" readOnly />
            </Form.Field>
          </Grid.Item>
        </Grid>
        <Form.Field name="hasCapacityToSign" rules={[certificationTrueRequired]}>
          <Form.Checkbox name="hasCapacityToSign">
            {formatMessage({ id: "containers.tax.w8.certifyCapacity" }, { entity })}
          </Form.Checkbox>
        </Form.Field>
      </SignatureBox>

      <Container>
        <TaxFooter loading={loading} okText={`Complete ${!isMobile ? "W-8BEN-E" : ""}`} />
      </Container>

      <SubmissionPopup
        visible={showConfirmSubmit}
        onClose={() => {
          setShowConfirmSubmit(false);
        }}
        // confirmLoading={loading}
        formType="W-8BEN-E"
        onOk={async () => {
          try {
            await signAndSubmitTaxForm(taxId, form.getFieldValue("certificationName"));
            const isFirstForm = !latestSubmission;

            if (isFirstForm || recipient?.taxDeliveryType === TaxDeliveryType.MAIL) {
              history.push(PATHS.US_TAX_DELIVERY);
            } else {
              history.push(PATHS.US_TAX_COMPLETE);
            }
          } catch (errors) {
            notifyError("Failed to submit form", { errors });
          }
        }}
      />
    </Form>
  );
}
