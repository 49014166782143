import { AliasToken } from "antd/lib/theme/internal";
import { useNewStyle } from "pages/App";
import React, { useMemo } from "react";
import { useIframeConfig } from "store/hooks/config";
import { useUrlParams } from "store/hooks/params";
import { ThemeProvider, useClientIntegration } from "utils/context";
import { defaultConfig } from "utils/context/ThemeProvider";
import { omitEmpty } from "utils/helpers";
import { useWindowSize } from "utils/hooks";

const grayColor = "#404d61";

export default function OnboardingTheme({ children }: { children?: React.ReactNode }) {
  const client = useClientIntegration();
  const params = useUrlParams();
  const iframeConfig = useIframeConfig();
  const newStyle = useNewStyle();
  const { isMobile } = useWindowSize();

  const clientConfig = useMemo(() => {
    return client === "widget"
      ? {
          colors: {
            ...defaultConfig.colors,
            ...omitEmpty(iframeConfig.colors || {}), // only widge takes config colors. portal takes default colors
            ...omitEmpty(params.colors || {}), // widget must accept params colors
          },
          style: {
            ...defaultConfig.style,
            ...omitEmpty(iframeConfig.style || {}), // only widge takes config colors. portal takes default colors
            ...omitEmpty(params.style || {}), // widget must accept params colors
          },
        }
      : {
          colors: {
            ...defaultConfig.colors,
            primary: "#2A67EA",
            text: "#0B0C0C",
            subtext: grayColor,
            background: "#FFFFFF",
            error: "#D4351C",
            success: "#00703C",
            warning: "#FFDD00",
            border: grayColor,
            // brandColor: "#2A67EA",
            // brandColor: iframeConfig.colors?.brandColor || colors.blue, // portal only needs brand color: ;
            // border: "#DDDDDD", // overwrite default border color because it's too dark
            // inputBorder: "#DDDDDD", // overwrite default input border color because it's too dark
            // subText: "#919EAB",
            // background: "#FFFFFF",
          },
          style: defaultConfig.style,
        };
  }, [iframeConfig, params, client, newStyle]);

  const desktopTypography: Partial<AliasToken> = {
    fontSize: 18,
    lineHeight: (18 + 8) / 18,
    fontSizeHeading1: 34,
    lineHeightHeading1: (34 + 8) / 34,
    fontSizeHeading2: 24,
    lineHeightHeading2: (24 + 8) / 24,
    fontSizeLG: 24,
    lineHeightLG: (24 + 8) / 24,
  };

  const mobileTypography: Partial<AliasToken> = {
    fontSize: 16,
    lineHeight: (16 + 8) / 16,
    fontSizeHeading1: 24,
    lineHeightHeading1: (24 + 8) / 24,
    fontSizeHeading2: 18,
    lineHeightHeading2: (18 + 8) / 18,
    fontSizeHeading3: 16,
    lineHeightHeading3: (16 + 8) / 16,
    fontSizeXL: 24,
    fontSizeLG: 24,
    lineHeightLG: (24 + 8) / 24,
    fontSizeSM: 12,
    lineHeightSM: (12 + 8) / 12,
  };

  const typography: Partial<AliasToken> = {
    ...mobileTypography,
    ...(!isMobile ? desktopTypography : {}),
    ...(client === "portal" ? { colorTextHeading: grayColor } : {}),
  };

  return (
    <ThemeProvider
      prefixCls="recipient-wizard"
      iframeConfig={clientConfig}
      token={{
        ...typography,
        controlHeight: 40,
      }}
      components={(token) => ({
        Button: { fontSize: 16, borderRadius: 8, controlOutline: "transparent", controlTmpOutline: "transparent" },
        Card: {
          colorTextHeading: token.colorText,
        },
        Checkbox: {
          marginXS: 16, // margin between groups of radio
          paddingXS: 8, // space between radio and text
        },
        Form: {
          fontSize: 16, // label size
          lineHeight: (16 + 8) / 16,
        },
        Layout: {
          headerHeight: 64,
          colorBgHeader: token.colorBgContainer,
          controlHeightLG: 12.8, // controls the hoz padding. layoutHeaderPaddingInline
        },
        Input: {
          controlHeight: 50,
        },
        Radio: {
          fontSizeLG: 28, // used for radioSize
          padding: 24, // mainly for RadioGroup optionType: "card"
          fontSize: 24, // overwrite component Form.fontSize (16)
        },
        Select: {
          controlHeight: 50,
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
}
