import { isIrsValidChars, isLatin, TaxStatus } from "@trolley/common-frontend";
import { Translator } from "utils/context";
import { IntlShape } from "react-intl";
import { TaxForm } from "store/actions/taxForms";
import { ListState } from "store/reducers/standardReducer";

export function getIrsRule(translator: Translator) {
  return {
    pattern: isIrsValidChars,
    message: translator({ id: "containers.tax.isIrsMessage" }),
  };
}
export const ssnPattern = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/;
export const einPattern = /^[0-9]{2}-?[0-9]{7}$/;
export function getLatinRule(translator: IntlShape["formatMessage"]) {
  return {
    pattern: isLatin,
    message: translator({ id: "containers.tax.isLatinMessage" }),
  };
}
export function getLatinRuleIgnoreStar(translator: Translator) {
  return {
    async validator(_: any, value: string) {
      if (value && !value.includes("*") && !isLatin.test(value)) {
        throw new Error(getLatinRule(translator).message);
      }
    },
  };
}

export function getCertificationValueRequired(translator: Translator) {
  return {
    async validator(_: any, value: string | boolean) {
      if (value === undefined) {
        throw new Error(translator({ id: "containers.tax.certificationRequired" }));
      }
    },
  };
}

export function getCertificationTrueRequired(translator: Translator) {
  return {
    async validator(_: any, value: string | boolean) {
      if (value !== true) {
        throw new Error(translator({ id: "containers.tax.certificationRequired" }));
      }
    },
  };
}

export function getValidateIncomeTaxSignature(name: string, translator: Translator) {
  return {
    async validator(_: any, value: string) {
      if (
        String(value).replace(/\s/g, "").toLocaleLowerCase() !==
        String(name || "")
          .replace(/\s/g, "")
          .toLocaleLowerCase()
      ) {
        throw new Error(translator({ id: "containers.tax.nameMusMatchIncomeTax" }, { name }));
      }
    },
  };
}

export function getLatestSubmission(taxForms: ListState<TaxForm>) {
  return taxForms.records.find((form) => [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(form.status as TaxStatus));
}
