import React from "react";
import { Button, Space } from "components";
import Item from "./Item";

interface Props {
  disabled?: boolean;
  text?: string;
  suffix?: React.ReactNode;
}

export default function SubmitButton({ disabled, text, suffix }: Props) {
  return (
    <Item>
      <Button type="primary" block htmlType="submit" disabled={disabled}>
        <Space>
          {text ?? "Submit"}
          {suffix}
        </Space>
      </Button>
    </Item>
  );
}
