import React, { ReactNode } from "react";

import { Tooltip } from "components";
import { createUseStyle, css } from "style/classname";

export default function Highlight({ tooltip, children }: { tooltip: string; children: ReactNode }) {
  const styledHighlight = useStyledHighlight();

  return (
    <Tooltip title={tooltip} placement="topLeft" trigger={["hover"]} mouseLeaveDelay={0}>
      <div className={styledHighlight}>{children}</div>
    </Tooltip>
  );
}

const useStyledHighlight = createUseStyle(({ theme }) =>
  css`
    margin-bottom: 8px;
    padding: 16px 8px;
    border-radius: ${theme.borderRadius}px;
    &:hover {
      background-color: ${theme.colorFillSecondary};
    }
  `(),
);
