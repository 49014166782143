import {
  CountryCode,
  NoBankingCountries,
  PayoutMethod,
  PayoutMethodType,
  RecipientAccount,
  formatPayoutMethod,
} from "@trolley/common-frontend";
import { Notification } from "components";
import React from "react";
import { isPaypalSettings, isVenmoSettings } from "store/actions/recipient";
import { useCurrencies } from "store/hooks/currencies";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { useIntl } from "utils/context";

interface Props {
  account: RecipientAccount | undefined;
  payoutMethod: PayoutMethod.PayoutMethodPublic | undefined;
  size?: "large" | "default";
}
export default function NotificationDisabledCountry(props: Props) {
  const { account, payoutMethod, size = "large" } = props;
  const { formatMessage } = useIntl();
  const { data: currencies, status: currenciesStatus } = useCurrencies();
  const recipient = useRecipient();
  const payoutCountry = account?.country || recipient?.address.country;

  if (payoutMethod && payoutCountry) {
    const notification = (
      <Notification
        data-testid="countryDisabled"
        title={formatMessage(
          {
            id: "containers.payoutMethod.disabledCountry",
          },
          { payoutMethod: formatPayoutMethod(payoutMethod.integration, formatMessage) },
        )}
        size={size}
        type="error"
      />
    );

    if (isPaypalSettings(payoutMethod?.integration, payoutMethod.settings)) {
      return !isCountryEnabledForPaypal(payoutMethod, payoutCountry) ? notification : null;
    }

    if (isVenmoSettings(payoutMethod?.integration, payoutMethod.settings)) {
      return !isCountryEnabledForVenmo(payoutMethod, payoutCountry) ? notification : null;
    }

    if (payoutMethod.integration === PayoutMethodType.BANKTRANSFER) {
      const bankCountryEnabled =
        !payoutMethod.enabledCountries?.length || payoutMethod.enabledCountries.includes(payoutCountry);
      const hasCurrencies = !!currencies[payoutCountry]?.length;

      if (!bankCountryEnabled || (currenciesStatus === BaseStatus.LOADED && !hasCurrencies)) {
        return notification;
      }

      return null;
    }

    if (
      NoBankingCountries.includes(payoutCountry) ||
      (!!payoutMethod.enabledCountries?.length && !payoutMethod.enabledCountries.includes(payoutCountry))
    ) {
      // all other payout methods should rely on the enabled countries array
      // ie. checks, bitcoin, interac
      return notification;
    }
  }

  return null;
}

export function isCountryEnabledForPaypal(
  payoutMethod: PayoutMethod.PayoutMethodPublic | undefined,
  recipientCountry: CountryCode | null | undefined,
): boolean {
  const settings = payoutMethod?.settings;
  if (recipientCountry && isPaypalSettings(payoutMethod?.integration, settings)) {
    return (
      settings.allowRestrictedCountries ||
      !settings.restrictedCountries.length ||
      !settings.restrictedCountries.includes(recipientCountry)
    );
  }

  return false;
}

export function isCountryEnabledForVenmo(
  payoutMethod: PayoutMethod.PayoutMethodPublic | undefined,
  recipientCountry: CountryCode | null | undefined,
): boolean {
  const settings = payoutMethod?.settings;
  if (recipientCountry && isVenmoSettings(payoutMethod?.integration, settings)) {
    return settings.allowedCountries?.includes(recipientCountry);
  }

  return false;
}
