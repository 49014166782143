import {
  formatPayoutMethod,
  getReturnReasonText,
  PaymentStatus,
  PaymentTaxesPayoutMethods,
  PayoutMethodType,
  ReturnReason,
} from "@trolley/common-frontend";
import { Timeline } from "antd";
import BigNumber from "bignumber.js";
import React, { ReactNode } from "react";

import { default as VenmoLogo } from "assets/images/venmo.svg";
import { CurrencyDisplay, DateDisplay, GreyBox, Grid, Icon, Text, Tooltip } from "components";
import { GridProps } from "components/Grid";
import { RecipientAccountDisplay } from "features";
import { getDeliveryStatus } from "pages/Payments";
import { CombinedPayment } from "store/hooks/payments";
import { useTickets } from "store/hooks/tickets";
import css, { createUseStyle, useTheme } from "style/classname";
import { useIntl } from "utils/context";

const ICON_ATTR = {
  style: {
    width: "20px",
    paddingRight: "6px",
  },
};

const GRID_PROPS: GridProps = {
  justify: "space-between",
  alignItems: "middle",
};

const UNFRIENDLY_REASONS = [
  ReturnReason.NONSUFFICIENT_FUNDS,
  ReturnReason.GATEWAY,
  ReturnReason.NOT_AUTHORIZED,
  ReturnReason.DUPLICATE,
  ReturnReason.SENDING_BANK_ISSUE,
  ReturnReason.FAILED_NETWORK,
];

export default function PaymentBody({ payment }: { payment: CombinedPayment }) {
  const PayoutMethodIcons: Record<PaymentTaxesPayoutMethods, ReactNode> = {
    [PaymentTaxesPayoutMethods.BANKTRANSFER]: <Icon type="university" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.PAYPAL]: <Icon theme="brands" type="paypal" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.BITCOIN]: <Icon theme="brands" type="bitcoin" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.INTERAC]: <Icon type="credit-card" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.DEBIT_CARD]: <Icon type="credit-card" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.CHECK]: <Icon type="money-check-edit-alt" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.MOBILE]: <Icon type="mobile-alt" {...ICON_ATTR} />,
    [PaymentTaxesPayoutMethods.VENMO]: <img src={VenmoLogo} alt="venmo-icon" style={{ width: "2em" }} />,
    [PaymentTaxesPayoutMethods.OTHER]: <Icon type="university" {...ICON_ATTR} />,
  };

  const intl = useIntl();
  const { data: tickets } = useTickets();
  const styledTable = useStyledTable();
  const hasTicket = tickets.records.some((t) => t.paymentId === payment.id);
  const token = useTheme();
  const styledTimeline = useStyledTimeline();

  const paymentAmount =
    payment.sourceAmount && payment.withholdingAmount
      ? new BigNumber(payment.sourceAmount).plus(payment.withholdingAmount)
      : new BigNumber(payment.amount);

  const fees = payment.recipientFees && new BigNumber(payment.recipientFees);

  const exchangeRate = payment.exchangeRate && new BigNumber(payment.exchangeRate);

  const iconSize = ".6em";

  const uniqueReturnReasons = [
    ...new Set(
      payment?.returnedReason?.map((r) =>
        UNFRIENDLY_REASONS.includes(r)
          ? intl.formatMessage({ id: "containers.payments.notProcessed" })
          : getReturnReasonText(r),
      ),
    ),
  ];

  const deliveryStatus = getDeliveryStatus(payment);

  return (
    <Grid>
      <Grid.Item className={styledTimeline}>
        <Timeline
          pendingDot={<Icon type="circle" color="grey" theme="solid" style={{ fontSize: iconSize }} />}
          pending={
            deliveryStatus === "delivering" &&
            payment.account && (
              <>
                <Grid {...GRID_PROPS}>
                  <Grid.Item>
                    <Text strong type="secondary" size="small">
                      <DateDisplay value={payment.estimatedDeliveryAt} time={false} />
                      {hasTicket && (
                        <Icon
                          tooltip={intl.formatMessage({
                            id: "containers.payments.ticketDelay",
                          })}
                          left
                          type="exclamation-triangle"
                          color="warning"
                        />
                      )}
                    </Text>
                  </Grid.Item>
                  <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
                  <Grid.Item>
                    <Text type="secondary" size="small">
                      {intl.formatMessage({
                        id: "containers.payments.estimatedDelivery",
                      })}
                    </Text>
                  </Grid.Item>
                </Grid>
                <RecipientAccountDisplay mini account={payment.account} />
              </>
            )
          }
        >
          <Timeline.Item color="green" dot={<Icon type="circle" theme="solid" style={{ fontSize: iconSize }} />}>
            <Grid {...GRID_PROPS}>
              <Grid.Item>
                <Text strong type="secondary" size="small">
                  <DateDisplay value={payment.processedAt} time={false} />
                </Text>
              </Grid.Item>
              <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
              <Grid.Item>
                <Text type="success" size="small">
                  {intl.formatMessage({ id: "containers.payments.sent" })}
                </Text>
              </Grid.Item>
            </Grid>
            <GreyBox margin="small" padding="small">
              <table className={styledTable}>
                <caption className="sr-only">{intl.formatMessage({ id: "containers.payments.amountDetails" })}</caption>
                <tbody>
                  <tr>
                    <th>{intl.formatMessage({ id: "containers.payments.amount" })}</th>
                    <td>
                      <CurrencyDisplay value={paymentAmount} currency={payment.sourceCurrency || payment.currency} />
                    </td>
                  </tr>
                  {fees && fees.gt(0) && (
                    <tr>
                      <th>{intl.formatMessage({ id: "containers.payments.fee" })}</th>
                      <td>
                        - <CurrencyDisplay value={fees} currency={payment.sourceCurrency} />
                      </td>
                    </tr>
                  )}
                  {payment.withholdingAmount && new BigNumber(payment.withholdingAmount).gt(0) && (
                    <tr>
                      <th>
                        {intl.formatMessage({
                          id: "containers.payments.withholding",
                        })}
                      </th>
                      <td>
                        - <CurrencyDisplay value={payment.withholdingAmount} currency={payment.withholdingCurrency} />
                      </td>
                    </tr>
                  )}
                  {payment.targetAmount && (
                    <>
                      <tr className="border-top">
                        <th>
                          {intl.formatMessage({
                            id: "containers.payments.targetAmount",
                          })}
                          {["wire", "no_fx_wire"].includes(payment.routeType || "") && (
                            <Tooltip
                              title={intl.formatMessage({
                                id: "containers.payments.swiftWireTooltip",
                              })}
                            >
                              <Icon size="small" theme="solid" right type="info-circle" />
                            </Tooltip>
                          )}
                        </th>
                        <td>
                          <CurrencyDisplay value={payment.targetAmount} currency={payment.targetCurrency} />
                        </td>
                      </tr>
                      {exchangeRate && !exchangeRate.eq(1) && (
                        <tr>
                          <th>
                            {intl.formatMessage({
                              id: "containers.payments.fxRate",
                            })}
                          </th>
                          <td>{exchangeRate.toFixed(5)}</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </GreyBox>
          </Timeline.Item>

          {deliveryStatus === "delivering" && (
            <Timeline.Item dot={<Icon type="circle" theme="solid" style={{ fontSize: iconSize }} />}>
              <Grid {...GRID_PROPS}>
                <Grid.Item>
                  <Text strong type="secondary" size="small">
                    {intl.formatMessage({ id: "containers.payments.today" })}
                  </Text>
                </Grid.Item>
                <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
                <Grid.Item>
                  <Text size="small" type="info">
                    {intl.formatMessage({
                      id: "containers.payments.paymentEnRoute",
                    })}
                  </Text>
                </Grid.Item>
              </Grid>
            </Timeline.Item>
          )}

          {/* OFFLINE PAYMENT */}
          {!payment.account && payment.payoutMethod && (
            <Timeline.Item color="green" dot={<Icon type="circle" theme="solid" style={{ fontSize: iconSize }} />}>
              <Grid {...GRID_PROPS}>
                <Grid.Item>
                  <Text strong type="secondary" size="small">
                    <DateDisplay value={payment.processedAt} time={false} />
                  </Text>
                </Grid.Item>
                <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
                <Grid.Item>
                  <Text size="small" type="success">
                    {intl.formatMessage({
                      id: "containers.payments.deliveredTo",
                    })}
                  </Text>
                </Grid.Item>
              </Grid>
              <Text size="small" style={{ maxWidth: "200px", padding: "4px 0px" }}>
                {PayoutMethodIcons[payment.payoutMethod || PaymentTaxesPayoutMethods.OTHER]}
                {formatPayoutMethod(payment.payoutMethod, intl.formatMessage)}
              </Text>
            </Timeline.Item>
          )}

          {/* DELIVERED */}
          {payment.account && (payment.status === PaymentStatus.RETURNED || deliveryStatus === "delivered") && (
            <Timeline.Item color="green" dot={<Icon type="circle" theme="solid" style={{ fontSize: iconSize }} />}>
              <Grid {...GRID_PROPS}>
                <Grid.Item>
                  <Text strong type="secondary" size="small">
                    <DateDisplay
                      value={deliveryStatus === "delivered" ? payment.estimatedDeliveryAt : payment.settledAt}
                      time={false}
                    />
                  </Text>
                </Grid.Item>
                <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
                <Grid.Item>
                  <Text size="small" type="success">
                    {intl.formatMessage({
                      id:
                        deliveryStatus === "delivering"
                          ? "containers.payments.deliveredTo"
                          : "containers.payments.sentTo",
                    })}
                  </Text>
                </Grid.Item>
              </Grid>
              <RecipientAccountDisplay mini account={payment.account} />
            </Timeline.Item>
          )}

          {/* RETURNED */}
          {payment.status === PaymentStatus.RETURNED && (
            <Timeline.Item color="red" dot={<Icon type="circle" theme="solid" style={{ fontSize: iconSize }} />}>
              <Grid {...GRID_PROPS}>
                <Grid.Item>
                  <Text strong type="secondary" size="small">
                    <DateDisplay value={payment.returnedAt} time={false} />
                  </Text>
                </Grid.Item>
                <Grid.Item flex={1} style={{ borderTop: `1px solid ${token.colorBorder}` }} />
                <Grid.Item>
                  <Text size="small" type="danger">
                    {intl.formatMessage({
                      id: "containers.payments.paymentReturned",
                    })}
                  </Text>
                </Grid.Item>
                {payment.returnedReason?.length && (
                  <GreyBox margin="small" padding="small">
                    <Grid>
                      <Grid.Item xs={24} sm={12}>
                        <Text strong size="small">
                          {intl.formatMessage({
                            id: "containers.payments.returnReason",
                          })}
                        </Text>
                        <Text size="small">
                          {uniqueReturnReasons.length > 1 ? (
                            <ul style={{ paddingLeft: "16px" }}>
                              {uniqueReturnReasons?.map((rr) => (
                                <li>{rr}</li>
                              ))}
                            </ul>
                          ) : (
                            uniqueReturnReasons[0]
                          )}
                        </Text>
                      </Grid.Item>
                      {payment.returnedNote && payment?.account?.type !== PayoutMethodType.PAYPAL && (
                        <Grid.Item xs={24} sm={12}>
                          <Text strong size="small">
                            {intl.formatMessage({
                              id: "containers.payments.returnNote",
                            })}
                          </Text>
                          <Text size="small">{payment.returnedNote}</Text>
                        </Grid.Item>
                      )}
                    </Grid>
                  </GreyBox>
                )}
              </Grid>
            </Timeline.Item>
          )}
        </Timeline>
      </Grid.Item>
      {payment.memo && (
        <Grid.Item>
          <Text strong type="label">
            {intl.formatMessage({ id: "containers.payments.note" })}
          </Text>
          <Text size="small">{payment.memo}</Text>
        </Grid.Item>
      )}
    </Grid>
  );
}

const useStyledTimeline = createUseStyle(({ theme }) =>
  css`
    flex: 1 0 300px;
    ${theme.screenUp("md")} {
      flex: 0 0 350px;
    }

    .${theme.prefixCls}-timeline-item {
      padding: 0;
      color: ${theme.colorText};
    }
    .${theme.prefixCls}-timeline-item-head-green {
      color: ${theme.colorSuccess};
      border-color: ${theme.colorBorder};
    }

    .${theme.prefixCls}-timeline-item-head-blue {
      color: ${theme.colorPrimary};
      border-color: ${theme.colorPrimary};
    }

    .${theme.prefixCls}-timeline-item-head {
      background-color: ${theme.colorBgLayout};
    }

    .${theme.prefixCls}-timeline-item-head-red {
      color: ${theme.colorError};
      border-color: ${theme.colorError};
    }

    .${theme.prefixCls}-timeline-item-head-custom {
      line-height: 0;
    }

    .${theme.prefixCls}-timeline-item-tail {
      border-left: 2px solid ${theme.colorSuccess};
      top: 13px;
      height: calc(100% - 4px);
    }

    .${theme.prefixCls}-timeline-item-pending .${theme.prefixCls}-timeline-item-head {
      font-size: unset;
    }
  `(),
);

const useStyledTable = createUseStyle(({ theme }) =>
  css`
    tbody {
      font-size: ${theme.fontSizeSM}px;
      td {
        text-align: right;
      }
      .border-top {
        th,
        td {
          padding-top: ${theme.marginXS}px;
          border-top: 1px solid ${theme.colorBorderSecondary};
        }
      }
    }
  `(),
);
