import { Button, Divider, Form2 as Form, Icon, Paragraph, TitleBar } from "components";
import React, { useState } from "react";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";

import portalLocale from "locale/en/portal.json";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { authPortal } from "store/actions/authPortal";
import { notifyError } from "store/actions/notifications";
import { useIframeConfig } from "store/hooks/config";

type FormFields = { email: string; code?: string };
export default function PortalLogin() {
  const [form] = Form.useForm<FormFields>();
  const config = useIframeConfig();
  const [email, setEmail] = useState<string>();
  const [invalidCode, setInvalidCode] = useState(false);
  const location = useLocation();
  const [sending, setSending] = useState(false);
  const portalDomain = config.portalDomain;
  const intl = useIntl();
  const loginStyle = useLoginStyle();

  function getQueryEmail() {
    if (location.search) {
      const matches = location.search.match(/email=([^&]+)/);

      // not using qs.parse because email could be not encoded.
      // and qs will translate '+' signs to a space

      return matches?.[1] ? decodeURIComponent(matches[1]) : undefined;
    }

    return undefined;
  }

  async function onFinish(values: FormFields) {
    if (portalDomain) {
      const stateEmail = email;
      if (!sending) {
        setSending(true);
        setInvalidCode(false);
        try {
          if (!stateEmail) {
            await authPortal(portalDomain, values.email);
            setEmail(values.email);
            setSending(false);
          } else if (values.code) {
            await authPortal(portalDomain, stateEmail, values.code);
          }
        } catch (errors) {
          form.setFields([
            {
              name: "code",
              errors: [
                intl.formatMessage({
                  id: "portal.invalidCode",
                  defaultMessage: "Invalid Code. ",
                }),
              ],
            },
          ]);
          setSending(false);
          setInvalidCode(true);
        }
      }
    } else {
      notifyError("Portal domain is missing");
    }
  }

  async function onSendValidationCode(e: any) {
    e?.preventDefault?.();

    if (portalDomain && email && !sending) {
      setSending(true);
      setInvalidCode(false);

      try {
        await authPortal(portalDomain, email);
        form.setFields([{ name: "code", value: "", errors: undefined }]);
      } catch {}
      setSending(false);
    }
  }

  return (
    <div className={loginStyle}>
      <Helmet>
        <title>{intl.formatMessage({ id: "portal.confirmAccount" })}</title>
      </Helmet>
      <TitleBar>
        {intl.formatMessage({
          id: email ? "portal.checkEmail" : "portal.letsConfirmAccount",
        })}
      </TitleBar>

      <Paragraph aria-live="polite" aria-atomic>
        {email ? (
          <>
            <Icon type="check-circle" left color="success" />
            {intl.formatMessage(
              {
                id: "portal.authCodeSent",
                defaultMessage: `Verification Code has been sent to {email}`,
              },
              { email },
            )}
          </>
        ) : (
          intl.formatMessage({
            id: "portal.willEmail",
            defaultMessage: portalLocale.willEmail,
          })
        )}
      </Paragraph>
      <Divider transparent />

      <Form form={form} onFinish={onFinish} initialValues={{ email: getQueryEmail() }}>
        {email ? (
          <div className="portal-auth">
            <div className="portal-auth__input">
              <Form.Field
                name="code"
                label={intl.formatMessage({
                  id: "portal.enterCode",
                  defaultMessage: "Enter Code",
                })}
                normalize={(val) => String(val || "").replace(/[^0-9]/, "")}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "portal.enterValidCode",
                      defaultMessage: "Enter a 6 digit code",
                    }),
                  },
                  {
                    async validator(_: any, value: any) {
                      if (!/^\d{6}$/.test(value)) {
                        throw new Error(
                          intl.formatMessage({
                            id: "portal.enterValidCode",
                            defaultMessage: "Enter a 6 digit code",
                          }),
                        );
                      }
                    },
                  },
                ]}
              >
                <Form.Input type="one-time-code" inputMode="numeric" maxLength={6} />
              </Form.Field>
            </div>

            <Divider transparent size="large" />

            {invalidCode ? (
              <Button size="large" type="primary" onClick={onSendValidationCode}>
                {intl.formatMessage({
                  id: "portal.resendCode",
                  defaultMessage: "Resend Code",
                })}
              </Button>
            ) : (
              <Button htmlType="submit" size="large" type="primary">
                {intl.formatMessage({
                  id: "portal.validateCode",
                  defaultMessage: "Validate Code",
                })}
              </Button>
            )}
            <Divider transparent />
            <Button
              onClick={() => {
                setEmail(undefined);
              }}
              type="link"
              icon="arrow-left"
            >
              {intl.formatMessage({
                id: "portal.differentAccount",
                defaultMessage: portalLocale.differentAccount,
              })}
            </Button>
          </div>
        ) : (
          <>
            <Form.Field
              name="email"
              label={intl.formatMessage({
                id: "containers.info.email.title",
                defaultMessage: "Email",
              })}
              preserve
              validateTrigger={false}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "portal.enterEmail",
                    defaultMessage: portalLocale.enterEmail,
                  }),
                },
                {
                  type: "email",
                  message: intl.formatMessage({
                    id: "containers.paypalPayoutMethod.email.valid",
                  }),
                },
              ]}
              extra={intl.formatMessage(
                {
                  id: "portal.emailHint",
                  defaultMessage: "The email address must be the same as the email on your {businessName} account.",
                },
                { businessName: config.businessName },
              )}
            >
              <Form.Input type="email" name="email" placeholder="eg. name@domain.com" />
            </Form.Field>

            <Divider transparent size="large" />

            <Button htmlType="submit" size="large" type="primary" disabled={sending}>
              {intl.formatMessage({
                id: "portal.sendCode",
                defaultMessage: "Send Verification Code",
              })}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
}

const useLoginStyle = createUseStyle(({ theme }) =>
  css`
    padding: 40px 16px;
    margin: 0 auto;
    max-width: 500px;
    .portal-auth {
      .portal-auth__input {
        .${theme.prefixCls}-input {
          letter-spacing: 8px;
          width: 200px;
          font-size: 24px;
        }
      }
    }
  `(),
);
