import { CountryCode, CurrencyCode, PayoutMethodType } from "@trolley/common-frontend";
import { Form2 as Form, Loader } from "components";
import InputPhone from "components/Form2/InputPhone";
import { phoneNumberValidator, parsePhoneNumber } from "utils/helpers/formatter/phoneNumber";
import { CountryCode as PhoneCountryCode } from "libphonenumber-js";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  RecipientAccount,
  VenmoAccountUpdate,
  addPayoutMethod,
  isVenmoSettings,
  updatePayoutMethod,
} from "store/actions/recipient";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";

type Props = {
  account: RecipientAccount | undefined;
};

export default function Venmo({ account }: Props) {
  const [form] = Form.useForm();
  const recipient = useRecipient();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const merchantSettings = useMerchant();
  const venmoPayout = merchantSettings?.enabledPayoutMethods.find((pm) => pm.integration === PayoutMethodType.VENMO);
  const venmoAllowedCountries = isVenmoSettings(venmoPayout?.integration, venmoPayout?.settings)
    ? venmoPayout?.settings.allowedCountries
    : [CountryCode.US];
  const selectedCountry = recipient?.address.country || CountryCode.US;

  interface FormFields {
    phoneNumber: string;
    phonNumberConfirmation: string;
    primary?: boolean;
  }

  async function onFinish({ primary, ...values }: FormFields) {
    if (!submitting && recipient) {
      setSubmitting(true);
      const parsedNumber = parsePhoneNumber(values.phoneNumber, selectedCountry as PhoneCountryCode);
      const update: VenmoAccountUpdate = {
        phoneNumber: parsedNumber.internationalFormat,
        type: PayoutMethodType.VENMO,
        currency: CurrencyCode.USD,
        country: parsedNumber.country as CountryCode,
      };
      try {
        if (recipient) {
          if (primary) {
            update.primary = true;
          }
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, update);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, update);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Form.Field
          style={{ maxWidth: "400px" }}
          initialValue={account?.phoneNumber}
          name="phoneNumber"
          label={intl.formatMessage({
            id: "containers.venmoPayoutMethod.phone.title",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "containers.info.phone.required",
              }),
            },
            phoneNumberValidator(intl.formatMessage, venmoAllowedCountries),
          ]}
        >
          <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phone" />
        </Form.Field>

        <Form.Field
          style={{ maxWidth: "400px" }}
          name="phoneNumberConfirmed"
          validateTrigger="onChange"
          label={intl.formatMessage({
            id: "containers.venmoPayoutMethod.phoneConfirm.title",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "containers.info.phone.required",
              }),
            },
            (form) => ({
              async validator(rule, value) {
                if (value !== form.getFieldValue("phoneNumber")) {
                  throw intl.formatMessage({
                    id: "containers.venmoPayoutMethod.phoneConfirm.valid",
                  });
                }
              },
            }),
          ]}
        >
          <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phone" />
        </Form.Field>

        <PayoutFooter busy={submitting} account={account} />
      </Loader>
    </Form>
  );
}
