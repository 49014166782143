import { CountryCode, formatCountry } from "@trolley/common-frontend";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import * as PhoneNumber from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Flag, Icon, Space, Text } from "components";
import css, { classnames, createUseStyle } from "style/classname";
import { useIntl } from "utils/context";
import Input, { InputProps } from "./Input2";
import Select from "./Select2";
import { parsePhoneNumber } from "utils/helpers/formatter/phoneNumber";

interface InputPhoneButtonProps
  extends Omit<InputProps, "addonBefore" | "prefix" | "value" | "onChange" | "placeholder" | "type"> {
  value?: string;
  onChange?(value: string): void;
  allowedCountries?: CountryCode[];
  defaultCountry?: CountryCode;
}

export default function InputPhoneButton({
  value,
  defaultCountry,
  allowedCountries,
  className,
  readOnly,
  onChange,
  disabled: customDisabled,
  allowClear,
  suffix,
  ...props
}: InputPhoneButtonProps) {
  // https://github.com/ant-design/ant-design/blob/4.24.7/components/input/Input.tsx#L159
  const disabledContext = useContext(DisabledContext);
  const disabled = disabledContext ?? customDisabled;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showSearch, setShowSearch] = useState(false);
  const { formatMessage } = useIntl();
  const [country, setCountry] = useState<PhoneNumber.CountryCode | undefined>();
  const { lineNumber, nationalFormat } = parsePhoneNumber(value, country);
  const styledCountrySelector = useStyledCountrySelector();
  const styledInput = useStyledInput({ disabled });
  const prefix =
    country && PhoneNumber.getCountryCallingCode(country) ? `+${PhoneNumber.getCountryCallingCode(country)}` : "";
  const placeholder = country
    ? PhoneNumber.getExampleNumber(country as PhoneNumber.CountryCode, examples)
        ?.formatInternational()
        .substring(prefix.length)
        .trim() || ""
    : "Select a country";

  useEffect(() => {
    if (value) {
      const parsed = parsePhoneNumber(value, country);
      if (
        parsed?.country &&
        parsed.country in CountryCode &&
        (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
      ) {
        // set new country based on the parser.
        setCountry(parsed.country);
      } else if (country && allowedCountries?.length && !allowedCountries.includes(country as CountryCode)) {
        // reset back to undefined selector picked a country that is not allowed
        setCountry(undefined);
      }
    } else if (!country && defaultCountry) {
      setCountry(defaultCountry as PhoneNumber.CountryCode);
    }
  }, [value, country, defaultCountry]);

  if (readOnly || disabled) {
    return (
      <Input
        value={value}
        type="tel"
        disabled={disabled}
        readOnly={readOnly}
        prefix={country && <Flag code={country as CountryCode} />}
      />
    );
  }

  return (
    <Space direction="column" align="stretch">
      <Input
        {...props}
        type="tel"
        addonBefore={
          <Button
            size="small"
            icon="chevron-down"
            iconProps={{ size: "small" }}
            shape="default"
            type="text"
            onClick={() => {
              if (!showSearch) {
                setShowSearch(true);
              }
            }}
          >
            <Flag code={country as CountryCode} />
          </Button>
        }
        ref={inputRef}
        prefix={prefix}
        value={value?.replace?.(prefix, "")}
        aria-label={formatMessage({ id: "components.phone.input" })}
        disabled={!country}
        onChange={(e) => {
          const parsed = parsePhoneNumber(`${prefix}${e.target.value}`, country);
          onChange?.(
            /^[0-9]*$/.test(parsed.lineNumber) // if it only contains digits, it's because it cannot match any country format
              ? `${prefix}${e.target.value}` // so leave the number format as is
              : `${prefix}${parsed.lineNumber}`, // it there is a matching format, use new format
          );
          if (
            parsed.country &&
            country !== parsed.country &&
            (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
          ) {
            setCountry(parsed.country);
          }
        }}
        placeholder={placeholder}
        className={classnames(styledInput, className)}
        suffix={
          !!country && (
            <Space>
              {suffix}
              {nationalFormat.replace(/[^0-9]/g, "").length !== lineNumber.replace(/[^0-9]/g, "").length && (
                <Icon
                  type="info-circle"
                  color="info"
                  tooltip={`To make a domestic call in ${formatCountry(
                    country,
                  )}, the phone number is ${nationalFormat}`}
                />
              )}
              {allowClear && value && (
                <Button
                  type="text"
                  size="small"
                  aria-label={formatMessage({ id: "common.close" })}
                  icon="circle-xmark"
                  iconProps={{
                    color: "grey",
                    theme: "solid",
                  }}
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setCountry(allowClear ? undefined : (defaultCountry as PhoneNumber.CountryCode));
                    onChange?.("");
                    props.onBlur?.(e as any); // this sometimes help triggering the validation
                  }}
                />
              )}
            </Space>
          )
        }
      />
      {showSearch && (
        <Select
          placeholder={
            <Text>
              <Icon type="globe" color="grey" fixedWidth={false} /> Search by country{" "}
            </Text>
          }
          defaultActiveFirstOption
          value={country}
          onBlur={props.onBlur}
          showAction={["focus"]}
          autoFocus
          showSearch
          optionFilterProp="data-search"
          className={styledCountrySelector}
          popupMatchSelectWidth={false}
          aria-label={formatMessage({ id: "components.phone.countryCallingCode" })}
          options={PhoneNumber.getCountries()
            .filter(
              (code) =>
                CountryCode[code] && (!allowedCountries?.length || allowedCountries.includes(code as CountryCode)),
            )
            .map((code) => ({
              value: code,
              "data-search": `+${PhoneNumber.getCountryCallingCode(code)} ${code} ${formatCountry(
                code,
                formatMessage,
              )}`,
              label: (
                <>
                  <Flag code={code as CountryCode} />
                  <span className="selected-hidden">
                    {" "}
                    {formatCountry(code, formatMessage)} +{PhoneNumber.getCountryCallingCode(code)}
                  </span>
                </>
              ),
            }))}
          onChange={(code) => {
            if (CountryCode[code as CountryCode]) {
              const parsed = parsePhoneNumber(
                [`+${PhoneNumber.getCountryCallingCode(code as PhoneNumber.CountryCode)}`, lineNumber].join(""),
                code,
              );
              setCountry(code);
              onChange?.(parsed.internationalFormat);
              inputRef?.current?.focus?.();
              setShowSearch(false);
            }
          }}
        />
      )}
    </Space>
  );
}

const useStyledInput = createUseStyle<{ disabled: boolean | undefined }>(({ theme, ...props }) =>
  css`
    .${theme.prefixCls}-input-group-addon {
      ${!props.disabled && `background-color: ${theme.colorBgContainer};`}
    }
    .${theme.prefixCls}-btn.${theme.prefixCls}-btn-circle {
      // fix style so the button doesn't expand the input's height
      height: ${theme.controlHeightXS}px;
      width: ${theme.controlHeightXS}px;
      min-width: ${theme.controlHeightXS}px;
      line-height: ${theme.controlHeightXS}px;
      padding: 0;
    }
  `(),
);

const useStyledCountrySelector = createUseStyle(({ theme }) =>
  css`
    text-align: left;
    width: 100%;
    &.${theme.prefixCls}-select {
      min-width: 60px;
      transition: min-width 200ms ease;
      .${theme.prefixCls}-select-selection-item {
        .selected-hidden {
          display: none;
        }
      }
      .${theme.prefixCls}-select &.${theme.prefixCls}-select-focused {
        min-width: 140px;
        .flag-label {
          display: inherit;
        }
      }
    }
  `(),
);
