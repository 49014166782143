/* eslint-disable @typescript-eslint/no-var-requires */

// IMPORTANT! this file has to be in .js because webpack builds locale keys from this file

const containers = require("./containers/index.js");
const components = require("./components.json");
const common = require("./common.json");
const portal = require("./portal.json");

module.exports = {
  containers,
  components,
  common,
  portal,
};
