import { RecipientType } from "@trolley/common-frontend";
import { Container, Divider, Form2, GreyBox, Icon, Typography } from "components";
import React from "react";
import { useTheme } from "style/classname";
import { useIntl } from "utils/context";
import { WizardPageProps } from ".";

interface IFormData {
  confirmBusinessName: string;
  businessName?: string;
}

export default function ConfirmBusinessName({ taxProfileWizard, onContinue }: WizardPageProps) {
  const [form] = Form2.useForm<IFormData>();
  const token = useTheme();
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    confirmBusinessName: !taxProfileWizard.confirmBusinessName
      ? !taxProfileWizard.payoutRecipientBusinessName ||
        (taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL &&
          taxProfileWizard.confirmedRecipientType === RecipientType.BUSINESS)
        ? "no"
        : ""
      : taxProfileWizard.confirmBusinessName,
    businessName:
      !taxProfileWizard.confirmBusinessName &&
      taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL &&
      taxProfileWizard.confirmedRecipientType === RecipientType.BUSINESS
        ? ""
        : taxProfileWizard.businessName ?? "",
  };
  const confirmBusinessName = Form2.useWatch("confirmBusinessName", form) ?? initialValues.confirmBusinessName;

  function onSubmit({ businessName }: IFormData) {
    onContinue({
      confirmBusinessName,
      businessName: confirmBusinessName === "no" ? businessName : taxProfileWizard.payoutRecipientBusinessName,
    });
  }

  return (
    <Form2
      form={form}
      initialValues={initialValues}
      validateTrigger="onChange"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Container padding="none">
        {taxProfileWizard.payoutRecipientBusinessName &&
          (taxProfileWizard.payoutRecipientType === RecipientType.BUSINESS ||
            taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL) && (
            <fieldset>
              <legend>
                <Typography.Title>
                  {formatMessage({ id: "containers.taxProfile.wizard.confirmBusinessNameQuestion" })}
                </Typography.Title>
              </legend>
              <Divider transparent />
              <GreyBox size="2x" border={false}>
                <Typography.Text strong>{taxProfileWizard.payoutRecipientBusinessName}</Typography.Text>
              </GreyBox>
              <Divider transparent size="xlarge" />
              <Form2.Item name="confirmBusinessName">
                <Form2.Radio.Group
                  optionType="card"
                  direction="vertical"
                  name="radio"
                  options={[
                    {
                      label: formatMessage(
                        { id: "containers.taxProfile.wizard.yesBusinessName" },
                        { recipientBusinessName: taxProfileWizard.payoutRecipientBusinessName },
                      ),
                      value: "yes",
                      disabled: !taxProfileWizard.payoutRecipientBusinessName,
                    },
                    {
                      label: formatMessage({ id: "containers.taxProfile.wizard.noBusinessName" }),
                      value: "no",
                    },
                  ]}
                />
              </Form2.Item>
            </fieldset>
          )}
        {confirmBusinessName === "no" && (
          <fieldset>
            {!taxProfileWizard.payoutRecipientBusinessName ||
              (taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL &&
                taxProfileWizard.confirmedRecipientType === RecipientType.BUSINESS && (
                  <legend>
                    <Typography.Title>
                      {formatMessage({ id: "containers.taxProfile.wizard.confirmBusinessNameTitle" })}
                    </Typography.Title>
                  </legend>
                ))}
            <Divider transparent size="large" />
            <div
              style={
                taxProfileWizard.payoutRecipientBusinessName &&
                (taxProfileWizard.payoutRecipientType === RecipientType.BUSINESS ||
                  taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL)
                  ? { borderLeft: `solid 1px ${token.grey1}`, paddingLeft: "32px", paddingRight: "15px" }
                  : { paddingRight: "15px" }
              }
            >
              <Form2.Item
                label={formatMessage({ id: "containers.taxProfile.wizard.enterBusinessName" })}
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: "containers.taxProfile.wizard.enterBusinessNameMessage" }),
                  },
                ]}
              >
                <Form2.Input
                  type="text"
                  placeholder={formatMessage({ id: "containers.taxProfile.wizard.businessName" })}
                />
              </Form2.Item>
            </div>
          </fieldset>
        )}
        <Divider transparent size="large" />
        <Form2.SubmitButton
          disabled={!confirmBusinessName}
          text={formatMessage({ id: "common.continue" })}
          suffix={<Icon type="arrow-right" />}
        />
      </Container>
    </Form2>
  );
}
