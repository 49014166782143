import local from "./local";
import development from "./development";
import developmentPlatform from "./development-platform";
import developmentSync from "./development-sync";
import developmentTax from "./development-tax";
import staging from "./staging";
import production from "./production";
import node from "./node-upgrade";

const ENV = process.env.SETTINGS || "";
const CLIENT = process.env.CLIENT || "portal";
const BUILD_VERSION = process.env.BUILD_VERSION;

const configEnv = {
  local,
  development,
  "development-platform": developmentPlatform,
  "development-sync": developmentSync,
  "development-tax": developmentTax,
  "node-upgrade": node,
  staging,
  production,
};

type Configuration = {
  SERVER_ADDRESS: string;
  ENV?:
    | "local"
    | "development"
    | "development-platform"
    | "development-sync"
    | "development-tax"
    | "staging"
    | "node-upgrade"
    | "production";
  CLIENT: "widget" | "portal";
  BUILD_VERSION?: string;
  DATADOG?: {
    applicationId: string;
    clientToken: string;
    site: string;
    service: string;
  };
};

const config: Configuration = {
  SERVER_ADDRESS: "https://api.trolley.com",
  ENV,
  CLIENT,
  BUILD_VERSION,
  ...configEnv[ENV],
};

export default config;
