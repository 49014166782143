import { Checkbox as CheckboxAnt } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import React, { ReactNode } from "react";
import CheckboxGroup from "./CheckboxGroup";
import { classnames, createUseStyle, css } from "style/classname";
import { getContrastText } from "utils/helpers";

interface Props extends CheckboxProps {
  checkedChildren?: ReactNode;
  uncheckedChildren?: ReactNode;
}

export default function Checkbox(props: Props) {
  const { className, checked, children, uncheckedChildren, checkedChildren } = props;
  const styledCheckbox = useStyledCheckbox(props);

  return (
    <CheckboxAnt {...props} checked={checked} className={classnames(styledCheckbox, className)}>
      {children ?? (checked ? checkedChildren : uncheckedChildren)}
    </CheckboxAnt>
  );
}

Checkbox.__ANT_CHECKBOX = true;
Checkbox.Group = CheckboxGroup;

const useStyledCheckbox = createUseStyle<Props>(({ theme }) =>
  css`
    &.${theme.prefixCls}-checkbox {
      .${theme.prefixCls}-checkbox-inner:after {
        border-color: ${getContrastText(theme.colorPrimary)};
      }
    }
  `(),
);
