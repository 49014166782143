import { WidgetMerchant } from "@trolley/common-frontend";
import { useShallowSelector } from ".";

export { WidgetMerchant as Merchant };

export function useMerchant() {
  return useShallowSelector((state) => {
    return state.merchant;
  });
}
