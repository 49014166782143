import { useEffect } from "react";
import { loadVerifications } from "store/actions/idVerification";
import { useShallowSelector } from ".";

export function useVerifications(recipientId: string) {
  useEffect(() => {
    if (recipientId) {
      loadVerifications(recipientId);
    }
  }, [recipientId]);

  return useShallowSelector((state) => {
    if (recipientId && state.verifications.fetchStatus.data === undefined) {
      loadVerifications(recipientId);
    }

    return {
      data: state.verifications.entities.data,
      status: state.verifications.fetchStatus.data,
      error: state.verifications.errors.data,
    };
  });
}
