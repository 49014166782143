import { useEffect } from "react";
import { loadTaxForm, loadTaxForms, TaxForm } from "store/actions/taxForms";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";
import { useMerchant } from "store/hooks/merchant";
import { useIframeConfig } from "store/hooks/config";

export function useTaxForm(taxId: string | undefined = "") {
  useEffect(() => {
    if (taxId) {
      loadTaxForm(taxId);
    }
  }, [taxId]);

  return useShallowSelector((state) => {
    if (taxId && state.taxForm.fetchStatus[taxId] === undefined) {
      loadTaxForm(taxId);
    }

    return {
      data: state.taxForm.entities[taxId],
      status: state.taxForm.fetchStatus[taxId],
      error: state.taxForm.errors[taxId],
    };
  });
}

export function useTaxForms() {
  const merchant = useMerchant();
  const config = useIframeConfig();
  const usTaxEnabled = !!(merchant?.features?.tax && merchant?.tax?.enabled && config.usTax);

  useEffect(() => {
    if (usTaxEnabled) {
      loadTaxForms();
    }
  }, [usTaxEnabled]);

  return useShallowSelector((state) => {
    if (usTaxEnabled && state.taxForms.fetchStatus.data === undefined) {
      loadTaxForms();
    }

    const data = state.taxForms.entities.data;

    return {
      enabled: usTaxEnabled,
      data: data
        ? {
            records: data.records.map((id) => state.taxForm.entities[id]).filter((tf) => tf) as TaxForm[],
            meta: data.meta,
          }
        : emptyList,
      status: state.taxForms.fetchStatus.data,
      error: state.taxForms.errors.data,
    };
  });
}
