import { Button, Icon } from "components";
import { useIntl } from "utils/context";
import Upload from "rc-upload";
import React, { useState } from "react";
import { RcFile } from "rc-upload/lib/interface";

interface Props {
  onSelectFile(file: UploadFile): void;
  hideFileName?: boolean;
}

export interface UploadFile extends RcFile {}

export default function UploadButton(props: Props) {
  const [file, setFile] = useState<UploadFile>();
  const { formatMessage } = useIntl();

  return (
    <div>
      <Upload
        component={(props) => <Button size="middle" {...props} />}
        beforeUpload={(file: UploadFile, fileList: UploadFile[]) => {
          setFile(file);
          props.onSelectFile(file);

          return false;
        }}
      >
        <Icon type="upload" theme="regular" left />
        {file
          ? formatMessage({ id: "components.uploadButton.selectDifFile" })
          : formatMessage({ id: "components.uploadButton.selectFile" })}
      </Upload>
      {!props.hideFileName && (
        <>
          &nbsp;&nbsp;<span>{file && file.name}</span>
        </>
      )}
    </div>
  );
}
