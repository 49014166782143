// import { default as info } from "./info.json";
// import { default as accountSummary } from "./accountSummary.json";
// import { default as payoutMethod } from "./payoutMethod.json";
// import { default as bankPayoutMethod } from "./bankPayoutMethod.json";
// import { default as mainContainer } from "./mainContainer.json";
// import { default as payoutComplete } from "./payoutComplete.json";
// import { default as paypalPayoutMethod } from "./paypalPayoutMethod.json";
// // import { default as taxComplete } from "./taxComplete.json";
// import { default as payments } from "./payments.json";
// import { default as checkPayoutMethod } from "./checkPayoutMethod.json";
// import { default as bitcoinPayoutMethod } from "./bitcoinPayoutMethod.json";
// import { default as interacPayoutMethod } from "./interacPayoutMethod.json";

const info = require("./info.json");
const accountSummary = require("./accountSummary.json");
const payoutMethod = require("./payoutMethod.json");
const bankPayoutMethod = require("./bankPayoutMethod.json");
const mainContainer = require("./mainContainer.json");
const payoutComplete = require("./payoutComplete.json");
const paypalPayoutMethod = require("./paypalPayoutMethod.json");
const taxComplete = require("./taxComplete.json");
const payments = require("./payments.json");
const checkPayoutMethod = require("./checkPayoutMethod.json");
const bitcoinPayoutMethod = require("./bitcoinPayoutMethod.json");
const interacPayoutMethod = require("./interacPayoutMethod.json");
const venmoPayoutMethod = require("./venmoPayoutMethod.json");
const tax = require("./tax.json");
const taxProfile = require("./taxProfile.json");
const trust = require("./trust.json");

module.exports = {
  info,
  accountSummary,
  payoutMethod,
  bankPayoutMethod,
  paypalPayoutMethod,
  checkPayoutMethod,
  bitcoinPayoutMethod,
  interacPayoutMethod,
  mainContainer,
  payoutComplete,
  taxComplete,
  payments,
  tax,
  venmoPayoutMethod,
  taxProfile,
  trust,
};
