import { useEffect, useState } from "react";
import { loadBankTransferFees, BankTransferFeesQuery } from "store/actions/fees";
import { useShallowSelector } from ".";
import { computeID } from "../actions/actionUtils";

export function useBankTransferFees(query: BankTransferFeesQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadBankTransferFees(query));
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.fees.fetchStatus[fetchId] === undefined) {
      loadBankTransferFees(query);
    }

    return state.fees.entities[fetchId]?.bankTransfer;
  });
}
