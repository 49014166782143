import TinyColor from "tinycolor2";
import colors from "style/colors";

export default function getContrastText(backgroundColor: string) {
  if (typeof backgroundColor === "string" && backgroundColor) {
    const color = new TinyColor(backgroundColor);

    return color.isValid() && color.isDark() ? colors.light : colors.dark;
  }

  return colors.dark;
}
