import { BaseError } from "@trolley/common-frontend";
import { PRODUCT_MODULES } from "utils/context/ProductsContext";

/* istanbul ignore file */
export enum WidgetEvent {
  DOCUMENT_HEIGHT = "document.height",
  DOCUMENT_LOADED = "document.loaded",
  DOCUMENT_FAILED = "document.failed",
  PAYOUT_CREATED = "payment.created",
  PAYOUT_DELETED = "payment.deleted",
  TAXFORM_SUBMITTED = "taxForm.submitted",
  TAXPROFILE_SUBMITTED = "taxForm.submitted",
  MODULE_LOADED = "module.loaded",
  MODULE_SUCCESSFUL = "module.successful",
  MODULE_FAILED = "module.failed",
  TICKET_UPDATED = "ticket.updated",
}

type ModuleDefinition = PRODUCT_MODULES | "profile";

export type DocumentFailedEventType = {
  event: WidgetEvent.DOCUMENT_FAILED;
  document: {
    message: string | null;
    code: string | null;
  };
};

type EventData = (
  | {
      event: WidgetEvent.DOCUMENT_HEIGHT;
      document: {
        height: number;
      };
      data: number;
    }
  | {
      event: WidgetEvent.DOCUMENT_LOADED;
    }
  | DocumentFailedEventType
  | {
      event: WidgetEvent.PAYOUT_CREATED | WidgetEvent.PAYOUT_DELETED;
      account: {
        id: string;
      };
    }
  | {
      event: WidgetEvent.TAXFORM_SUBMITTED;
      taxForm: {
        id: string;
      };
    }
  | {
      event: WidgetEvent.TAXPROFILE_SUBMITTED;
      taxProfile: {
        id: string;
      };
    }
  | {
      event: WidgetEvent.MODULE_FAILED | WidgetEvent.MODULE_LOADED | WidgetEvent.MODULE_SUCCESSFUL;
      module: ModuleDefinition[];
    }
  | {
      event: WidgetEvent.TICKET_UPDATED;
      ticket: {
        id: string;
      };
    }
) & { data?: number }; /** @deprecated */

export function emitEvent(data: EventData, targetOrigin: string = "*") {
  window.parent.postMessage(data, targetOrigin);
}

export function mapDocumentFailedEvent(error: BaseError): DocumentFailedEventType {
  const INVALID_API_KEY = "invalid_api_key";
  const EMAIL = "email";
  const INVALID_FIELD = "invalid_field";
  const MappedMessages: {
    message: string | string[];
    code: string;
    newCode: string;
  }[] = [
    {
      message: "Invalid token: bad hash",
      code: INVALID_API_KEY,
      newCode: "bad_hash",
    },
    {
      message: ["Timestamp is more than", "seconds off of server time"],
      code: INVALID_API_KEY,
      newCode: "bad_hash",
    },
    {
      message: "Invalid API Key",
      code: INVALID_API_KEY,
      newCode: INVALID_API_KEY,
    },
    {
      message: "Email already exists",
      code: EMAIL,
      newCode: "duplicate_email",
    },
    {
      message: ["Missing parameter - ", "key=", "sign=", "ts="],
      code: INVALID_API_KEY,
      newCode: "invalid_param_ts",
    },
    {
      message: "Missing parameter - raw",
      code: INVALID_API_KEY,
      newCode: "invalid_param",
    },
    {
      message: "Missing required parameter: refid or email",
      code: INVALID_API_KEY,
      newCode: "invalid_refid_email",
    },
    {
      message: "Value is invalid",
      code: INVALID_FIELD,
      newCode: "invalid_value",
    },
  ];

  const { message: errorMsg, code: errorCode } = error;
  const mappedMsg =
    errorMsg && errorCode
      ? MappedMessages.find(({ message, code }) => {
          if (errorCode !== code) return false;
          if (Array.isArray(message)) {
            return (message as string[]).every((messageToken) => errorMsg.includes(messageToken));
          }

          return message === errorMsg;
        })
      : null;

  return {
    event: WidgetEvent.DOCUMENT_FAILED,
    document: {
      message: error.message ?? null,
      code: mappedMsg ? mappedMsg.newCode : error.code ?? null,
    },
  };
}
