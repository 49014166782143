import React, { CSSProperties, InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { getContrastText } from "utils/helpers";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "value"> {
  checked?: boolean;
  value?: string | number | boolean;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  children?: ReactNode;
  onChange?(checked: boolean): void;
}
export const CHECKBOX_WRAPPER_NAME = "checkbox-wrapper";

export default forwardRef<HTMLInputElement, Props>(function Checkbox(props, ref) {
  const { checked, value, name, onChange, wrapperClassName, children, wrapperStyle, ...rest } = props;
  const styledCheckbox = useStyledCheckbox();
  const isChecked = checked === undefined ? !!value : checked;
  const styledCheck = useStyledCheck({ isChecked });

  return (
    <label
      className={classnames(CHECKBOX_WRAPPER_NAME, styledCheckbox, wrapperClassName)}
      style={wrapperStyle}
      htmlFor={name}
    >
      <div className={styledCheck} />
      <input
        {...rest}
        name={name}
        id={name}
        type="checkbox"
        checked={isChecked}
        value={value === undefined ? undefined : String(value)}
        onChange={(e) => {
          onChange?.(e.target.checked);
        }}
        ref={ref}
      />
      {children}
    </label>
  );
});

const useStyledCheck = createUseStyle<{ isChecked: boolean }>(({ theme, isChecked }) =>
  css`
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.1s ease-in-out;
    display: block;
    width: ${theme.fontSize + 4}px;
    height: ${theme.fontSize + 4}px;
    background-color: ${isChecked ? theme.colorPrimary : theme.colorBgLayout};
    border: 2px solid ${theme.colorPrimary};
    border-radius: 4px;
    &::before {
      display: block;
      position: absolute;
      transition: all 0.1s ease-in-out;
      content: "";
      opacity: ${isChecked ? 1 : 0};
      border: solid ${theme.colorBgLayout};
      border-width: ${isChecked ? `0 2px 2px 0` : `0`};
      top: ${isChecked ? 1 : 0}px;
      left: ${isChecked ? 5 : 0}px;
      height: ${isChecked ? 11 : 0}px;
      width: ${isChecked ? 5 : 0}px;
      border-color: ${isChecked ? getContrastText(theme.colorPrimary) : "transparent"};
      transform: rotate(45deg);
    }
  `(),
);

const useStyledCheckbox = createUseStyle(({ theme }) =>
  css`
    position: relative;
    padding-left: 24px;
    input[type="checkbox"] {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border: none;
      opacity: 0;
    }
    cursor: pointer;
  `(),
);
