import { CurrencyCode, PaymentStatus } from "@trolley/common-frontend";
import { useEffect, useMemo, useState } from "react";
import { loadOfflinePayments } from "store/actions/offlinePayments";
import { loadPayments, Payment, PaymentsQuery } from "store/actions/payments";
import { BaseError, BaseStatus, emptyList } from "store/reducers/standardReducer";
import { omitUndefined } from "utils/helpers";
import { useShallowSelector } from ".";

export function usePayments(recipientId: string | null | undefined, query: PaymentsQuery) {
  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (id) {
      setFetchId(loadPayments(id, query));
    }
  }, [id, query]);

  return useShallowSelector((state) => {
    if (id && fetchId && state.payments.fetchStatus[fetchId] === undefined) {
      loadPayments(id, query);
    }

    return {
      data: state.payments.entities[fetchId] || emptyList,
      error: state.payments.errors[fetchId],
      status: state.payments.fetchStatus[fetchId],
    };
  });
}

export function useOfflinePayments(
  recipientId: string | null | undefined,
  query: PaymentsQuery,
  optionalLoad?: boolean,
) {
  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (id && optionalLoad) {
      setFetchId(loadOfflinePayments(id, query));
    }
  }, [id, query, optionalLoad]);

  return useShallowSelector((state) => {
    if (id && fetchId && optionalLoad && state.offlinePayments.fetchStatus[fetchId] === undefined) {
      loadOfflinePayments(id, query);
    }

    return {
      data: state.offlinePayments.entities[fetchId] || emptyList,
      error: state.offlinePayments.errors[fetchId],
      status: state.offlinePayments.fetchStatus[fetchId],
    };
  });
}

export type CombinedPayment = Partial<Omit<Payment, "status" | "taxes">> & {
  id: string;
  processedAt: string | null;
  returnedReason?: string[] | null;
  status?: any;
  amount: string;
  currency: CurrencyCode | null;
  targetAmount?: string;
  targetCurrency?: CurrencyCode | null;
  payoutMethod?: string | null;
  memo: string | null;
  returnedNote?: string | null;
  account?: Payment["account"];
  externalId?: string;
};

export function useCombinedPayments(
  recipientId: string | null | undefined,
  sourceQuery: PaymentsQuery,
  includeOfflinePayments?: boolean,
) {
  const query = useMemo<PaymentsQuery>(
    () =>
      omitUndefined({
        ...sourceQuery,
        page: 1,
        pageSize: 1000,
        startDate: undefined,
        endDate: undefined,
        status: [PaymentStatus.PROCESSED, PaymentStatus.RETURNED],
        orderBy: ["processedAt"],
        sortBy: ["desc"],
      }),
    [sourceQuery],
  );

  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";
  const { data: payments, status: paymentsStatus, error: paymentsError } = usePayments(id, query);
  const { data: offlinePayments, status: offlinePaymentsStatus, error: offlinePaymentsError } = useOfflinePayments(
    id,
    query,
    includeOfflinePayments,
  );

  const combinedPayments = (includeOfflinePayments
    ? ([...payments.records, ...offlinePayments.records].sort((p1, p2) => {
        const dateA = p1.processedAt || "";
        const dateB = p2.processedAt || "";
        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }

        return 0;
      }) as CombinedPayment[])
    : payments.records
  ).filter((p) => {
    if (p.processedAt && sourceQuery.startDate && sourceQuery.endDate) {
      return p.processedAt >= sourceQuery.startDate && p.processedAt <= sourceQuery.endDate;
    }

    return true;
  });

  const page = sourceQuery.page || 1;
  const pageSize = sourceQuery.pageSize || 10;
  const pagedPayments = combinedPayments.slice((page - 1) * pageSize, page * pageSize);

  return {
    data: {
      records: pagedPayments,
      meta: {
        page: page,
        pages: Math.ceil(combinedPayments.length / pageSize),
        records: combinedPayments.length,
      },
    },
    status: [paymentsStatus, offlinePaymentsStatus].some((status) => status === BaseStatus.LOADING)
      ? BaseStatus.LOADING
      : [paymentsStatus, offlinePaymentsStatus].some((status) => status === BaseStatus.ERROR)
      ? BaseStatus.ERROR
      : [paymentsStatus, offlinePaymentsStatus].every((status) => status === BaseStatus.LOADED)
      ? BaseStatus.LOADED
      : undefined,
    error:
      paymentsError || offlinePaymentsError
        ? [...((paymentsError || []) as BaseError[]), ...((offlinePaymentsError || []) as BaseError[])]
        : undefined,
  };
}
