import React, { useEffect, useState } from "react";
import {
  BackButton,
  Button,
  Container,
  Divider,
  Footer,
  Form,
  Text,
  UploadButton,
  Title,
  Grid,
  GreyBox,
  Notification,
  Card,
  Typography,
  LinkButton,
} from "../../components";
import { useForm } from "../../components/Form";
import { UploadFile } from "../../components/UploadButton";
import { useIntl, ActionType, useStepperEffect, PRODUCT_MODULES } from "../../utils/context";
import { uploadKybDocuments } from "../../store/actions/idVerification";
import { BaseError, formatCountry } from "@trolley/common-frontend";
import { emitEvent, handleFormErrors, stringifyAddress, WidgetEvent } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { useRecipient } from "../../store/hooks/recipient";
import { createUseStyle, css } from "../../style/classname";
import { PATHS } from "pages/App/routes";

const ALLOWED_MIME_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/tiff", "image/heic", "image/heif"];
const FILE_SIZE_LIMIT_MB = 10;

export default function KybUpload() {
  useStepperEffect(ActionType.TRUST, 1 / 2);
  const [form] = useForm();
  const [status, setStatus] = useState<undefined | "saving" | "saved">();
  const [file, setFile] = useState<UploadFile>();
  const history = useHistory();
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const styledContainers = useStyledContainers();
  const styledEditLink = useStyledEditLink();

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: [PRODUCT_MODULES.TRUST],
    });
  }, []);

  async function onSubmit() {
    try {
      if (file) {
        setStatus("saving");
        await uploadKybDocuments(file);
        setStatus("saved");
        history.goBack();
      }
    } catch (errors) {
      const invalidFieldError: BaseError = errors?.find?.(
        (e: BaseError) => ["files"].includes(e.field || "") && e.code === "invalid_field",
      );
      if (invalidFieldError) {
        form.setFields([
          {
            name: "error",
            errors: [formatMessage({ id: "containers.accountSummary.ticket.errors.unsupportedFileFormat" })],
          },
        ]);
      } else {
        handleFormErrors(errors, form);
        history.goBack();
      }
      setStatus(undefined);
    }
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Container padding="large">
        <Grid alignItems="middle" padding="large" direction="column">
          <Grid.Item xs={24} sm={{ flex: "20" }}>
            <legend style={{ margin: 0 }}>
              <Title level={2} style={{ margin: 0 }}>
                {formatMessage({ id: "containers.accountSummary.kyb.upload.title" })}
              </Title>
            </legend>
          </Grid.Item>
          <Grid.Item xs={24} sm={{ flex: "20" }}>
            <Text padded>
              {formatMessage(
                {
                  id: "containers.accountSummary.kyb.upload.description",
                },
                {
                  country: formatCountry(recipient?.address?.country ?? "", formatMessage),
                },
              )}
            </Text>
            <Notification type="warning" title={formatMessage({ id: "containers.accountSummary.kyb.upload.ensure" })} />
          </Grid.Item>
        </Grid>

        <Divider transparent size="large" />
        <Grid padding="large">
          <Grid.Item xs={24} md={12}>
            <>
              <Text type="secondary" strong>
                {formatMessage({ id: "containers.accountSummary.kyb.upload.businessSection.title" })}
              </Text>
              <Divider transparent size="small" />
              <Card
                type="inner"
                className={styledContainers}
                actions={[
                  <LinkButton
                    to={{ pathname: PATHS.INFO, state: { saveAndReturn: true, from: PATHS.KYB_UPLOAD } }}
                    icon="pencil"
                    className={styledEditLink}
                    style={{ width: "unset" }}
                  >
                    {formatMessage({ id: "containers.accountSummary.kyb.upload.businessSection.edit" })}
                  </LinkButton>,
                ]}
              >
                <dl>
                  <Grid padding="medium" direction="column">
                    <Grid.Item>
                      <dt>
                        <Typography.Text italic strong>
                          {formatMessage({ id: "containers.accountSummary.kyb.upload.businessSection.businessName" })}
                        </Typography.Text>
                      </dt>
                    </Grid.Item>
                    <Grid.Item>
                      <dd>
                        <Typography.Text italic>{recipient?.name ?? "-"}</Typography.Text>
                      </dd>
                    </Grid.Item>
                    {recipient?.brn && (
                      <>
                        <Grid.Item>
                          <dt>
                            <Typography.Text italic strong>
                              {formatMessage({ id: "containers.accountSummary.kyb.upload.businessSection.brn" })}
                            </Typography.Text>
                          </dt>
                        </Grid.Item>
                        <Grid.Item>
                          <dd>
                            <Typography.Text italic>{recipient?.brn ?? "-"}</Typography.Text>
                          </dd>
                        </Grid.Item>
                      </>
                    )}
                    <Grid.Item>
                      <dt>
                        <Typography.Text italic strong>
                          {formatMessage({
                            id: "containers.accountSummary.kyb.upload.businessSection.businessAddress",
                          })}
                        </Typography.Text>
                      </dt>
                    </Grid.Item>
                    <Grid.Item>
                      <dd>
                        <Typography.Text italic>
                          {recipient
                            ? stringifyAddress({
                                ...recipient.address,
                                region: null,
                                country: null,
                                street2: null,
                                postalCode: null,
                              })
                            : "-"}
                        </Typography.Text>
                      </dd>
                    </Grid.Item>
                  </Grid>
                </dl>
              </Card>
            </>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <>
              <Text type="secondary" strong>
                {formatMessage({ id: "containers.accountSummary.kyb.upload.uploadSection.title" })}
              </Text>
              <Divider transparent size="small" />
              <GreyBox
                className={styledContainers}
                style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <div style={{ textAlign: "center", margin: "60px 0" }}>
                  <Text>{formatMessage({ id: "containers.accountSummary.kyb.upload.validation.size" })}</Text>
                  <Text>{formatMessage({ id: "containers.accountSummary.kyb.upload.validation.type" })}</Text>
                  <Divider transparent size="small" />
                  <UploadButton onSelectFile={setFile} />
                  {file && (
                    <Form.Field
                      name="error"
                      showEmptyError={false}
                      rules={[
                        () => ({
                          async validator() {
                            if (!ALLOWED_MIME_TYPES.includes(file.type)) {
                              throw new Error(
                                formatMessage({ id: "containers.accountSummary.ticket.errors.unsupportedFileFormat" }),
                              );
                            }
                            if (file.size >= FILE_SIZE_LIMIT_MB * 1024 * 1024) {
                              throw new Error(
                                formatMessage(
                                  { id: "containers.accountSummary.ticket.errors.fileSizeLimit" },
                                  { fileSizeMB: FILE_SIZE_LIMIT_MB },
                                ),
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <Form.Input hidden value={file.name} />
                    </Form.Field>
                  )}
                </div>
              </GreyBox>
            </>
          </Grid.Item>
        </Grid>
        <Divider transparent />
        <Footer
          main={
            <Button type="primary" size="large" loading={status === "saving"} disabled={!file} htmlType="submit">
              {formatMessage({
                id: "common.save",
              })}
            </Button>
          }
          extra={<BackButton title={formatMessage({ id: "common.cancel" })} />}
        />
      </Container>
    </Form>
  );
}

const useStyledContainers = createUseStyle(({ theme }) =>
  css`
    ${theme.screenUp("md")} {
      height: 100%;
    }
    .tr-card-actions {
      border-top: 0;
    }
  `(),
);

const useStyledEditLink = createUseStyle(({ theme }) =>
  css`
    border-color: ${theme.colorPrimary};
    > span {
      color: ${theme.colorPrimary};
    }
  `(),
);
