import "assets/style/global.css";
import "core-js"; // polyfill
import "antd/dist/reset.css";

import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import App from "pages/App";
import ErrorBoundary from "pages/App/ErrorBoundary";
import MainContainer from "pages/App/MainContainer";
import { PATHS } from "pages/App/routes";
import Notifier from "pages/Notifier";
import PageNotFound from "pages/PageNotFound";
import ShowRoom from "pages/ShowRoom";
import { history } from "services/history";
import store from "store";
import { ThemeProvider } from "utils/context";

try {
  (module as any)?.hot?.accept?.(); // hot module reload
} catch {}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider prefixCls="tr" form={{ scrollToFirstError: { behavior: "smooth" } }}>
        <ErrorBoundary>
          <Switch>
            <Route path={PATHS.SHOWROOM} component={ShowRoom} />
            <Route path={PATHS.PAGE_NOT_FOUND} component={PageNotFound} />
            <Route
              render={() => (
                <App>
                  <ErrorBoundary>
                    <Notifier />
                    <MainContainer />
                  </ErrorBoundary>
                </App>
              )}
            />
          </Switch>
        </ErrorBoundary>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app"),
);
