/* istanbul ignore file */
import { Country, EndOfYear, Recipient, Ticket } from "@trolley/common-frontend";
import { RouterState, routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { history } from "services/history";
import { BankCodes } from "store/actions/bankCodes";
import { AccountVerification, BankInfo } from "store/actions/bankInfo";
import { OfflinePayment } from "store/actions/offlinePayments";
import { UrlParams } from "store/actions/params";
import { Payment } from "store/actions/payments";
import { RecipientAccountConfig } from "store/actions/recipientAccountConfig";
import { RecipientConfig } from "store/actions/recipientConfig";
import { TaxForm } from "store/actions/taxForms";
import { TaxProfile } from "store/actions/taxProfiles";
import { IFrameConfig } from "store/hooks/config";
import { Merchant } from "store/hooks/merchant";
import { AuthType } from "./actions/auth";
import { W8UsWithholdingTable } from "./actions/withholdingTable";
import createRootReducer from "./reducers";
import { NotificationState } from "./reducers/notifications";
import { ListState, StandardState } from "./reducers/standardReducer";
import { VerificationHistory } from "./actions/idVerification";
import { Fees } from "store/actions/fees";

const middleware = [routerMiddleware(history)];

if (process.env.SETTINGS !== "production" || !process.env.BUILD_VERSION) {
  middleware.push(createLogger({ level: "log" }));
}

export type Store = {
  router: RouterState;
  auth: AuthType;
  notifications: NotificationState;
  params: UrlParams | null;
  config: IFrameConfig | null;
  merchant: Merchant | null;
  recipient: StandardState<Recipient>;
  recipientConfig: StandardState<RecipientConfig>;
  recipientAccountConfig: StandardState<RecipientAccountConfig>;
  accountVerification: StandardState<AccountVerification>;
  bankCodes: StandardState<BankCodes>;
  bankInfo: StandardState<BankInfo>;
  currencies: StandardState<Country.CountryCurrency[]>;
  payments: StandardState<ListState<Payment>>;
  offlinePayments: StandardState<ListState<OfflinePayment>>;
  taxForms: StandardState<ListState<string>>;
  taxForm: StandardState<TaxForm>;
  taxProfiles: StandardState<ListState<string>>;
  taxProfile: StandardState<TaxProfile>;
  dac7EoyTaxForms: StandardState<ListState<EndOfYear.DAC7RecipientEoyTax>>;
  eoyTaxForms: StandardState<ListState<EndOfYear.RecipientEoyTax>>;
  withholdingTable: StandardState<W8UsWithholdingTable>;
  tickets: StandardState<ListState<Ticket>>;
  idvSession: StandardState<string>;
  verifications: StandardState<VerificationHistory>;
  fees: StandardState<Fees>;
};

const store = createStore(createRootReducer(history), applyMiddleware(...middleware));

export default store;
