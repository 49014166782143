import React, { useState, useEffect } from "react";
import { Modal, Form2 as Form, Icon, Text, Divider } from "components";
import { parsePhoneNumber } from "utils/helpers/formatter/phoneNumber";
import { loadPhoneVerification } from "store/actions/recipient";
import { useIntl } from "utils/context";
import { useTheme } from "style/classname";
import { BaseError, CountryCode, PhoneVerificationChannels } from "@trolley/common-frontend";

type phoneVerificationProps = {
  visible: boolean;
  onClose(): void;
  onOk(): void;
  submitting?: boolean;
  phone: string;
};

export default function PhoneVerificationPopup({ visible, onClose, onOk, submitting, phone }: phoneVerificationProps) {
  const { formatMessage } = useIntl();
  const [showInput, setShowInput] = useState("send");
  const [disableOk, setDisableOk] = useState(false);
  const [showSkip, setShowSkip] = useState(false);
  const parsed = parsePhoneNumber(phone);
  const smsChannelOnly = !!(phone && parsed.country === CountryCode.CN);
  const [form] = Form.useForm();
  const theme = useTheme();

  useEffect(() => {
    // Modal does not dismount on close, Below code reset state
    if (visible) {
      setShowInput("send");
      setDisableOk(false);
      setShowSkip(false);
      form.resetFields();
    }
  }, [visible]);

  async function onFinish(values: { phone: string; channel: PhoneVerificationChannels; code?: string }) {
    if (showInput === "send") {
      try {
        const isPhoneVerified = await loadPhoneVerification(phone, values.channel);
        if (isPhoneVerified) {
          onOk();
        }
        setShowInput("verify");
      } catch (errors) {
        form.setFields([{ name: "phone", errors: [formatMessage({ id: "containers.info.phoneVerification.error" })] }]);
        setDisableOk(true);
      }
    } else {
      try {
        await loadPhoneVerification(phone, values.channel, values.code);
        onOk();
      } catch (errors) {
        const invalidFieldError: BaseError = errors?.find?.(
          (e: BaseError) => ["code"].includes(e.field || "") && e.code === "invalid_field",
        );
        if (invalidFieldError) {
          form.setFields([
            { name: "code", errors: [formatMessage({ id: "containers.info.phoneVerification.invalidCode" })] },
          ]);
          setShowSkip(true); // Force atleast 1 verification attempt, verification can be skipped after 1 failled attempt
        } else {
          form.setFields([
            { name: "code", errors: [formatMessage({ id: "containers.info.phoneVerification.error" })] },
          ]);
          setDisableOk(true);
        }
      }
    }
  }

  return visible ? (
    <Modal
      open={!!visible}
      onCancel={disableOk || showSkip ? onOk : onClose}
      destroyOnClose
      onOk={form.submit}
      confirmLoading={submitting}
      cancelText={
        showInput === "verify" && (disableOk || showSkip)
          ? formatMessage({ id: "containers.info.phoneVerification.skip" })
          : formatMessage({ id: "containers.info.phoneVerification.cancel" })
      }
      okButtonProps={{ disabled: disableOk }}
      okText={
        showInput === "verify"
          ? formatMessage({ id: "containers.info.phoneVerification.verifyCode" })
          : formatMessage({ id: "containers.info.phoneVerification.sendCode" })
      }
      title={formatMessage({ id: "containers.info.phoneVerification.popupTitle" })}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ phone: phone, channel: PhoneVerificationChannels.SMS }}
        validateTrigger="onSubmit"
        style={{ padding: `${theme.paddingXS}px` }}
      >
        <Divider size="xlarge" />
        {!smsChannelOnly && (
          <Form.Item
            name="channel"
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.phoneVerification.channel.required" }),
              },
            ]}
          >
            <Form.Radio.Group
              direction="horizontal"
              optionType="card"
              name="radio"
              disabled={showInput !== "send"}
              options={[
                {
                  label: (
                    <>
                      <Icon type="message-dots" theme="solid" size="large" />
                      <Text strong padded>
                        {formatMessage({ id: "containers.info.phoneVerification.channel.sms" })}
                      </Text>
                    </>
                  ),
                  value: PhoneVerificationChannels.SMS,
                },
                {
                  label: (
                    <>
                      <Icon type="phone-volume" theme="solid" size="large" />
                      <Text strong padded>
                        {formatMessage({ id: "containers.info.phoneVerification.channel.call" })}
                      </Text>
                    </>
                  ),
                  value: PhoneVerificationChannels.CALL,
                },
              ]}
            />
          </Form.Item>
        )}
        {showInput === "send" ? (
          <Form.Item
            label={formatMessage({
              id: smsChannelOnly
                ? "containers.info.phoneVerification.popupTextSms"
                : "containers.info.phoneVerification.popupText",
            })}
            name="phone"
            required
          >
            <Form.InputPhone name="phone" readOnly />
          </Form.Item>
        ) : (
          <Form.Item
            label={formatMessage({ id: "containers.info.phoneVerification.popupText2" })}
            name="code"
            normalize={(val) => String(val || "").replace(/[^0-9]/, "")}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.phoneVerification.enterValidCode" }),
              },
              {
                pattern: /^\d{6}$/,
                message: formatMessage({ id: "containers.info.phoneVerification.enterValidCode" }),
              },
            ]}
          >
            <Form.Input type="one-time-code" name="code" inputMode="numeric" maxLength={6} />
          </Form.Item>
        )}
        <Form preserve={false} />
      </Form>
    </Modal>
  ) : null;
}
