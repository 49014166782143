import qs from "qs";
import { Query } from "utils/helpers";

export default function stringifyQuery<T extends Query>(query: T | undefined | null, addQueryPrefix: boolean = true) {
  if (!query || typeof query !== "object") {
    return "";
  }

  const formattedQuery = Object.entries(query).reduce((acc: Query, [k, v]) => {
    if (Array.isArray(v)) {
      /**
       * // To FIX in the future:
       *
       * Currently qs.stringify is bugged.
       * qs.stringify({ a: ["x,y", "z"], b: "other"}, { arrayFormat: "comma" })
       * Expect: 'a=x%2Cy,z&b=other'
       * Actual: 'a=x%2Cy%2Cz&b=other'
       *
       * At the moment, we need to encodeURIComponent each query element, and stringify using encode=false
       * */
      acc[k] = v.length ? v.map(encodeURIComponent) : null; // to be changed when qs bug is fixed
    } else if (typeof v === "object") {
      acc[k] = v;
    } else {
      acc[k] = v === null || v === undefined ? null : encodeURIComponent(v);
    }

    return acc;
  }, {});

  return qs.stringify(formattedQuery, {
    addQueryPrefix,
    arrayFormat: "comma",
    encode: false, // to be removed when qs is fixed
  });
}
