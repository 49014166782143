import { BaseError } from "@trolley/common-frontend";
import { App, ModalFuncProps } from "antd";
import React from "react";

import Text from "components/Text";
import { Theme, classnames, createUseStyle, css } from "style/classname";
import { ConnectedIntlProvider, useIntl } from "utils/context";

interface ConfirmProps
  extends Omit<ModalFuncProps, "icon" | "closeIcon" | "closable" | "okButtonProps" | "cancelButtonProps"> {
  okButtonProps?: Omit<
    ModalFuncProps["okButtonProps"],
    | "loading" // this is handled internally
    | "disabled"
  >;
  cancelButtonProps?: Omit<ModalFuncProps["cancelButtonProps"], "loading" | "disabled">;
}

interface InfoProps
  extends Omit<ModalFuncProps, "icon" | "closeIcon" | "closable" | "okButtonProps" | "cancelButtonProps" | "onCancel"> {
  okButtonProps?: Omit<
    ModalFuncProps["okButtonProps"],
    | "loading" // this is handled internally
    | "disabled"
  >;
}

export default function useModal() {
  const { modal } = App.useApp();
  const { formatMessage } = useIntl();
  const styledConfirm = useStyledConfirm();

  function confirm({
    centered = true,
    maskClosable = true,
    className,
    onOk,
    okText = formatMessage({ id: "common.yes" }),
    cancelText = formatMessage({ id: "common.no" }),
    content,
    ...rest
  }: ConfirmProps) {
    const m = modal.confirm({
      modalRender: (node) => <ConnectedIntlProvider>{node}</ConnectedIntlProvider>,
      icon: null,
      closable: false,
      centered,
      autoFocusButton: null, // default it focus on ok button
      maskClosable,
      className: classnames(styledConfirm, className),
      cancelText,
      content,
      okText,
      onOk: async (e) => {
        // we need to manually update the render state. it is not reactive
        if (onOk) {
          try {
            m.update((prev) => ({
              ...prev,
              maskClosable: false,
              okButtonProps: {
                ...prev?.okButtonProps,
              },
              cancelButtonProps: {
                ...prev?.cancelButtonProps,
                disabled: true,
                onClick(e) {
                  e?.preventDefault?.();
                },
              },
              content,
            }));
            await onOk(e);
          } catch (err) {
            const errorStr: string | undefined =
              err?.message || err?.find?.((baseErr: BaseError) => baseErr?.message)?.message;
            m.update({
              maskClosable,
              content: errorStr ? (
                <>
                  {content}
                  <Text type="danger" padded>
                    {errorStr}
                  </Text>
                </>
              ) : (
                content
              ),
            });
            throw err;
          }
        }
      },
      ...rest,
    });

    return m;
  }

  function info({
    centered = true,
    maskClosable = true,
    className,
    okText = formatMessage({ id: "common.close" }),
    content,
    ...rest
  }: InfoProps) {
    const m = modal.info({
      modalRender: (node) => <ConnectedIntlProvider>{node}</ConnectedIntlProvider>,
      icon: null,
      closable: false,
      centered,
      maskClosable,
      className: classnames(styledConfirm, className),
      content,
      okText,
      onCancel: rest.onOk,
      ...rest,
    });

    return m;
  }

  return {
    confirm,
    info,
  };
}

const useStyledConfirm = createUseStyle(({ theme }) =>
  css<{ theme: Theme }>`
    .${theme.prefixCls}-modal-content {
      padding: ${theme.paddingLG}px;
      .${theme.prefixCls}-modal-confirm-title + .${theme.prefixCls}-modal-confirm-content {
        max-width: inherit;
      }
    }
    .${theme.prefixCls}-modal-confirm-btns {
      margin-top: ${theme.marginLG}px;
      .${theme.prefixCls}-btn {
        min-width: 80px;
      }
    }
  `(),
);
