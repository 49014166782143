import RCCollapse from "rc-collapse";
import RCPanel from "rc-collapse/lib/Panel";
import { CollapseProps as RCCollapseProps } from "rc-collapse/lib/interface";
import { CSSMotionProps, MotionEndEventHandler, MotionEventHandler } from "rc-motion";
import React, { ReactNode } from "react";
import { classnames, createUseStyle, css } from "style/classname";

interface Props extends RCCollapseProps {
  size?: "small" | "default";
  children: ReactNode;
}

export default function Collapse({ className, size = "default", accordion = true, ...rest }: Props) {
  const styledCollapse = useStyledCollapse({ ...rest, size });

  return (
    <RCCollapse
      {...rest}
      className={classnames(styledCollapse, className)}
      openMotion={collapseMotion}
      accordion={accordion}
    />
  );
}

Collapse.Panel = RCPanel;

const useStyledCollapse = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    .rc-collapse-motion {
      transition: height 0.25s, opacity 0.25s;
    }
    .rc-collapse-content-hidden {
      display: none;
    }
    &.rc-collapse {
      border-radius: ${theme.borderRadius}px;
      border: 1px solid ${theme.colorBorder};
      overflow: hidden;
    }
    .rc-collapse-item {
      border-top: 1px solid ${theme.colorBorder};
      &:first-child {
        border-top: none;
      }

      &:last-child > .rc-collapse-content {
        border-radius: 0 0 3px 3px;
      }
    }
    .rc-collapse-header {
      position: relative;
      display: flex;
      align-items: center;
      line-height: 22px;
      padding: ${props.size === "small" ? "4px 40px 4px 8px" : "8px 40px 8px 12px"};
      min-height: 48px;
      color: ${theme.colorTextHeading};
      cursor: pointer;
      transition: background-color 0.15s ease;
      &:hover {
        background-color: ${theme.colorPrimaryBg};
      }
      &:focus-visible {
        outline: ${theme.lineWidth}px ${theme.lineType} ${theme.colorPrimary};
      }
      .rc-collapse-extra {
        margin: 0 16px 0 auto;
      }
      .arrow {
        font-style: normal;
        position: absolute;
        margin-top: -10px;
        top: 50%;
        right: 16px;
        &:before {
          display: block;
          color: ${theme.colorPrimary};
          font-size: ${theme.fontSize}px;
          transition: transform 0.2s ease;
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          content: "\\f078"; //circle
        }
      }
    }
    .rc-collapse-item-active {
      & > .rc-collapse-header {
        .arrow {
          &:before {
            transform: scaleY(-1);
          }
        }
      }
    }
    .rc-collapse-header-collapsible-only {
      cursor: default;
      .rc-collapse-header-text {
        cursor: pointer;
      }
    }

    .rc-collapse-content {
      overflow: hidden;
      padding: 0 8px;
      color: ${theme.colorText};
      background-color: ${theme.colorBgLayout};
      & > .rc-collapse-content-box {
        margin: 16px 0;
      }
    }
  `(),
);

const getCollapsedHeight: MotionEventHandler = () => ({
  height: 0,
  opacity: 0,
});
const getRealHeight: MotionEventHandler = (node) => ({
  height: node.scrollHeight,
  opacity: 1,
});
const getCurrentHeight: MotionEventHandler = (node) => ({
  height: node.offsetHeight,
});
const skipOpacityTransition: MotionEndEventHandler = (_, event) => (event as TransitionEvent).propertyName === "height";

export const collapseMotion: CSSMotionProps = {
  motionName: "rc-collapse-motion",
  onEnterStart: getCollapsedHeight,
  onEnterActive: getRealHeight,
  onLeaveStart: getCurrentHeight,
  onLeaveActive: getCollapsedHeight,
  onEnterEnd: skipOpacityTransition,
  onLeaveEnd: skipOpacityTransition,
  motionDeadline: 500,
  leavedClassName: "rc-collapse-content-hidden",
};
