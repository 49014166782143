import { GreyBox, Modal, Text } from "components";
import { useIntl } from "utils/context";
import React from "react";

interface Props {
  onClose(): void;
  onOk(): void;
  rules: {
    noUsCertification?: boolean;
    noTaxId?: boolean;
    noTreatyCountry?: boolean;
  };
  visible?: boolean;
}

export default function WithholdingWarningPopup({ visible, rules, onOk, onClose }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={!!visible}
      onCancel={onClose}
      onOk={onOk}
      okText={formatMessage({ id: "common.continue" })}
      title={formatMessage({ id: "containers.tax.subjectToWithholdingTitle" })}
    >
      <GreyBox>
        <span>{formatMessage({ id: "containers.tax.w8.reviewWithholdingNote" })}</span>
        <ul>
          {rules.noUsCertification && (
            <li>
              <Text strong>{formatMessage({ id: "containers.tax.w8.notCertified" })}</Text>
            </li>
          )}
          {rules.noTaxId && (
            <li>
              <Text strong>{formatMessage({ id: "containers.tax.w8.noTin" })}</Text>
            </li>
          )}
          {rules.noTreatyCountry && (
            <li>
              <Text strong>{formatMessage({ id: "containers.tax.w8.noTreatyCountry" })}</Text>
            </li>
          )}
        </ul>
      </GreyBox>
    </Modal>
  );
}
