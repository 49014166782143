import { useEffect } from "react";
import { useShallowSelector } from ".";
import { emptyList } from "store/reducers/standardReducer";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "./recipient";

export function useTickets() {
  const recipient = useRecipient();

  useEffect(() => {
    if (recipient?.id) {
      loadTicketsWithDelay(recipient.id);
    }
  }, []);

  return useShallowSelector((state) => {
    if (state.tickets.fetchStatus.data === undefined && recipient?.id) {
      loadTicketsWithDelay(recipient.id);
    }

    return {
      data: state.tickets.entities.data || emptyList,
      error: state.tickets.errors.data,
      status: state.tickets.fetchStatus.data,
    };
  });
}
