import { PaymentStatus, getReturnReasonText } from "@trolley/common-frontend";
import { Notification } from "components";
import React from "react";
import { useIntl, IntlMessageKeys } from "utils/context";
import { isLoaded } from "store/actions/actionUtils";
import { RecipientAccount } from "store/actions/recipient";
import { usePayments } from "store/hooks/payments";
import { useRecipient } from "store/hooks/recipient";

type Props = {
  account: RecipientAccount;
};

export default function DisabledNotice({ account }: Props) {
  const recipient = useRecipient();
  const { data: payments, status: paymentsStatus } = usePayments(recipient?.id, {
    page: 1,
    pageSize: 1000,
    status: PaymentStatus.RETURNED,
  });
  const intl = useIntl();

  if (isLoaded(paymentsStatus)) {
    const associatedPayment = payments.records.find((p) => p.account?.id === account.id && p.returnedReason?.length);

    if (associatedPayment) {
      return (
        <Notification
          type="error"
          data-testid="disable"
          title={intl.formatMessage({
            id: "containers.bankPayoutMethod.disabledAccount",
          })}
        >
          {associatedPayment && (
            <ul>
              {associatedPayment.returnedReason?.map((r) => (
                <li key={r}>
                  {intl.formatMessage({
                    id: `containers.bankPayoutMethod.disabledReason.${r}` as IntlMessageKeys,
                    defaultMessage: getReturnReasonText(r),
                  })}
                </li>
              ))}
            </ul>
          )}
        </Notification>
      );
    }
  }

  return null;
}
