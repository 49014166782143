import { HTMLAttributes, default as React } from "react";
import css, { classnames, createUseStyle } from "style/classname";

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: keyof HTMLElementTagNameMap;
  padding?: "small" | "large" | "default";
  margin?: "none" | "small" | "large";
  size?: "default" | "small" | "medium" | "large" | "xlarge" | "2x";
  border?: boolean;
}

export default function GreyBox({
  type = "div",
  className,
  children,
  padding = "default",
  margin = "none",
  size = "default",
  border = true,
  ...rest
}: Props) {
  const styledContainer = useStyle({
    className,
    children,
    padding,
    margin,
    size,
    border,
    ...rest,
  });

  return React.createElement(type, { ...rest, className: classnames(className, styledContainer) }, children);
}

const useStyle = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    width: 100%;
    background-color: ${theme.grey2};
    border-radius: ${theme.borderRadius}px;
    border: ${props.border ? `${theme.lineWidth}px solid ${theme.colorBorder}` : 0};
    padding: ${() => {
      switch (props.padding) {
        case "small":
          return "8px";
        case "large":
          return "28px 32px";
        default:
          return "16px";
      }
    }};
    margin: ${() => {
      switch (props.margin) {
        case "small":
          return "8px 0px";
        case "large":
          return "16px 0px";
        default:
          return "0px";
      }
    }};
    ${() => {
      switch (props.size) {
        case "small":
          return `font-size: ${theme.fontSizeSM}px;`;
        case "medium":
          return `font-size: ${theme.fontSize}px;`;
        case "large":
          return `font-size: ${theme.fontSizeLG}px;`;
        case "xlarge":
          return `font-size: ${theme.fontSizeXL}px;`;
        case "2x":
          return `font-size: ${theme.fontSize * 2}px;`;
        default:
          return "";
      }
    }};
  `(),
);
