import { getBankRuleProps } from "@trolley/common-frontend";
import Form from "components/Form2";
import React from "react";
import { useIntl } from "utils/context";

interface Props {
  disabled?: boolean;
}

export default function BankRegionCodeFormField({ disabled }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Form.Control dependencies={["currency", "country"]}>
      {({ getFieldsValue }) => {
        const { country, currency } = getFieldsValue();
        const bankRegionCodeProps = getBankRuleProps("bankRegionCode", undefined, { country, currency }, formatMessage);

        return bankRegionCodeProps ? (
          <Form.Field
            name="bankRegionCode"
            key="bank_field_bankRegionCode"
            validateFirst
            label={bankRegionCodeProps.label}
            normalize={bankRegionCodeProps.normalize}
            rules={bankRegionCodeProps.rules}
          >
            <Form.SelectRegion disabled={disabled} country={country} />
          </Form.Field>
        ) : null;
      }}
    </Form.Control>
  );
}
