import React from "react";
import { useIntl } from "utils/context";

import { Divider, LinkButton, Paragraph, Text } from "components";
import { PATHS } from "pages/App/routes";
import { CompletedIcon, IncompleteIcon } from "pages/PayoutComplete";
import { useMerchant } from "store/hooks/merchant";

interface Props {
  enabled: boolean;
  completed: boolean;
}

export default function RequireEUTax({ enabled, completed }: Props) {
  const { formatMessage } = useIntl();
  const merchant = useMerchant();

  return enabled ? (
    <>
      {completed ? (
        <>
          <Text type="success" key="tax/complete" padded data-test="taxFormCompleted">
            <CompletedIcon />
            You have completed a Tax Profile.
          </Text>
          <Divider transparent size="large" />

          <Paragraph limitWordPerLine style={{ marginLeft: "auto", marginRight: "auto" }}>
            {formatMessage(
              { id: "containers.payoutComplete.reviewDetails" },
              { done: <b>{formatMessage({ id: "common.done" })}</b> },
            )}
          </Paragraph>

          <Divider transparent size="large" />

          <LinkButton size="large" type="primary" to={PATHS.HOME}>
            {formatMessage({ id: "common.done" })}
          </LinkButton>
        </>
      ) : (
        <>
          <Text padded type="secondary" key="tax-incomplete" data-test="taxFormNotCompleted">
            <IncompleteIcon />
            You have not completed a Tax Profile yet.
          </Text>
          {merchant?.euTax?.paymentBehaviour === "block" && (
            <>
              <Divider transparent />
              <Paragraph limitWordPerLine style={{ marginLeft: "auto", marginRight: "auto" }}>
                We are required to collect your Tax information in order to send you payments. You can complete a tax
                profile now, or you can choose to complete it later.
              </Paragraph>
            </>
          )}
          <Divider transparent size="large" />
          <LinkButton size="large" type="primary" to={PATHS.WIZARD_TAXPROFILE_NEW}>
            Submit a Tax Profile
          </LinkButton>
          <Divider transparent />
          <LinkButton size="large" to={PATHS.HOME}>
            {formatMessage({ id: "common.done" })}
          </LinkButton>
        </>
      )}
    </>
  ) : null;
}
