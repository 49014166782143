import { RecipientType } from "@trolley/common-frontend";
import { FormInstance } from "antd";
import { Container, Divider, Form2, GreyBox, Icon, Typography } from "components";
import React from "react";
import { TaxProfile } from "store/actions/taxProfiles";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  confirmedRecipientType: string;
}

interface Props extends WizardPageProps {
  taxProfile?: TaxProfile;
}

export default function ConfirmRecipientType({ taxProfileWizard, onContinue, taxProfile }: Props) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    confirmedRecipientType: taxProfile?.recipientType ? taxProfile?.recipientType : "",
  };

  function onSubmit({ confirmedRecipientType }: IFormData) {
    onContinue({
      confirmedRecipientType: confirmedRecipientType as RecipientType,
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.taxProfile.wizard.confirmIndividualRecipientTypeTitle" })
                : formatMessage({ id: "containers.taxProfile.wizard.confirmBusinessRecipientTypeTitle" })}{" "}
              {formatMessage({ id: "containers.taxProfile.wizard.confirmRecipientTypeIsThisTrue" })}
            </Typography.Title>
          </legend>
          <Divider transparent />
          <GreyBox size="2x" border={false}>
            <Typography.Text strong>
              {taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.info.type.individual" })
                : formatMessage({ id: "containers.info.type.business" })}
            </Typography.Text>
          </GreyBox>
          <Divider transparent size="xlarge" />
          <Form2.Item name="confirmedRecipientType">
            <Form2.Radio.Group
              optionType="card"
              direction="vertical"
              name="radio"
              options={[
                {
                  label:
                    taxProfileWizard.payoutRecipientType === RecipientType.INDIVIDUAL
                      ? formatMessage({ id: "containers.taxProfile.wizard.yesIndividualRecipientType" })
                      : formatMessage({ id: "containers.taxProfile.wizard.yesBusinessRecipientType" }),
                  value: taxProfileWizard.payoutRecipientType ?? "",
                },
                {
                  label: formatMessage(
                    { id: "containers.taxProfile.wizard.noRecipientType" },
                    {
                      recipientType:
                        taxProfileWizard.payoutRecipientType === RecipientType.BUSINESS
                          ? formatMessage({ id: "containers.info.type.individual" })
                          : formatMessage({ id: "containers.info.type.business" }),
                    },
                  ),
                  value: `${
                    taxProfileWizard.payoutRecipientType === RecipientType.BUSINESS
                      ? RecipientType.INDIVIDUAL
                      : RecipientType.BUSINESS
                  }`,
                },
              ]}
            />
          </Form2.Item>
        </fieldset>
        <Form2.Item dependencies={["confirmedRecipientType"]}>
          {(form: FormInstance<IFormData>) => {
            const confirmedRecipientType = form.getFieldValue("confirmedRecipientType");

            return (
              <Form2.SubmitButton
                disabled={!confirmedRecipientType}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
