import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { PATHS } from "./routes";
import { LocationDescriptor } from "history";

interface RestrictedRouteProps extends RouteProps {
  isEntitled: boolean;
  redirectTo?: LocationDescriptor<unknown>;
}

export default function RestrictedRoute({ isEntitled, redirectTo, ...rest }: RestrictedRouteProps) {
  return isEntitled ? <Route {...rest} /> : <Redirect to={redirectTo ?? PATHS.HOME} />;
}
