import { Form as AntForm, FormItemProps } from "antd";
import { Icon } from "components";
import React, { ReactNode } from "react";
import { classnames, createUseStyle, css } from "style/classname";

export interface ItemProps<V = any> extends Omit<FormItemProps<V>, "tooltip" | "noStyle"> {
  tooltip?: ReactNode;
}

export default function FormItem<V = any>(props: ItemProps<V>) {
  const { tooltip, validateFirst = true, className, ...rest } = props;
  const styledFormItem = useStyledFormItem(props);

  return (
    <AntForm.Item
      {...rest}
      noStyle
      validateFirst={validateFirst}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <Icon type="circle-question" theme="solid" />,
        }
      }
      className={classnames(styledFormItem, className)}
    />
  );
}

const useStyledFormItem = createUseStyle<ItemProps>(({ theme }) =>
  css`
    &.${theme.prefixCls}-form-item {
      .${theme.prefixCls}-form-item-label {
        font-weight: bold;
      }
    }
  `(),
);
