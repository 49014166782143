import { CountryCode, formatCountry, PayoutMethodType, UsTeritories } from "@trolley/common-frontend";
import { Container, CurrencyDisplay, Divider, Form, Grid, Loader, Notification, Text } from "components";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import { DisabledNotice } from "features";
import { useIntl } from "utils/context";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { POST } from "services/request";
import { addPayoutMethod, CheckAccountUpdate, RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useRecipient } from "store/hooks/recipient";
import { handleFormErrors } from "utils/helpers";

import { useMerchant } from "store/hooks/merchant";
import { isValidUsPostalCode } from "../Info2";
import PayoutFooter from "./PayoutFooter";
import config from "config";

export const checkCountries = UsTeritories;

export default function Check({ account }: { account: RecipientAccount | undefined }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const iFrameConfig = useIframeConfig();
  const recipient = useRecipient();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const [activate, setActivate] = useState(false);
  const [validAddress, setValidAddress] = useState<"deliverable" | "undeliverable" | undefined>();
  const merchant = useMerchant();
  const checkPayoutMethod = merchant?.enabledPayoutMethods.find((pm) => pm.integration === PayoutMethodType.CHECK);
  const deliveryEstimate =
    account?.deliveryBusinessDaysEstimate || checkPayoutMethod?.deliveryBusinessDaysEstimate || 0;

  async function onFinish(values: any) {
    if (!submitting && recipient) {
      setSubmitting(true);
      try {
        const { body } = await POST<any>("/v1/address/lookup", {
          query: values,
        });
        setValidAddress(body?.addressData?.deliverability || "undeliverable");
        if (body?.addressData?.deliverability === "deliverable") {
          const postData: CheckAccountUpdate = {
            type: PayoutMethodType.CHECK,
            mailing: values,
          };

          if (activate) {
            postData.primary = true;
          }

          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, postData);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, postData);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        setValidAddress(undefined);
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form
      form={form}
      initialValues={account?.mailing ?? { country: recipient?.address.country }}
      onFieldsChange={() => {
        setValidAddress(undefined);
      }}
      onFinish={onFinish}
      onFinishFailed={() => {
        setActivate(false);
      }}
    >
      <Loader spinning={submitting}>
        <Container>
          {iFrameConfig.showFees && (
            <>
              <Text>
                {intl.formatMessage({
                  id: "containers.bankPayoutMethod.processingTimes.title",
                })}
                <b>
                  {deliveryEstimate < 1
                    ? intl.formatMessage({ id: "containers.payoutMethod.processingTimes.sameDay" })
                    : intl.formatMessage(
                        {
                          id: "containers.payoutMethod.processingTimes.bussinessDaysEstimate",
                        },
                        { from: deliveryEstimate, to: deliveryEstimate + 1 },
                      )}
                </b>{" "}
              </Text>
              {recipient?.gatewayFees?.check ? (
                <Text size="small">
                  {intl.formatMessage({
                    id: "containers.payoutMethod.estimatedFees",
                  })}
                  :{" "}
                  <b>
                    <CurrencyDisplay
                      value={recipient.gatewayFees.check.value}
                      currency={recipient.gatewayFees.check.currency}
                    />
                  </b>
                </Text>
              ) : null}
            </>
          )}
          <Divider transparent />
          {account?.status === "disabled" && <DisabledNotice account={account} />}

          <Form.Field
            name="name"
            label={intl.formatMessage({
              id: "containers.checkPayoutMethod.name.title",
            })}
            hint={intl.formatMessage({
              id: "containers.checkPayoutMethod.name.hint",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "containers.checkPayoutMethod.name.required",
                }),
              },
            ]}
          >
            <Form.Input name="name" />
          </Form.Field>

          <Text padded>
            {intl.formatMessage({
              id: "containers.checkPayoutMethod.mailingAddress",
            })}
          </Text>

          <Form.Field
            name="country"
            label={intl.formatMessage({ id: "containers.info.country.title" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "containers.info.country.required",
                }),
              },
            ]}
          >
            <Form.SelectCountry selectableCountries={checkCountries} type="address" name="country" />
          </Form.Field>

          <Grid>
            <Grid.Item xs={24} sm={12}>
              <Form.Field
                name="street1"
                label={intl.formatMessage({
                  id: "containers.checkPayoutMethod.street1.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.checkPayoutMethod.street1.required",
                    }),
                  },
                  (_form) => ({
                    async validator(rule: any, value: string) {
                      const fullAddress = [value, _form.getFieldValue("street2") || ""].join("");
                      if (fullAddress.length > 50) {
                        throw intl.formatMessage({
                          id: "containers.checkPayoutMethod.street1.exceed50",
                        });
                      }
                    },
                  }),
                ]}
              >
                <Form.Input name="street1" />
              </Form.Field>
            </Grid.Item>

            <Grid.Item xs={24} sm={12}>
              <Form.Field
                name="street2"
                initialValue=""
                label={intl.formatMessage({
                  id: "containers.checkPayoutMethod.street2.title",
                })}
              >
                <Form.Input name="street2" />
              </Form.Field>
            </Grid.Item>
            <Grid.Item xs={24} sm={12}>
              <Form.Field
                name="city"
                label={intl.formatMessage({ id: "containers.info.city.title" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.info.city.required",
                    }),
                  },
                ]}
              >
                <Form.Input name="city" />
              </Form.Field>
            </Grid.Item>
            <Grid.Item xs={24} sm={12}>
              <Form.Control dependencies={["country"]}>
                {({ value: { country } }) => (
                  <Form.Field
                    name="region"
                    label={intl.formatMessage({
                      id:
                        country === CountryCode.US ? "containers.info.region.US.title" : "containers.info.region.title",
                    })}
                    rules={[
                      {
                        required: true,
                        message:
                          country === CountryCode.US
                            ? intl.formatMessage({
                                id: "containers.info.region.US.required",
                              })
                            : intl.formatMessage({
                                id: "containers.info.region.required",
                              }),
                      },
                      isRegionInCountryValidator("country", intl.formatMessage),
                    ]}
                  >
                    <Form.SelectRegion name="region" country={country} />
                  </Form.Field>
                )}
              </Form.Control>
            </Grid.Item>

            <Grid.Item xs={24} sm={12}>
              <Form.Field
                name="postal"
                label={intl.formatMessage({
                  id: "containers.info.postalCode.US.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.info.postalCode.US.required",
                    }),
                  },
                  {
                    async validator(_: any, value: string) {
                      if (value && config.ENV === "production" && !isValidUsPostalCode(value)) {
                        throw intl.formatMessage({
                          id: "containers.info.postalCode.US.valid",
                        });
                      }
                    },
                  },
                ]}
              >
                <Form.Input name="postal" />
              </Form.Field>
            </Grid.Item>
          </Grid>

          <Notification
            type={validAddress === "undeliverable" ? "error" : "info"}
            iconProps={{
              type: validAddress === "undeliverable" ? "map-marker-exclamation" : "map-marker-alt",
              theme: "solid",
            }}
            title={intl.formatMessage({
              id:
                validAddress === "undeliverable"
                  ? "containers.checkPayoutMethod.invalidAddress"
                  : "containers.checkPayoutMethod.addressVerification",
            })}
          >
            {intl.formatMessage({
              id:
                validAddress === "undeliverable"
                  ? "containers.checkPayoutMethod.invalidAddressDescription"
                  : "containers.checkPayoutMethod.addressVerificationDescription",
            })}

            <Form.Control shouldUpdate>
              {({ value: { name, street1, street2, city, region, postal, country } }) => (
                <Text strong padded>
                  {name && <div>{name}</div>}
                  {street1 && (
                    <div>
                      {street1} {street2}
                    </div>
                  )}
                  {(city || region) && (
                    <div>
                      {[city, region].filter((v) => v).join(", ")} {postal}
                    </div>
                  )}
                  {formatCountry(country, intl.formatMessage)}
                </Text>
              )}
            </Form.Control>
          </Notification>
          <PayoutFooter
            setBusy={setSubmitting}
            busy={submitting}
            account={account}
            onSave={() => {
              setActivate(false);
            }}
            onSaveActivate={() => {
              setActivate(true);
            }}
          />
        </Container>
      </Loader>
    </Form>
  );
}
