import { TaxFormType, TaxStatus, UsUploadTaxFormType } from "@trolley/common-frontend";
import {
  BackButton,
  Button,
  Container,
  DateDisplay,
  Divider,
  Footer,
  GreyBox,
  Icon,
  LinkButton,
  Loader,
  Notification,
  Paragraph,
  Text,
  TitleBar,
  Tooltip,
} from "components";
import { RadioGroup } from "components/Form2/Radio";
import { PATHS } from "pages/App/routes";
import WizardPopup from "pages/WizardPopup";
import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { copyForm, getTaxFormLabel } from "store/actions/taxForms";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useUrlParams } from "store/hooks/params";
import { useTaxForms } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { ActionType, PRODUCT_MODULES, useIntl, useStepperEffect } from "utils/context";
import { emitEvent, WidgetEvent } from "utils/helpers";

export default function TaxSection() {
  const config = useIframeConfig();
  const history = useHistory();
  const merchant = useMerchant();
  const { locale } = useUrlParams();
  const { formatMessage } = useIntl();
  const { data: taxForms, status: taxFormsStatus } = useTaxForms();

  useStepperEffect(ActionType.TAX_US, 0);

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: [PRODUCT_MODULES.TAX],
    });
  }, []);

  const { submittedForm, expiredForm, ...formsInProgress } = useMemo(
    () => ({
      [TaxFormType.W8BEN]: taxForms.records.find(
        (form) => form.kind === TaxFormType.W8BEN && !form.signed && form.status === TaxStatus.INCOMPLETE,
      ),
      [TaxFormType.W8BENE]: taxForms.records.find(
        (form) => form.kind === TaxFormType.W8BENE && !form.signed && form.status === TaxStatus.INCOMPLETE,
      ),
      [TaxFormType.W9]: taxForms.records.find(
        (form) => form.kind === TaxFormType.W9 && !form.signed && form.status === TaxStatus.INCOMPLETE,
      ),
      expiredForm: taxForms.records.find(
        (form) =>
          [TaxFormType.W8BENE, TaxFormType.W8BEN].includes(form.kind as TaxFormType) &&
          form.signed &&
          form.status === TaxStatus.EXPIRED,
      ),
      submittedForm: taxForms.records.find(
        (form) =>
          [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(form.status as TaxStatus) &&
          [TaxFormType.W9, TaxFormType.W8BEN, TaxFormType.W8BENE].includes(form.kind as TaxFormType),
      ),
    }),
    [taxForms],
  );

  const [type, setType] = useState<TaxFormType>();
  const [isUsPerson, setIsUsPerson] = useState<boolean | undefined>();
  const [showWizard, setShowWizard] = useState(false);

  // TODO - handle the fact that this wont be translated
  const taxText = config.taxHelpText || formatMessage({ id: "containers.tax.defaultTaxHelpText" });
  const showInProgress = Object.values(formsInProgress).some((v) => !!v); // W8BENEInProgress || W8BENInProgress || W9InProgress;

  const tooltip = (
    <Icon
      theme="solid"
      type="question-circle"
      color="grey"
      tooltip={
        <div>
          <Text size="large">{formatMessage({ id: "containers.tax.usPerson" })}</Text>
          <ul>
            <li>{formatMessage({ id: "containers.tax.usPersonInfo.individual" })}</li>
            <li>{formatMessage({ id: "containers.tax.usPersonInfo.company" })}</li>
            <li>{formatMessage({ id: "containers.tax.usPersonInfo.estate" })}</li>
            <li>{formatMessage({ id: "containers.tax.usPersonInfo.trust" })}</li>
          </ul>
        </div>
      }
    />
  );

  if (!merchant) {
    return null;
  }

  function getPathParams() {
    if (merchant?.tax?.collectionType === "us_only") {
      return formsInProgress[TaxFormType.W9]
        ? { pathname: `${PATHS.US_TAX}/${formsInProgress[TaxFormType.W9]?.id}` }
        : { pathname: PATHS.US_TAX_NEW, state: { formType: TaxFormType.W9 } };
    } else if (type) {
      return formsInProgress[type]
        ? { pathname: `${PATHS.US_TAX}/${formsInProgress[type].id}` }
        : { pathname: PATHS.US_TAX_NEW, state: { formType: type } };
    }

    return {}; // this won't happen, it's there only to complain with type validation
  }

  const continueParams = getPathParams();

  return (
    <>
      <Loader spinning={taxFormsStatus === BaseStatus.LOADING}>
        <Container>
          <Helmet>
            <title>
              {merchant.tax?.collectionType === "us_international"
                ? formatMessage({ id: "containers.tax.taxFormSelection" })
                : formatMessage({ id: "containers.tax.taxFormW9" })}
            </title>
          </Helmet>
          <TitleBar>
            {merchant.tax?.collectionType === "us_international"
              ? formatMessage({ id: "containers.tax.taxFormSelection" })
              : formatMessage({ id: "containers.tax.taxFormW9" })}
          </TitleBar>

          {locale && locale !== "en" && (
            <Tooltip
              title={formatMessage(
                { id: "containers.tax.digitalFormText" },
                {
                  link: (c) => (
                    <Button type="link" href="https://www.irs.gov/forms-instructions" rel="noreferrer" target="_blank">
                      {c}
                      <Icon type="external-link" right size="small" />
                    </Button>
                  ),
                },
              )}
            >
              <Text inline style={{ textDecoration: "underline dashed" }}>
                <Icon theme="solid" color="grey" left type="question-circle" />
                {formatMessage({ id: "containers.tax.digitalFormTooltip" })}
              </Text>
            </Tooltip>
          )}
          {submittedForm ? (
            <GreyBox margin="small">
              {formatMessage(
                { id: "containers.tax.submittedForm" },
                {
                  p: (c) => <p>{c}</p>,
                  date: (
                    <b>
                      <DateDisplay value={submittedForm.signedAt} date="LL" time={false} />
                    </b>
                  ),
                },
              )}
            </GreyBox>
          ) : expiredForm ? (
            <Notification
              closable
              type="warning"
              title={formatMessage(
                { id: "containers.tax.w8.formExpiredLink" },
                {
                  formType: getTaxFormLabel(expiredForm.kind),
                  a: (c) => (
                    <Button
                      type="link"
                      onClick={async () => {
                        if (expiredForm?.id) {
                          const newFormId = await copyForm(expiredForm.id);
                          history.push(`/tax/${newFormId}`);
                        }
                      }}
                    >
                      {c}
                    </Button>
                  ),
                },
              )}
            />
          ) : showInProgress ? (
            <GreyBox margin="small">
              <Paragraph>{formatMessage({ id: "containers.tax.alreadyStarted" })}</Paragraph>
              <Paragraph>{formatMessage({ id: "containers.tax.submitToUpdate" })}</Paragraph>
              {formsInProgress[TaxFormType.W8BENE] && (
                <Text>
                  <LinkButton type="link" icon="file-alt" to={`/tax/${formsInProgress[TaxFormType.W8BENE]?.id}`}>
                    {getTaxFormLabel(TaxFormType.W8BENE)}
                  </LinkButton>
                </Text>
              )}
              {formsInProgress[TaxFormType.W8BEN] && (
                <Text>
                  <LinkButton type="link" icon="file-alt" to={`/tax/${formsInProgress[TaxFormType.W8BEN]?.id}`}>
                    {getTaxFormLabel(TaxFormType.W8BEN)}
                  </LinkButton>
                </Text>
              )}
              {formsInProgress[TaxFormType.W9] && (
                <Text>
                  <LinkButton type="link" icon="file-alt" to={`/tax/${formsInProgress[TaxFormType.W9]?.id}`}>
                    {getTaxFormLabel(TaxFormType.W9)}
                  </LinkButton>
                </Text>
              )}
            </GreyBox>
          ) : null}

          <Divider transparent />

          <Paragraph>{taxText}</Paragraph>

          {config?.taxFormUpload && (
            <p>
              {formatMessage(
                { id: "containers.tax.wizardNeedHelp" },
                {
                  a: (c) => (
                    <Button
                      type="link"
                      onClick={() => {
                        setShowWizard(true);
                      }}
                    >
                      {c}
                    </Button>
                  ),
                  link: (c) => (
                    <LinkButton type="link" to={PATHS.US_TAX_UPLOAD}>
                      {c}
                    </LinkButton>
                  ),
                },
              )}

              <WizardPopup
                visible={showWizard}
                onBack={() => {
                  setShowWizard(false);
                }}
                openForm={(formType: TaxFormType | UsUploadTaxFormType | undefined) => {
                  if (
                    !formType ||
                    [TaxFormType.W9, TaxFormType.W8BEN, TaxFormType.W8BENE].includes(formType as TaxFormType)
                  ) {
                    history.push(PATHS.US_TAX_NEW, { formType });
                  }
                }}
              />
            </p>
          )}

          {merchant.tax?.collectionType === "us_international" && (
            <>
              <RadioGroup
                name="usPerson"
                direction="vertical"
                value={isUsPerson}
                options={[
                  {
                    label: (
                      <span>
                        {formatMessage({ id: "containers.tax.amUsPerson" })} {tooltip}
                      </span>
                    ),
                    value: true,
                  },
                  {
                    label: <span>{formatMessage({ id: "containers.tax.amNotUsPerson" })}</span>,
                    value: false,
                  },
                ]}
                onChange={(e) => {
                  if (e.target.value) {
                    setType(TaxFormType.W9);
                    setIsUsPerson(true);
                  } else {
                    setType(undefined);
                    setIsUsPerson(false);
                  }
                }}
              />
              {isUsPerson === false && (
                <>
                  <Divider transparent />
                  <Text padded>{formatMessage({ id: "containers.tax.completeW8" })}</Text>
                  <RadioGroup
                    direction="vertical"
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value as TaxFormType);
                    }}
                    value={type}
                    options={[
                      {
                        value: TaxFormType.W8BEN,
                        label: (
                          <span>
                            {formatMessage({
                              id: "containers.tax.amIndividual",
                            })}
                          </span>
                        ),
                      },
                      {
                        value: TaxFormType.W8BENE,
                        label: (
                          <span>
                            {formatMessage({
                              id: "containers.tax.amCorporation",
                            })}
                          </span>
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </>
          )}
          <Footer
            main={
              <LinkButton
                type="primary"
                to={continueParams}
                size="large"
                disabled={merchant.tax?.collectionType !== "us_only" && !type}
              >
                {formatMessage({ id: "common.continue" })}
              </LinkButton>
            }
            extra={<BackButton />}
          />
        </Container>
      </Loader>
    </>
  );
}
