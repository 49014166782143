import { PaymentStatus } from "@trolley/common-frontend";
import { Pagination as AntPagination } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

import {
  BackButton,
  CollapseList,
  Container,
  Divider,
  Footer,
  Form,
  Grid,
  Icon,
  LinkButton,
  Loader,
  TitleBar,
} from "components";
import { PATHS } from "pages/App/routes";
import { useIframeConfig } from "store/hooks/config";
import { CombinedPayment, useCombinedPayments } from "store/hooks/payments";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { useIntl } from "utils/context";
import { omitUndefined } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import PaymentBody from "./PaymentBody";
import PaymentHeader from "./PaymentHeader";
import Helmet from "react-helmet";

const NOW = dayjs();

const DEFAULT_QUERY = {
  page: 1,
  pageSize: 10,
  startDate: NOW.subtract(1, "year").format(),
  endDate: NOW.endOf("day").format(),
};

const RECENT_QUERY = {
  page: 1,
  pageSize: 3,
};

export function getDeliveryStatus(p: CombinedPayment): "delivered" | "delivering" | undefined {
  if (p.status === PaymentStatus.PROCESSED) {
    return !p.estimatedDeliveryAt || NOW.isAfter(p.estimatedDeliveryAt, "day") ? "delivered" : "delivering";
  }

  return undefined;
}

interface Props {
  listType?: "recent" | "all";
}

export default function Payments(props: Props) {
  const { listType = "all" } = props;
  const recipient = useRecipient();
  const config = useIframeConfig();
  const intl = useIntl();
  const { isMobile } = useWindowSize();
  const dateRanges = [
    {
      label: intl.formatMessage({
        id: "containers.payments.dateRanges.last30days",
      }),
      key: "month",
      start: NOW.subtract(20, "days").format(),
      end: NOW.endOf("day").format(),
    },
    {
      label: intl.formatMessage({
        id: "containers.payments.dateRanges.last90days",
      }),
      key: "quarter",
      start: NOW.subtract(90, "days").format(),
      end: NOW.endOf("day").format(),
    },
    {
      label: intl.formatMessage({
        id: "containers.payments.dateRanges.last12months",
      }),
      key: "year",
      start: NOW.subtract(1, "year").format(),
      end: NOW.endOf("day").format(),
    },
    {
      label: intl.formatMessage({ id: "containers.payments.dateRanges.all" }),
      key: "all",
    },
  ];

  const showRecentList = listType === "recent";
  const [query, setQuery] = useState(showRecentList ? RECENT_QUERY : DEFAULT_QUERY);
  const { data: combinedPayments, status: combinedPaymentsStatus } = useCombinedPayments(
    recipient?.id,
    query,
    config.showOfflinePayments,
  );
  const [dateRange, setDateRange] = useState(dateRanges[2].key);
  const [activeKey, setActiveKey] = useState("");

  if (!recipient) {
    return null;
  }

  function onChangeDateRange(e: any) {
    const optionDetails = dateRanges.find((range) => range.key === e.target.value);

    if (optionDetails && recipient) {
      setDateRange(e.target.value);
      setQuery((state) =>
        omitUndefined({
          ...state,
          page: 1, // on any date change, reset to page 1
          startDate: optionDetails.start,
          endDate: optionDetails.end,
        }),
      );
    }
  }

  const totalRecords = combinedPayments.meta.records;

  return (
    <>
      <Container>
        {showRecentList ? (
          <TitleBar level={2}>
            {intl.formatMessage({
              id: "containers.accountSummary.titles.recentPayments",
            })}
          </TitleBar>
        ) : (
          <>
            <Helmet>
              <title>{intl.formatMessage({ id: "containers.payments.paymentHistory" })}</title>
            </Helmet>
            <TitleBar>
              {intl.formatMessage({
                id: "containers.payments.paymentHistory",
              })}
            </TitleBar>
            <Grid justify="space-between" alignItems="middle" padding={["small", "medium"]}>
              <Grid.Item xs={24} sm={{ flex: "1 1 0" }} role="status" aria-live="polite" aria-atomic>
                {intl.formatMessage({ id: "containers.payments.showingPayments" }, { records: totalRecords })}
              </Grid.Item>
              <Grid.Item xs={24} sm={{ flex: "0 0 205px" }}>
                <Form.Select
                  name="dateRange"
                  prefix={<Icon type="calendar" />}
                  onChange={onChangeDateRange}
                  value={dateRange}
                  options={dateRanges.map((date) => ({
                    value: date.key,
                    label: date.label,
                  }))}
                />
              </Grid.Item>
            </Grid>
            <Divider transparent />
          </>
        )}
        <Loader spinning={combinedPaymentsStatus === BaseStatus.LOADING}>
          <CollapseList<CombinedPayment>
            items={combinedPayments.records}
            renderHeader={(p) => <PaymentHeader payment={p} activeKey={activeKey} status={getDeliveryStatus(p)} />}
            renderBody={(p) => <PaymentBody payment={p} />}
            emptyText={intl.formatMessage({
              id: "containers.accountSummary.noRecentPayments",
            })}
            onChangeActiveKey={setActiveKey}
            defaultRecordCount={query.pageSize}
            footer={
              combinedPaymentsStatus !== BaseStatus.LOADING &&
              showRecentList && (
                <LinkButton type="link" placement="center" to={PATHS.PAYMENTS} size="small">
                  {intl.formatMessage({
                    id: "containers.accountSummary.buttons.paymentHistory",
                  })}
                  <Icon type="angle-right" right />
                </LinkButton>
              )
            }
          />
        </Loader>
        <Divider transparent />
        {!showRecentList && (totalRecords > query.pageSize || query.pageSize > 10 || query.page > 1) && (
          <AntPagination
            onChange={(page, pageSize) => {
              setQuery((state) => ({ ...state, page, pageSize }));
            }}
            showSizeChanger={totalRecords > query.pageSize || query.pageSize > 10}
            simple={isMobile}
            current={query.page}
            total={totalRecords}
            style={{ width: "fit-content", margin: isMobile ? "0 auto" : "0 0 0 auto" }}
          />
        )}
        {listType === "all" && <Footer extra={<BackButton />} />}
      </Container>
    </>
  );
}
