import { TaxFormType, UsUploadTaxFormType } from "@trolley/common-frontend";
import { Button, Divider, GreyBox, Grid, Icon, Modal, Typography } from "components";
import { PATHS } from "pages/App/routes";
import React, { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { getTaxFormLabel } from "store/actions/taxForms";

const makeRoute = (yesRedirect: string, noRedirect: string, question: string) => ({
  noRedirect,
  yesRedirect,
  question,
});

const makeForm = (
  links: { label: string; location: string }[],
  form: TaxFormType | UsUploadTaxFormType | undefined,
) => ({
  links,
  form,
});

function isForm(route: ReturnType<typeof makeForm | typeof makeRoute>): route is ReturnType<typeof makeForm> {
  return "form" in route;
}

const routes: Record<string, ReturnType<typeof makeForm | typeof makeRoute>> = {
  q1: makeRoute(
    "OTHER",
    "W-8BEN",
    `Are you receiving a compensation for personal services performed in the United States for which you are claiming a treaty benefit?`,
  ),
  q2: makeRoute("W-8IMY", "q1", "Are you acting as Intermediary?"),
  q3: makeRoute(
    "W-8ECI",
    "q2",
    "Is your income effectively connected with the conduct of trade or business within the U.S?",
  ),
  q4: makeRoute("W-9", "q3", "Are you a U.S. Citizen or subject to taxation as a U.S. Citizen?"),
  q5: makeRoute(
    "W-8BEN-E",
    "W-8EXP",
    `Are you representing a foreign government, an international organization,
    a foreign central bank of issue, a foreign tax-exempt organization or a foreign private foundation, or government
    of a U.S. possession claiming the applicability of section(s) 115(2), 501(c), 892, 895, or 1443(b)?`,
  ),
  q6: makeRoute(
    "W-8ECI",
    "q5",
    "Is your income effectively connected with the conduct of trade or business within the U.S?",
  ),
  q7: makeRoute(
    "W-8IMY",
    "q6",
    `Is the entity a foreign partnership, a foreign simple trust,
    or a foreign grantor trust (and not claiming treaty benefits)?`,
  ),
  q8: makeRoute("W-9", "q7", "Is the entity incorporated in the US?"),
  q9: makeRoute("q4", "q8", "Are you an individual?"),
  "W-9": makeForm([{ label: "Form W-9", location: "https://www.irs.gov/pub/irs-pdf/fw9.pdf" }], TaxFormType.W9),
  "W-8BEN": makeForm(
    [{ label: "Form W-8BEN", location: "https://www.irs.gov/pub/irs-pdf/fw8ben.pdf" }],
    TaxFormType.W8BEN,
  ),
  "W-8BEN-E": makeForm(
    [{ label: "Form W-8BEN-E", location: "https://www.irs.gov/pub/irs-pdf/fw8bene.pdf" }],
    TaxFormType.W8BENE,
  ),
  "W-8ECI": makeForm(
    [{ label: "Form W-8ECI", location: "https://www.irs.gov/pub/irs-pdf/fw8eci.pdf" }],
    UsUploadTaxFormType.W8ECI,
  ),
  "W-8IMY": makeForm(
    [{ label: "Form W-8IMY", location: "https://www.irs.gov/pub/irs-pdf/fw8imy.pdf" }],
    UsUploadTaxFormType.W8IMY,
  ),
  "W-4": makeForm([{ label: "Form W-4", location: "https://www.irs.gov/pub/irs-pdf/fw4.pdf" }], UsUploadTaxFormType.W4),
  "W-8EXP": makeForm(
    [{ label: "Form W-8EXP", location: "https://www.irs.gov/pub/irs-pdf/fw8exp.pdf" }],
    UsUploadTaxFormType.W8EXP,
  ),
  8233: makeForm([{ label: "Form 8233", location: "https://www.irs.gov/pub/irs-pdf/f8233.pdf" }], TaxFormType.F8233),
  OTHER: makeForm(
    [
      { label: "Form W-4", location: "https://www.irs.gov/pub/irs-pdf/fw4.pdf" },
      { label: "Form 8233", location: "https://www.irs.gov/pub/irs-pdf/f8233.pdf" },
    ],
    undefined,
  ),
};

interface Props {
  visible: boolean;
  onBack(): void;
  openForm(formType: TaxFormType | UsUploadTaxFormType | undefined): void;
}

export default function WizardPopup(props: Props) {
  const [question, setQuestion] = useState("q9");
  const history = useHistory();

  function onBack() {
    setQuestion("q9");
    props.onBack();
  }

  function onUpload(formType: TaxFormType | UsUploadTaxFormType | undefined) {
    return () => {
      history.push({ pathname: PATHS.US_TAX_UPLOAD, state: { formType } });
    };
  }

  function onAnswer(q: string) {
    return () => {
      setQuestion(q);
    };
  }

  function renderQuestion(): {
    body: ReactNode;
    popupProps: { okText?: string; onOk?: () => void; footer?: ReactNode };
  } {
    const route = routes[question];

    if (isForm(route)) {
      if (route.form === TaxFormType.W9 || route.form === TaxFormType.W8BEN || route.form === TaxFormType.W8BENE) {
        return {
          body: (
            <GreyBox>
              <Typography.Paragraph strong>
                We are not authorized to give any tax advice, but based on the information you have provided, we believe
                that you are required to fill out the Form {getTaxFormLabel(route.form)}.
              </Typography.Paragraph>
            </GreyBox>
          ),
          popupProps: {
            onOk() {
              props.openForm(route.form);
            },
            okText: "Go to Form",
          },
        };
      }
      if (route.form === TaxFormType.US_UPLOAD) {
        return {
          body: (
            <GreyBox>
              <Typography.Paragraph strong>
                We are not authorized to give any tax advice, but based on the information you have provided, we believe
                that you are required to fill out either a Form W-4 or a Form 8233. Please seek tax advice if you have
                any doubts.
              </Typography.Paragraph>
              <Divider transparent />
              {route.links.map(({ label, location }) => (
                <Typography.Text key={location}>
                  <Button type="link" target="_blank" rel="noopener noreferrer" href={location}>
                    {label}
                  </Button>
                </Typography.Text>
              ))}
              <Divider transparent />
              <Typography.Text strong>Click "Upload" to upload the completed form</Typography.Text>
            </GreyBox>
          ),
          popupProps: {
            okText: "Upload",
            onOk: onUpload(UsUploadTaxFormType.W4),
          },
        };
      }

      return {
        body: (
          <GreyBox>
            <Typography.Paragraph strong>
              We are not authorized to give any tax advice, but based on the information you have provided, we believe
              that you are required to fill out the following form. Please seek tax advice if you have any doubts.
            </Typography.Paragraph>
            <Divider transparent />
            {route.links.map(({ label, location }) => (
              <Typography.Text key={location}>
                <Button type="link" target="_blank" rel="noopener noreferrer" href={location}>
                  {label}
                </Button>
              </Typography.Text>
            ))}
            <Divider transparent />
            <Typography.Text strong>Click "Upload" to upload the completed form</Typography.Text>
          </GreyBox>
        ),
        popupProps: {
          onOk: onUpload(route.form),
          okText: "Upload",
        },
      };
    }

    return {
      body: (
        <GreyBox>
          <Typography.Text strong>{route.question}</Typography.Text>
        </GreyBox>
      ),
      popupProps: {
        footer: (
          <Grid padding="small" direction="row-reverse" key={route.question}>
            <Grid.Item>
              <Button onClick={onAnswer(route.yesRedirect)} size="large">
                <Icon type="check" left color="success" />
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button onClick={onAnswer(route.noRedirect)} size="large">
                <Icon type="times" left color="error" />
                No
              </Button>
            </Grid.Item>
            <Grid.Item flex={1} align="left">
              <Button onClick={onBack} size="large">
                Close
              </Button>
            </Grid.Item>
          </Grid>
        ),
      },
    };
  }

  const currentQuestion = renderQuestion();

  return (
    <Modal
      title="Tax Form Wizard"
      cancelText="Close"
      onCancel={onBack}
      open={props.visible}
      {...currentQuestion.popupProps}
    >
      <div aria-live="assertive" aria-atomic aria-relevant="text">
        {props.visible && currentQuestion.body}
      </div>
    </Modal>
  );
}
