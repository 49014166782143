export type AllowedKeyTypes = string | number;

export type SimpleMultiMap<K extends AllowedKeyTypes, V> = { [n in K]?: V[] };
/**
 * creates a { [name: string]: V[]; } given a list, a function to find the key and a function to find the value
 *
 * @param list of values to turn into a map
 * @param getKey gets the key from a list item
 * @param getValue  gets the value from a list item
 */
export default function createMultiMap<T, V, K extends AllowedKeyTypes>(
  list: T[],
  getKey: (t: T) => K,
  getValue: (t: T) => V,
): SimpleMultiMap<K, V> {
  return list.reduce((acc: SimpleMultiMap<K, V>, item) => {
    const key = getKey(item);
    if (!acc[key]) {
      acc[key] = [] as V[];
    }

    (acc[key] as V[]).push(getValue(item));

    return acc;
  }, {}) as SimpleMultiMap<K, V>;
}
