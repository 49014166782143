import { POST } from "services/request";
import * as types from "@trolley/common-frontend";

import store from "store";
import { standardDispatch, OpCode } from "store/dispatcher";
import { isLoaded } from "./actionUtils";
import { EoyTaxReportStatus } from "@trolley/common-frontend";

export interface EoyTaxForm extends types.EndOfYear.RecipientEoyTax {}
export function loadEoyTaxForms(force?: true) {
  const id = "data";
  const { eoyTaxForms } = store.getState();
  if (force || !isLoaded(eoyTaxForms.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "eoyTaxForms", { id });

    POST<types.EndOfYear.ListRecipientEoyTax>("/v1/recipients/eoy-form/search", {
      query: {
        pageSize: 100,
        status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
      },
    })
      .then((res) => {
        standardDispatch(OpCode.DATA, "eoyTaxForms", {
          id,
          data: {
            records: res.body.recipientEoyTaxes,
            meta: res.body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "eoyTaxForms", { id, errors });
      });
  }
}
