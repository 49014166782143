import React, { CSSProperties, ReactElement, SelectHTMLAttributes, forwardRef, useState } from "react";
import css, { classnames, createUseStyle } from "style/classname";

function getChevronSVG(color: string) {
  return window.encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 512"><path fill="${color}" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z" /></svg>`,
  );
}

export interface SelectOption {
  value: string | number;
  label?: string;
  disabled?: boolean;
  [key: string]: any;
}

export interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "prefix"> {
  children?: never;
  placeholder?: string;
  allowClear?: boolean;
  options?: SelectOption[];
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  readOnly?: boolean;
  prefix?: ReactElement;
}

export default forwardRef<HTMLSelectElement, SelectProps>(function Select(props: SelectProps, ref) {
  const {
    wrapperClassName,
    wrapperStyle,
    className,
    options,
    prefix,
    placeholder,
    allowClear,
    required,
    ...selectProps
  } = props;
  const [prefixRef, setPrefixRef] = useState<HTMLElement | null>();
  const styledSelect = useStyledSelect({
    ...props,
    prefixWidth: prefixRef?.offsetWidth || 0,
    required,
  });

  return (
    <div className={classnames("select-wrapper", styledSelect, wrapperClassName)} style={wrapperStyle}>
      {prefix &&
        React.cloneElement(
          prefix,
          {
            ...prefix.props,
            ref: setPrefixRef,
            className: classnames(prefix.props?.className, "select-prefix"),
          },
          prefix.props.children,
        )}
      <select
        {...selectProps}
        className={classnames("select-field", className)}
        ref={ref}
        defaultValue="" // this allows to show the empty option when they're disabled
      >
        <option key="empty" value="" disabled={!allowClear}>
          {placeholder || ""}
        </option>
        {options?.map((option) => (
          <option key={option.value} value={option.value} disabled={!!option.disabled}>
            {option.label || option.value}
          </option>
        ))}
      </select>
    </div>
  );
});

const useStyledSelect = createUseStyle<SelectProps & { prefixWidth: number }>(({ theme, ...props }) =>
  css`
    position: relative;
    select {
      &::placeholder {
        color: ${theme.colorTextQuaternary};
      }
      cursor: pointer;
      appearance: none;
      transition: border 0.2s ease, color 0.2s ease;
      display: block;
      border-radius: ${theme.borderRadius}px;
      height: ${theme.controlHeight}px;
      color: ${(theme.Input as any)?.colorText};
      background-color: ${theme.colorBgContainer};
      border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
      width: 100%;
      padding-left: 12px;
      padding-right: 20px;
      &:focus,
      &:hover,
      &:active {
        outline: none;
      }
      &[readonly] {
        background-color: transparent;
        padding: 0;
        border: none;
        pointer-events: none;
      }
      &[disabled] {
        background-color: ${theme.colorBgContainerDisabled};
        color: ${theme.colorTextDisabled};
        cursor: not-allowed;
      }
      &:not([readonly], [disabled]) {
        background-position: right center;
        background-size: 16px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;utf8,${getChevronSVG((theme.Input as any)?.colorText)}");
        &:focus,
        &:hover,
        &:active {
          border-color: ${theme.colorPrimaryBorder};
        }
      }
    }
    option {
      color: ${theme.colorText};
      &[disabled] {
        color: ${theme.colorTextDisabled};
        &[value=""] {
          display: none;
        }
      }
    }
    .select-prefix {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      padding-left: 8px;
      & ~ select {
        padding-left: ${props.prefixWidth + 8}px;
      }
    }
  `(),
);
