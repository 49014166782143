import { Container, FileDownload, Grid, List, Loader, Paragraph, Status, Text, TitleBar } from "components";
import dayjs from "dayjs";
import { default as React } from "react";
import { useIntl } from "utils/context";
import { useEoyTaxForms } from "store/hooks/eoyTaxForms";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { EndOfYear, EndOfYearFormType, EoyTaxReportStatus } from "@trolley/common-frontend";

const TaxStatementLabel: Record<EndOfYearFormType, string> = {
  [EndOfYearFormType.F1099]: "1099-MISC",
  [EndOfYearFormType.F1099_NEC]: "1099-NEC",
  [EndOfYearFormType.F1099_K]: "1099-K",
  [EndOfYearFormType.F1042]: "1042-S",
  [EndOfYearFormType.NONE]: "No Form",
};

const taxYear = dayjs().subtract(1, "year").year();

export default function EndOfYearForms() {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const { data: eoyTaxForms, status: eoyTaxFormsStatus } = useEoyTaxForms();
  const taxYearFormCount = eoyTaxForms.records.filter((eoyTax) => eoyTax.taxYear === taxYear).length;

  function render1099(eoyForm: EndOfYear.RecipientEoyTax) {
    return (
      <Grid justify="space-between" alignItems="middle" wrap={false} padding="none">
        <Grid.Item>
          <Text strong inline>
            {eoyForm.taxYear}&nbsp;
          </Text>
          <Text type="secondary" size="small" inline>
            {TaxStatementLabel[eoyForm.formType]}&nbsp;
          </Text>
          {eoyForm.status === EoyTaxReportStatus.VOID ? (
            <Status type="default">
              {formatMessage({
                id: `containers.accountSummary.taxStatuses.voided`,
              })}
            </Status>
          ) : (
            dayjs().isBefore(dayjs(`${eoyForm.taxYear + 1}-02-01`)) && <Status type="success">NEW</Status>
          )}
        </Grid.Item>
        <Grid.Item align="right">
          {recipient && (
            <FileDownload
              size="small"
              fileName={[eoyForm.taxYear, TaxStatementLabel[eoyForm.formType] || eoyForm.formType, eoyForm.status].join(
                "_",
              )}
              url={`/v1/recipients/${recipient.id}/tax/download-end-of-year/${eoyForm.taxYear}/${eoyForm.id}`}
            >
              Download
            </FileDownload>
          )}
        </Grid.Item>
      </Grid>
    );
  }

  const sentReports = eoyTaxForms ? eoyTaxForms.records.filter((eot) => eot.status === EoyTaxReportStatus.SENT) : [];

  if (sentReports.length > 0) {
    return (
      <Loader spinning={eoyTaxFormsStatus === BaseStatus.LOADING}>
        <Container>
          <TitleBar level={2}>
            {formatMessage({
              id: "containers.accountSummary.titles.taxStatements",
            })}
          </TitleBar>
          <Paragraph type="secondary">
            {formatMessage({
              id: "containers.accountSummary.endOfYearReady",
            })}
          </Paragraph>
          <List<EndOfYear.RecipientEoyTax>
            items={eoyTaxForms.records}
            renderItem={render1099}
            defaultRecordCount={taxYearFormCount || 1}
            size="small"
            showMoreText={formatMessage({
              id: "containers.accountSummary.buttons.seeMoreTaxStatements",
            })}
            showLessText={formatMessage({
              id: "containers.accountSummary.buttons.seeLessTaxStatements",
            })}
          />
        </Container>
      </Loader>
    );
  }

  return null;
}
