/* istanbul ignore file */
import store, { Store } from "store";
import {
  OpCode,
  LoadingAction,
  DataAction,
  UpdateAction,
  DeleteAction,
  ErrorAction,
  StandardAction,
  DispatchAction,
} from "./reducers/standardReducer";

export { store, OpCode };

export function standardDispatch(opcode: OpCode.LOADING, name: keyof Store, action?: LoadingAction): void;
export function standardDispatch<T>(opcode: OpCode.DATA, name: keyof Store, action: DataAction<T>): void;
export function standardDispatch<T>(opcode: OpCode.UPDATE, name: keyof Store, action: UpdateAction<T>): void;
export function standardDispatch(opcode: OpCode.DELETE, name: keyof Store, action?: DeleteAction): void;
export function standardDispatch(opcode: OpCode.RESET, name: keyof Store): void;
export function standardDispatch(opcode: OpCode.ERROR, name: keyof Store, action: ErrorAction): void;
export function standardDispatch<T extends object | null | string>(
  opcode: OpCode,
  name: keyof Store,
  action: StandardAction<T> = {},
) {
  store.dispatch<DispatchAction<T>>({
    type: `${name}/${opcode}`,
    ...action,
  });
}
