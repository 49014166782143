import React, { CSSProperties, ChangeEventHandler, ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";

interface RadioProps {
  name: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options: { label: ReactNode; value: string; disabled?: boolean; id?: string; "aria-label"?: string }[];
  inline?: boolean;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  "aria-label"?: string;
}

export const RADIO_GROUP_NAME = "radio-group";

export default function Radio(props: RadioProps) {
  const { name, value, onChange, options, className, disabled, inline, ...rest } = props;
  const styledRadio = useStyledRadio(props);

  return (
    <div {...rest} className={classnames(RADIO_GROUP_NAME, styledRadio, className)}>
      {options.map((option) => {
        const checked = String(option.value).toLocaleLowerCase() === String(value).toLocaleLowerCase();

        return (
          <label
            htmlFor={option.id}
            key={option.value}
            className={classnames("radio-option", { "radio-checked": checked })}
            aria-label={option["aria-label"]}
          >
            <input
              name={name}
              type="radio"
              value={option.value}
              disabled={option.disabled || disabled}
              checked={checked}
              onChange={onChange}
              id={option.id}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
}

const useStyledRadio = createUseStyle<RadioProps>(({ theme, ...props }) =>
  css`
    min-height: ${theme.controlHeight}px;
    .radio-option {
      padding-left: 24px;
      position: relative;
      vertical-align: middle;
      ${props.inline
        ? `
        margin: 4px 0px;
        display: inline-block;
        & + .radio-option {
          margin-left: 12px;
        }
        `
        : `
        display: block;
        margin: 4px 0px 8px;
        `};
      ${props.disabled
        ? `
        cursor: not-allowed;
        color: ${theme.colorTextDisabled};
        `
        : `
        cursor: pointer;
        `}
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: ${theme.fontSize + 4}px;
        height: ${theme.fontSize + 4}px;
        background-color: ${theme.colorBgLayout};
        border: 2px solid ${props.disabled ? theme.colorBorder : theme.colorPrimary};
        border-radius: 50%;
      }
      &:after {
        content: "";
        opacity: 0;
        display: block;
        background-color: ${props.disabled ? theme.colorBorder : theme.colorPrimary};
        transition: all 0.1s ease-in-out;
        position: absolute;
        top: 8px;
        left: 8px;
        border-radius: 50%;
        width: 0;
        height: 0;
      }
      &.radio-checked:after {
        opacity: 1;
        width: ${(theme.fontSize + 6) / 2}px;
        height: ${(theme.fontSize + 6) / 2}px;
        top: 4px;
        left: 4px;
      }
    }
    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;
      border: none;
      cursor: pointer;
      height: 18px;
      width: 18px;
      opacity: 0;
    }
  `(),
);
