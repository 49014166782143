import { Form, Grid } from "components";
import dayjs from "dayjs";
import { Rule } from "rc-field-form/lib/interface";
import React, { ChangeEvent } from "react";
import { FormatMessage, IntlShape, useIntl } from "utils/context";

interface Props {
  name?: string;
  value?: string;
  disabled?: boolean;
  onChange?(e: any): void;
}

export function isPastDateValidator(formatMessage?: IntlShape["formatMessage"]): Rule {
  return {
    async validator(rule: any, value: any) {
      const date = dayjs(value, "YYYY-MM-DD", true); // strict validation required
      if (value) {
        if (!date.isValid() || new Date(value).toString() === "Invalid Date") {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.validDate" }) || "Select a valid date");
        }
        if (!date.isBefore(dayjs(), "day")) {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.earlierDate" }) || "Select an earlier date");
        }
      }
    },
  };
}

export function isLegalAgeDateValidator(formatMessage?: IntlShape["formatMessage"] | FormatMessage): Rule {
  return {
    async validator(_: any, value: any) {
      const date = dayjs(value, "YYYY-MM-DD", true); // strict validation required
      if (value) {
        if (!date.isValid() || new Date(value).toString() === "Invalid Date") {
          throw new Error(formatMessage?.({ id: "components.formSelectDate.validDate" }));
        }
        if (dayjs().diff(date, "years") < 10) {
          throw new Error(
            formatMessage?.({
              id: "components.formSelectDate.date10YearsAgo",
            }) || "Select an earlier date",
          );
        }
      }
    },
  };
}

export default function DatePicker(props: Props) {
  const { name, disabled, value, onChange } = props;
  const { formatMessage } = useIntl();
  const [, year, month, date] = String(value || "").match(/^(\d*)-(\d{0,2})-(\d{0,2})/) || [value, "", "", ""];

  function onChangeYear(e: ChangeEvent<HTMLInputElement>) {
    const newYear = e.target.value;
    onChange?.(newYear || month || date ? [newYear, month, date].join("-") : "");
  }

  function onChangeMonth(e: ChangeEvent<HTMLSelectElement>) {
    const newMonth = e.target.value;
    onChange?.(year || newMonth || date ? [year, newMonth, date].join("-") : "");
  }

  function onChangeDate(e: ChangeEvent<HTMLInputElement>) {
    const newDay = e.target.value;
    onChange?.(year || month || newDay ? [year, month, newDay].join("-") : "");
  }

  function onBlurDate() {
    const newDay = date ? String(parseInt(date.replace(/[^0-9]/g, ""), 10)).padStart(2, "0") : "";
    onChange?.(year || month || newDay ? [year, month, newDay].join("-") : "");
  }

  return (
    <Grid padding="xsmall" wrap={false} style={{ width: "100%" }}>
      <Grid.Item flex="1 1 100px">
        <Form.Input
          value={year}
          name={`${name}_year`}
          onChange={onChangeYear}
          disabled={disabled}
          aria-label={formatMessage({ id: "components.formSelectDate.year" })}
          maxLength={4}
          placeholder="YYYY"
        />
      </Grid.Item>
      <Grid.Item flex="5 1 120px">
        <Form.Select
          name={`${name}_month`}
          value={month || undefined}
          onChange={onChangeMonth}
          aria-label={formatMessage({ id: "components.formSelectDate.months.select" })}
          disabled={disabled}
          placeholder={
            formatMessage({
              id: "components.formSelectDate.months.select",
              defaultMessage: "MM",
            }) as string
          }
          options={[
            {
              value: "",
              label: formatMessage({
                id: "components.formSelectDate.months.select",
                defaultMessage: "YYYY",
              }),
            },
            {
              value: "01",
              label: formatMessage({
                id: "components.formSelectDate.months.january",
              }),
            },
            {
              value: "02",
              label: formatMessage({
                id: "components.formSelectDate.months.february",
              }),
            },
            {
              value: "03",
              label: formatMessage({
                id: "components.formSelectDate.months.march",
              }),
            },
            {
              value: "04",
              label: formatMessage({
                id: "components.formSelectDate.months.april",
              }),
            },
            {
              value: "05",
              label: formatMessage({
                id: "components.formSelectDate.months.may",
              }),
            },
            {
              value: "06",
              label: formatMessage({
                id: "components.formSelectDate.months.june",
              }),
            },
            {
              value: "07",
              label: formatMessage({
                id: "components.formSelectDate.months.july",
              }),
            },
            {
              value: "08",
              label: formatMessage({
                id: "components.formSelectDate.months.august",
              }),
            },
            {
              value: "09",
              label: formatMessage({
                id: "components.formSelectDate.months.september",
              }),
            },
            {
              value: "10",
              label: formatMessage({
                id: "components.formSelectDate.months.october",
              }),
            },
            {
              value: "11",
              label: formatMessage({
                id: "components.formSelectDate.months.november",
              }),
            },
            {
              value: "12",
              label: formatMessage({
                id: "components.formSelectDate.months.december",
              }),
            },
          ]}
        />
      </Grid.Item>
      <Grid.Item flex="1 1 80px">
        <Form.Input
          name={`${name}_date`}
          value={date}
          aria-label={formatMessage({ id: "components.formSelectDate.daySelect" })}
          onChange={onChangeDate}
          disabled={disabled}
          onBlur={onBlurDate}
          maxLength={2}
          placeholder="DD"
        />
      </Grid.Item>
    </Grid>
  );
}
