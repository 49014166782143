import { Container, Divider, Form2, Icon, Typography } from "components";
import dayjs from "dayjs";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  dob: string;
}

export default function ConfirmDateOfBirth({ taxProfileWizard, onContinue }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    dob: taxProfileWizard.dob ?? "",
  };

  function onSubmit({ dob }: IFormData) {
    onContinue({
      dob,
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.confirmDateOfBirthTitle" })}
            </Typography.Title>
          </legend>
          <Divider transparent size="large" />
          <Form2.Item
            name="dob"
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.enterDateOfBirthMessage" }),
              },
              {
                async validator(_: any, value: any) {
                  const date = dayjs(value, "YYYY-MM-DD", true); // strict validation required
                  if (value) {
                    if (!date.isValid() || new Date(value).toString() === "Invalid Date") {
                      throw new Error(formatMessage({ id: "common.invalidDate" }));
                    }
                  }
                },
              },
            ]}
          >
            <Form2.DatePicker />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.SubmitButton text={formatMessage({ id: "common.continue" })} suffix={<Icon type="arrow-right" />} />
      </Container>
    </Form2>
  );
}
