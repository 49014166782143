import {
  CountryCode,
  formatCountry,
  getPostalCodeProps,
  getRegionLabel,
  TaxEntityTypeUS,
  TaxFormType,
} from "@trolley/common-frontend";
import { Button, Form, Grid, Tooltip } from "components";
import { isPastDateValidator } from "components/Form/DatePicker";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import { useIntl } from "utils/context";
import { getPostalLocaleId } from "pages/Info";
import CopyProfileName from "pages/TaxSection/CopyProfileName";
import { isUsUpload, FormFields } from "pages/TaxSection/UploadTaxForm";
import { getIrsRule } from "pages/TaxSection/variables";
import { getUSTaxEntityTinType, idTypes, taxTypes, TIN_FORMAT } from "pages/TaxSection/W9/variables";
import { default as info } from "locale/en/containers/info.json";
import React, { useState } from "react";
import { notifySuccess } from "store/actions/notifications";
import { TaxForm } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipient";
import { stringifyAddress } from "utils/helpers";

import CopyProfileAddress from "../CopyProfileAddress";

export default function UploadTaxForm() {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();
  const [showCopyProfileName, setShowCopyProfileName] = useState<boolean | undefined>();
  const [showCopyRecipientAddress, setShowCopyRecipientAddress] = useState<boolean | undefined>();
  const [showDBAName, setShowDBAName] = useState(false);

  return (
    <>
      <Form.Field
        name={["data", "w9Data", "name"]}
        label={formatMessage({ id: "containers.tax.nameAsShown" })}
        rules={[
          {
            required: true,
            message: formatMessage({
              id: "containers.tax.nameAsShownRequired",
            }),
          },
          getIrsRule(formatMessage),
        ]}
        hint={
          showDBAName ? null : (
            <Tooltip
              placement="topLeft"
              title={formatMessage({
                id: "containers.tax.nameAsShownTooltip",
              })}
            >
              <Button
                type="link"
                onClick={() => {
                  setShowDBAName(true);
                }}
              >
                {formatMessage({
                  id: "containers.tax.uploadTaxForm.addBusiness",
                })}
              </Button>
            </Tooltip>
          )
        }
      >
        {(control, meta, { setFieldsValue }) => (
          <>
            <Form.Input
              {...control}
              name="name"
              onFocus={(e) => {
                if (!e.target.value && showCopyProfileName === undefined && recipient?.name) {
                  setShowCopyProfileName(true);
                }
              }}
            />
            <CopyProfileName
              label={formatMessage({ id: "containers.tax.nameAsShown" })}
              name={recipient?.name}
              visible={!!showCopyProfileName}
              onClose={() => {
                setShowCopyProfileName(false);
              }}
              onOk={() => {
                setFieldsValue({
                  data: {
                    w9Data: {
                      name: recipient?.name,
                    },
                  },
                });
                notifySuccess(formatMessage({ id: "containers.tax.nameCopied" }));
                setShowCopyProfileName(false);
              }}
            />
          </>
        )}
      </Form.Field>

      {showDBAName ? (
        <Form.Field
          name={["data", "w9Data", "dbaName"]}
          optional
          label={formatMessage({ id: "containers.tax.businessName" })}
          rules={[getIrsRule(formatMessage)]}
        >
          <Form.Input name="dbaName" />
        </Form.Field>
      ) : null}

      <Grid>
        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "w9Data", "address"]}
            label={formatMessage({ id: "containers.info.street1.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.street1.required",
                }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            {(control, meta, { setFieldsValue }) => (
              <>
                <Form.Input
                  {...control}
                  name="address"
                  onFocus={(e) => {
                    if (!e.target.value && showCopyRecipientAddress === undefined && recipient?.address?.country) {
                      setShowCopyRecipientAddress(true);
                    }
                  }}
                />
                <CopyProfileAddress
                  label={formatMessage({ id: "containers.info.street1.title" })}
                  address={[
                    [recipient?.address.street1, recipient?.address.street2].filter((v) => v).join(" "),
                    stringifyAddress({
                      city: recipient?.address.city,
                      region: getRegionLabel(recipient?.address.region, recipient?.address.country),
                      postalCode: recipient?.address.postalCode,
                    }),
                    formatCountry(recipient?.address.country, formatMessage),
                  ]}
                  visible={!!showCopyRecipientAddress}
                  onClose={() => {
                    setShowCopyRecipientAddress(false);
                  }}
                  onOk={() => {
                    if (recipient) {
                      setFieldsValue({
                        data: {
                          w9Data: {
                            address: [recipient.address.street1, recipient.address.street2].filter((v) => v).join(" "),
                            city: recipient.address.city,
                            state: recipient.address.region,
                            zip: recipient.address.postalCode,
                            country: recipient.address.country,
                          },
                        },
                      });
                      notifySuccess(formatMessage({ id: "containers.tax.addressCopied" }));
                    }
                    setShowCopyRecipientAddress(false);
                  }}
                />
              </>
            )}
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "w9Data", "city"]}
            label={formatMessage({ id: "containers.info.city.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.info.city.required" }),
              },
              getIrsRule(formatMessage),
            ]}
          >
            <Form.Input name="city" />
          </Form.Field>
        </Grid.Item>

        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name={["data", "w9Data", "country"]}
            label={formatMessage({ id: "containers.info.country.title" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.info.country.required",
                }),
              },
            ]}
          >
            <Form.SelectCountry type="all" name="country" />
          </Form.Field>
        </Grid.Item>

        <Form.Control<TaxForm> dependencies={[["data", "w9Data", "country"]]}>
          {({ value }: { value: TaxForm }) => {
            const country: CountryCode | undefined = isUsUpload(value?.data, value?.kind)
              ? (value.data.w9Data?.country as CountryCode)
              : undefined;
            const regionLabel = (country && info.region[country]?.title) || info.region.title;
            const postalCodeProps = getPostalCodeProps(country, formatMessage);

            return (
              <>
                <Grid.Item xs={24} sm={{ flex: "1 1 25%" }}>
                  <Form.Field
                    name={["data", "w9Data", "state"]}
                    label={regionLabel}
                    rules={[
                      { required: true, message: `Enter a ${regionLabel}` },
                      isRegionInCountryValidator(["data", "w9Data", "country"], formatMessage),
                    ]}
                  >
                    <Form.SelectRegion name="state" country={country} />
                  </Form.Field>
                </Grid.Item>
                {postalCodeProps.hasPostalCode && (
                  <Grid.Item xs={24} sm={{ flex: "1 1 100px" }}>
                    <Form.Field
                      name={["data", "w9Data", "zip"]}
                      label={formatMessage({
                        id: getPostalLocaleId(country, "title"),
                      })}
                      hint={postalCodeProps.hint}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: getPostalLocaleId(country, "required"),
                          }),
                        },
                        {
                          async validator(_: any, v: any) {
                            if (v && country === CountryCode.US && !postalCodeProps.isPostalCode?.(v)) {
                              throw new Error(
                                formatMessage({
                                  id: "containers.info.postalCode.US.valid",
                                }),
                              );
                            }
                          },
                        },
                        getIrsRule(formatMessage),
                      ]}
                    >
                      <Form.Input name="zip" />
                    </Form.Field>
                  </Grid.Item>
                )}
              </>
            );
          }}
        </Form.Control>
      </Grid>

      <Grid>
        <Form.Control
          dependencies={[
            ["data", "w9Data", "taxType"],
            ["data", "w9Data", "idType"],
          ]}
        >
          {({ value: { data } }: { value: FormFields }, meta, { setFieldsValue }) => {
            const taxType = data.uploadKind === TaxFormType.W9 ? (data.w9Data?.taxType as TaxEntityTypeUS) : undefined;
            const idType = data.uploadKind === TaxFormType.W9 ? data.w9Data?.idType : undefined;
            const tinTypes = getUSTaxEntityTinType(taxType);
            const tinType = tinTypes.length === 1 ? tinTypes[0] : (idType as "ssn" | "tin" | undefined);

            return (
              <>
                <Grid.Item xs={24} sm={12}>
                  <Form.Field
                    name={["data", "w9Data", "taxType"]}
                    label={formatMessage({
                      id: "containers.tax.classification",
                    })}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: "containers.tax.classificationRequired",
                        }),
                      },
                    ]}
                  >
                    <Form.Select
                      name="taxType"
                      options={taxTypes}
                      onChange={(e) => {
                        setFieldsValue({
                          data: {
                            w9Data: {
                              taxId: undefined,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Field>
                </Grid.Item>

                {tinTypes.length > 1 && (
                  <Grid.Item xs={24} sm={12}>
                    <Form.Field
                      name={["data", "w9Data", "idType"]}
                      label={formatMessage({ id: "containers.tax.taxIdType" })}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: "containers.tax.taxIdTypeRequired",
                          }),
                        },
                      ]}
                    >
                      <Form.Select
                        name="idType"
                        options={idTypes}
                        onChange={() => {
                          setFieldsValue({
                            data: {
                              w9Data: {
                                taxId: undefined,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Field>
                  </Grid.Item>
                )}

                <Grid.Item xs={24} sm={12}>
                  <Form.Field
                    key={String([taxType, tinType])}
                    label={
                      tinType
                        ? tinType === "ssn"
                          ? formatMessage({ id: "containers.tax.ssn" })
                          : formatMessage({ id: "containers.tax.ein" })
                        : formatMessage({ id: "containers.tax.tin" })
                    }
                    name={["data", "w9Data", "taxId"]}
                    rules={
                      tinType
                        ? [
                            {
                              required: true,
                              message:
                                tinType === "ssn"
                                  ? formatMessage({
                                      id: "containers.tax.ssnRequired",
                                    })
                                  : formatMessage({
                                      id: "containers.tax.einRequired",
                                    }),
                            },
                            {
                              async validator(_: any, value: string) {
                                if (tinType && value && !value.includes("*") && value.replace(/\D/g, "").length < 9) {
                                  throw new Error(
                                    formatMessage(
                                      { id: "containers.tax.tinValidation" },
                                      { tinName: tinType.toLocaleUpperCase() },
                                    ),
                                  );
                                }
                              },
                            },
                          ]
                        : []
                    }
                  >
                    <Form.InputMask
                      name="taxId"
                      mask={(tinType && TIN_FORMAT[tinType]) || ""}
                      disabled={!tinType}
                      placeholder={tinType === "ssn" ? "XXX-XX-XXXX" : "XX-XXXXXXX"}
                    />
                  </Form.Field>
                </Grid.Item>
              </>
            );
          }}
        </Form.Control>
        <Grid.Item xs={24} sm={12}>
          <Form.Field
            name="signedAt"
            label={formatMessage({ id: "containers.tax.dateOfSignature" })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.tax.dateOfSignatureRequired",
                }),
              },
              isPastDateValidator(),
            ]}
          >
            <Form.DatePicker name="signedAt" />
          </Form.Field>
        </Grid.Item>
      </Grid>

      <Form.Field name={["data", "w9Data", "subjectToWithholding"]}>
        {({ value, onChange, ...rest }) => (
          <Form.Checkbox
            {...rest}
            name="subjectToWithholding"
            checked={value === undefined ? undefined : !value}
            onChange={(checked) => {
              onChange?.(!checked);
            }}
          >
            {formatMessage({
              id: "containers.tax.w9SubjectToWithholding.because",
            })}
            <ol type="a">
              <li>
                {" "}
                {formatMessage({
                  id: "containers.tax.w9SubjectToWithholding.exempt",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w9SubjectToWithholding.notNotified",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w9SubjectToWithholding.notified",
                })}
              </li>
            </ol>
          </Form.Checkbox>
        )}
      </Form.Field>
    </>
  );
}
