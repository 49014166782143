import { Avatar, Layout } from "antd";
import React from "react";

import { Grid, Space, Text } from "components";
import { LanguageSelector } from "features";
import PortalLogoutButton from "pages/Portal//PortalLogoutButton";
import { useIframeConfig } from "store/hooks/config";

export default function AppHeader() {
  const iframeConfig = useIframeConfig();

  return (
    <Layout.Header>
      <Grid justify="space-between" alignItems="middle">
        <Grid.Item>
          <Space>
            {iframeConfig.icon && <Avatar shape="square" src={iframeConfig.icon} />}
            <Text size="large" strong>
              {iframeConfig?.businessName || " "}
            </Text>
          </Space>
        </Grid.Item>
        <Grid.Item>
          <Space>
            {iframeConfig.showLanguage && <LanguageSelector />}
            <PortalLogoutButton />
          </Space>
        </Grid.Item>
      </Grid>
    </Layout.Header>
  );
}
