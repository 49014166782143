import * as types from "@trolley/common-frontend/lib/types";

import { GET } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { computeID, isLoaded } from "./actionUtils";

export interface RecipientAccountConfig extends types.RecipientAccountConfig {
  estimatedFees: string;
}

export interface RecipientAccountConfigQuery {
  recipientId: string;
  accountType: types.PayoutMethodType;
  countryCode: types.CountryCode;
  currencyCode: types.CurrencyCode;
}

export function loadRecipientAccountConfig(rawQuery: RecipientAccountConfigQuery, force?: boolean) {
  const { recipientAccountConfig } = store.getState();
  const { recipientId, accountType, ...query } = rawQuery;
  const id = computeID(rawQuery);

  if (force || !isLoaded(recipientAccountConfig.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipientAccountConfig", { id });

    GET<RecipientAccountConfig>(`/v1/recipients/${recipientId}/account/${accountType}/config`, {
      query,
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "recipientAccountConfig", {
          id,
          data: body,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "recipientAccountConfig", { id, errors });
      });
  }

  return id;
}

export async function loadAsyncRecipientAccountConfig(rawQuery: RecipientAccountConfigQuery) {
  const { recipientAccountConfig } = store.getState();
  const { recipientId, accountType, ...query } = rawQuery;
  const id = computeID(rawQuery);

  const data = recipientAccountConfig.entities[id];

  if (data) {
    return data;
  }

  standardDispatch(OpCode.LOADING, "recipientAccountConfig", { id });

  try {
    const { body } = await GET<RecipientAccountConfig>(`/v1/recipients/${recipientId}/account/${accountType}/config`, {
      query,
    });

    standardDispatch(OpCode.DATA, "recipientAccountConfig", {
      id,
      data: body,
    });

    return body;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipientAccountConfig", { id, errors });
    throw errors;
  }
}
