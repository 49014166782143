import { CountryCode, CurrencyCode } from "@trolley/common-frontend";
import { POST } from "services/request";
import { OpCode, standardDispatch, store } from "store/dispatcher";
import { computeID, isLoaded } from "./actionUtils";

export interface Fees {
  bankTransfer: {
    amount: string;
    currency: CurrencyCode;
  };
}

export interface BankTransferFeesQuery {
  country: CountryCode;
  sourceCurrency?: CurrencyCode;
  targetCurrency?: CurrencyCode;
}

export function loadBankTransferFees(query: BankTransferFeesQuery, force?: boolean) {
  const { fees } = store.getState();
  const id = computeID(query);
  if (force || !isLoaded(fees.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "fees", { id });
    POST<{ fee: Fees["bankTransfer"] }>(`/v1/fees/bank-transfer/get`, { query })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "fees", {
          id,
          data: {
            bankTransfer: body.fee,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "fees", { id, errors });
      });
  }

  return id;
}

export async function loadAsyncBankTransferFees(query: BankTransferFeesQuery) {
  const { fees } = store.getState();
  const id = computeID(query);

  const data = fees.entities[id];
  if (data) {
    return data.bankTransfer;
  }

  standardDispatch(OpCode.LOADING, "fees", { id });

  try {
    const { body } = await POST<{ fee: Fees["bankTransfer"] }>(`/v1/fees/bank-transfer/get`, { query });

    standardDispatch(OpCode.DATA, "fees", {
      id,
      data: {
        bankTransfer: body.fee,
      },
    });

    return body.fee;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "fees", { id, errors });
    throw errors;
  }
}
