import RCForm, { FormProps, useForm, useWatch } from "rc-field-form";
import React from "react";
import Checkbox from "./Checkbox";
import Control from "./Control";
import DatePicker from "./DatePicker";
import Field from "./Field";
import GovernmentIds from "./GovernmentIds";
import Input from "./Input";
import InputMask from "./InputMask";
import InputPhone from "./InputPhone";
import InputPhone2 from "./InputPhone2";
import InputPhoneButton from "./InputPhoneButton";
import InputTextPhone from "./InputTextPhone";
import Radio from "./Radio";
import Select from "./Select";
import SelectCountry from "./SelectCountry";
import SelectFTINType from "./SelectFTINType";
import SelectRegion from "./SelectRegion";

interface Props<T> extends FormProps<T> {}

export default function Form<T>({ validateTrigger = "onSubmit", onSubmitCapture, ...props }: Props<T>) {
  return (
    <RCForm
      {...props}
      validateTrigger={validateTrigger}
      onSubmitCapture={(e) => {
        e.preventDefault();
        onSubmitCapture?.(e);
      }}
    />
  );
}
Form.useWatch = useWatch;
Form.useForm = useForm;
Form.Control = Control;
Form.Field = Field;
Form.Input = Input;
Form.InputMask = InputMask;
Form.InputPhone = InputPhone;
Form.InputPhone2 = InputPhone2;
Form.InputPhoneButton = InputPhoneButton;
Form.InputTextPhone = InputTextPhone;
Form.Select = Select;
Form.Radio = Radio;
Form.DatePicker = DatePicker;
Form.GovernmentIds = GovernmentIds;
Form.SelectCountry = SelectCountry;
Form.SelectFTINType = SelectFTINType;
Form.SelectRegion = SelectRegion;
Form.Checkbox = Checkbox;

export {
  Checkbox,
  DatePicker,
  Field,
  Form,
  Input,
  InputPhone,
  InputPhone2,
  InputPhoneButton,
  Radio,
  Select,
  SelectCountry,
  SelectFTINType,
  SelectRegion,
  useForm,
  useWatch,
};
