import { getFtins, FTINType, CountryCode, RecipientType } from "@trolley/common-frontend";

const unsupportedFtinTypes: Partial<Record<CountryCode, string[]>> = {
  [CountryCode.AR]: ["cbu"],
  [CountryCode.AU]: ["tfn"],
  [CountryCode.IT]: ["aic"],
  [CountryCode.MX]: ["clabe"],
  [CountryCode.NO]: ["kontonr"],
};

export function getFilteredFtinsByCountry() {
  const fTinsByCountry: Record<string, FTINType> = getFtins();
  Object.keys(fTinsByCountry).forEach((countryCode: CountryCode) => {
    const unsupportedFtins = unsupportedFtinTypes[countryCode];
    if (unsupportedFtins) {
      unsupportedFtins.forEach((ftinType: string) => {
        delete fTinsByCountry[countryCode][ftinType];
      });
    }
  });

  return fTinsByCountry;
}

export function getFTinLabel(tinCountry: CountryCode, tinType: string | null | undefined) {
  const fTinsByCountry = getFilteredFtinsByCountry();
  if (!tinCountry || !tinType || tinType === "other" || !fTinsByCountry[tinCountry]) return "Other";
  const fTins: FTINType = fTinsByCountry[tinCountry];

  return fTins[tinType]?.localName ?? fTins[tinType]?.name ?? "";
}

export function filterFtinsByRecipientType(ftins: FTINType, recipientType: RecipientType) {
  Object.entries(ftins).forEach(([ftinType, value]) => {
    if (recipientType === RecipientType.INDIVIDUAL && !value.isIndividual) {
      delete ftins[ftinType];
    } else if (recipientType === RecipientType.BUSINESS && !value.isCompany) {
      delete ftins[ftinType];
    }
  });

  return ftins;
}

export function getTinTypeLabel(tinCountry: string, tinType: string | null) {
  if (!tinCountry || !tinType) return "";
  const fTins = getFilteredFtinsByCountry();

  const localName = fTins?.[tinCountry]?.[tinType]?.localName;
  const name = fTins?.[tinCountry]?.[tinType]?.name;

  return localName ?? name ?? "";
}

export function getFtinExamples(tinCountry: string, tinType: string): string[] {
  const fTinsByCountry = getFilteredFtinsByCountry();

  const fTins: FTINType = fTinsByCountry[tinCountry] ? fTinsByCountry[tinCountry] : {};
  if (fTins[tinType]) {
    const { examples } = fTins[tinType];

    return examples || [];
  }

  return [];
}
