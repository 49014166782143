import { BaseStatus } from "store/reducers/standardReducer";

export function computeID(options: { [key: string]: any }) {
  return JSON.stringify(
    Object.keys(options)
      .sort()
      .map((key) => [key, options[key]]),
  );
}

/**
 * @param status
 * @param strict
 * Will return true only is status is LOADING or LOADED. undefined or ERROR will return false.
 * Strict if you want it to return true only when the status is LOADED
 */
export function isLoaded(status: BaseStatus | undefined, strict: boolean = false) {
  if (!strict) {
    return !!status && [BaseStatus.LOADING, BaseStatus.LOADED].includes(status);
  }

  return status === BaseStatus.LOADED;
}
