import React from "react";
import { classnames, createUseStyle, css } from "style/classname";

interface Props {
  errors: string[] | string | undefined;
  showEmpty?: boolean;
}

export const FIELD_ERROR_NAME = "field-error";

export default function FormError({ errors, showEmpty = true }: Props) {
  const styledError = useStyledError();
  const classNames = classnames(FIELD_ERROR_NAME, styledError);

  if (typeof errors === "string") {
    return <div className={classNames}>{errors}</div>;
  }

  if (Array.isArray(errors) && errors.length) {
    const err = errors.find((v) => !!v && typeof v === "string");
    if (err) {
      return <div className={classNames}>{err}</div>;
    }
  }

  return showEmpty ? <div className={classNames}>&nbsp;</div> : null;
}

const useStyledError = createUseStyle(({ theme }) =>
  css`
    color: ${theme.colorErrorText};
    font-size: ${theme.fontSizeSM}px;
    line-height: ${theme.lineHeightSM};
  `(),
);
