import { CountryCode, formatCountry } from "@trolley/common-frontend";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import * as PhoneNumber from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { Button, Flag, Icon, Space } from "components";
import css, { classnames, createUseStyle } from "style/classname";
import { useIntl } from "utils/context";
import Input, { InputProps } from "./Input";
import { createMultiMap } from "utils/helpers";
import { parsePhoneNumber } from "utils/helpers/formatter/phoneNumber";

interface InputTextPhoneProps
  extends Omit<InputProps, "addonBefore" | "prefix" | "value" | "onChange" | "placeholder" | "type"> {
  value?: string;
  onChange?(value: string): void;
  allowedCountries?: CountryCode[];
  defaultCountry?: CountryCode;
}

export default function InputTextPhone({
  value,
  defaultCountry,
  allowedCountries,
  className,
  readOnly,
  onChange,
  disabled: customDisabled,
  allowClear,
  suffix,
  ...props
}: InputTextPhoneProps) {
  // https://github.com/ant-design/ant-design/blob/4.24.7/components/input/Input.tsx#L159
  const disabledContext = useContext(DisabledContext);
  const disabled = disabledContext ?? customDisabled;
  const { formatMessage } = useIntl();
  const [country, setCountry] = useState<PhoneNumber.CountryCode | undefined>();
  const [lineNumber, setLineNumber] = useState<string>("");
  const [inputPrefix, setInputPrefix] = useState<string>("");
  const { nationalFormat } = parsePhoneNumber(value, country);
  const styledInput = useStyledInput({ disabled });
  const prefix =
    country && PhoneNumber.getCountryCallingCode(country) ? `+${PhoneNumber.getCountryCallingCode(country)}` : "";
  const placeholder = country
    ? PhoneNumber.getExampleNumber(country as PhoneNumber.CountryCode, examples)
        ?.formatInternational()
        .substring(prefix.length)
        .trim() || ""
    : "Select a country";

  useEffect(() => {
    if (value) {
      const parsed = parsePhoneNumber(value, country);
      if (
        parsed?.country &&
        parsed.country in CountryCode &&
        (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
      ) {
        // set new country based on the parser.
        setCountry(parsed.country);
        setInputPrefix(parsed.prefix);
        setLineNumber(parsed.lineNumber);
      } else if (country && allowedCountries?.length && !allowedCountries.includes(country as CountryCode)) {
        // reset back to undefined selector picked a country that is not allowed
        setCountry(undefined);
      }
    } else if (!country && defaultCountry) {
      setCountry(defaultCountry as PhoneNumber.CountryCode);
      setInputPrefix(`+${PhoneNumber.getCountryCallingCode(defaultCountry as PhoneNumber.CountryCode)}`);
    }
  }, [value, defaultCountry]);

  useEffect(() => {
    const parsed = parsePhoneNumber(lineNumber, country);
    if (
      parsed?.country &&
      parsed.country in CountryCode &&
      (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
    ) {
      // set new country based on the parser.
      setCountry(parsed.country);
    } else if (country && allowedCountries?.length && !allowedCountries.includes(country as CountryCode)) {
      // reset back to undefined selector picked a country that is not allowed
      setCountry(undefined);
    }
  }, [country]);

  const mappedCodes = useMemo(() => {
    if (!PhoneNumber) return {};
    const arrayCountryCodes = PhoneNumber.getCountries()
      .filter(
        (code) => CountryCode[code] && (!allowedCountries?.length || allowedCountries.includes(code as CountryCode)),
      )
      .map((code) => ({
        countryCode: code,
        code: PhoneNumber.getCountryCallingCode(code),
      }));

    return createMultiMap(
      arrayCountryCodes,
      (el: { countryCode: PhoneNumber.CountryCode; code: PhoneNumber.CountryCallingCode }) => `+${el.code}`,
      (el: { countryCode: PhoneNumber.CountryCode; code: PhoneNumber.CountryCallingCode }) => el,
    );
  }, [PhoneNumber]);

  function isSamePrefix(prefix1: string, prefix2: string) {
    if (!prefix1 || !prefix2) return prefix1 === prefix2;
    const p1 = prefix1.includes("+") ? prefix1 : `+${prefix1}`;
    const p2 = prefix2.includes("+") ? prefix2 : `+${prefix2}`;

    return p1 === p2;
  }

  if (readOnly || disabled) {
    return (
      <Input
        value={value}
        type="tel"
        disabled={disabled}
        readOnly={readOnly}
        prefix={country && <Flag code={country as CountryCode} />}
      />
    );
  }

  return (
    <Space inline compact>
      <Input
        style={{ maxWidth: "25%" }}
        maxLength={4}
        name="country-code"
        autoComplete="tel"
        type="text"
        prefix={
          country ? <Flag code={country as CountryCode} /> : <Icon type="globe" color="grey" fixedWidth={false} />
        }
        onChange={({ target: { value: inputValue } }) => {
          setInputPrefix(inputValue && inputValue.charAt(0) === "+" ? inputValue : `+${inputValue}`);
        }}
        onBlur={({ target: { value: inputValue } }) => {
          if (!inputPrefix || inputPrefix === "+") setCountry(undefined);
          const countries =
            mappedCodes[inputPrefix] && (mappedCodes[inputPrefix] || []).length > 0
              ? mappedCodes[inputPrefix] || []
              : [];
          setCountry(
            countries.length > 0
              ? ["1", "+1"].includes(inputPrefix)
                ? CountryCode.US
                : countries[0].countryCode
              : undefined,
          );
        }}
        value={inputPrefix}
      />
      <Input
        {...props}
        name="phone"
        autoComplete="tel"
        type="tel"
        value={lineNumber}
        aria-label={formatMessage({ id: "components.phone.input" })}
        disabled={!country}
        onChange={(e) => {
          setLineNumber(e.target.value);
          onChange?.(`${inputPrefix}${e.target.value}`);
        }}
        onBlur={(e) => {
          if (!country) {
            // inputPrefix has a not recognized value
            onChange?.(`${inputPrefix}${e.target.value}`);

            return;
          }
          const parsed = parsePhoneNumber(`${inputPrefix}${e.target.value}`, country);

          if (isSamePrefix(inputPrefix, parsed.prefix)) {
            // only update number when prefix hasn't changed by parser
            onChange?.(
              /^[0-9]*$/.test(parsed.lineNumber) // if it only contains digits, it's because it cannot match any country format
                ? `${inputPrefix}${e.target.value}` // so leave the number format as is
                : `${inputPrefix}${parsed.lineNumber}`, // it there is a matching format, use new format
            );
          } else {
            onChange?.(`${inputPrefix}${e.target.value}`);
          }
          if (
            parsed.country &&
            country !== parsed.country &&
            (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
          ) {
            setCountry(parsed.country);
          }
        }}
        placeholder={placeholder}
        className={classnames(styledInput, className)}
        suffix={
          !!country && (
            <Space>
              {suffix}
              {nationalFormat.replace(/[^0-9]/g, "").length !== lineNumber.replace(/[^0-9]/g, "").length && (
                <Icon
                  type="info-circle"
                  color="info"
                  tooltip={`To make a domestic call in ${formatCountry(
                    country,
                  )}, the phone number is ${nationalFormat}`}
                />
              )}
              {allowClear && value && (
                <Button
                  type="text"
                  size="small"
                  aria-label={formatMessage({ id: "common.close" })}
                  icon="circle-xmark"
                  iconProps={{
                    color: "grey",
                    theme: "solid",
                  }}
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setCountry(allowClear ? undefined : (defaultCountry as PhoneNumber.CountryCode));
                    onChange?.("");
                    props.onBlur?.(e as any); // this sometimes help triggering the validation
                  }}
                />
              )}
            </Space>
          )
        }
      />
    </Space>
  );
}

const useStyledInput = createUseStyle<{ disabled: boolean | undefined }>(({ theme, ...props }) =>
  css`
    .${theme.prefixCls}-input-group-addon {
      ${!props.disabled && `background-color: ${theme.colorBgContainer};`}
    }
    .${theme.prefixCls}-btn.${theme.prefixCls}-btn-circle {
      // fix style so the button doesn't expand the input's height
      height: ${theme.controlHeightXS}px;
      width: ${theme.controlHeightXS}px;
      min-width: ${theme.controlHeightXS}px;
      line-height: ${theme.controlHeightXS}px;
      padding: 0;
    }
  `(),
);
