import * as ACTIONS from "store/actions";
import { Store } from "store";

export function params(state: Store["params"] = null, action: any) {
  switch (action && action.type) {
    case ACTIONS.RECEIVE_PARAMS:
      return action.data;
    default:
      return state;
  }
}
