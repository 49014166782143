import { Loader } from "components";
import { useIntl } from "utils/context";
import { PATHS } from "pages/App/routes";
import W8BEN, { isW8BENForm } from "pages/TaxSection/W8BEN";
import W8BENE, { isW8BENEForm } from "pages/TaxSection/W8BENE";
import W9, { isW9Form } from "pages/TaxSection/W9";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notifyError } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";
import { useTaxForm } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { TaxFormType } from "@trolley/common-frontend";

export default function TaxFormEdit() {
  const { taxId } = useParams<{ taxId: string }>();
  const status = useShallowSelector((state) => state.taxForm.fetchStatus.data);
  const { data: taxForm, status: taxFormStatus } = useTaxForm(taxId);
  const history = useHistory<{ formType?: TaxFormType }>();
  const formType = history.location.state?.formType;
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (taxId) {
      if (taxFormStatus === BaseStatus.ERROR && !taxForm) {
        notifyError(formatMessage({ id: "containers.tax.taxFormEdit.formNotFound" }));
        history.push(PATHS.US_TAX);
      } else if (
        taxForm &&
        ![TaxFormType.W9, TaxFormType.W8BEN, TaxFormType.W8BENE].includes(taxForm.kind as TaxFormType)
      ) {
        notifyError(formatMessage({ id: "containers.tax.taxFormEdit.invalidType" }));
        history.push(PATHS.US_TAX);
      }
    } else if (!history.location.state?.formType) {
      notifyError(formatMessage({ id: "containers.tax.taxFormEdit.unknownType" }));
      history.push(PATHS.US_TAX);
    }
  }, [taxId, taxFormStatus, history.location.pathname]);

  return (
    <Loader spinning={[status, taxFormStatus].some((status) => status === BaseStatus.LOADING)}>
      {((taxForm && isW9Form(taxForm)) || (!taxForm && formType === TaxFormType.W9)) && (
        <W9 taxForm={taxForm} taxFormStatus={taxFormStatus} />
      )}
      {((taxForm && isW8BENForm(taxForm)) || (!taxForm && formType === TaxFormType.W8BEN)) && (
        <W8BEN taxForm={taxForm} taxFormStatus={taxFormStatus} />
      )}
      {((taxForm && isW8BENEForm(taxForm)) || (!taxForm && formType === TaxFormType.W8BENE)) && (
        <W8BENE taxForm={taxForm} taxFormStatus={taxFormStatus} />
      )}
    </Loader>
  );
}
