import {
  CountryCode,
  formatCountry,
  formatPayoutMethod,
  getRegionLabel,
  NoBankingCountries,
  PayoutMethod,
  PayoutMethodType,
  WidgetMerchant,
} from "@trolley/common-frontend";
import { default as interac } from "assets/images/interac.svg";
import { default as VenmoLogo } from "assets/images/venmo.svg";
import {
  BackButton,
  Container,
  Divider,
  GreyBox,
  Icon,
  LinkButton,
  Loader,
  Notification,
  Space,
  Stepper,
  Text,
  TitleBar,
} from "components";
import css, { classnames, createUseStyle } from "style/classname";
import { PRODUCT_MODULES, useIntl } from "utils/context";

import { PATHS } from "pages/App/routes";
import NotificationDisabledCountry, {
  isCountryEnabledForPaypal,
  isCountryEnabledForVenmo,
} from "pages/PayoutMethod2/NotificationDisabledCountry";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RecipientAccount } from "store/actions/recipient";
import { useMerchant } from "store/hooks/merchant";
import { useUrlParams } from "store/hooks/params";
import { useRecipient } from "store/hooks/recipient";
import { emitEvent, stringifyAddress, WidgetEvent } from "utils/helpers";
import { integrationToFeature } from "../AccountSummary/RecipientAccounts";
import BankTransfer from "./BankTransfer";
import Bitcoin from "./Bitcoin";
import Check, { checkCountries } from "./Check";
import Interac from "./Interac";
import PayPal from "./PayPal";
import Venmo from "./Venmo";
import Helmet from "react-helmet";
import { CurrenciesLoader } from "features";

type StyleType = {
  selected: boolean;
  disabled: boolean;
};

const ICON_ATTR: any = {
  size: "xlarge",
};

function getPayoutMethodIcon(type: PayoutMethodType) {
  switch (type) {
    case PayoutMethodType.BANKTRANSFER:
      return <Icon type="university" {...ICON_ATTR} />;
    case PayoutMethodType.PAYPAL:
      return <Icon theme="brands" type="paypal" {...ICON_ATTR} />;
    case PayoutMethodType.BITCOIN:
      return <Icon theme="brands" type="bitcoin" {...ICON_ATTR} />;
    case PayoutMethodType.INTERAC:
      return <img src={interac} alt="interac-icon" style={{ width: "2em" }} />;
    case PayoutMethodType.CHECK:
      return <Icon type="money-check-edit-alt" {...ICON_ATTR} />;
    case PayoutMethodType.VENMO:
      return <img src={VenmoLogo} alt="venmo-icon" style={{ width: "2em" }} />;
    default:
      return null;
  }
}

function SelectablePayoutMethod(props: {
  account: RecipientAccount | undefined;
  selectedMethod: PayoutMethodType | undefined;
  payoutMethod: PayoutMethod.PayoutMethodPublic;
  onClick: (val: PayoutMethodType) => void;
}) {
  const { account, payoutMethod, selectedMethod, onClick } = props;
  const { formatMessage } = useIntl();
  const styledCheck = useStyledCheck();
  const styledPayoutMethod = useStyledPayoutMethod({
    selected: selectedMethod === payoutMethod.integration,
    disabled: selectedMethod !== payoutMethod.integration && !!account,
  });
  const PAYOUT_METHODS_TITLE: Record<PayoutMethodType, string> = {
    [PayoutMethodType.BANKTRANSFER]: formatPayoutMethod(PayoutMethodType.BANKTRANSFER, formatMessage),
    [PayoutMethodType.PAYPAL]: formatPayoutMethod(PayoutMethodType.PAYPAL, formatMessage),
    [PayoutMethodType.BITCOIN]: formatPayoutMethod(PayoutMethodType.BITCOIN, formatMessage),
    [PayoutMethodType.INTERAC]: formatPayoutMethod(PayoutMethodType.INTERAC, formatMessage),
    [PayoutMethodType.CHECK]: formatPayoutMethod(PayoutMethodType.CHECK, formatMessage),
    [PayoutMethodType.VENMO]: formatPayoutMethod(PayoutMethodType.VENMO, formatMessage),
  };

  return payoutMethod.integration ? (
    <div
      className={classnames("payout-select", styledPayoutMethod)}
      onClick={() => {
        if (!account && payoutMethod.integration) {
          onClick(payoutMethod.integration);
        }
      }}
      onKeyPress={(e) => {
        if (!account && payoutMethod.integration && e.key === "Enter") {
          onClick(payoutMethod.integration);
        }
      }}
      role="button"
      tabIndex={0}
    >
      {selectedMethod === payoutMethod.integration && (
        <Icon theme="solid" size="large" className={styledCheck} type="check-circle" color="success" />
      )}
      <div>
        {getPayoutMethodIcon(payoutMethod.integration)}
        <Text strong>{PAYOUT_METHODS_TITLE[payoutMethod.integration]}</Text>
      </div>
    </div>
  ) : null;
}

export default function PayoutMethod() {
  const recipient = useRecipient();
  const { hideEmail, payoutMethods: paramsEnabledPayoutMethods = [] } = useUrlParams();
  const merchant = useMerchant();
  const { formatMessage } = useIntl();
  const { accountId } = useParams<{ accountId?: string }>();
  const account = accountId ? recipient?.accounts?.find((a) => a.id === accountId) : undefined;
  const recipientCountry = (recipient?.address.country as CountryCode) || "";

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: [PRODUCT_MODULES.PAY],
    });
  }, []);

  // default should be based on whats enabled
  const enabledPayoutMethods = merchant?.enabledPayoutMethods
    .filter((pm) => {
      const integration = pm.integration || "";
      if (integrationToFeature[integration] && !merchant.features?.[integrationToFeature[integration]]) {
        return false;
      } else {
        return (
          account?.type === pm.integration ||
          !pm.enabledCountries?.length ||
          pm.enabledCountries.includes(recipientCountry)
        );
      }
    })
    .filter((pm) => {
      const integration = pm.integration || "";

      if (integration === PayoutMethodType.INTERAC) {
        return account?.type === pm.integration || [CountryCode.CA].includes(recipientCountry);
      } else if (integration === PayoutMethodType.CHECK) {
        return account?.type === pm.integration || checkCountries.includes(recipientCountry);
      } else if (integration === PayoutMethodType.BANKTRANSFER) {
        return account?.type === pm.integration || !NoBankingCountries.includes(recipientCountry);
      } else if (integration === PayoutMethodType.PAYPAL) {
        return account?.type === pm.integration || isCountryEnabledForPaypal(pm, recipientCountry);
      } else if (integration === PayoutMethodType.VENMO) {
        return account?.type === pm.integration || isCountryEnabledForVenmo(pm, recipientCountry);
      }

      return true;
    });

  const selectablePayoutMethods = getSelectablePayoutMethods(enabledPayoutMethods, paramsEnabledPayoutMethods);

  const [selectedMethod, setSelectedMethod] = useState<PayoutMethodType | undefined>(
    account ? (account.type as PayoutMethodType) : selectablePayoutMethods?.[0]?.integration,
  );

  if (!recipient || !merchant) {
    return <Loader />;
  }

  function renderPayoutMethods() {
    if (!selectablePayoutMethods?.length) {
      return (
        <>
          <Notification
            type="warning"
            title={formatMessage(
              { id: "containers.payoutMethod.noPayoutMethods" },
              {
                country: <strong>{formatCountry(recipientCountry, formatMessage)}</strong>,
              },
            )}
          />
          <Divider transparent size="xlarge" />
          <BackButton />
        </>
      );
    }

    return (
      <Space>
        {selectablePayoutMethods.map((m) => (
          <SelectablePayoutMethod
            payoutMethod={m}
            key={m.integration}
            account={account}
            selectedMethod={selectedMethod}
            onClick={setSelectedMethod}
          />
        ))}
      </Space>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: "containers.payoutMethod.headers.payoutMethod" })}</title>
      </Helmet>
      <Stepper steps={2} stepIndex={1} />

      <Container>
        <GreyBox type="aside">
          <TitleBar
            level={2}
            style={{ marginTop: 0 }}
            extraActions={
              <LinkButton to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }} icon="pencil">
                {formatMessage({ id: "common.edit" })}
              </LinkButton>
            }
          >
            {formatMessage({ id: "containers.info.title" })}
          </TitleBar>
          <Text type="secondary">
            {recipient.type === "business" && `${recipient.name}, `}
            {!!(recipient.firstName && recipient.lastName) && `${recipient.firstName} ${recipient.lastName}`}
            {!hideEmail && ` (${recipient.email})`}
          </Text>
          <Text type="secondary">
            <strong>
              {formatMessage({ id: "containers.bankPayoutMethod.address" })}
              :&nbsp;
            </strong>
            {stringifyAddress({
              ...recipient.address,
              region: getRegionLabel(recipient.address.region, recipientCountry),
              country: formatMessage({ id: `countries.${recipientCountry}` }), // since we have common-frontend keys locally... seems simpler
            })}
          </Text>
        </GreyBox>

        <TitleBar>{formatMessage({ id: "containers.payoutMethod.title" })}</TitleBar>
        {renderPayoutMethods()}
        <Divider transparent />
        <NotificationDisabledCountry
          payoutMethod={enabledPayoutMethods?.find((pm) => pm.integration === selectedMethod)}
          account={account}
          size="default"
        />
      </Container>

      {selectedMethod === PayoutMethodType.BANKTRANSFER && (
        <CurrenciesLoader>
          {(currencies) => <BankTransfer account={account} currencies={currencies} />}
        </CurrenciesLoader>
      )}
      {selectedMethod === PayoutMethodType.PAYPAL && <PayPal account={account} />}
      {selectedMethod === PayoutMethodType.BITCOIN && <Bitcoin account={account} />}
      {selectedMethod === PayoutMethodType.CHECK && <Check account={account} />}
      {selectedMethod === PayoutMethodType.INTERAC && <Interac account={account} />}
      {selectedMethod === PayoutMethodType.VENMO && <Venmo account={account} />}
    </>
  );
}

function getSelectablePayoutMethods(
  enabledPayoutMethods: WidgetMerchant["enabledPayoutMethods"] | undefined,
  paramsEnabledPayoutMethods: string[],
) {
  let filteredMethods = enabledPayoutMethods?.filter((pm) => paramsEnabledPayoutMethods.includes(pm.integration || ""));

  if (!filteredMethods?.length) {
    filteredMethods = enabledPayoutMethods;
  }

  // sort bank transfer first
  filteredMethods?.sort((a, b) =>
    a.integration === PayoutMethodType.BANKTRANSFER ? -1 : b.integration === PayoutMethodType.BANKTRANSFER ? 1 : 0,
  );

  return filteredMethods;
}

const useStyledPayoutMethod = createUseStyle<StyleType>(({ theme, ...props }) =>
  css`
    &.payout-select {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 90px;
      border: 2px solid ${props.selected ? theme.colorSuccess : theme.colorBorder};
      border-radius: ${theme.borderRadius}px;
      text-align: center;
      padding: 15px 0px;
      position: relative;
      transition: all 0.3s ease;
      background-color: ${() => {
        if (props.selected) {
          return theme.colorSuccessBg;
        }
        if (props.disabled) {
          return theme.colorBgContainerDisabled;
        }

        return ``;
      }};
      ${props.disabled
        ? `
      color: ${theme.colorTextDisabled};
      cursor: not-allowed;
      `
        : `
      &:hover {
        border-color: ${theme.colorSuccessHover};
        cursor: ${props.selected ? "auto" : "pointer"};
      }
      `};
    }
  `(),
);

const useStyledCheck = createUseStyle(css`
  &.icon {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -6px;
    right: -6px;
    background: ${({ theme }) => theme.colorBgLayout};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`);
