import { TaxFormType, TaxStatus } from "@trolley/common-frontend";
import { Stepper } from "components";
import useModal from "components/Modal/useModal";
import React, { useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { FormW8BEN, TaxForm, copyForm } from "store/actions/taxForms";
import { useTaxForms } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { ActionType, useIntl, useStepperEffect } from "utils/context";
import Certification from "./Certification";
import Identification from "./Identification";
import NonUsCertification from "./NonUsCertification";
import Review from "./Review";
import { useNewStyle } from "pages/App";

export function isW8BENForm(data: TaxForm | undefined): data is FormW8BEN {
  return data?.kind === TaxFormType.W8BEN;
}

enum PAGES {
  NON_US_CERTIFICATION = "",
  IDENTIFICATION = "identification",
  REVIEW = "review",
  CERTIFICATION = "certification",
}

export default function W8BEN({ taxForm, taxFormStatus }: { taxForm?: FormW8BEN; taxFormStatus?: BaseStatus }) {
  const history = useHistory();
  const match = useRouteMatch();
  const newLayout = useNewStyle();
  const modal = useModal();
  const { formatMessage } = useIntl();
  const taxFormLoading = taxFormStatus === BaseStatus.LOADING;
  const taxData = taxForm?.data;
  const page = (history.location.pathname.split("/")[3] || "") as PAGES;
  const pages = Object.values(PAGES);
  const pageIndex = pages.indexOf(page);
  const { data: taxForms } = useTaxForms();
  const expiredForm = taxForms.records.find(
    (t) => t.taxFormType === TaxFormType.W8BEN && t.signed && t.status === TaxStatus.EXPIRED,
  );

  useStepperEffect(ActionType.TAX_US, (pageIndex + 1) / pages.length);

  useEffect(() => {
    setTimeout(() => {
      if (expiredForm && !taxForm?.id) {
        modal.confirm({
          title: formatMessage({ id: "containers.tax.w8.copyExpiredForm" }),
          content: formatMessage({ id: "containers.tax.w8.formExpired" }, { formType: "W-8BEN" }),
          okText: formatMessage({ id: "containers.tax.w8.copyForm" }),
          onOk: async () => {
            if (expiredForm?.id) {
              const newFormId = await copyForm(expiredForm.id);

              history.push(`/tax/${newFormId}`);
            }
          },
        });
      }
    }, 3000);
  }, [expiredForm]);

  function getPath(page: PAGES) {
    if (!page) {
      return match.path;
    }

    return [match.path, page].join("/");
  }

  function getUrl(page: string) {
    if (!page) {
      return match.url;
    }

    return [match.url, page].join("/");
  }

  const nonUsCertification = (
    <NonUsCertification
      taxId={taxForm?.id}
      taxData={taxData}
      loading={taxFormLoading}
      onComplete={(id) => {
        if (!taxForm) {
          history.replace(`/tax/${id}`);
        }
        history.push(`/tax/${id}/${PAGES.IDENTIFICATION}`);
      }}
    />
  );

  return (
    <>
      {newLayout ? null : <Stepper steps={pages.length} stepIndex={pageIndex} />}
      {!taxForm || !taxData ? (
        nonUsCertification
      ) : (
        <Switch>
          <Route
            path={getPath(PAGES.IDENTIFICATION)}
            render={() => (
              <Identification
                taxId={taxForm.id}
                calculatedWithholdings={taxForm.calculatedWithholdings}
                taxData={taxData}
                loading={taxFormLoading}
                onComplete={() => {
                  history.push(getUrl(PAGES.REVIEW));
                }}
              />
            )}
          />

          <Route
            path={getPath(PAGES.REVIEW)}
            render={() => (
              <Review
                taxData={taxData}
                calculatedWithholdings={taxForm.calculatedWithholdings}
                loading={taxFormLoading}
                onComplete={() => {
                  history.push(getUrl(PAGES.CERTIFICATION));
                }}
              />
            )}
          />

          <Route
            path={getPath(PAGES.CERTIFICATION)}
            render={() => <Certification taxId={taxForm.id} taxData={taxData} loading={taxFormLoading} />}
          />
          <Route path={getPath(PAGES.NON_US_CERTIFICATION)} render={() => nonUsCertification} />
        </Switch>
      )}
    </>
  );
}
