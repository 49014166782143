import isEmpty from "../isEmpty";

export default function pickDiff<T extends object>(
  q: Partial<T>,
  original: object | undefined,
  includeEmpty?: boolean,
): Partial<T> | undefined {
  const output = {} as T;

  if (original && typeof original === "object") {
    Object.entries(q).forEach(([k, v]) => {
      if (k in original) {
        const isDifferent = JSON.stringify(v) !== JSON.stringify(original[k]);
        if (isDifferent && (Array.isArray(v) || !isEmpty(v as any) || includeEmpty)) {
          if (typeof v === "object" && v !== null) {
            if (Array.isArray(v)) {
              output[k] = v.filter((val) => !isEmpty(val));
            } else {
              const innerDiff = pickDiff(v, original[k], includeEmpty);

              if (innerDiff) {
                output[k] = innerDiff;
              }
            }
          } else {
            output[k] = v;
          }
        }
      }
    });
  }

  return Object.keys(output).length > 0 ? output : undefined;
}
