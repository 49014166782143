import { Container, Notification } from "components";
import React from "react";
import { useIntl } from "utils/context";

export default function TimeOut() {
  const { formatMessage } = useIntl();

  return (
    <Container padding="large">
      <Notification
        type="warning"
        showIcon={false}
        centered
        style={{ width: "300px", maxWidth: "100%", margin: "0 auto" }}
        title={formatMessage({ id: "containers.mainContainer.sessionExpired" })}
      >
        {formatMessage({ id: "containers.mainContainer.refreshPage" })}
      </Notification>
    </Container>
  );
}
