export enum PATHS {
  // public
  SHOWROOM = "/showroom",

  PAGE_NOT_FOUND = "/404",
  HOME = "/",
  INFO = "/info",
  PAYMENTS = "/payments",
  PAYOUT = "/payout-method",
  PAYOUT_COMPLETE = "/payout-method/complete",

  US_TAX = "/tax",
  US_TAX_NEW = "/tax/new",
  US_TAX_UPLOAD = "/tax/upload",
  US_TAX_DELIVERY = "/tax/delivery",
  US_TAX_COMPLETE = "/tax/complete",

  // all wizards
  WIZARD = "/wizard",
  WIZARD_SHOWROOM = "/wizard/showroom",
  WIZARD_TAXPROFILE_NEW = "/wizard/tax-profile/new",
  WIZARD_TAXPROFILE = "/wizard/tax-profile",

  // verifications
  KYB_UPLOAD = "/kyb/upload",
}
