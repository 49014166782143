import { Container, FileDownload, Grid, List, Loader, Paragraph, Status, Text, TitleBar } from "components";
import dayjs from "dayjs";
import { default as React } from "react";
import { useIntl } from "utils/context";
import { useDac7EoyTaxForms } from "store/hooks/dac7EoyTaxForms";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { EndOfYear, EoyTaxReportStatus } from "@trolley/common-frontend";

const taxYear = dayjs().subtract(1, "year").year();

export default function DAC7EndOfYearStatements() {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const { data: dac7EoyTaxForms, status: dac7EoyTaxFormsStatus } = useDac7EoyTaxForms();
  const taxYearFormCount = dac7EoyTaxForms.records.filter((dac7EoyTax) => dac7EoyTax.taxYear === taxYear).length;

  function renderStatement(dac7EoyTax: EndOfYear.DAC7RecipientEoyTax) {
    return (
      <Grid justify="space-between" alignItems="middle" wrap={false} padding="none">
        <Grid.Item>
          <Text strong inline>
            {dac7EoyTax.taxYear}&nbsp;
          </Text>
          {dac7EoyTax.status === EoyTaxReportStatus.VOID ? (
            <Status type="default">
              {formatMessage({
                id: `containers.accountSummary.taxStatuses.voided`,
              })}
            </Status>
          ) : (
            dayjs().isBefore(dayjs(`${dac7EoyTax.taxYear + 1}-02-01`)) && <Status type="success">NEW</Status>
          )}
        </Grid.Item>
        <Grid.Item align="right">
          {recipient && (
            <FileDownload
              size="small"
              fileName={[dac7EoyTax.taxYear, "tax_profile", dac7EoyTax.status].join("_")}
              url={`/v1/recipients/${recipient.id}/tax-dac7/download-end-of-year/${dac7EoyTax.taxYear}/${dac7EoyTax.id}`}
            >
              Download
            </FileDownload>
          )}
        </Grid.Item>
      </Grid>
    );
  }

  const sentReports = dac7EoyTaxForms
    ? dac7EoyTaxForms.records.filter((dac7EoyTax) => dac7EoyTax.status === EoyTaxReportStatus.SENT)
    : [];

  if (sentReports.length > 0) {
    return (
      <Loader spinning={dac7EoyTaxFormsStatus === BaseStatus.LOADING}>
        <Container>
          <TitleBar level={2}>
            {formatMessage({
              id: "containers.accountSummary.titles.dac7Statements",
            })}
          </TitleBar>
          <Paragraph type="secondary">
            {formatMessage({
              id: "containers.accountSummary.endOfYearDAC7Ready",
            })}
          </Paragraph>
          <List<EndOfYear.DAC7RecipientEoyTax>
            items={dac7EoyTaxForms.records}
            renderItem={renderStatement}
            defaultRecordCount={taxYearFormCount || 1}
            size="small"
            showMoreText={formatMessage({
              id: "containers.accountSummary.buttons.seeMoreTaxStatements",
            })}
            showLessText={formatMessage({
              id: "containers.accountSummary.buttons.seeLessTaxStatements",
            })}
          />
        </Container>
      </Loader>
    );
  }

  return null;
}
