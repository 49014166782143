import { generate } from "@ant-design/colors";
import createEmotion from "@emotion/css/create-instance";
import { theme as antTheme, ConfigProvider, GlobalToken } from "antd";
import config from "config";
import { useContext } from "react";
import { useIframeConfig } from "store/hooks/config";
import colors from "style/colors";
import tinycolor from "tinycolor2";
import { CustomTokens, GreyColors } from "../utils/context/ThemeProvider/interface";

type CSSValue = null | undefined | boolean | number | string;

type AnimationProp = {
  fadeIn: string;
  fadeInUp: string;
  fadeInDown: string;
  spin: string;
};

const { css: emotionCss, cx, injectGlobal, keyframes } = createEmotion({
  key: config.CLIENT,
});

export { cx as classnames, css, css as default, injectGlobal, keyframes, ThemeProps };

export function createUseStyle(callback: (props: ThemeProps) => string): () => string;
export function createUseStyle<P>(callback: (props: P & ThemeProps) => string): (p: P) => string;
export function createUseStyle<P>(callback: (props: P & ThemeProps) => string): (p: P) => string {
  return (p: P) => {
    const theme = useTheme();

    return callback({ ...p, theme, animation });
  };
}

function css<P>(template: TemplateStringsArray, ...args: (CSSValue | ((p: P) => CSSValue))[]): (p?: P) => string {
  return (p: P) => {
    const formattedArgs = args.map((arg) => {
      if (typeof arg === "function") {
        return noFalsy(arg(p));
      }

      return noFalsy(arg);
    });

    return emotionCss(template, ...formattedArgs);
  };
}

const animation = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  fadeInUp: keyframes`
    0% {
      opacity: 0;
      transform: translateY(8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  `,
  fadeInDown: keyframes`
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  `,
  spin: keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `,
};

/**
 * noFasly returns an empty string when any value is an unaccepted falsy CSS value such as:
 * - undefined
 * - null
 * - false
 * @param value ny CSS styling value
 * @returns empty if the value is false
 */
function noFalsy(value: CSSValue): CSSValue {
  if (value === undefined || value === null || value === false) {
    return "";
  }

  return value;
}

export type Theme = GlobalToken &
  CustomTokens &
  GreyColors & {
    prefixCls: string; // this is the className prefix
    screenUp(screenSize: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"): string;
    screenDown(screenSize: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"): string;
  };

interface ThemeProps {
  theme: Theme;
  animation: AnimationProp;
}

export function useTheme(): Theme {
  const confiContext = useContext(ConfigProvider.ConfigContext);
  const config = useIframeConfig();
  const token = antTheme.useToken().token;

  /**
   * screenUp a helper css styling function to redact breakpoint styles
   * @param breakpoint size of the breakpoint
   * @returns "@media screen and (min-width: [   ])"
   */
  function screenUp(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
    let minWidth: number;
    switch (breakpoint) {
      case "sm":
        minWidth = token.screenSM; // 576
        break;
      case "md":
        minWidth = token.screenMD; // 768
        break;
      case "lg":
        minWidth = token.screenLG; // 992
        break;
      case "xl":
        minWidth = token.screenXL; // 1200
        break;
      case "xxl":
        minWidth = token.screenXXL; // 1600
        break;
      default:
        minWidth = token.screenXS; // 480
    }

    return `@media screen and (min-width: ${minWidth}px)`;
  }

  /**
   * screenDown a helper css styling function to redact breakpoint styles
   * screen down takes 0.02px below the given breakpoint as the max limit
   * @param breakpoint size of the breakpoint
   * @returns "@media screen and (max-width: [   ])"
   */

  function screenDown(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
    let minWidth: number;
    switch (breakpoint) {
      case "xs":
        minWidth = token.screenXS - 0.02;
        break;
      case "sm":
        minWidth = token.screenSM - 0.02;
        break;
      case "md":
        minWidth = token.screenMD - 0.02;
        break;
      case "lg":
        minWidth = token.screenLG - 0.02;
        break;
      case "xl":
        minWidth = token.screenXL - 0.02;
        break;
      default:
        minWidth = token.screenXXL - 0.02;
    }

    return `@media screen and (max-width: ${minWidth}px)`;
  }

  return {
    prefixCls: confiContext.getPrefixCls(),
    ...token,
    ...getGreys(token.colorBgBase),
    ...getCustomColors(config.colors?.brandColor || colors.blue, token.colorBgBase),
    screenUp,
    screenDown,
  };
}

function getGreys(backgroundColor: string) {
  return generate(colors.grey, {
    theme: tinycolor(backgroundColor).isDark() ? "dark" : "default",
    backgroundColor,
  }).reduce((prev, color, i) => {
    prev[`grey${i + 1}`] = color;

    return prev;
  }, {} as GreyColors);
}

function getCustomColors(color: string, backgroundColor: string): CustomTokens {
  const isDark = tinycolor(backgroundColor).isDark();

  const brandColors = generate(color, {
    theme: isDark ? "dark" : "default",
    backgroundColor,
  });

  return {
    colorBrandBg: brandColors[0],
    colorBrandBgHover: brandColors[1],
    colorBrandBorder: brandColors[2],
    colorBrandBorderHover: brandColors[3],
    colorBrandHover: brandColors[isDark ? 6 : 4],
    colorBrand: brandColors[5],
    colorBrandActive: brandColors[isDark ? 4 : 6],
  };
}
