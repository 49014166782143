import { Button } from "components";
import React from "react";
import css, { createUseStyle } from "style/classname";

export type BaseProps<T> = {
  defaultRecordCount?: number;
  showMoreText?: React.ReactNode;
  showLessText?: React.ReactNode;
  footer?: React.ReactNode;
  items: T[];
  itemKey?: string;
};

export default function ListFooter(props: {
  footer: BaseProps<{}>["footer"];
  showMoreText: BaseProps<{}>["showMoreText"];
  showLessText: BaseProps<{}>["showLessText"];
  showMore: boolean;
  onShowMore: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { footer, showMore, onShowMore, showMoreText, showLessText } = props;
  const styledFooter = useStyledFooter();

  return (
    <div className={styledFooter}>
      {footer ? (
        footer
      ) : (
        <Button
          type="link"
          onClick={() => {
            onShowMore((state) => !state);
          }}
          icon={showMore ? "chevron-up" : "chevron-down"}
          placement="center"
          size="small"
          tabIndex={0}
        >
          {showMore ? showLessText : showMoreText}
        </Button>
      )}
    </div>
  );
}

const useStyledFooter = createUseStyle(({ theme }) =>
  css`
    padding: 0px 8px;
    align-content: center;
    text-align: center;
    border-top: 1px solid ${theme.colorBorder};
    min-height: 40px;
    display: grid;
  `(),
);
