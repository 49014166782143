import { formatCountry, getRegionLabel } from "@trolley/common-frontend";
import dayjs from "dayjs";
import React, { useState } from "react";
import Helmet from "react-helmet";

import { Container, Divider, Form, GreyBox, Grid, Modal, Paragraph, Text, TitleBar } from "components";
import { useForm } from "components/Form";
import SignatureBox from "pages/TaxSection/SignatureBox";
import TaxFooter from "pages/TaxSection/TaxFooter";
import { getCertificationTrueRequired, getIrsRule, getValidateIncomeTaxSignature } from "pages/TaxSection/variables";
import { notifyError } from "store/actions/notifications";
import { TaxDataW9, signAndSubmitTaxForm, updateTaxForm } from "store/actions/taxForms";
import css from "style/classname";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues } from "utils/helpers";
import SubmissionPopup from "../SubmissionPopup";
import { getUSTaxEntityTinType, taxTypes } from "./variables";

interface Props {
  taxId: string;
  loading?: boolean;
  taxData: TaxDataW9;
  onComplete(): void;
}

export default function Certification(props: Props) {
  const [form] = useForm();
  const { taxId, taxData, loading } = props;
  const [showConfirmWithholding, setShowConfirmWithholding] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);
  const [recipientSignature, setSignature] = useState("");
  const { formatMessage } = useIntl();
  const name = String(
    taxData.name ||
      [taxData.firstName, taxData.lastName] // first/last name is deprecated
        .filter((v) => v)
        .join(" ")
        .trim(),
  );
  const tinTypes = getUSTaxEntityTinType(taxData.taxType);
  const recipientType = (tinTypes.length > 1 ? taxData.idType : tinTypes[0]) === "ssn" ? "individual" : "business"; //  this should be based on idType (ssn or tin) to determine if recipient is individual or business, not Recipient['type']

  const checkboxProps = {
    showEmptyError: false,
  };

  const certificationTrueRequired = getCertificationTrueRequired(formatMessage);

  async function onFinish({
    signature, // only used for /sign enpoint
    dateSigned, // not used. for display only
    ...values
  }: Partial<TaxDataW9> & { signature: string; dateSigned: string }) {
    if (values.subjectToWithholding && !showConfirmWithholding) {
      setShowConfirmWithholding(true);
    } else {
      try {
        setShowConfirmWithholding(false);

        try {
          const formattedData = {
            data: omitMaskedValues(values),
          };
          await updateTaxForm(taxId, formattedData);
          setSignature(signature);
          setShowFinalize(true);
        } catch (errors) {
          handleFormErrors(errors, form);
        }
      } catch {
        setShowConfirmWithholding(false);
      }
    }
  }

  async function onSign() {
    try {
      await signAndSubmitTaxForm(taxId, recipientSignature);
      props.onComplete();
    } catch (errors) {
      notifyError(formatMessage({ id: "containers.tax.failedToSubmit" }), {
        errors,
      });
    }
  }

  return (
    <Form
      form={form}
      initialValues={{
        ...taxData,
        subjectToWithholding: taxData.subjectToWithholding ?? true,
      }}
      onFinish={onFinish}
    >
      <Container>
        <Helmet>
          <title>{formatMessage({ id: "containers.tax.certification" })}</title>
        </Helmet>
        <TitleBar>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-9" })}</TitleBar>
        <TitleBar level={3}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({ id: "containers.tax.certification" }),
              step: 2,
              totalSteps: 2,
            },
          )}
        </TitleBar>
        <dl className={styledTable}>
          <Grid>
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={4}>{formatMessage({ id: "containers.tax.w9.taxIdInformation" })}</TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.tax.classification" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>
                {taxData.taxType &&
                  (
                    taxTypes.find((item) => item.value === taxData.taxType) || {
                      label: "default",
                    }
                  ).label}
              </dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.tax.nameAsShown" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{name}</dd>
            </Grid.Item>
            {taxData.dbaName && (
              <>
                <Grid.Item xs={12}>
                  <dt>{formatMessage({ id: "containers.tax.businessName" })}</dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd data-test="dbaName">{taxData.dbaName}</dd>
                </Grid.Item>
              </>
            )}
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={4}>
                {formatMessage({
                  id: "containers.tax.w9.taxAddressInformation",
                })}
              </TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.country.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{formatCountry(taxData.country, formatMessage)}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.street1.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.address}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.city.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.city}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.tax.w9.stateRegion" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{getRegionLabel(taxData.state, taxData.country)}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.region.US.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.zip}</dd>
            </Grid.Item>
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={4}>{formatMessage({ id: "containers.tax.tin" })}</TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>
                {recipientType === "individual"
                  ? formatMessage({ id: "containers.tax.ssn" })
                  : formatMessage({ id: "containers.tax.ein" })}
              </dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.taxId}</dd>
            </Grid.Item>
            {(taxData.exemptPayee || taxData.exemptReporting) && (
              <>
                <Divider />
                <Grid.Item xs={24}>
                  <TitleBar level={4}>{formatMessage({ id: "containers.tax.w9.exemptions" })}</TitleBar>
                </Grid.Item>
              </>
            )}
            {taxData.exemptPayee && (
              <>
                <Grid.Item xs={12}>
                  <dt>{formatMessage({ id: "containers.tax.w9.exemptPayeeCode" })}</dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd data-test="exemptPayee">
                    {formatMessage({ id: "containers.tax.w9.code" }, { exemptCode: taxData.exemptPayee })}
                  </dd>
                </Grid.Item>
              </>
            )}
            {taxData.exemptReporting && (
              <>
                <Grid.Item xs={12}>
                  <dt>{formatMessage({ id: "containers.tax.w9.exemptFatca" })}</dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd>{formatMessage({ id: "containers.tax.w9.code" }, { exemptCode: taxData.exemptReporting })}</dd>
                </Grid.Item>
              </>
            )}
          </Grid>
        </dl>

        <TitleBar level={3}>
          {formatMessage(
            { id: "containers.tax.w9.partII" },
            { title: formatMessage({ id: "containers.tax.certification" }) },
          )}
        </TitleBar>
        <Paragraph>{formatMessage({ id: "containers.tax.w9.underPenalty" })}</Paragraph>

        <Form.Field name="idIsCorrect" rules={[certificationTrueRequired]} {...checkboxProps}>
          <Form.Checkbox name="idIsCorrect">{formatMessage({ id: "containers.tax.w9.correctTin" })}</Form.Checkbox>
        </Form.Field>

        <Divider transparent />

        <Form.Field name="subjectToWithholding" {...checkboxProps}>
          {({ value, onChange, ...rest }) => (
            <Form.Checkbox
              {...rest}
              name="subjectToWithholding"
              checked={value === undefined ? undefined : !value}
              onChange={(checked) => {
                onChange?.(!checked);
              }}
            >
              {formatMessage({
                id: "containers.tax.w9SubjectToWithholding.because",
              })}
              <ol type="a">
                <li>
                  {" "}
                  {formatMessage({
                    id: "containers.tax.w9SubjectToWithholding.exempt",
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: "containers.tax.w9SubjectToWithholding.notNotified",
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: "containers.tax.w9SubjectToWithholding.notified",
                  })}
                </li>
              </ol>
            </Form.Checkbox>
          )}
        </Form.Field>

        <Divider transparent />

        <Form.Field name="isUsPerson" rules={[certificationTrueRequired]} {...checkboxProps}>
          <Form.Checkbox name="isUsPerson">{formatMessage({ id: "containers.tax.w9.usCitizen" })}</Form.Checkbox>
        </Form.Field>

        <Divider transparent />

        <Form.Field name="hasAuthorized" rules={[certificationTrueRequired]} {...checkboxProps}>
          <Form.Checkbox name="hasAuthorized">{formatMessage({ id: "containers.tax.w9.correctFatca" })}</Form.Checkbox>
        </Form.Field>

        <Divider transparent />

        <Form.Field name="hasAcknowledged" rules={[certificationTrueRequired]} {...checkboxProps}>
          <Form.Checkbox name="hasAcknowledged">
            <strong>{formatMessage({ id: "containers.tax.w9.haveRead" })}</strong>
          </Form.Checkbox>
        </Form.Field>

        <Text>{formatMessage({ id: "containers.tax.formConfirmation" }, { name: "", form: "W-9" })}</Text>
      </Container>

      <Divider transparent />

      <SignatureBox>
        <Grid>
          <Grid.Item xs={24} sm={16}>
            <Form.Field
              name="signature" // only used on the /sign endpoint
              label={
                recipientType === "individual"
                  ? formatMessage({ id: "containers.tax.signatureWithName" }, { name })
                  : formatMessage({
                      id: "containers.tax.signatureWithFullName",
                    })
              }
              rules={[
                getIrsRule(formatMessage),
                ...(recipientType === "individual"
                  ? [getValidateIncomeTaxSignature(name, formatMessage)]
                  : [
                      {
                        required: true,
                        message: formatMessage({
                          id: "containers.tax.signatureRequired",
                        }),
                      },
                    ]),
              ]}
            >
              <Form.Input name="signature" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={8}>
            {/* Date signed is not used. only for display purpose. API will create a signedAt field when form is signed  */}
            <Form.Field name="dateSigned" label="Date">
              {() => <Form.Input readOnly value={dayjs().format("LL")} name="dateSigned" />}
            </Form.Field>
          </Grid.Item>
        </Grid>
        <Text type="secondary" size="small">
          {formatMessage({ id: "containers.tax.bySigning" })}
        </Text>
      </SignatureBox>

      <Container>
        <TaxFooter loading={loading} okText={formatMessage({ id: "containers.tax.completeForm" }, { form: "W-9" })} />
      </Container>

      <Modal
        open={showConfirmWithholding}
        onCancel={() => {
          setShowConfirmWithholding(false);
        }}
        onOk={form.submit}
        confirmLoading={loading}
        okText={formatMessage({ id: "containers.tax.w9.withholdingWarningOk" })}
        title={formatMessage({
          id: "containers.tax.w9.withholdingWarningTitle",
        })}
      >
        <GreyBox>
          <b>{formatMessage({ id: "containers.tax.w9.withholdingWarning" })}</b>
        </GreyBox>
      </Modal>

      <SubmissionPopup
        onClose={() => {
          setShowFinalize(false);
        }}
        onOk={onSign}
        visible={showFinalize}
        formType="W-9"
      />
    </Form>
  );
}

const styledTable = css`
  width: 100%;
  td {
    vertical-align: top;
    padding-bottom: 4px;
    hr {
      margin-top: 10px;
      margin-bottom: 6px;
    }
  }
  th {
    padding-bottom: 10px;
  }
  tr {
    td:first-of-type {
      max-width: 140px;
      vertical-align: top;
    }
  }
`();
