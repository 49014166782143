/**
 * isEmpty true if empty string | null | undefined
 * @param value
 * @returns
 */
export default function isEmpty(value: string | null | undefined | number | boolean | any[] | object): boolean {
  if (value && typeof value === "object") {
    return Array.isArray(value)
      ? value.every(isEmpty) // empty array
      : Object.keys(value).length === 0; // empty object {}
  }

  return value === "" || value === null || value === undefined;
}
