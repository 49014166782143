import * as types from "@trolley/common-frontend";
import { POST } from "services/request";
import { OpCode, standardDispatch } from "store/dispatcher";
import { createFormData, emitEvent, WidgetEvent } from "utils/helpers";
import { RcFile } from "rc-upload/lib/interface";
import store from "store";
import { PRODUCT_MODULES } from "utils/context/ProductsContext";
import { isLoaded } from "./actionUtils";
import { loadRecipient } from "./recipient";

export interface IndividualVerificationResponse extends types.IndividualVerificationResponse {}
export interface KybVerificationResponse extends types.KybVerificationResponse {}
export interface PhoneVerificationResponse extends types.PhoneVerificationResponse {}

export type VerificationHistory = {
  individual: IndividualVerificationResponse[];
  business: KybVerificationResponse[];
  phone: PhoneVerificationResponse[];
};

export async function asyncLoadIdvSessionUrl() {
  const { idvSession } = store.getState();
  const id = "data";

  if (idvSession.entities[id]) {
    return idvSession.entities[id];
  }

  try {
    standardDispatch(OpCode.LOADING, "idvSession", { id });
    const res = await POST<types.VeriffRecipientSessionResponse>("/v1/idv/recipient/session");
    standardDispatch(OpCode.DATA, "idvSession", {
      id,
      data: res.body.session.url,
    });

    return res.body.session.url;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "idvSession", { id, errors });
    throw errors;
  }
}

export function startIdVerification() {
  standardDispatch(OpCode.UPDATE, "recipient", { update: { idvStatus: types.VerificationStatus.REVIEW } });
}

function refreshRecipientVerification() {
  const {
    recipient: {
      entities: { data: recipient },
    },
    config,
  } = store.getState();
  if (recipient) {
    loadRecipient(recipient.id);
    if (config?.showVerificationHistory) {
      loadVerifications(recipient.id, true);
    }
  }
}

export function cancelIdVerification() {
  // As IDV is not completed, revert back status set in startIdVerification
  standardDispatch(OpCode.UPDATE, "recipient", { update: { idvStatus: types.VerificationStatus.PENDING } });
  refreshRecipientVerification();
}

export function completeIdVerification() {
  // Decision webhook from Veriff can take time, Below flags are used in portal sidebar and inactive reasons
  standardDispatch(OpCode.UPDATE, "recipient", { update: { idvStatus: types.VerificationStatus.REVIEW } });
  emitEvent({
    event: WidgetEvent.MODULE_SUCCESSFUL,
    module: [PRODUCT_MODULES.TRUST],
  });
  refreshRecipientVerification();
}

export async function uploadKybDocuments(file: RcFile) {
  try {
    const formData = createFormData([file]);
    await POST("/v1/idv/file/upload", { isUpload: true, body: formData });
    // TODO: Change kybStatus based on api response, we will need to change api OR reload recipient
    standardDispatch(OpCode.UPDATE, "recipient", { update: { kybStatus: types.KybStatus.PENDING } });
    emitEvent({
      event: WidgetEvent.MODULE_SUCCESSFUL,
      module: [PRODUCT_MODULES.TRUST],
    });
    refreshRecipientVerification();
  } catch (errors) {
    standardDispatch(OpCode.UPDATE, "recipient", { update: { kybStatus: types.KybStatus.RETRY } });
    emitEvent({
      event: WidgetEvent.MODULE_FAILED,
      module: [PRODUCT_MODULES.TRUST],
    });

    throw errors;
  }
}

export function loadVerifications(recipientId: string, force?: boolean) {
  const id = "data";
  const { verifications } = store.getState();
  if (recipientId && (force || !isLoaded(verifications.fetchStatus[id]))) {
    standardDispatch(OpCode.LOADING, "verifications", { id });
    POST<VerificationHistory>("/v1/verifications/get", {
      query: {
        recipientId,
        // only getting individual and business for now
        filterBy: {
          [types.VerificationType.INDIVIDUAL]: true,
          [types.VerificationType.BUSINESS]: true,
        },
      },
    })
      .then(({ body: verificationHistoryResponse }) => {
        standardDispatch(OpCode.DATA, "verifications", {
          id,
          data: verificationHistoryResponse,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "verifications", { id, errors });
      });
  }
}
