import { Stepper, Modal } from "components";
import { ActionType, useIntl, useStepperEffect } from "utils/context";
import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { copyForm, FormW8BENE, TaxDataW8BENE, TaxForm } from "store/actions/taxForms";
import { W8UsWithholdingTable } from "store/actions/withholdingTable";
import { useTaxForms } from "store/hooks/taxForms";
import { useWithholdingTable } from "store/hooks/withholdingTable";
import { BaseStatus } from "store/reducers/standardReducer";
import { TaxFormType, TaxStatus } from "@trolley/common-frontend";
import Certification from "./Certification";
import Identification from "./Identification";
import NonUsCertification from "./NonUsCertification";
import Review from "./Review";
import TreatyBenefits from "./TreatyBenefits";
import { useNewStyle } from "pages/App";

export function isW8BENEForm(form: TaxForm | undefined): form is FormW8BENE {
  return form?.kind === TaxFormType.W8BENE;
}
function getStepperInfo(taxData: TaxDataW8BENE | undefined, withholdingTable: W8UsWithholdingTable, pathname: string) {
  const hasTreatySection =
    taxData?.residenceCountry &&
    withholdingTable[taxData.residenceCountry] && // country has treaty withholding
    !taxData.noTaxId;
  const page = (pathname.split("/")[3] || "") as PAGES;
  const pages = Object.values(PAGES).filter((p) => hasTreatySection || p !== PAGES.TREATY);
  const pageIndex = pages.indexOf(page);

  return { pages, pageIndex };
}

enum PAGES {
  NON_US_CERTIFICATION = "",
  IDENTIFICATION = "identification",
  TREATY = "treaty",
  REVIEW = "review",
  CERTIFICATION = "certification",
}

export default function W8BENE({ taxForm, taxFormStatus }: { taxForm?: FormW8BENE; taxFormStatus?: BaseStatus }) {
  const history = useHistory();
  const match = useRouteMatch();
  const newLayout = useNewStyle();
  const { data: withholdingTable } = useWithholdingTable();
  const { formatMessage } = useIntl();
  const taxFormLoading = taxFormStatus === BaseStatus.LOADING;
  const taxData = taxForm?.data;
  const { pages, pageIndex } = getStepperInfo(taxData, withholdingTable, history.location.pathname);
  const { data: taxForms } = useTaxForms();
  const expiredForm = taxForms.records.find(
    (t) => t.taxFormType === TaxFormType.W8BENE && t.signed && t.status === TaxStatus.EXPIRED,
  );
  const [showCopy, setShowCopy] = useState(false);
  useStepperEffect(ActionType.TAX_US, (pageIndex + 1) / pages.length);

  useEffect(() => {
    setTimeout(() => {
      if (expiredForm && !taxForm?.id) {
        setShowCopy(true);
      }
    }, 3000);
  }, [expiredForm]);

  async function copyExpiredForm() {
    if (expiredForm?.id) {
      const newFormId = await copyForm(expiredForm.id);

      history.push(`/tax/${newFormId}`);
    }
  }

  function getPath(page: PAGES) {
    if (!page) {
      return match.path;
    }

    return [match.path, page].join("/");
  }

  function getUrl(page: string) {
    if (!page) {
      return match.url;
    }

    return [match.url, page].join("/");
  }

  const nonUsCertification = (
    <NonUsCertification
      taxId={taxForm?.id}
      taxData={taxData}
      loading={taxFormLoading}
      pageIndex={pageIndex}
      pageTotal={pages.length}
      onComplete={(id) => {
        if (!taxForm) {
          history.replace(`/tax/${id}`);
        }
        history.push(`/tax/${id}/${PAGES.IDENTIFICATION}`);
      }}
    />
  );

  return (
    <div>
      {newLayout ? null : <Stepper steps={pages.length} stepIndex={pageIndex} />}
      {!taxForm || !taxData ? (
        nonUsCertification
      ) : (
        <Switch>
          <Route
            path={getPath(PAGES.IDENTIFICATION)}
            render={() => (
              <Identification
                pageIndex={pageIndex}
                pageTotal={pages.length}
                taxId={taxForm.id}
                taxData={taxData}
                loading={taxFormLoading}
                onComplete={(tf) => {
                  const hasTreatySection =
                    isW8BENEForm(tf) &&
                    tf.data.residenceCountry &&
                    withholdingTable[tf.data.residenceCountry] && // country has treaty withholding
                    !tf.data.noTaxId;

                  if (hasTreatySection) {
                    history.push(getUrl(PAGES.TREATY));
                  } else {
                    history.push(getUrl(PAGES.REVIEW));
                  }
                }}
              />
            )}
          />
          <Route
            path={getPath(PAGES.TREATY)}
            render={() => (
              <TreatyBenefits
                taxId={taxForm.id}
                taxData={taxData}
                loading={taxFormLoading}
                calculatedWithholdings={taxForm.calculatedWithholdings}
                pageIndex={pageIndex}
                pageTotal={pages.length}
                onComplete={() => {
                  history.push(getUrl(PAGES.REVIEW));
                }}
              />
            )}
          />
          <Route
            path={getPath(PAGES.REVIEW)}
            render={() => (
              <Review
                taxData={taxData}
                calculatedWithholdings={taxForm.calculatedWithholdings}
                loading={taxFormLoading}
                pageIndex={pageIndex}
                pageTotal={pages.length}
                onComplete={() => {
                  history.push(getUrl(PAGES.CERTIFICATION));
                }}
              />
            )}
          />
          <Route
            path={getPath(PAGES.CERTIFICATION)}
            render={() => (
              <Certification
                taxId={taxForm.id}
                taxData={taxData}
                loading={taxFormLoading}
                pageIndex={pageIndex}
                pageTotal={pages.length}
              />
            )}
          />
          <Route path={getPath(PAGES.NON_US_CERTIFICATION)} render={() => nonUsCertification} />
        </Switch>
      )}
      <Modal
        open={showCopy}
        title="Copy Expired Form"
        okText="Copy Form"
        onOk={copyExpiredForm}
        onCancel={() => setShowCopy(false)}
      >
        Your W-8BEN-E tax form has now expired, as required by the IRS after 3 full calendar years. Would you like to
        copy the information from your old W-8BEN-E form to submit a new one? You will have the opportunity to review
        the information before e-signing it.
        {formatMessage({ id: "containers.tax.w8.formExpired" }, { formType: "W-8BEN" })}
      </Modal>
    </div>
  );
}
