import React, { HTMLAttributes, ReactNode } from "react";

interface Props<E = HTMLElement> extends HTMLAttributes<E> {
  type?: keyof HTMLElementTagNameMap;
  children: ReactNode;
}
export default function Stop({ type = "span", children, onClick, ...rest }: Props) {
  return React.createElement(
    type,
    {
      ...rest,
      onClick: (e: any) => {
        e?.stopPropagation?.();
        onClick?.(e);
      },
    },
    children,
  );
}
