import { AlertProps as AntAlertProps, Alert as AntAlert } from "antd";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import Icon, { IconName, IconProps } from "components/Icon";
import { useNewStyle } from "pages/App";
import React, { CSSProperties, HTMLAttributes, ReactNode, useState } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import { capitalize, getContrastText } from "utils/helpers";
import { IntlMessageKeys, useIntl } from "utils/context";
import Button from "./Button";
import Grid from "./Grid";
import Text from "./Text";

export interface NotificationProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  type: "success" | "warning" | "error" | "info";
  style?: CSSProperties;
  closable?: boolean;
  title: ReactNode;
  children?: React.ReactNode;

  // new style only
  size?: "default" | "large";
  action?: AntAlertProps["action"];

  // Legacy style only
  showIcon?: boolean;
  iconProps?: IconProps;
  centered?: boolean;
}

export default function Notification(props: NotificationProps) {
  const [show, setShow] = useState(true);
  const { formatMessage } = useIntl();
  const newStyle = useNewStyle();
  const legacyStyledNotification = useLegacyStyledNotification(props);
  const styledNotification = useStyledNotification(props);
  const {
    title,
    action,
    className,
    children,
    closable,
    showIcon = true,
    centered,
    type = "info",
    iconProps,
    size,
    style,
    ...rest
  } = props;

  function renderIcon() {
    switch (type) {
      case "success":
        return <Icon size="large" color="success" type="check-circle" {...iconProps} />;
      case "warning":
        return <Icon size="large" color="warning" type="exclamation-triangle" {...iconProps} />;
      case "error":
        return <Icon size="large" color="error" type="exclamation-circle" {...iconProps} />;
      case "info":
      default:
        return <Icon size="large" color="info" type="info-circle" {...iconProps} />;
    }
  }

  if (newStyle) {
    return (
      <AntAlert
        className={classnames(styledNotification, className)}
        type={type}
        showIcon={size !== "large"}
        icon={
          TYPE_TO_ICONNAME[type] ? (
            <Icon theme="regular" type={TYPE_TO_ICONNAME[type].icon} color={TYPE_TO_ICONNAME[type].color} />
          ) : undefined
        }
        closable={!!closable}
        closeIcon={<Icon type="times" theme="solid" size="large" />}
        message={
          <>
            <span className="sr-only">{formatMessage({ id: getNotificationType(type) })}. </span>
            {title}
          </>
        }
        description={children}
        action={action}
        {...rest}
      />
    );
  }

  if (show) {
    return (
      <div className={classnames(legacyStyledNotification, className)} style={style} {...rest}>
        <Grid
          alignItems={centered ? "middle" : "top"}
          padding={["none", "medium"]}
          style={{ position: "relative" }}
          wrap={false}
          direction={centered ? "column" : "row"}
        >
          {showIcon && (
            <Grid.Item align="center" flex="32px" style={{ padding: 0 }}>
              {renderIcon()}
            </Grid.Item>
          )}
          <Grid.Item flex={1} align={centered ? "center" : undefined}>
            <Text strong={!!children} style={{ marginBottom: children ? "8px" : 0 }}>
              {title}
            </Text>
            {children}
          </Grid.Item>
          {closable && (
            <Button
              type="text"
              style={{ top: "-4px" }}
              icon="times"
              onClick={() => {
                setShow(false);
              }}
              aria-label={formatMessage({ id: "common.close" })}
            />
          )}
        </Grid>
      </div>
    );
  }

  return null;
}

const useStyledNotification = createUseStyle<NotificationProps>(({ theme, ...props }) => {
  const typeColor = theme[`color${capitalize(props.type)}`] || theme.colorInfo;

  return css`
    &.${theme.prefixCls}-alert {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      &:not(:first-child) {
        margin-top: ${theme.margin}px;
      }
      &:not(:last-child) {
        margin-bottom: ${theme.margin}px;
      }
      .${theme.prefixCls}-alert-icon {
        line-height: inherit;
      }
      .${theme.prefixCls}-alert-close-icon {
        line-height: inherit;
      }

      ${props.size === "large"
        ? `
        border-width: 4px;
        border-color: ${typeColor};
        padding: 0px;
        flex-direction: column;
        align-items: stretch;
        background-color: ${theme.colorBgElevated};
        .${theme.prefixCls}-alert-message {
          color: ${getContrastText(typeColor)};
          font-size: ${theme.fontSizeXL}px;
          line-height: ${theme.lineHeightLG};
          font-weight: 600; // for semibold
          padding: ${theme.padding}px ${theme.paddingLG}px;
          padding-right: ${theme.paddingXL + theme.controlHeight}px; // close button width
          background-color: ${typeColor};
        }
        .${theme.prefixCls}-alert-description {
          padding: ${theme.paddingLG}px;
        }
        .${theme.prefixCls}-alert-action {
          padding: 0 ${theme.paddingLG}px ${theme.paddingLG}px;
        }
        ${theme.screenUp("md")} {
          .${theme.prefixCls}-alert-message {
            padding: ${theme.paddingLG}px ${theme.paddingXL}px;
          }
          .${theme.prefixCls}-alert-description {
            padding: ${theme.paddingXL}px;
          }
          .${theme.prefixCls}-alert-action {
            padding: 0 ${theme.paddingXL}px ${theme.paddingXL}px;
          }
        }
        .${theme.prefixCls}-alert-close-icon {
          color: ${getContrastText(typeColor)};
          line-height: inherit;
          height: ${theme.controlHeightSM}px;
          width: ${theme.controlHeightSM}px;
          border-radius: ${theme.borderRadiusLG}px;
          &:hover {
            background-color: ${theme.colorBgTextHover};
          }
          position: absolute;
          right: 14px;
          top: 14px;
          ${theme.screenUp("md")} {
            right: 20px;
            top: 20px;
          }
        }
      `
        : `
        padding: ${theme.paddingSM}px ${theme.padding}px;
      `}
    }
  `();
});

const useLegacyStyledNotification = createUseStyle<NotificationProps>(({ theme, ...props }) =>
  css`
    width: 100%;
    color: ${theme.colorText};
    background-color: ${() => {
      switch (props.type) {
        case "error":
          return getAlphaColor(theme.colorError, 0.2);
        case "warning":
          return getAlphaColor(theme.colorWarning, 0.2);
        case "success":
          return getAlphaColor(theme.colorSuccess, 0.2);
        case "info":
        default:
          return theme.colorFillSecondary;
      }
    }};
    border-radius: ${theme.borderRadius}px;
    padding: ${theme.paddingSM}px;
    margin-top: ${theme.marginXS}px;
    margin-bottom: ${theme.margin}px;
    ${theme.screenUp("md")} {
      padding: ${theme.paddingMD}px;
    }
  `(),
);

const TYPE_TO_ICONNAME: Record<NotificationProps["type"], { icon: IconName; color: IconProps["color"] }> = {
  success: {
    icon: "check-circle",
    color: "success",
  },
  warning: {
    icon: "exclamation-circle",
    color: "warning",
  },
  info: {
    icon: "info-circle",
    color: "info",
  },
  error: {
    icon: "times-circle",
    color: "error",
  },
};

function getNotificationType(type: NotificationProps["type"]): IntlMessageKeys {
  switch (type) {
    case "success":
      return "common.success";
    case "warning":
      return "common.warning";
    case "error":
      return "common.error";
    case "info":
    default:
      return "common.info";
  }
}
