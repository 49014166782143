import { generate } from "@ant-design/colors";
import { RecursivePartial } from "@trolley/common-frontend";
import { App as AntApp, ConfigProvider, theme as antTheme } from "antd";
import { MapToken, OverrideToken } from "antd/lib/theme/interface";
import { AliasToken, SeedToken } from "antd/lib/theme/internal";
import { generateNeutralColorPalettes as generateDarkNeutralColorPalettes } from "antd/lib/theme/themes/dark/colors";
import { getSolidColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import { generateNeutralColorPalettes } from "antd/lib/theme/themes/default/colors";
import { defaultPresetColors as antPresetColors } from "antd/lib/theme/themes/seed";
import genColorMapToken from "antd/lib/theme/themes/shared/genColorMapToken";
import genCommonMapToken from "antd/lib/theme/themes/shared/genCommonMapToken";
import genFontMapToken from "antd/lib/theme/themes/shared/genFontMapToken";
import genSizeMapToken from "antd/lib/theme/themes/shared/genSizeMapToken";
import assignDeep from "assign-deep";
import React, { ReactNode } from "react";
import TinyColor from "tinycolor2";

import { ConfigProviderProps } from "antd/lib/config-provider";
import { useNewStyle } from "pages/App";
import { IFrameConfig } from "store/hooks/config";
import colors from "style/colors";
import omitEmpty from "utils/helpers/structure/omitEmpty";
import { useDeepCompareMemo } from "utils/hooks";

export const defaultConfig: Pick<IFrameConfig, "colors" | "style"> = {
  colors: {
    brandColor: colors.primary,
    primary: colors.blue,
    info: colors.blue,
    success: colors.green,
    error: colors.red,
    warning: colors.orange,
    heading: colors.dark,
    text: colors.dark,
    inputText: colors.dark,
    border: getSolidColor(colors.grey, -10),
    inputBorder: getSolidColor(colors.grey, -10),
    subText: getSolidColor(colors.grey, 25),
    background: colors.light,
  },
  style: {
    borderRadius: "24",
    buttonBorderRadius: "24",
  },
};

export default function ThemeProvider({
  children,
  prefixCls,
  iframeConfig,
  token: overrideToken,
  components: overrideComponents = () => ({}),
  form,
}: {
  children?: ReactNode;
  prefixCls?: string;
  iframeConfig?: RecursivePartial<Pick<IFrameConfig, "colors" | "style">>;
  token?: Partial<AliasToken>;
  components?: (token: AliasToken) => OverrideToken;
  form?: ConfigProviderProps["form"];
}) {
  const newStyle = useNewStyle();

  const { token, components } = useDeepCompareMemo(() => {
    const { overrideSeed, overrideAlias } = Object.entries(overrideToken || {}).reduce(
      (acc, [key, color]) => {
        if (key in antTheme.defaultSeed) {
          acc.overrideSeed[key] = color;
        } else {
          acc.overrideAlias[key] = color;
        }

        return acc;
      },
      { overrideSeed: {}, overrideAlias: {} },
    );

    const seed: SeedToken = {
      ...antTheme.defaultSeed,
      controlHeight: newStyle ? 40 : 32,
      fontSize: 14,
      // fontSize: newStyle ? 16 : 14,
      fontFamily: `"Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      ...omitEmpty<Partial<SeedToken>>({
        colorPrimary: iframeConfig?.colors?.primary || defaultConfig.colors.primary,
        colorLink: iframeConfig?.colors?.primary || defaultConfig.colors.primary,
        colorInfo: iframeConfig?.colors?.info || defaultConfig.colors.info,
        colorSuccess: iframeConfig?.colors?.success || defaultConfig.colors.success,
        colorWarning: iframeConfig?.colors?.warning || defaultConfig.colors.warning,
        colorError: iframeConfig?.colors?.error || defaultConfig.colors.error,
        colorTextBase: iframeConfig?.colors?.text || defaultConfig.colors.text,
        colorBgBase: iframeConfig?.colors?.background || defaultConfig.colors.background,
        borderRadius: Number(iframeConfig?.style?.borderRadius ?? defaultConfig.style.borderRadius),
        ...overrideSeed,
      }),
    };

    const isDark = new TinyColor(seed.colorBgBase).isDark();

    const aliasToken: AliasToken = {
      ...antTheme.getDesignToken({
        token: {
          ...seed,
          ...getDefaultFontSizes(seed.fontSize, newStyle),
          colorBgLayout: seed.colorBgBase,
          ...omitEmpty({
            colorTextHeading: iframeConfig?.colors?.heading,
            colorTextSecondary: iframeConfig?.colors?.subText,
            colorTextTertiary: iframeConfig?.colors?.subText,
            // colorTextQuaternary: iframeConfig.colors?.subText, // leave it to default. it's usually an opacity used in Switch bgcolor
            colorBorder: iframeConfig?.colors?.border,
            colorBorderSecondary: iframeConfig?.colors?.border,
          }),

          // BUG in ant/lib/theme/themes/shared/genColorMapToken.js
          colorSuccessHover: generatePalette(seed.colorSuccess, seed.colorBgBase)[5], // BUGFIX color need to be fixed for dark theme.
          colorInfoHover: generatePalette(seed.colorInfo, seed.colorBgBase)[5], // BUGFIX color need to be fixed for dark theme
          colorWarningHover: generatePalette(seed.colorWarning, seed.colorBgBase)[5], // BUGFIX color need to be fixed for dark theme
          ...overrideAlias,
        },
        algorithm: [customAlgorithm],
      }),
    };

    const components: OverrideToken = assignDeep(
      {
        Avatar: {
          borderRadius: 8,
          borderRadiusLG: 8,
        },
        Alert: {
          // fontSizeLG: ..., // message font size
          fontSizeHeading3: aliasToken.fontSize, // icon font size
        },
        Button: {
          ...omitEmpty({
            borderRadius: iframeConfig?.style?.buttonBorderRadius && Number(iframeConfig?.style?.buttonBorderRadius),
            borderRadiusSM: iframeConfig?.style?.buttonBorderRadius && Number(iframeConfig?.style?.buttonBorderRadius),
            borderRadiusLG: iframeConfig?.style?.buttonBorderRadius && Number(iframeConfig?.style?.buttonBorderRadius),
          }),
          marginXS: newStyle ? 8 : 4, // icon margin
          paddingContentHorizontal: newStyle ? 32 : 12, // horizontal padding for default and large buttons
        },
        Form: {
          colorTextHeading: aliasToken.colorTextSecondary,
          paddingXS: 12, // space between label/input on vertical layout
        },
        Input: {
          colorText: iframeConfig?.colors?.inputText || aliasToken.colorText,
        },
        Layout: {
          headerHeight: 128,
          colorBgHeader: aliasToken.colorBgContainer,
          controlHeightLG: 32,
        },
        Modal: {
          titleFontSize: aliasToken.fontSize + 6,
        },
        Radio: {
          colorWhite: aliasToken.colorBgContainer, // this is the dot in the midle when selected. it should look like bg color so it appears transparent
          padding: 12, // mainly for RadioGrou optionType: "card"
        },
        Tooltip: {
          colorBgDefault: isDark ? aliasToken.colorBgElevated : getSolidColor(colors.grey, 40),
          borderRadius: 8,
        },
      } as OverrideToken,
      overrideComponents(aliasToken),
    );

    return {
      components,
      token: aliasToken,
    };
  }, [iframeConfig, overrideToken, newStyle]);

  return (
    <ConfigProvider
      theme={{ token, components }}
      prefixCls={prefixCls}
      form={form}
      componentSize={newStyle ? "large" : "middle"}
    >
      {/**
       * Ant's App should be in pair with ConfigProvider
       * https://ant.design/components/app#sequence-with-configprovider
       * */}
      <AntApp>{children}</AntApp>
    </ConfigProvider>
  );
}

function customAlgorithm(token: SeedToken): MapToken {
  const isDark = new TinyColor(token.colorBgBase).isDark();
  const colorPalettes = Object.keys(antPresetColors)
    .map((colorKey) => {
      const colors = generatePalette(token[colorKey], token.colorBgBase);

      return new Array(10).fill(1).reduce((prev, _, i) => {
        prev[`${colorKey}-${i + 1}`] = colors[i + 1]; // LEGACY, DEPRECATED
        prev[`${colorKey}${i + 1}`] = colors[i + 1];

        return prev;
      }, {});
    })
    .reduce((prev, cur) => {
      prev = { ...prev, ...cur };

      return prev;
    }, {});

  return {
    ...token,
    ...colorPalettes,
    ...genColorMapToken(token, {
      generateColorPalettes: (baseColor: string) => generatePalette(baseColor, token.colorBgBase),
      generateNeutralColorPalettes: isDark ? generateDarkNeutralColorPalettes : generateNeutralColorPalettes,
    }),
    ...genFontMapToken(token.fontSize),
    ...genSizeMapToken(token),

    // ...genControlHeight(token), // use the follwoing 3 lines instead
    controlHeightXS: token.controlHeight - 16, // default is controlHeight * 0.5
    controlHeightSM: token.controlHeight - 8, // default is controlHeight * 0.75
    controlHeightLG: token.controlHeight + 8, // default is controlHeight * 1.25
    ...genCommonMapToken(token),
  };
}

function generatePalette(baseColor: string, backgroundColor: string) {
  const isDark = new TinyColor(backgroundColor).isDark();
  const colors = generate(baseColor, {
    theme: isDark ? "dark" : "default",
    // this line below is the main reason we overwrite this function. we need to blend the base color with the background color
    backgroundColor,
  });

  return {
    1: colors[0], // bg
    2: colors[1], // bg hover
    3: colors[2], // border
    4: colors[3], // border hover
    5: colors[isDark ? 6 : 4], // hover
    6: colors[5], // main color
    7: colors[isDark ? 4 : 6], // pressed
    8: colors[isDark ? 6 : 4], // text hover
    9: colors[5], // text color
    10: colors[isDark ? 4 : 6], // text pressed
  };
}

function getDefaultFontSizes(fontSize: number, newStyle?: boolean) {
  if (newStyle) {
    const headerSize1 = fontSize + 6;
    const headerSize2 = fontSize + 4;
    const headerSize3 = fontSize + 2;
    const headerSize4 = fontSize + 2;
    const headerSize5 = fontSize + 2;

    return {
      fontSizeHeading1: headerSize1,
      fontSizeHeading2: headerSize2,
      fontSizeHeading3: headerSize3,
      fontSizeHeading4: headerSize4,
      fontSizeHeading5: headerSize5,
      lineHeightHeading1: (headerSize1 + 8) / headerSize1,
      lineHeightHeading2: (headerSize2 + 8) / headerSize2,
      lineHeightHeading3: (headerSize3 + 8) / headerSize3,
      lineHeightHeading4: (headerSize4 + 8) / headerSize4,
      lineHeightHeading5: (headerSize5 + 8) / headerSize5,
    };
  }
  const headerSize = fontSize + 4; // all header sizes are the same for now to match legacy style
  const headerLineHeight = (headerSize + 8) / headerSize;

  return {
    fontSizeHeading1: headerSize,
    fontSizeHeading2: headerSize,
    fontSizeHeading3: headerSize,
    fontSizeHeading4: headerSize,
    fontSizeHeading5: headerSize,
    lineHeightHeading1: headerLineHeight,
    lineHeightHeading2: headerLineHeight,
    lineHeightHeading3: headerLineHeight,
    lineHeightHeading4: headerLineHeight,
    lineHeightHeading5: headerLineHeight,
  };
}
