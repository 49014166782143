import { Typography } from "components";
import React from "react";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";

interface Props {
  children?: React.ReactNode;
}

export default function CompletionNotification(props: Props) {
  const styledTitle = useStyledTitle(props);
  const { formatMessage } = useIntl();

  return (
    <Typography.Title level={1} className={styledTitle}>
      <Typography.Text strong>{formatMessage({ id: "containers.taxProfile.wizard.thatsIt" })}</Typography.Text>
      <Typography.Text>{props.children}</Typography.Text>
    </Typography.Title>
  );
}

const useStyledTitle = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    padding: 25px;
    text-align: center;
    background-color: ${theme.colorSuccess};
    & span {
      color: ${theme.colorWhite};
      font-weight: 400;
    }
    & span:last-of-type {
      font-size: ${theme.fontSizeLG}px;
    }
  `(),
);
