import { PayoutMethodType, formatPayoutMethod } from "@trolley/common-frontend";
import { Container, CurrencyDisplay, Divider, Form, Grid, Loader, Notification, Text } from "components";
import { DisabledNotice } from "features";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { RecipientAccount, addPayoutMethod, updatePayoutMethod } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";

type FormFields = {
  emailAddress: string;
  reEmailAddress: string;
};

export default function Paypal({ account }: { account: RecipientAccount | undefined }) {
  const [form] = Form.useForm();
  const config = useIframeConfig();
  const recipient = useRecipient();
  const merchant = useMerchant();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [activate, setActivate] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const merchantPayPalSettings = merchant?.enabledPayoutMethods.find(
    (pm) => pm.integration === PayoutMethodType.PAYPAL,
  );

  const deliveryEstimate =
    account?.deliveryBusinessDaysEstimate || merchantPayPalSettings?.deliveryBusinessDaysEstimate || 0;

  async function onSubmit(values: FormFields) {
    if (!submitting && recipient) {
      setSubmitting(true);
      try {
        const data: any = { ...values, type: PayoutMethodType.PAYPAL };
        if (activate) {
          data.primary = true;
        }
        if (account?.recipientAccountId) {
          await updatePayoutMethod(recipient.id, account.recipientAccountId, data);
          history.push(PATHS.HOME);
        } else {
          data.country = recipient.address.country;
          await addPayoutMethod(recipient.id, data);
          history.push(PATHS.PAYOUT_COMPLETE);
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setActivate(false);
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onSubmit}>
      <Loader spinning={submitting}>
        <Container>
          {config.showFees && (
            <>
              <Text size="small">
                {formatMessage({
                  id: "containers.bankPayoutMethod.processingTimes.title",
                })}
                <b>
                  {deliveryEstimate < 1
                    ? formatMessage({ id: "containers.payoutMethod.processingTimes.sameDay" })
                    : formatMessage(
                        {
                          id: "containers.payoutMethod.processingTimes.bussinessDaysEstimate",
                        },
                        { from: deliveryEstimate, to: deliveryEstimate + 1 },
                      )}
                </b>{" "}
              </Text>
              <Text size="small" data-test="estimatedFees">
                {formatMessage({ id: "containers.payoutMethod.estimatedFees" })}:{" "}
                <Text inline medium>
                  {recipient?.gatewayFees?.paypal ? (
                    <CurrencyDisplay
                      currency={recipient.gatewayFees.paypal.currency}
                      value={recipient.gatewayFees.paypal.value}
                    />
                  ) : (
                    formatMessage(
                      {
                        id: "containers.payoutMethod.payoutFeesMayApply",
                      },
                      {
                        payoutMethod: formatPayoutMethod(PayoutMethodType.PAYPAL, formatMessage),
                      },
                    )
                  )}
                </Text>
              </Text>
            </>
          )}
          <Divider transparent />
          {account?.status === "disabled" && <DisabledNotice account={account} />}

          <Grid justify="space-between">
            <Grid.Item style={{ flex: "10 1 400px" }}>
              <Form.Field
                name="emailAddress"
                label={formatMessage({
                  id: "containers.paypalPayoutMethod.email.title",
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.paypalPayoutMethod.email.required",
                    }),
                  },
                  {
                    type: "email",
                    message: formatMessage({
                      id: "containers.paypalPayoutMethod.email.valid",
                    }),
                  },
                ]}
              >
                <Form.Input name="emailAddress" />
              </Form.Field>

              <Form.Field
                name="reEmailAddress"
                label={formatMessage({
                  id: "containers.paypalPayoutMethod.reEmail.title",
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.paypalPayoutMethod.reEmail.required",
                    }),
                  },
                  {
                    type: "email",
                    message: formatMessage({
                      id: "containers.paypalPayoutMethod.reEmail.valid",
                    }),
                  },
                  ({ getFieldValue }) => {
                    return {
                      async validator(_: any, value: string) {
                        if (value !== getFieldValue("emailAddress")) {
                          throw new Error(
                            formatMessage({
                              id: "containers.paypalPayoutMethod.emailMustBeSame",
                            }),
                          );
                        }
                      },
                    };
                  },
                ]}
              >
                <Form.Input name="reEmailAddress" />
              </Form.Field>
            </Grid.Item>
            <Grid.Item style={{ flex: "1 1 400px", paddingTop: "16px" }}>
              <Notification
                title={formatMessage({
                  id: "containers.paypalPayoutMethod.important",
                })}
                type="warning"
              >
                <ul>
                  <li>
                    {formatMessage({
                      id: "containers.paypalPayoutMethod.ensureEmailIsCorrect",
                    })}
                  </li>
                  <li>
                    {formatMessage({
                      id: "containers.paypalPayoutMethod.paypalWithdrawalFees",
                    })}
                  </li>
                </ul>
              </Notification>
            </Grid.Item>
          </Grid>
          <PayoutFooter
            setBusy={setSubmitting}
            busy={submitting}
            account={account}
            onSave={() => {
              setActivate(false);
            }}
            onSaveActivate={() => {
              setActivate(true);
            }}
          />
        </Container>
      </Loader>
    </Form>
  );
}
