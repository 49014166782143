import { Button, Tooltip } from "components";
import { ButtonProps } from "components/Button";
import * as mimeTypes from "mime-types";
import React, { useState } from "react";
import * as request from "services/request";
import { BaseError, BaseStatus } from "store/reducers/standardReducer";
import { Query } from "utils/helpers";

interface Props extends Omit<ButtonProps, "onClick"> {
  url: string;
  fileName: string;
  query?: Query;
  defaultExtension?: string; // file name extension
  tooltip?: string;
  onDownloadComplete?(): void;
  onDownloadFail?(error?: BaseError): void;
}

/**
 * Download file to client's device
 */
async function downloadFile(url: string, query: Query | undefined, fileName: string, defaultExtension?: string) {
  const { blob } = await request.GET(url, {
    query,
    blob: true,
  });
  if (blob) {
    const ext = mimeTypes.extension(blob.type) || defaultExtension || "";
    const fileFullName = [fileName || Date.now(), ext].join(".");
    const a = document.createElement("a");

    a.setAttribute("style", "display: none");
    document.body.appendChild(a);
    const obUrl = window.URL.createObjectURL(blob);
    a.href = obUrl;
    a.setAttribute("download", fileFullName);
    a.click();
    window.URL.revokeObjectURL(obUrl);
  }
}

export default function FileDownload({
  url,
  icon = "cloud-download-alt",
  query,
  tooltip,
  fileName,
  disabled,
  defaultExtension,
  onDownloadComplete,
  onDownloadFail,
  className,
  children,
  ...buttonProps
}: Props) {
  const [status, setStatus] = useState<BaseStatus | undefined>();
  const [apiError, setApiError] = useState("");
  const errorMessage = apiError || tooltip;

  async function onDownload(e: React.MouseEvent<HTMLAnchorElement> | any) {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (!disabled && status !== BaseStatus.LOADING) {
      setApiError("");
      setStatus(BaseStatus.LOADING);
      try {
        await downloadFile(url, query, fileName, defaultExtension);
        setStatus(BaseStatus.LOADED);
        onDownloadComplete?.();
      } catch (errors) {
        setStatus(BaseStatus.ERROR);
        const error: BaseError | undefined = Array.isArray(errors) && errors.find((err: BaseError) => err.message);

        if (error?.message) {
          setApiError(error.message);
        }
        onDownloadFail?.(error);
      }
    }
  }

  function renderStatus(): Partial<ButtonProps> {
    switch (status) {
      case BaseStatus.LOADED:
        return {
          icon: "check-circle",
          iconProps: {
            color: "success",
          },
        };
      case BaseStatus.ERROR:
        return {
          icon: "exclamation-circle",
          iconProps: {
            color: "error",
          },
        };
      default:
        return {
          icon,
        };
    }
  }

  function renderElement() {
    return (
      <Button
        {...buttonProps}
        loading={status === BaseStatus.LOADING}
        {...renderStatus()}
        className={className}
        onClick={onDownload}
      >
        {children}
      </Button>
    );
  }

  return errorMessage ? <Tooltip title={errorMessage}>{renderElement()}</Tooltip> : renderElement();
}
