import { createBrowserHistory, createMemoryHistory, History, MemoryHistoryBuildOptions } from "history";
import { PATHS } from "pages/App/routes";
import { client } from "utils/context";

function getBuildOptions(): MemoryHistoryBuildOptions {
  const options: MemoryHistoryBuildOptions = {};

  if (globalThis.location.pathname) {
    const url = window.location.pathname;
    // // for now only allow to land directly on /showroom  or /info page.
    // // prevent from landing on tax page
    if (url.includes("/showroom")) {
      options.initialEntries = [url];
    } else if (url.startsWith(PATHS.INFO)) {
      options.initialEntries = [PATHS.INFO];
    }
  }

  return options;
}

export const history: History =
  client === "portal"
    ? createBrowserHistory() // on portal it's usually rendered in a browser, the pages are protected by the auth login form
    : createMemoryHistory(getBuildOptions()); // on widget we need to restrict which initial page the recipient can land on. we use memHistory because it's typically rendered within an iframe, so the user doesn't

history.listen((location, action) => {
  try {
    // this only works on the widget. In "portal", the layout height is confined to the height of the windows. only layout content is scrollable
    globalThis.scrollTo?.(0, 0);
  } catch {}
});
