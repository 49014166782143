import { connectRouter } from "connected-react-router";
import { History } from "history";
import { AnyAction, CombinedState, combineReducers } from "redux";

import { Store } from "store";
import { auth } from "./auth";
import { config } from "./config";
import { merchant } from "./merchant";
import notifications from "./notifications";
import { params } from "./params";
import * as ACTIONS from "../actions";
import standardReducer from "./standardReducer";
import { SessionMode } from "store/actions/auth";
import { pick } from "utils/helpers";

export default (history: History) => (stateIn: CombinedState<Store>, action: AnyAction) => {
  let state = stateIn;

  if (action?.type === ACTIONS.RECEIVE_AUTH && [SessionMode.ENDED, SessionMode.EXPIRED].includes(action?.data.state)) {
    state = pick(stateIn, ["router", "auth", "notifications", "merchant", "config", "currencies"]);
    if (stateIn.params) {
      state.params = pick(stateIn.params, ["locale", "hideEmail", "roEmail", "colors", "style"]);
    }
  }

  return combineReducers<Store>({
    router: connectRouter(history),
    auth,
    notifications,

    merchant,
    config,
    params,
    recipient: standardReducer("recipient"),
    recipientConfig: standardReducer("recipientConfig"),
    recipientAccountConfig: standardReducer("recipientAccountConfig"),
    currencies: standardReducer("currencies"),
    bankCodes: standardReducer("bankCodes"),
    accountVerification: standardReducer("accountVerification"),
    bankInfo: standardReducer("bankInfo"),
    payments: standardReducer("payments"),
    offlinePayments: standardReducer("offlinePayments"),
    taxForms: standardReducer("taxForms"),
    taxForm: standardReducer("taxForm"),
    taxProfiles: standardReducer("taxProfiles"),
    taxProfile: standardReducer("taxProfile"),
    dac7EoyTaxForms: standardReducer("dac7EoyTaxForms"),
    eoyTaxForms: standardReducer("eoyTaxForms"),
    withholdingTable: standardReducer("withholdingTable"),
    tickets: standardReducer("tickets"),
    idvSession: standardReducer("idvSession"),
    verifications: standardReducer("verifications"),
    fees: standardReducer("fees"),
  })(state, action);
};
