import {
  formatCountry,
  formatCurrency,
  formatPayoutMethod,
  getGovIdProps,
  IncompleteReason,
  IncompleteReasonType,
  PayoutMethodType,
  RecipientType,
  TaxStatus,
} from "@trolley/common-frontend";
import React from "react";

import { Container, Icon, LinkButton, Notification, Text } from "components";
import { PATHS } from "pages/App/routes";
import { Recipient } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipientConfig } from "store/hooks/recipientConfig";
import { useTaxForms } from "store/hooks/taxForms";
import { useIntl } from "utils/context";

interface Props {
  recipient: Recipient;
  showLink?: boolean;
}

export default function InactiveReasons({ recipient, showLink }: Props) {
  const merchant = useMerchant();
  const config = useIframeConfig();
  const { data: recipientConfig } = useRecipientConfig();
  const { formatMessage } = useIntl();
  const { data: taxForms } = useTaxForms();
  const {
    inactiveReasons = { street1: IncompleteReasonType.MISSING } as Partial<IncompleteReason>,
    address: recipientAddress,
  } = recipient;

  if (!recipient.accounts.length) {
    // only show inactive reasons when they have entered a new payout method.
    return null;
  }

  const reasons: [string, string | React.ReactNode][] = [];
  const primaryAccount = recipient.accounts.find((a) => a.primary);
  const incompleteAddress = [
    inactiveReasons.street1,
    inactiveReasons.city,
    inactiveReasons.regionCode,
    inactiveReasons.postalCode,
    inactiveReasons.countryCode,
  ].some((reason) => reason && [IncompleteReasonType.MISSING, IncompleteReasonType.INVALID].includes(reason));

  if (inactiveReasons.countryCode === IncompleteReasonType.NOT_SUPPORTED) {
    /**
     * countryCode = "not-supported" is when recipient country:
     * - has a bank transfer and either:
     *    - recipient country is in a banned banking country
     *    - or bank account country is in a banned banking country
     * - or, paypal non-supported country
     * - or, venmo non-supported country
     * */
    reasons.push([
      IncompleteReasonType.NOT_SUPPORTED,
      <span>
        {formatMessage(
          {
            id: "containers.accountSummary.incompleteReasons.countryNotSupported",
          },
          {
            payoutMethod: formatPayoutMethod(recipient.payoutMethod || PayoutMethodType.BANKTRANSFER, formatMessage),
            country: formatCountry(primaryAccount?.country || recipientAddress.country, formatMessage),
          },
        )}
      </span>,
    ]);
  }

  if (inactiveReasons.accountCurrencyCode && primaryAccount?.currency) {
    // accountCurrenCode = MISSING should never happen. if it does... there's a bug in API
    reasons.push([
      "accountCurrencyCode",
      <span>
        {formatMessage(
          {
            id: "containers.accountSummary.incompleteReasons.currencyNotSupported",
          },
          {
            currencyCode: formatCurrency(primaryAccount.currency, formatMessage),
            country: formatCountry(primaryAccount.country || recipientAddress.country, formatMessage),
          },
        )}
      </span>,
    ]);
  }

  /**
   * Explanation: InactiveReason enabledCountry: CountryCode is when the RecipientAccount is not payable due
   * to a disabled payout method country (PayoutMethod['enabledCountries'])
   */
  if (inactiveReasons.enabledCountry) {
    reasons.push([
      `enabledCountry-${inactiveReasons.enabledCountry}`,
      <span>
        {formatMessage(
          {
            id: "containers.accountSummary.incompleteReasons.countryNotSupported",
          },
          {
            payoutMethod: formatPayoutMethod(
              primaryAccount?.type || // this is mainly the reason, the rest is for fallback in case the system is bugged.
                recipient?.payoutMethod ||
                PayoutMethodType.BANKTRANSFER,
              formatMessage,
            ),
            country: formatCountry(inactiveReasons.enabledCountry, formatMessage),
          },
        )}
      </span>,
    ]);
  }

  if (incompleteAddress) {
    reasons.push([
      "incompleteAddress",
      <>
        {formatMessage({
          id: "containers.accountSummary.incompleteReasons.incompleteAddress",
        })}{" "}
        {showLink && (
          <LinkButton
            type="link"
            to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
            icon="pencil"
            aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage({
              id: "containers.accountSummary.incompleteReasons.incompleteAddress",
            })}`}
          />
        )}
      </>,
    ]);
  }

  if (inactiveReasons.primaryAccount) {
    if (inactiveReasons.primaryAccount === IncompleteReasonType.DISABLED) {
      reasons.push([
        "primaryAccount",
        <>
          {formatMessage({
            id: "containers.accountSummary.incompleteReasons.primaryAccountDisabled",
          })}
          {showLink && (
            <LinkButton
              type="link"
              icon="pencil"
              aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage({
                id: "containers.accountSummary.incompleteReasons.primaryAccountDisabled",
              })}`}
              to={{
                pathname: PATHS.PAYOUT,
                state: {
                  account: primaryAccount,
                },
              }}
            />
          )}
        </>,
      ]);
    } else if (inactiveReasons.primaryAccount === IncompleteReasonType.MISSING) {
      // there is an account but there's no active one
      reasons.push([
        "noActiveAccount",
        formatMessage({
          id: "containers.accountSummary.incompleteReasons.primaryAccount",
        }),
      ]);
    } else {
      // invalid
      reasons.push([
        "primaryAccount",
        formatMessage({
          id: "containers.accountSummary.incompleteReasons.invalidPrimaryAccount",
        }),
      ]);
    }
  }

  if (inactiveReasons.type) {
    reasons.push([
      "type",
      <>
        {formatMessage(
          { id: "containers.accountSummary.incompleteReasons.type" },
          {
            currencyCode: primaryAccount?.currency || "",
            country: formatCountry(primaryAccount?.country, formatMessage),
          },
        )}
        {showLink && (
          <LinkButton
            type="link"
            icon="pencil"
            aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage(
              { id: "containers.accountSummary.incompleteReasons.type" },
              {
                currencyCode: primaryAccount?.currency || "",
                country: formatCountry(primaryAccount?.country, formatMessage),
              },
            )}`}
            to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
          />
        )}
      </>,
    ]);
  }

  if (inactiveReasons.firstName || inactiveReasons.lastName) {
    reasons.push([
      "name",
      <>
        {formatMessage({
          id: "containers.accountSummary.incompleteReasons.name",
        })}
        {showLink && (
          <LinkButton
            type="link"
            icon="pencil"
            aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage({
              id: "containers.accountSummary.incompleteReasons.name",
            })}`}
            to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
          />
        )}
      </>,
    ]);
  }

  if (inactiveReasons.phone) {
    reasons.push([
      "phone",
      <>
        {formatMessage({
          id: "containers.accountSummary.incompleteReasons.phone",
        })}
        {showLink && (
          <LinkButton
            type="link"
            icon="pencil"
            aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage({
              id: "containers.accountSummary.incompleteReasons.phone",
            })}`}
            to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
          />
        )}
      </>,
    ]);
  }

  if (inactiveReasons.governmentId) {
    if (inactiveReasons.governmentId === IncompleteReasonType.COUNTRY_MISMATCH) {
      reasons.push([
        "countryMismatch",
        formatMessage(
          { id: "containers.accountSummary.incompleteReasons.countryMismatch" },
          {
            country: formatCountry(recipientAddress.country, formatMessage),
            bankCountry: formatCountry(primaryAccount?.country, formatMessage),
          },
        ),
      ]);
    } else {
      const country = primaryAccount?.country || recipientAddress.country;
      const defaultGovIdProps = getGovIdProps(country, recipient.type as RecipientType);

      const countryDocumentTypes =
        recipient.type === RecipientType.INDIVIDUAL
          ? recipientConfig?.documentTypes?.individual
          : recipientConfig?.documentTypes?.business;

      const newGovIdProps = { label: countryDocumentTypes?.map((doc) => doc.value).join(" / "), tooltip: undefined };

      const govIdProps = newGovIdProps.label !== undefined ? newGovIdProps : defaultGovIdProps;

      reasons.push([
        "governmentId",
        <>
          {formatMessage(
            { id: "containers.accountSummary.incompleteReasons.governmentId" },
            { country: formatCountry(country, formatMessage) },
          )}
          {govIdProps?.label && (
            <Text inline>
              {" "}
              {govIdProps.label}
              {govIdProps?.tooltip && <Icon type="question-circle" tooltip={govIdProps.tooltip} right />}
            </Text>
          )}
          {showLink && (
            <LinkButton
              type="link"
              icon="pencil"
              aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage(
                { id: "containers.accountSummary.incompleteReasons.governmentId" },
                { country: formatCountry(country, formatMessage) },
              )}`}
              to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
            />
          )}
        </>,
      ]);
    }
  }

  if (
    inactiveReasons.taxForm === IncompleteReasonType.MISSING &&
    // we only the reason if there is an incomplete form. We already indicate they need a tax form on the sidebar/header
    taxForms.records.some((form) => form.status === TaxStatus.INCOMPLETE)
  ) {
    reasons.push([
      "taxForm",
      <>
        {formatMessage({
          id: "containers.accountSummary.incompleteReasons.taxForm",
        })}
        {showLink && merchant?.features?.tax && merchant?.tax?.enabled && config.usTax && (
          <LinkButton
            type="link"
            icon="pencil"
            aria-label={`${formatMessage({ id: "common.edit" })} - ${formatMessage({
              id: "containers.accountSummary.incompleteReasons.taxForm",
            })}`}
            to={PATHS.US_TAX}
          />
        )}
      </>,
    ]);
  }

  return reasons.length > 0 ? (
    <Container>
      <Notification type="warning" title={formatMessage({ id: "containers.accountSummary.incomplete" })}>
        <ul style={{ marginBottom: "0px" }}>
          {reasons.map(([key, reason]) => (
            <li key={key}>{reason}</li>
          ))}
        </ul>
      </Notification>
    </Container>
  ) : null;
}

// mock data
// const inactiveReasons: Partial<IncompleteReason> = {
//   firstName: IncompleteReasonType.MISSING,
//   lastName: IncompleteReasonType.MISSING,
//   accountCurrencyCode: IncompleteReasonType.MISSING, // | IncompleteReasonType.INVALID
//   countryCode: IncompleteReasonType.MISSING, // | IncompleteReasonType.NOT_SUPPORTED
//   city: IncompleteReasonType.MISSING,
//   street1: IncompleteReasonType.MISSING,
//   regionCode: IncompleteReasonType.MISSING,
//   dob: IncompleteReasonType.MISSING,
//   type: IncompleteReasonType.INVALID,
//   phone: IncompleteReasonType.MISSING, // | IncompleteReasonType.INVALID
//   governmentId: IncompleteReasonType.MISSING, // | IncompleteReasonType.INVALID | IncompleteReasonType.COUNTRY_MISMATCH
//   emailAddress: IncompleteReasonType.MISSING,
//   phoneNumber: IncompleteReasonType.MISSING,
//   postalCode: IncompleteReasonType.MISSING, // | IncompleteReasonType.INVALID
//   primaryAccount: IncompleteReasonType.INVALID, // | IncompleteReasonType.DISABLED | IncompleteReasonType.INVALID
//   enabledCountry: CountryCode.FI,
//   taxForm: IncompleteReasonType.MISSING, // | IncompleteReasonType.TAXFORM_REQUIRES_REVIEW | IncompleteReasonType.TAXFORM_VOID | IncompleteReasonType.TAXFORM_INCOMPLETE | IncompleteReasonType.TAXFORM_EXPIRED
//   taxProfile: IncompleteReasonType.TAX_PROFILE_REQUIRED, // | IncompleteReasonType.TAX_PROFILE_THRESHOLD_REACHED | IncompleteReasonType.MISSING | IncompleteReasonType.TAXFORM_REQUIRES_REVIEW | IncompleteReasonType.TAXFORM_VOID | IncompleteReasonType.TAXFORM_INCOMPLETE | IncompleteReasonType.TAXFORM_EXPIRED
//   trust: IncompleteReasonType.IDV_MISSING, // | IncompleteReasonType.IDV_REVIEW
// };
