import React, { useState } from "react";

import { BackButton, Button, Footer, Form2 as Form } from "components";
import { RecipientAccount } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { AccountVerification } from "store/actions/bankInfo";
import { AccVerifResultEnum } from "./BankTransfer/AccountVerificationResultAlert";
import AccountVerificationSubmitModal from "./BankTransfer/AccountVerificationSubmitModal";

interface Props {
  account: RecipientAccount | undefined;
  busy: boolean;
  disableOtherSubmit?: boolean;
  accountVerificationResult?: AccountVerification;
  submitCall?: () => void;
}

export default function PayoutFooter({
  account,
  busy,
  disableOtherSubmit,
  accountVerificationResult,
  submitCall,
}: Props) {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const submitLabel = formatMessage({
    id: account && account.id ? "common.save" : "common.add",
  });
  const showPrimaryCheckbox = !account?.primary && recipient?.accounts.some((acc) => acc.primary);

  function onClickSave() {
    setOpenModal(true);
  }

  function onCancelModal() {
    setOpenModal(false);
  }

  return (
    <>
      {showPrimaryCheckbox && (
        <Form.Item name="primary" valuePropName="checked">
          <Form.Checkbox>
            {formatMessage({
              id: "containers.accountSummary.buttons.makeActive",
            })}
          </Form.Checkbox>
        </Form.Item>
      )}
      <Footer
        main={
          <Button
            size="large"
            htmlType={
              !accountVerificationResult || accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                ? "submit"
                : "button"
            }
            onClick={accountVerificationResult?.reasonCode !== AccVerifResultEnum.AV100 ? onClickSave : undefined}
            type="primary"
            loading={busy}
          >
            {submitLabel}
          </Button>
        }
        secondary={
          <Button
            size="large"
            htmlType={
              !accountVerificationResult || accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                ? "submit"
                : "button"
            }
            onClick={accountVerificationResult?.reasonCode !== AccVerifResultEnum.AV100 ? onClickSave : undefined}
            type="primary"
            disabled={disableOtherSubmit}
          >
            {submitLabel}
          </Button>
        }
        extra={<BackButton />}
      />
      <AccountVerificationSubmitModal
        result={accountVerificationResult}
        open={openModal}
        onClose={onCancelModal}
        submitCall={submitCall}
      />
    </>
  );
}
