import {
  addressMappedCountries,
  allMappedCountries,
  bankingMappedCountries,
  CountryCode,
  formatCountry,
  MappedCountry,
  onboardingMappedCountries,
} from "@trolley/common-frontend";
import React from "react";

import Flag from "components/Flag";
import { useTheme } from "style/classname";
import { useIntl } from "utils/context";
import { useDeepCompareMemo } from "utils/hooks";
import Select, { SelectProps } from "./Select";
import FormItem from "components/Form2/Item";

const TOP_COUNTRIES = [CountryCode.AU, CountryCode.CA, CountryCode.GB, CountryCode.US];

export function isCountryCode(countryCode: string | null | undefined): countryCode is CountryCode {
  return !!(countryCode && CountryCode[countryCode]);
}

const sortCountries = (areas: MappedCountry) => {
  const keys = Object.keys(areas);
  if (!keys.length) {
    return [];
  }
  try {
    const sortedArray = keys.map((key) => ({
      label: areas[key].name,
      value: key as CountryCode,
    }));

    sortedArray.sort((a, b) => {
      if (TOP_COUNTRIES.includes(a.value) && TOP_COUNTRIES.includes(b.value)) {
        return TOP_COUNTRIES.indexOf(a.value) < TOP_COUNTRIES.indexOf(b.value) ? -1 : 1;
      }

      if (TOP_COUNTRIES.includes(a.value)) {
        return -1;
      }
      if (TOP_COUNTRIES.includes(b.value)) {
        return 1;
      }

      const nameA = a.label.toLocaleUpperCase();
      const nameB = b.label.toLocaleUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  } catch {
    return [];
  }
};

export const allCountriesOptions = sortCountries(allMappedCountries);
export const onboardingCountriesOptions = sortCountries(onboardingMappedCountries);
export const addressCountriesOptions = sortCountries(addressMappedCountries);
export const bankingCountriesOptions = sortCountries(bankingMappedCountries);

function getCountrySet({ type, includes, excludes, sort }: ExtraProps) {
  let countrySet: CountryOption[];

  switch (type) {
    case "all":
      countrySet = allCountriesOptions;
      break;
    case "address":
      countrySet = addressCountriesOptions;
      break;
    case "onboarding":
      countrySet = onboardingCountriesOptions;
      break;
    default:
      countrySet = bankingCountriesOptions;
      break;
  }

  if (includes || excludes) {
    countrySet = countrySet.filter(
      (option) =>
        (!includes?.length || includes.includes(option.value)) &&
        (!excludes?.length || !excludes.includes(option.value)),
    );
  }

  if (sort) {
    countrySet.sort(sort);
  }

  return countrySet;
}

type CountryOption = { label: any; value: CountryCode };

interface ExtraProps {
  type?: "all" | "address" | "onboarding" | "banking"; // defaults to accepted countries
  includes?: CountryCode[];
  excludes?: CountryCode[];
  sort?(a: CountryOption, b: CountryOption): number;
}

interface Props extends SelectProps<CountryCode[] | CountryCode>, ExtraProps {}

export default function SelectCountry(props: Props) {
  const { formatMessage } = useIntl();
  const token = useTheme();
  const { label } = FormItem.useFormItemInstance();

  const {
    type = "banking",
    includes,
    excludes,
    sort,
    showSearch = true,
    placeholder = formatMessage({ id: "components.formCountry.select" }),
    value,
    ...rest
  } = props;
  const includedTopCountries = TOP_COUNTRIES.filter(
    (c) => (!includes || includes.includes(c)) && !excludes?.includes(c),
  );

  const options = useDeepCompareMemo(() => {
    return getCountrySet({ type, includes, excludes, sort }).map((country, i) => ({
      value: country.value,
      style: i === includedTopCountries.length - 1 ? { borderBottom: `2px solid ${token.colorSplit}` } : undefined,
      "data-search": `${country.value} ${formatCountry(country.value, formatMessage)}`,
      "aria-label": formatCountry(country.value, formatMessage),
      label: (
        <>
          <Flag code={country.value} left />
          {formatCountry(country.value, formatMessage)}
        </>
      ),
    }));
  }, [type, includes || [], excludes || []]);

  return (
    <Select
      showSearch={showSearch}
      placeholder={placeholder}
      value={value || undefined}
      {...rest}
      optionFilterProp="data-search"
      options={options}
      aria-label={label}
    />
  );
}
