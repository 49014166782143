import { TaxDeliveryType } from "@trolley/common-frontend";
import { Button, Container, Divider, Footer, GreyBox, Modal, Paragraph, Text, TitleBar } from "components";
import { CSSProperties, default as React, useState } from "react";
import Helmet from "react-helmet";
import { notifyError } from "store/actions/notifications";
import { updateRecipient } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import css from "style/classname";
import { useClientIntegration, useIntl } from "utils/context";

const iconStyle: CSSProperties = {
  marginLeft: "-24px",
  marginTop: "2px",
};

type Props = {
  submitText?: string;
  popup?: boolean;
  onComplete(): void;
  onExit(): void;
};

type AllProps = Props;

export default function TaxFormDelivery(props: AllProps) {
  const { submitText, popup, onComplete, onExit } = props;
  const client = useClientIntegration();
  const config = useIframeConfig();
  const merchant = useMerchant();
  const recipient = useRecipient();
  const intl = useIntl();
  const [selected, setSelected] = useState(popup ? recipient?.taxDeliveryType : TaxDeliveryType.E_DELIVERY); // defaults to E-delivery on full page load (usuallly their first time)
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);

  async function onOk() {
    if (recipient) {
      if (selected === TaxDeliveryType.MAIL && !show) {
        setShow(true);
      } else {
        setSubmitting(true);
        try {
          await updateRecipient(recipient.id, { taxDeliveryType: selected });
          setShow(false);
          onComplete();
        } catch (errors) {
          notifyError(
            intl.formatMessage({
              id: "containers.tax.taxFormDelivery.failedToUpdate",
            }),
            { errors },
          );
        }
        setSubmitting(false);
      }
    }
  }

  if (!merchant) {
    return null;
  }

  const merchantName = config.businessName || merchant.name;
  const merchantSupportEmail = config.supportEmail;
  const merchantSitePortal =
    client === "widget"
      ? config.businessURL || merchant.website
      : config.portalURL || config.businessURL || merchant.website;

  const content = (
    <>
      <Text align="center" strong>
        {intl.formatMessage({
          id: "containers.tax.taxFormDelivery.receiveStatement",
        })}
      </Text>
      <div className={styledWell}>
        <Button
          type={selected === "e-delivery" ? "success" : "default"}
          aria-pressed={selected === "e-delivery"}
          size="large"
          block
          onClick={() => {
            setSelected(TaxDeliveryType.E_DELIVERY);
          }}
          icon={selected === TaxDeliveryType.E_DELIVERY ? "check-circle" : undefined}
          iconProps={{ style: iconStyle }}
        >
          {intl.formatMessage({
            id: "containers.tax.taxFormDelivery.eDelivery",
          })}
        </Button>
        <Divider>{intl.formatMessage({ id: "containers.tax.taxFormDelivery.or" })}</Divider>
        <Button
          type={selected === "mail" ? "success" : "default"}
          aria-pressed={selected === "mail"}
          size="large"
          block
          onClick={() => {
            setSelected(TaxDeliveryType.MAIL);
          }}
          icon={selected === TaxDeliveryType.MAIL ? "check-circle" : undefined}
          iconProps={{ style: iconStyle }}
        >
          {intl.formatMessage({
            id: "containers.tax.taxFormDelivery.postalMail",
          })}
        </Button>
      </div>
      <Paragraph>{intl.formatMessage({ id: "containers.tax.taxFormDelivery.consent" }, { merchantName })}</Paragraph>
      <Paragraph>
        {intl.formatMessage(
          { id: "containers.tax.taxFormDelivery.requestCopy" },
          { merchantName, merchantSitePortal, merchantSupportEmail },
        )}
      </Paragraph>
      <Modal
        open={show}
        onCancel={() => {
          setShow(false);
        }}
        confirmLoading={submitting}
        onOk={onOk}
        okText={intl.formatMessage({
          id: "containers.tax.taxFormDelivery.selectPostal",
        })}
        title={intl.formatMessage({
          id: "containers.tax.taxFormDelivery.selectingPostalMail",
        })}
      >
        <Paragraph>
          {intl.formatMessage({
            id: "containers.tax.taxFormDelivery.confirmSelectPostal",
          })}
        </Paragraph>
        <GreyBox margin="small">
          <Paragraph>
            {intl.formatMessage({
              id: "containers.tax.taxFormDelivery.benefits",
            })}
          </Paragraph>
          <ul>
            <li>
              {intl.formatMessage({
                id: "containers.tax.taxFormDelivery.receiveFast",
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "containers.tax.taxFormDelivery.avoidNotReceiving",
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "containers.tax.taxFormDelivery.helpEnvironment",
              })}
            </li>
          </ul>
          <Divider transparent />
          <Paragraph>
            {intl.formatMessage({
              id: "containers.tax.taxFormDelivery.offer",
            })}
          </Paragraph>
        </GreyBox>
      </Modal>
    </>
  );

  if (popup) {
    return (
      <>
        <Modal
          onCancel={onExit}
          open
          confirmLoading={submitting}
          title={intl.formatMessage({
            id: "containers.tax.taxFormDelivery.title",
          })}
          okText={intl.formatMessage({ id: "common.save" })}
          cancelText={intl.formatMessage({ id: "common.close" })}
          onOk={onOk}
        >
          {content}
        </Modal>
      </>
    );
  }

  return (
    <>
      <Container>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "containers.tax.taxFormDelivery.title",
            })}
          </title>
        </Helmet>
        <TitleBar>
          {intl.formatMessage({
            id: "containers.tax.taxFormDelivery.title",
          })}
        </TitleBar>
        {content}
        <Footer
          main={
            <Button disabled={!selected} onClick={onOk} type="primary" size="large" loading={submitting}>
              {submitText || intl.formatMessage({ id: "common.continue" })}
            </Button>
          }
          extra={
            <Button size="large" onClick={onExit}>
              {intl.formatMessage({ id: "common.exit" })}
            </Button>
          }
        />
      </Container>
    </>
  );
}

const styledWell = css`
  max-width: 400px;
  margin: 20px auto 38px;
`();
