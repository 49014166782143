import assignDeep from "assign-deep";
import { Draft, castDraft, produce, setAutoFreeze } from "immer";
import { Action } from "redux";
import { BaseError, RecursivePartial } from "@trolley/common-frontend";
import { Store } from "store";

setAutoFreeze(true); // important to autofreeze even in dev so we can catch errors

export { BaseError };
export enum BaseStatus {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

export type MappedStatus = Mapped<BaseStatus | undefined>;
export type Mapped<T> = Record<string, T | undefined>;

export type ListState<T> = {
  readonly records: T[];
  readonly meta: {
    readonly records: number;
    readonly page: number;
    readonly pages: number;
  };
};

export const emptyList = {
  records: [],
  meta: {
    records: 0,
    page: 1,
    pages: 1,
  },
};

export type StandardState<T> = {
  readonly entities: Mapped<T>;
  readonly fetchStatus: MappedStatus;
  readonly errors: Mapped<BaseError[] | undefined>;
};

export enum OpCode {
  LOADING = "LOADING", // Mark the fetchStatus as 'loading'
  DATA = "DATA", // Save the object in the data table and set fetchStatus = 'loaded'
  UPDATE = "UPDATE", // Update the object in the data table and set fetchStatus = 'loaded'
  DELETE = "DELETE", // Delete the entities[id], errors[id], and fetchStatus[id]
  RESET = "RESET", // Resets entities = {}, errors = {}, fetchStatus = {}
  ERROR = "ERROR", // Mark fetch status as 'error' and save BaseError[] in errors[id]
}

interface ActionBase {
  id?: string | number;
}

export interface LoadingAction extends ActionBase {
  loading?: boolean;
  ids?: string[];
}

type BulkData<T = any> = Record<string, T>;
export interface DataAction<T> extends ActionBase {
  data?: T; // entities[id] === action.data
  /**
   */
  bulk?: BulkData<T>; // entities[Object.keys(data)] = Object.values(data)
  /**
   *  only update the entity, ignore status and error updates. Mainly used for updates received by websockets
   */
  entitiesOnly?: true;
}

export interface UpdateAction<T> extends ActionBase {
  update?: RecursivePartial<T>;
  bulk?: BulkData<T>;
  /**
   *  only update the entity, ignore status and error updates. Mainly used for updates received by websockets
   */
  entitiesOnly?: true; // only update the entity, ignore status and error updates
}

export interface DeleteAction extends ActionBase {
  ids?: string[];
}

export interface ErrorAction extends ActionBase {
  ids?: string[];
  errors: BaseError[];
}

export type StandardAction<T = any> = LoadingAction | UpdateAction<T> | DataAction<T> | DeleteAction | ErrorAction;
export type DispatchAction<T = any> = StandardAction<T> & Action;

function isLoadingAction(opcode: keyof typeof OpCode, action: StandardAction): action is LoadingAction {
  return opcode === "LOADING";
}

function isDataAction<T>(opcode: keyof typeof OpCode, action: StandardAction<T>): action is DataAction<T> {
  return opcode === "DATA";
}

function isUpdateAction<T>(opcode: keyof typeof OpCode, action: StandardAction<T>): action is UpdateAction<T> {
  return opcode === "UPDATE";
}

function isDeleteAction(opcode: keyof typeof OpCode, action: StandardAction): action is DeleteAction {
  return opcode === "DELETE";
}

function isErrorAction(opcode: keyof typeof OpCode, action: StandardAction): action is ErrorAction {
  return opcode === "ERROR";
}

type StandardReducerFunction<T> = (state: StandardState<T>, action?: StandardAction<T> & Action) => StandardState<T>;

function updateGlobalLoading<T>(state: StandardState<T>) {
  const loading = !!Object.entries(state.fetchStatus).find(([k, v]) => k !== "LOADING" && v === "loading");

  if (loading !== (state.fetchStatus.LOADING === "loading")) {
    return produce(state, (draft) => {
      draft.fetchStatus.LOADING = loading ? BaseStatus.LOADING : BaseStatus.LOADED;
    });
  }

  return state;
}

/*
 * Standard reducer definition
 *
 * It assumes that all actions are of type NAME/OPCODE
 * where name is the name that you registered when you added this to the reducer pipeline
 *
 * e.g.
 *     ...
 *     payments: standardReducer('payments')
 *     ...
 */

const initialState = {
  entities: {},
  fetchStatus: {},
  errors: {},
};

export default function standardReducer<T extends object | null | string>(
  name: keyof Store,
): StandardReducerFunction<T> {
  return (state: StandardState<T> = initialState, action: DispatchAction): StandardState<T> => {
    if (!action.type.startsWith(`${name}/`)) {
      return state;
    }
    const parts: string[] = action.type.split("/");

    if (parts.length !== 2) {
      return state;
    }
    const opcode = <OpCode>parts[parts.length - 1];
    const id = action.id || "data";

    return updateGlobalLoading(produceState<T>(state, id, opcode, action));
  };
}

function pickNewer<T extends unknown>(newData: T, oldData: Draft<T> | undefined): Draft<T> {
  if (
    typeof newData === "object" &&
    newData !== null &&
    "updatedAt" in newData &&
    typeof oldData === "object" &&
    oldData !== null &&
    "updatedAt" in oldData
  ) {
    return String(newData.updatedAt) >= String(oldData.updatedAt) ? castDraft(newData) : oldData;
  }

  return castDraft(newData);
}

export function produceState<T>(state: StandardState<T>, id: string | number, opcode: OpCode, action: DispatchAction) {
  return produce(state, (draft) => {
    if (isLoadingAction(opcode, action)) {
      // LOADING ACTION
      if (Array.isArray(action.ids)) {
        action.ids.forEach((_id) => {
          if (typeof _id === "string") {
            draft.fetchStatus[_id] = action.loading !== false ? BaseStatus.LOADING : BaseStatus.LOADED;
            delete draft.errors[_id];
          }
        });
      } else {
        draft.fetchStatus[id] = action.loading !== false ? BaseStatus.LOADING : BaseStatus.LOADED;
        delete draft.errors[id];
      }
    } else if (isErrorAction(opcode, action)) {
      // ERROR ACTION
      if (Array.isArray(action.ids)) {
        action.ids.forEach((_id) => {
          draft.fetchStatus[_id] = BaseStatus.ERROR;
          draft.errors[_id] = action.errors;
        });
      } else {
        draft.fetchStatus[id] = BaseStatus.ERROR;
        if (action.errors && Array.isArray(action.errors)) {
          draft.errors[id] = action.errors;
        }
      }
    } else if (isDataAction(opcode, action)) {
      // DATA ACTION
      const bulk = action.bulk;
      const data = action.data;
      if (typeof bulk === "object" && bulk !== null) {
        Object.keys(bulk).forEach((key) => {
          draft.entities[key] = pickNewer(bulk[key], draft.entities[key]);
          if (!action.entitiesOnly) {
            draft.fetchStatus[key] = BaseStatus.LOADED;
            delete draft.errors[key];
          }
        });
      } else if (data !== undefined) {
        draft.entities[id] = pickNewer(data, draft.entities[id]);
      }
      if (!action.entitiesOnly) {
        draft.fetchStatus[id] = BaseStatus.LOADED;
        delete draft.errors[id];

        if (id !== "data") {
          // also update the data status on data;
          draft.fetchStatus.data = BaseStatus.LOADED;
          delete draft.errors.data;
        }
      }
    } else if (isUpdateAction(opcode, action)) {
      // UPDATE ACTION
      const bulk = action.bulk;
      const update = action.update;
      if (bulk) {
        assignDeep(draft.entities, bulk);
        if (!action.entitiesOnly) {
          Object.keys(bulk).forEach((key) => {
            draft.fetchStatus[key] = BaseStatus.LOADED;
            delete draft.errors[key];
          });
        }
      } else if (String(update) === "[object Object]" && draft.entities[id]) {
        assignDeep(draft.entities[id], update);
      } else {
        draft.entities[id] = castDraft(update as any);
      }

      if (!action.entitiesOnly) {
        draft.fetchStatus[id] = BaseStatus.LOADED;
        delete draft.errors[id];

        if (id !== "data") {
          // also update the data status on data;
          draft.fetchStatus.data = BaseStatus.LOADED;
          delete draft.errors.data;
        }
      }
    } else if (isDeleteAction(opcode, action)) {
      // DELETE ACTION
      if (Array.isArray(action.ids)) {
        action.ids.forEach((_id) => {
          if (typeof _id === "string") {
            delete draft.entities[_id];
            delete draft.fetchStatus[_id];
            delete draft.errors[_id];
          }
        });
      } else {
        delete draft.entities[id];
        delete draft.fetchStatus[id];
        delete draft.errors[id];
      }
    } else if (opcode === OpCode.RESET) {
      // RESET ACTION
      draft.entities = {};
      draft.fetchStatus = {};
      draft.errors = {};
    }
  });
}
