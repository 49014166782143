import { Button, Grid, Icon, Title } from "components";
import Form, { useForm } from "components/Form";
import { FormFieldProps } from "components/Form/Field";
import dayjs from "dayjs";
import common from "locale/en/common.json";
import accountSummary from "locale/en/containers/accountSummary.json";
import React, { useEffect, useState } from "react";
import { useIntl } from "utils/context";
import { TICKET_RESOLUTION_DELAY } from "store/actions/tickets";
import { handleFormErrors } from "utils/helpers";
import { useWindowSize } from "utils/hooks";

type InputProps = {
  id?: string;
  name?: string;
  value?: any;
  onChange?: (value: any) => any;
  disabled?: boolean;
};

type Props<T> = {
  onSave(value: any): Promise<void>;
  FieldComponent: React.ComponentType<InputProps>;
  name?: string;
  defaultValue?: any;
  forceDefaultOnMount?: boolean; // useful to provide full info on load even if not submitted. ie. Gov IDs
  submitted: string | undefined; // datestring
  fieldComponentProps?: T;
} & FormFieldProps<any>;

export default function LockedInput<T>({
  submitted,
  onSave,
  FieldComponent,
  name,
  defaultValue,
  forceDefaultOnMount,
  fieldComponentProps,
  label,
  ...rest
}: Props<T>) {
  const [form] = useForm();
  const { isMobile } = useWindowSize();
  const [editable, setEditable] = useState(!submitted);
  const [status, setStatus] = useState<undefined | "saving" | "saved">();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (submitted) {
      setEditable(false);
    }
  }, [submitted]);

  useEffect(() => {
    if (submitted || forceDefaultOnMount) {
      form.setFieldsValue({ [name]: defaultValue });
    }
  }, []);

  async function onSubmit(values: any) {
    try {
      setStatus("saving");
      await onSave(values[name]);

      window.setTimeout(() => {
        if (submitted) {
          setEditable(false);
        }
        setStatus("saved");
      }, TICKET_RESOLUTION_DELAY + 300); // 300 delay for ticket fetch
    } catch (e) {
      setStatus(undefined);
      handleFormErrors(e, form);
    }
  }

  const id = Array.isArray(name) ? name.join(".") : typeof name === "number" ? String(name) : name; // could also be undefined

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Field name={name} {...rest}>
        {({ value, onChange }) => (
          <Grid alignItems="middle" padding="small">
            <Grid.Item xs={24} sm={{ flex: "20" }}>
              <fieldset>
                <legend style={{ margin: 0 }}>
                  <Title level={2}>{label}</Title>
                </legend>
                <FieldComponent
                  value={value}
                  onChange={onChange}
                  disabled={!editable || status === "saving"}
                  id={id}
                  {...fieldComponentProps}
                />
              </fieldset>
            </Grid.Item>
            <Grid.Item alignSelf="flex-start" align="right" flex="1 50px">
              {!editable ? (
                <Button
                  size="middle"
                  onClick={(e) => {
                    e?.preventDefault?.();
                    setEditable(true);
                  }}
                  block={!isMobile}
                >
                  {(submitted || status === "saved") && (
                    <Icon
                      type={submitted ? "check-circle" : "exclamation-circle"}
                      color={submitted ? "success" : "error"}
                      tooltip={
                        submitted
                          ? formatMessage(
                              {
                                id: "common.submittedOn",
                                defaultMessage: common.submittedOn,
                              },
                              { date: dayjs(submitted).format("ll") },
                            )
                          : formatMessage({
                              id: "containers.accountSummary.ticket.sameValueWarning",
                              defaultMessage: accountSummary.ticket.sameValueWarning,
                            })
                      }
                      left
                    />
                  )}
                  {formatMessage({
                    id: "common.edit",
                    defaultMessage: common.edit,
                  })}
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={status === "saving"}
                  disabled={!value}
                  block={!isMobile}
                >
                  {status === "saved" && (
                    <Icon
                      type={submitted ? "check-circle" : "exclamation-circle"}
                      color={submitted ? "success" : "error"}
                      tooltip={
                        submitted
                          ? formatMessage(
                              {
                                id: "common.submittedOn",
                                defaultMessage: common.submittedOn,
                              },
                              { date: dayjs(submitted).format("ll") },
                            )
                          : formatMessage({
                              id: "containers.accountSummary.ticket.sameValueWarning",
                              defaultMessage: accountSummary.ticket.sameValueWarning,
                            })
                      }
                      left
                    />
                  )}
                  {formatMessage({
                    id: "common.save",
                    defaultMessage: common.save,
                  })}
                </Button>
              )}
            </Grid.Item>
          </Grid>
        )}
      </Form.Field>
    </Form>
  );
}
