import { PaymentCategory, TaxIncomeCode } from "@trolley/common-frontend";
import localeTax from "locale/en/containers/tax.json";
import { Translator } from "utils/context";

export function getPaymentCategoryLabels(
  translator: Translator,
): Record<PaymentCategory, { name: string; incomeCode?: TaxIncomeCode }> {
  return {
    [PaymentCategory.ROYALTIES]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.ROYALTIES}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.ROYALTIES],
      }), // "Royalties - Copyright",
      incomeCode: TaxIncomeCode.ROYALTIES_COPYRIGHTS_12,
    },
    [PaymentCategory.ROYALTIES_FILM]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.ROYALTIES_FILM}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.ROYALTIES_FILM],
      }), // "Royalties - Film & TV",
      incomeCode: TaxIncomeCode.ROYALTIES_FILM_TV,
    },
    [PaymentCategory.SERVICES]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.SERVICES}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.SERVICES],
      }), // "Independent Personal Services",
      incomeCode: TaxIncomeCode.INDEPENDENT_PERSONAL_SERVICE_17,
    },
    [PaymentCategory.SERVICES_MARKETPLACE]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.SERVICES_MARKETPLACE}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.SERVICES_MARKETPLACE],
      }), // "Third Party Settlement",
    },
    [PaymentCategory.RENT]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.RENT}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.RENT],
      }), // "Rent property income and natural resources royalties",
      incomeCode: TaxIncomeCode.REAL_PROPERTY_INCOME,
    },
    [PaymentCategory.PRIZES]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.PRIZES}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.PRIZES],
      }), // "Prizes and awards",
      incomeCode: TaxIncomeCode.OTHER_INCOME,
    },
    [PaymentCategory.REFUNDS]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.REFUNDS}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.REFUNDS],
      }), // "Refunds",
    },
    [PaymentCategory.EDUCATION]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.EDUCATION}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.EDUCATION],
      }), // "Education",
    },
    [PaymentCategory.GOODS]: {
      name: translator({
        id: `containers.tax.paymentCategories.${PaymentCategory.GOODS}`,
        defaultMessage: localeTax.paymentCategories[PaymentCategory.GOODS],
      }), // "GOODS",
    },
  };
}
