import { Typography } from "antd";
import { ParagraphProps as AntParagraphProps } from "antd/lib/typography/Paragraph";
import React, { PropsWithChildren } from "react";

import { MAX_CHARACTERS_PER_LINE } from "components/Typography";
import css, { classnames, createUseStyle } from "style/classname";

interface ParagraphProps extends AntParagraphProps {
  /**
   * the limit to number of characters per line (60ch)
   */
  limitWordPerLine?: boolean;
  size?: "initial" | "small" | "large" | "xlarge";
}

export default function Paragraph(props: PropsWithChildren<ParagraphProps>) {
  const { limitWordPerLine, className, role = "paragraph", ...rest } = props;
  const styledParagraph = useStyledParagraph(props);

  return <Typography.Paragraph {...rest} role={role} className={classnames(className, styledParagraph)} />;
}

const useStyledParagraph = createUseStyle<ParagraphProps>(({ theme, ...props }) =>
  css`
    text-wrap: pretty;
    ${props.limitWordPerLine && `max-width: ${MAX_CHARACTERS_PER_LINE}ch;`};
    ${() => {
      switch (props.size) {
        case "initial":
          return `
          font-size: ${theme.fontSize}px;
          line-height: ${theme.lineHeight};
        `;
        case "small":
          return `
        font-size: ${theme.fontSizeSM}px;
        line-height: ${theme.lineHeightSM};
        `;
        case "large":
          return `
        font-size: ${theme.fontSizeLG}px;
        line-height: ${theme.lineHeightLG};
        `;
        case "xlarge":
          return `
        font-size: ${theme.fontSizeXL}px;
        line-height: ${(theme.fontSizeXL + 8) / theme.fontSizeXL};
        `;
        default:
          return ``;
      }
    }}
  `(),
);
