import { Container, Divider, Icon, LinkButton, Text, TitleBar } from "components";
import { PATHS } from "pages/App/routes";
import React from "react";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";

import { TaxStatus } from "@trolley/common-frontend";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useTaxForms } from "store/hooks/taxForms";
import { getContrastText } from "utils/helpers";
import { useTaxProfiles } from "store/hooks/taxProfiles";
import RequireUSTax from "./RequireUSTax";
import RequireEUTax from "./RequireEUTax";
import Helmet from "react-helmet";

export default function PayoutComplete() {
  const merchant = useMerchant();
  const config = useIframeConfig();
  const { data: taxForms } = useTaxForms();
  const { data: taxProfiles } = useTaxProfiles();
  const { formatMessage } = useIntl();

  if (!merchant) {
    return null;
  }

  const usTaxEnabled = !!(merchant?.tax?.enabled && config.usTax);
  const usTaxCompleted = taxForms.records.some((form) =>
    [TaxStatus.REVIEWED, TaxStatus.SUBMITTED].includes(form.status as TaxStatus),
  );

  const euTaxEnabled = !!merchant?.features?.euTax && !!merchant?.euTax?.enabled;
  const euTaxCompleted = taxProfiles.records.some((t) =>
    [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(t.status as TaxStatus),
  );

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: "containers.payoutComplete.title" })}</title>
      </Helmet>
      <Container style={{ textAlign: "center" }}>
        <Divider transparent size="xlarge" />
        <BigCompleteIcon />
        <Divider transparent />
        <TitleBar>{formatMessage({ id: "containers.payoutComplete.title" })}</TitleBar>
        <Divider transparent size="large" />
        <Text type="success" padded data-test="preferencesSetup">
          <CompletedIcon />
          {formatMessage({ id: "containers.payoutComplete.preferencesSetup" })}
        </Text>

        <RequireUSTax enabled={usTaxEnabled} completed={usTaxCompleted} />

        <RequireEUTax enabled={euTaxEnabled} completed={euTaxCompleted} />

        {!euTaxEnabled && !usTaxEnabled && (
          <div style={{ textAlign: "center", padding: "24px" }}>
            <LinkButton size="large" type="primary" to={PATHS.HOME}>
              {formatMessage({ id: "common.done" })}
            </LinkButton>
          </div>
        )}
      </Container>
    </>
  );
}

export function BigCompleteIcon() {
  const successIconStyle = useSuccessIcon();

  return (
    <div className={successIconStyle}>
      <Icon type="check" theme="solid" />
    </div>
  );
}

export function CompletedIcon() {
  return <Icon theme="solid" size="large" type="check-circle" color="success" left />;
}

export function IncompleteIcon() {
  return <Icon type="circle" size="large" left />;
}

const useSuccessIcon = createUseStyle(({ theme }) =>
  css`
    border: 16px solid ${theme.colorSuccessBgHover};
    background-color: ${theme.colorSuccess};
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0 auto;
    box-sizing: content-box;
    i {
      color: ${getContrastText(theme.colorSuccess)};
      border: 18px solid ${theme.colorSuccess};
      background-color: transparent;
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  `(),
);
