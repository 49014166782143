import { Collapse, Text } from "components";
import React, { useState } from "react";
import { css } from "style/classname";
import ListFooter from "./ListFooter";
import { BaseProps } from "./index";

interface CollapseProps<T> extends BaseProps<T> {
  renderHeader: (item: T) => React.ReactNode;
  renderBody: (item: T) => React.ReactNode;
  emptyText: string;
  showArrow?: boolean;
  onChangeActiveKey?(key: string): any;
}
export default function CollapseList<T>(props: CollapseProps<T>) {
  const {
    defaultRecordCount = 3,
    items,
    showArrow = true,
    renderHeader,
    renderBody,
    showMoreText,
    showLessText,
    footer,
    emptyText,
    onChangeActiveKey,
    itemKey = "id",
  } = props;
  const [showMore, setShowMore] = useState(false);

  function renderItems() {
    if (items.length === 0) {
      return (
        <div>
          <Text type="secondary" align="center" style={{ padding: "24px 8px" }}>
            {emptyText}
          </Text>
        </div>
      );
    }

    return items
      .filter((item, index) => showMore || index < defaultRecordCount)
      .map((item) => {
        return (
          <Collapse.Panel showArrow={showArrow} key={item[itemKey]} header={renderHeader(item)} className={styledPanel}>
            {renderBody(item)}
          </Collapse.Panel>
        );
      });
  }

  return (
    <Collapse onChange={onChangeActiveKey} size="small">
      {renderItems()}
      {(items.length > defaultRecordCount || footer) && (
        <ListFooter
          footer={footer}
          showMore={showMore}
          showMoreText={showMoreText}
          showLessText={showLessText}
          onShowMore={setShowMore}
        />
      )}
    </Collapse>
  );
}

const styledPanel = css`
  .rc-collapse-header {
    .rc-collapse-header-text {
      width: 100%;
    }
  }
`();
