import { useEffect } from "react";
import { TaxProfile, loadTaxProfile, loadTaxProfiles } from "store/actions/taxProfiles";
import { useMerchant } from "store/hooks/merchant";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useTaxProfile(taxProfileId: string) {
  const id = taxProfileId || "";
  useEffect(() => {
    if (id) {
      loadTaxProfile(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.taxProfile.fetchStatus[id] === undefined) {
      loadTaxProfile(id);
    }

    return {
      data: state.taxProfile.entities[id],
      status: state.taxProfile.fetchStatus[id],
      error: state.taxProfile.errors[id],
    };
  });
}

export function useTaxProfiles() {
  const merchant = useMerchant();
  const euTaxEnabled = !!merchant?.features?.euTax && merchant?.euTax?.enabled;

  useEffect(() => {
    if (euTaxEnabled) {
      loadTaxProfiles();
    }
  }, [euTaxEnabled]);

  return useShallowSelector((state) => {
    if (euTaxEnabled && state.taxProfiles.fetchStatus.data === undefined) {
      loadTaxProfiles();
    }

    const data = state.taxProfiles.entities.data;

    return {
      enabled: euTaxEnabled,
      data: data
        ? {
            records: data.records.map((id) => state.taxProfile.entities[id]).filter((tp) => tp) as TaxProfile[],
            meta: data.meta,
          }
        : emptyList,
      status: state.taxProfiles.fetchStatus.data,
      error: state.taxProfiles.errors.data,
    };
  });
}
