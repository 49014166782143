import { useEffect } from "react";
import { loadRecipientConfig } from "store/actions/recipientConfig";
import { useShallowSelector } from ".";
import { useRecipient } from "store/hooks/recipient";

export function useRecipientConfig() {
  const recipient = useRecipient();
  const id = recipient?.id || "";

  useEffect(() => {
    if (id) {
      loadRecipientConfig(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.recipientConfig.fetchStatus[id] === undefined) {
      loadRecipientConfig(id);
    }

    return {
      data: state.recipientConfig.entities[id],
      status: state.recipientConfig.fetchStatus[id],
      error: state.recipientConfig.errors[id],
    };
  });
}
