import { IFrameConfig as CommonIFrameConfig } from "@trolley/common-frontend";
import { useShallowSelector } from ".";

export interface IFrameConfig extends CommonIFrameConfig {}

export function useIframeConfig() {
  return useShallowSelector<Partial<IFrameConfig>>((state) => {
    return state.config || {};
  });
}
