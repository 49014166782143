import { allMappedCountries } from "@trolley/common-frontend";
import { NamePath, RuleRender } from "rc-field-form/lib/interface";
import React, { useMemo } from "react";

import { FormatMessage, useIntl } from "utils/context";
import Select, { SelectProps } from "./Select";

export function isRegionInCountryValidator(field: NamePath, formatMessage: FormatMessage): RuleRender {
  return ({ getFieldValue }) => ({
    async validator(rule, region) {
      // Changing country does not clear region now.
      // region cannot be cleared.
      // Make sure the select region is a region of the country.
      const country = getFieldValue(field);
      if (
        region &&
        country &&
        !allMappedCountries[country]?.regions.some((r) => r.shortCode === region || r.name === region)
      ) {
        throw new Error(formatMessage({ id: "common.invalidValue" }));
      }
    },
  });
}

const sortRegions = (
  entries: { name: string; shortCode?: string }[],
  country: string,
): { label: string; value: string }[] => {
  try {
    const sortedArray = entries.map((region) => {
      return {
        label: ["US", "CA"].includes(country) ? `${region.name} - ${region.shortCode}` : region.name,
        value: region.shortCode || region.name,
      };
    });

    sortedArray.sort((a, b) => {
      const nameA = a.label.toLocaleLowerCase();
      const nameB = b.label.toLocaleLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  } catch {
    return [];
  }
};

interface Props extends Omit<SelectProps<string>, "options"> {
  country: string | undefined | null;
}

export default function SelectRegion(props: Props) {
  const { formatMessage } = useIntl();
  const { showSearch = true, country, placeholder, value, ...rest } = props;
  const options = useMemo(
    () =>
      country && allMappedCountries[country]
        ? sortRegions(allMappedCountries[country].regions, country).map((region) => ({
            ...region,
            "data-search": [region.label, region.value].join(" "),
            "aria-label": region.label,
          }))
        : [],
    [country],
  );

  return (
    <Select
      {...rest}
      showSearch={showSearch}
      placeholder={placeholder || formatMessage({ id: "components.formRegion.select" })}
      optionFilterProp="data-search"
      value={value ?? undefined}
      options={options}
    />
  );
}
