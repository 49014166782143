import { CountryCode, allMappedCountries, formatCountry } from "@trolley/common-frontend";
import { Button, Flag, Icon } from "components";
import css, { createUseStyle } from "style/classname";
import { FormatMessage, IntlShape, useIntl } from "utils/context";

import { allCountries as allCountriesPhone } from "country-telephone-data";
import * as PhoneNumber from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import info from "locale/en/containers/info.json";
import { Rule } from "rc-field-form/lib/interface";
import React, { useEffect, useRef, useState } from "react";

import { Select } from ".";
import Input, { InputProps } from "./Input";
import { isCountryCode } from "./SelectCountry";

/**
 * @deprecated
 */
const allAcceptedCountryPhones = allCountriesPhone
  .map((country) => ({
    ...country,
    iso2: country.iso2.toLocaleUpperCase() as CountryCode,
  }))
  .filter((country) => allMappedCountries[country.iso2]);

/**
 * @deprecated
 */
export const iso2Lookup: Record<CountryCode, CountryPhoneData> = allAcceptedCountryPhones.reduce((acc, country) => {
  acc[country.iso2] = country;

  return acc;
}, {} as Record<CountryCode, CountryPhoneData>);

export function countryPhoneValidator(
  formatMessage: IntlShape["formatMessage"] | FormatMessage,
  allowedCountries?: CountryCode[],
): Rule {
  return {
    async validator(_: any, fullPhoneNumber: string) {
      if (fullPhoneNumber) {
        let parsed: PhoneNumber.PhoneNumber;
        try {
          parsed = PhoneNumber.parsePhoneNumberWithError(fullPhoneNumber);
        } catch (error) {
          throw new Error(
            formatMessage(
              {
                id: "containers.info.phone.valid",
                defaultMessage: info.phone.valid,
              },
              { format: "" },
            ),
          );
        }

        if (!parsed.country || !parsed.isValid()) {
          throw new Error(
            formatMessage(
              {
                id: "containers.info.phone.valid",
                defaultMessage: info.phone.valid,
              },
              { format: "" },
            ),
          );
        }

        if (allowedCountries?.length && !allowedCountries.includes(parsed.country as CountryCode)) {
          throw new Error(
            formatMessage({
              id: "containers.info.phone.validCountry",
              defaultMessage: info.phone.validCountry,
            }),
          );
        }
      }
    },
  };
}

export function getCountryDialCode(country: CountryCode | PhoneNumber.CountryCode | null | undefined) {
  if (country) {
    const dialCode = PhoneNumber.getCountryCallingCode(country as PhoneNumber.CountryCode);

    if (dialCode) {
      return `+${dialCode}`;
    }
  }

  return "";
}

export function parsePhoneNumber(value: string = "", defaultCountry?: PhoneNumber.CountryCode | undefined) {
  try {
    const parsed = PhoneNumber.parsePhoneNumber(value, defaultCountry);
    if (parsed.countryCallingCode) {
      const internationalFormat = parsed.formatInternational();
      const prefix = `+${parsed.countryCallingCode}`;
      const lineNumber = internationalFormat.substring(prefix.length).trim();
      /**
       * National phone format if you were to call within the country. It will sometimes include a "trunk prefix".
       * https://en.wikipedia.org/wiki/Trunk_prefix
       */
      const nationalFormat = parsed.formatNational();
      const possibleCountries = parsed.getPossibleCountries();

      return {
        country: parsed.country || possibleCountries.find((c) => c === defaultCountry) || possibleCountries[0],
        prefix,
        lineNumber,
        internationalFormat,
        nationalFormat,
      };
    }
  } catch {
    // unable to parse due to number being incomplete
  }

  /**
   * FALLBACK parsing
   */
  const internationalFormat = PhoneNumber.formatIncompletePhoneNumber(value, defaultCountry);
  const prefix = /^\+\d+/.test(value)
    ? defaultCountry
      ? `+${PhoneNumber.getCountryCallingCode(defaultCountry)}`
      : value.replace(/^(\+[0-9]+).*$/, "$1")
    : "";
  const lineNumber = internationalFormat.substring(prefix.length).trim();

  return {
    prefix,
    country: defaultCountry,
    lineNumber,
    internationalFormat,
    nationalFormat: lineNumber,
  };
}

export type CountryPhoneData = {
  iso2: CountryCode; // countryCode
  dialCode: string;
  format: string;
  priority: number;
};

interface Props extends Omit<InputProps, "onChange" | "prefix" | "suffix"> {
  value?: string;
  onChange?(value: any): void;
  allowedCountries?: CountryCode[];
  defaultCountry?: CountryCode;
}

export default function InputPhone(props: Props) {
  const { value, defaultCountry, allowedCountries, readOnly, onChange, disabled: customDisabled, ...rest } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { formatMessage } = useIntl();
  const styledCountrySelect = useStyledCountrySelect(props);
  const [country, setCountry] = useState<PhoneNumber.CountryCode | undefined>();
  const { prefix, lineNumber } = parsePhoneNumber(value, country);
  const placeholder = country
    ? PhoneNumber.getExampleNumber(country as PhoneNumber.CountryCode, examples)
        ?.formatInternational()
        .substring(prefix.length)
        .trim() || ""
    : formatMessage({ id: "components.formCountry.select" });

  useEffect(() => {
    if (value) {
      try {
        const parsed = parsePhoneNumber(value, country);
        if (
          parsed?.country &&
          isCountryCode(parsed.country) &&
          (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
        ) {
          setCountry(parsed.country);
        } else if (country && allowedCountries?.length && !allowedCountries.includes(country as CountryCode)) {
          // reset back to undefined selector picked a country that is not allowed
          setCountry(undefined);
        }
      } catch (error) {
        //
      }
    } else if (!country && defaultCountry) {
      setCountry(defaultCountry as PhoneNumber.CountryCode);
      onChange?.(getCountryDialCode(defaultCountry));
    }
  }, [value, country]);

  return (
    <Input
      {...rest}
      type="tel"
      prefix={
        <div className={styledCountrySelect}>
          {country ? (
            <>
              <Flag code={country as CountryCode} left />
              {prefix}
            </>
          ) : (
            <Icon type="globe" theme="light" right />
          )}
          {!props.readOnly && !props.disabled && (
            <Select
              value={country || ""}
              options={PhoneNumber.getCountries()
                .filter(
                  (code) =>
                    isCountryCode(code) &&
                    (!allowedCountries?.length || allowedCountries.includes(code as CountryCode)),
                )
                .map((code) => {
                  const dialCode = `+${PhoneNumber.getCountryCallingCode(code)}`;

                  return {
                    value: code,
                    label: `${formatCountry(code, formatMessage) || code}  ${dialCode}`,
                  };
                })}
              onChange={(e) => {
                const code = e.target.value;
                if (isCountryCode(code)) {
                  const parsed = parsePhoneNumber(
                    [`+${PhoneNumber.getCountryCallingCode(code as PhoneNumber.CountryCode)}`, lineNumber].join(""),
                    code as PhoneNumber.CountryCode,
                  );
                  setCountry(code as PhoneNumber.CountryCode);
                  onChange?.(parsed.internationalFormat);
                  inputRef?.current?.focus?.();
                }
              }}
            />
          )}
        </div>
      }
      value={lineNumber}
      ref={inputRef}
      disabled={!country}
      onChange={(e) => {
        const parsed = parsePhoneNumber(`${prefix}${e.target.value}`, country);
        onChange?.(parsed.internationalFormat);
        if (
          parsed.country &&
          country !== parsed.country &&
          (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))
        ) {
          setCountry(parsed.country);
        }
      }}
      placeholder={placeholder}
      suffix={
        !props.readOnly && !props.disabled && (lineNumber || (!defaultCountry && country)) ? (
          <Button
            type="text"
            onClick={() => {
              setCountry(undefined);
              onChange?.("");
            }}
            icon="times-circle"
            aria-label={formatMessage({ id: "common.delete" })}
            iconProps={{
              theme: "solid",
              color: "grey",
            }}
          />
        ) : (
          <span />
        )
      }
    />
  );
}

export function formatPhone(value: string, country: undefined | CountryPhoneData) {
  if (country && country?.format) {
    let format = country.format;

    value.split("").forEach((char: string) => {
      if (/\d/.test(char)) {
        // allow to append extra digits
        format = /\./.test(format) ? format.replace(".", char) : format.concat(char);
      }
    });

    return format.replace(/\D*$/, ""); // remove trailing non-digit values
  }

  return value.replace(/\D/g, "");
}

const useStyledCountrySelect = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    ${!props.readOnly && !props.disabled
      ? `
        padding-right: 24px;
      `
      : `
    `}

    ${props.disabled
      ? `
        color: ${theme.colorTextDisabled};
        cursor: not-allowed;
      `
      : ``}
    width: 100px;
    height: ${theme.controlHeight - 2}px;
    display: flex;
    align-items: center;
    .dialCode {
      display: block;
      margin-left: 8px;
    }
    .select-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border-right: 1px solid ${theme.colorBorder};
    }
    .select-field {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      background-color: transparent !important;
      color: transparent !important;
      z-index: 2;
    }
  `(),
);
