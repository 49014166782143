import { PayoutMethodType } from "@trolley/common-frontend";
import { CurrencyDisplay, Text } from "components";
import React, { ReactNode } from "react";
import { Fees } from "store/actions/fees";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";

interface Props {
  type: PayoutMethodType;
  bankTransferFees?: Fees["bankTransfer"];
}

export default function PayoutEstimatedFees(props: Props) {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const { type } = props;

  let fee: ReactNode | null | undefined;

  if (type === PayoutMethodType.BANKTRANSFER && props.bankTransferFees) {
    fee = (
      <Text inline medium>
        <CurrencyDisplay value={props.bankTransferFees.amount} currency={props.bankTransferFees.currency} />
      </Text>
    );
  } else if (type === PayoutMethodType.INTERAC) {
    fee = (
      <Text inline medium>
        60 minutes
      </Text>
    );
  } else if (type === PayoutMethodType.CHECK) {
    recipient?.gatewayFees?.check
      ? (fee = (
          <Text inline medium>
            <CurrencyDisplay
              value={recipient.gatewayFees.check.value}
              currency={recipient.gatewayFees.check.currency}
            />
          </Text>
        ))
      : null;
  } else if (type === PayoutMethodType.PAYPAL) {
    recipient?.gatewayFees?.paypal
      ? (fee = (
          <Text inline medium>
            <CurrencyDisplay
              value={recipient.gatewayFees.paypal.value}
              currency={recipient.gatewayFees.paypal.currency}
            />
          </Text>
        ))
      : (fee = formatMessage(
          {
            id: "containers.payoutMethod.payoutFeesMayApply",
          },
          { payoutMethod: "PayPal" },
        ));
  }

  return fee ? (
    <Text>
      {formatMessage({
        id: "containers.payoutMethod.estimatedFees",
      })}{" "}
      : {fee}
    </Text>
  ) : null;
}
