import { CountryCode, formatCountry } from "@trolley/common-frontend";
import React, { CSSProperties, forwardRef } from "react";
import ReactCountryFlag from "react-country-flag";

import { classnames, createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";

export interface Props {
  code: CountryCode;
  left?: boolean;
  right?: boolean;
  className?: string;
  style?: CSSProperties;
}

export default forwardRef<HTMLSpanElement, Props>(function Flag(props, ref) {
  const { code, className, style } = props;
  const { formatMessage } = useIntl();
  const spriteFlag = useSpriteFlag(props);
  const countryCode = String(code).toLocaleUpperCase().substring(0, 2);

  return CountryCode[countryCode] ? (
    <span ref={ref} className={classnames(spriteFlag, className)} aria-hidden>
      <ReactCountryFlag
        countryCode={countryCode}
        aria-label={formatCountry(countryCode, formatMessage)}
        svg={false}
        style={style}
      />
    </span>
  ) : null;
});

const useSpriteFlag = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    ${props.left && `margin-right: 6px;`}
    ${props.right && `margin-left: 6px;`}
  `(),
);
