import { BaseError } from "store/reducers/standardReducer";
import { Response } from ".";
// Parse the standard error structure from the Trolley API
//  @res == the XHR http response structure
export default function handleErrors(res: Response<{ errors: BaseError[] | undefined }>) {
  const errors = res.body?.errors || [];
  if (Array.isArray(errors)) {
    return errors;
  }
  const keys = Object.keys(errors);

  if (keys.length > 0) {
    return keys.reduce(
      (acc: BaseError[], key: string) => [
        ...acc,
        {
          code: "invalid_field",
          field: key,
          message: errors[key][0],
        },
      ],
      [],
    );
  }

  return [];
}
