import { formatPayoutMethod, PayoutMethodType } from "@trolley/common-frontend";
import { Grid, Icon, Status, Text } from "components";
import { useIntl } from "utils/context";
import React from "react";
import { RecipientAccount } from "store/actions/recipient";
import { default as VenmoLogo } from "assets/images/venmo.svg";

type Props = {
  account: RecipientAccount;
  showIcon?: boolean;
  showStatus?: boolean;
  mini?: boolean;
};

export default function AccountDisplay(props: Props) {
  const { account, showIcon = true, showStatus = true, mini } = props;

  const intl = useIntl();

  const ICON_ATTR: any = {
    size: mini ? undefined : "xlarge",
    left: !!mini,
    fixedWidth: false,
  };

  const PAYOUT_METHODS: Record<PayoutMethodType, { type: PayoutMethodType; icon: JSX.Element; title: string }> = {
    [PayoutMethodType.BANKTRANSFER]: {
      type: PayoutMethodType.BANKTRANSFER,
      icon: <Icon type="university" {...ICON_ATTR} />,
      title: formatPayoutMethod(PayoutMethodType.BANKTRANSFER, intl.formatMessage),
    },
    [PayoutMethodType.PAYPAL]: {
      type: PayoutMethodType.PAYPAL,
      icon: <Icon theme="brands" type="paypal" {...ICON_ATTR} />,
      title: formatPayoutMethod(PayoutMethodType.PAYPAL, intl.formatMessage),
    },
    [PayoutMethodType.BITCOIN]: {
      type: PayoutMethodType.BITCOIN,
      icon: <Icon theme="brands" type="bitcoin" {...ICON_ATTR} />,
      title: formatPayoutMethod(PayoutMethodType.BITCOIN, intl.formatMessage),
    },
    [PayoutMethodType.INTERAC]: {
      type: PayoutMethodType.INTERAC,
      icon: <Icon type="credit-card" {...ICON_ATTR} />,
      title: formatPayoutMethod(PayoutMethodType.INTERAC, intl.formatMessage),
    },
    [PayoutMethodType.CHECK]: {
      type: PayoutMethodType.CHECK,
      icon: <Icon type="money-check-edit-alt" {...ICON_ATTR} />,
      title: formatPayoutMethod(PayoutMethodType.CHECK, intl.formatMessage),
    },
    [PayoutMethodType.VENMO]: {
      type: PayoutMethodType.VENMO,
      icon: <img src={VenmoLogo} alt="venmo-icon" style={{ width: "32px" }} />,
      title: formatPayoutMethod(PayoutMethodType.VENMO, intl.formatMessage),
    },
  };

  const payoutTypeProps =
    (account.type && PAYOUT_METHODS[account.type]) || PAYOUT_METHODS[PayoutMethodType.BANKTRANSFER];

  function formatDetails() {
    switch (account.type) {
      case PayoutMethodType.PAYPAL:
        return (
          <Text type={mini ? undefined : "secondary"} size="small" style={{ maxWidth: "200px" }}>
            {mini && payoutTypeProps.icon}
            {account.emailAddress}
          </Text>
        );
      case PayoutMethodType.BITCOIN:
        return account.wallet ? (
          <Text type={mini ? undefined : "secondary"} size="small" style={{ maxWidth: "200px" }}>
            {mini && payoutTypeProps.icon}
            ••••••{account.wallet.substring(account.wallet.length - 4)}
          </Text>
        ) : (
          " - "
        );
      case PayoutMethodType.CHECK:
        return account.mailing ? (
          <Text type={mini ? undefined : "secondary"} size="small" style={{ maxWidth: "200px" }}>
            {mini && payoutTypeProps.icon}
            {account.mailing.name}
          </Text>
        ) : (
          " - "
        );
      default:
        let field = Object.keys(account)[0];
        if (account.iban) {
          field = "iban";
        } else if (account.accountNum) {
          field = "accountNum";
        }

        const bankName = account.bankName || "";

        return (
          <Text type={mini ? undefined : "secondary"} size="small" inline={!mini} padded={mini}>
            <Text inline ellipsis={{ tooltip: bankName }}>
              {mini && payoutTypeProps.icon}
              {bankName}
            </Text>{" "}
            <Text inline>{`- •••${account[field]?.substring?.(account[field].length - 2)} (${account.currency})`}</Text>
          </Text>
        );
    }
  }

  return mini ? (
    <Text>{formatDetails()}</Text>
  ) : (
    <Grid wrap={false} alignItems="middle" padding="xsmall">
      {showIcon && (
        <Grid.Item align="center" style={{ minWidth: mini ? "20px" : "50px" }}>
          {payoutTypeProps.icon}
        </Grid.Item>
      )}
      <Grid.Item flex={1} style={{ width: "1px" }}>
        <Text>
          <Text
            strong
            inline
            style={
              showStatus && showIcon && ["primary", "disabled"].includes(account.status) ? { maxWidth: "50%" } : {}
            }
          >
            {payoutTypeProps.title}
          </Text>{" "}
          {showStatus && account.status === "primary" && (
            <Status type="success">{intl.formatMessage({ id: "containers.accountSummary.active" })}</Status>
          )}
          {showStatus && account.status === "disabled" && (
            <Status type="default">{intl.formatMessage({ id: "containers.accountSummary.disabled" })}</Status>
          )}
        </Text>
        {formatDetails()}
      </Grid.Item>
    </Grid>
  );
}
