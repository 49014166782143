/* eslint-disable react/no-unused-prop-types */
import { Typography as AntTypography } from "antd";
import { TitleProps as AntTitleProps } from "antd/lib/typography/Title";
import { Space } from "components";
import { SpaceProps } from "components/Space";
import React, { ReactNode } from "react";
import { classnames, createUseStyle, css, useTheme } from "style/classname";

export interface TitleProps extends AntTitleProps, Pick<SpaceProps, "justify" | "align" | "size"> {
  level?: AntTitleProps["level"];
  extraActions?: ReactNode;
  padded?: false;
  children?: ReactNode;
}

export default function TitleBar(props: TitleProps) {
  const { children, extraActions, align = "end", justify = "space-between", size, className, ...rest } = props;
  const theme = useTheme();
  const styledTitle = useStyledTitle(props);

  return (
    <AntTypography.Title {...rest} className={classnames(styledTitle, className)}>
      {extraActions ? (
        <Space justify={justify} align={align} size={size}>
          <span>{children}</span>
          <span style={{ fontSize: `${theme.fontSize}px` }}>{extraActions}</span>
        </Space>
      ) : (
        <span>{children}</span> // wrap in span prevent a runtime error with page translators
      )}
    </AntTypography.Title>
  );
}

const useStyledTitle = createUseStyle<TitleProps>(({ theme, padded }) =>
  css`
    &.${theme.prefixCls}-typography {
      ${padded === false &&
      `
        margin-top: 0px;
        margin-bottom: 0px;
      `}
      &:not(:first-child) {
        margin-top: ${theme.marginLG}px; /* when it's not the first element, add more top margin */
      }
    }
  `(),
);
