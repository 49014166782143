import store from "store";
import * as request from "services/request";
import * as ACTIONS from "store/actions";
import { initStore, SessionBody, SessionMode } from "store/actions/auth";
import { authTokenClear, getDeviceId } from "utils/helpers";
import { history } from "services/history";

export function getUrlPortalDomain() {
  try {
    const hostname = window.location.hostname;

    if (!hostname.includes(".portal")) {
      return "";
    }

    return hostname.split(".portal")[0];
  } catch {
    return "";
  }
}

export async function authPortal(portalDomain: string, email?: string, code?: string) {
  const deviceId = getDeviceId();

  store.dispatch({
    type: ACTIONS.REQUEST_AUTH,
  });
  try {
    const res = await request.POST<SessionBody>("/v1/iframe/authPortal", {
      query: { email, code, portalDomain, deviceId },
      noToken: true,
    });
    await initStore(res.body);
  } catch (errors) {
    store.dispatch({
      errors,
      type: ACTIONS.ERROR_AUTH,
    });
    throw errors;
  }
}

export function authLogout() {
  authTokenClear();
  store.dispatch({
    type: ACTIONS.RECEIVE_AUTH,
    data: {
      state: SessionMode.ENDED,
    },
  });
  history.replace({ search: "" }); // this removes the url queries that were preset upon landing
}

export function authTimeout() {
  authTokenClear();
  store.dispatch({
    type: ACTIONS.RECEIVE_AUTH,
    data: {
      state: SessionMode.EXPIRED,
    },
  });
  history.replace({ search: "" });
}
