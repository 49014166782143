import store from "store";
import * as ACTIONS from "store/actions";
import { IFrameConfig } from "store/hooks/config";
import { initLocale } from "utils/context";
import { updateRecipient } from "./recipient";

export type Products = "pay" | "tax" | "trust";

// TODO [FE]: documentation pattern bellow is wrong

/** URL parameters are a way for the merchant to render the widget and overwrite the default confured settings
 * @locale will overwrite the default Recipient['language']
 * @payoutMethods will show a subset (or intersection) of merchant's enabled payout methods.
 * @roEmail will make the email input in the General Information READ-ONLY
 * @hideEmail will hide the email input in the General Information
 * @addr will default the General Information fields with the following fields if empty
 * @colors will overwrite the IframeConfig colors
 * @style will overwrite the IframeConfig style
 * @products features to render
 */
export type UrlParams = {
  locale?: string;
  payoutMethods?: string[];
  roEmail?: boolean;
  hideEmail?: boolean;
  addr?: {
    [key: string]: string;
  };
  colors?: Partial<IFrameConfig["colors"]>;
  style?: Partial<IFrameConfig["style"]>;
  products?: [Products?, Products?, Products?];
};

export async function changeLocale(locale: string) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  await initLocale(locale);
  if (recipient) {
    await updateRecipient(recipient.id, { language: locale });
  }
  const params = store.getState().params;
  store.dispatch({
    type: ACTIONS.RECEIVE_PARAMS,
    data: {
      ...params,
      locale,
    },
  });
}
