import { RecipientType, TaxStatus } from "@trolley/common-frontend";
import { default as WhiteWave } from "assets/images/wave-white.png";
import { default as Waves } from "assets/images/waves.png";
import { Grid, Space } from "components";
import { LanguageSelector } from "features";
import PortalLogoutButton from "pages/Portal/PortalLogoutButton";
import { default as React, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "store/hooks/auth";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForms } from "store/hooks/taxForms";
import css, { classnames, createUseStyle } from "style/classname";
import { useIntl, PRODUCT_MODULES, useProducts } from "utils/context";

import { useTaxProfiles } from "store/hooks/taxProfiles";
import { getContrastText, showProduct } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import { PATHS } from "pages/App/routes";
import { useStyledPortalLogout } from "pages/App/MainContainer";

export function getStep(pathname: string) {
  if (pathname.includes(PATHS.INFO)) {
    return 1;
  }
  if (pathname.includes(PATHS.PAYOUT)) {
    return 2;
  }

  if (pathname.includes(PATHS.US_TAX)) {
    return 3;
  }

  return 0;
}

export default function PortalSidebar(props: { children: React.ReactNode }) {
  const { isLoggedIn } = useAuth();
  const merchant = useMerchant();
  const recipient = useRecipient();

  const { queryProducts, entitledProducts } = useProducts();
  const showTaxProduct = showProduct(PRODUCT_MODULES.TAX, queryProducts, entitledProducts);
  const showPayProduct = showProduct(PRODUCT_MODULES.PAY, queryProducts, entitledProducts);
  const showTrustProduct = showProduct(PRODUCT_MODULES.TRUST, queryProducts, entitledProducts);

  const { isMobile } = useWindowSize();
  const { data: taxForms } = useTaxForms();
  const { data: taxProfiles } = useTaxProfiles();
  const config = useIframeConfig();
  const showPayoutMethods = !!config.showPayoutMethods;
  const payoutMethodsEnabled = showPayProduct && showPayoutMethods;
  const showSidebar = !isMobile;
  const styledHeader = useStyledPortalHeader({ showSidebar });
  const styledPortalSider = useStyledPortalSider({ showSidebar });
  const styledPortalLogout = useStyledPortalLogout();
  const styledLanguageSelector = useStyledLanguageSelector();
  const { formatMessage } = useIntl();
  const portalContent = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    try {
      if (portalContent.current) {
        // only works in "portal". the layout is contrained to the window height, and only the portal content "div" is scrollable.
        portalContent.current.scrollTop = 0;
      }
    } catch {}
  }, [history.location.pathname]);

  const step = getStep(history.location.pathname);

  const completePayout =
    (
      recipient?.accounts?.filter((account) =>
        merchant?.enabledPayoutMethods.find((pm) => pm.integration === account.type),
      ) || []
    ).length > 0;

  const usTaxEnabled = showTaxProduct && !!(merchant?.features?.tax && merchant?.tax?.enabled && config.usTax);
  const usTaxFormRequired = merchant?.tax?.paymentBehaviour === "block";
  const completeUSTax = taxForms.records.some((t) =>
    [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(t.status as TaxStatus),
  );

  const euTaxEnabled = showTaxProduct && !!merchant?.features?.euTax && merchant?.euTax?.enabled;
  const completeEUTax = taxProfiles.records.some((t) =>
    [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(t.status as TaxStatus),
  );

  const verificationEnabled =
    showTrustProduct &&
    ((recipient?.type === RecipientType.INDIVIDUAL && !!merchant?.features?.idv && merchant?.trust?.requireIDV) ||
      (recipient?.type === RecipientType.BUSINESS && !!merchant?.features?.dsa && merchant?.trust?.requireDSA)) &&
    merchant?.trust?.requireVerificationToBeActive;

  const trustVerificationInProgress =
    verificationEnabled &&
    ((recipient?.type === RecipientType.INDIVIDUAL && recipient?.idvStatus) ||
      (recipient?.type === RecipientType.BUSINESS && recipient?.kybStatus));

  const completedIDV = !!trustVerificationInProgress && !recipient?.inactiveReasons?.approvedIdVerification;

  const complete =
    (!payoutMethodsEnabled || completePayout) &&
    (!usTaxEnabled || !usTaxFormRequired || completeUSTax) &&
    (!euTaxEnabled || completeEUTax) &&
    (!verificationEnabled || completedIDV);

  return (
    <div className={classnames("portal-steppers", styledPortalSider)}>
      <header className={styledHeader}>
        <div className="wave" />
        <div className="white-wave" />
        <div className="header-content">
          <Grid padding={["small", "medium"]} direction={showSidebar ? "column" : "row"} wrap={false}>
            {config.icon && (
              <Grid.Item align="center" alignSelf="center">
                <div className="header__logo" style={{ backgroundImage: `url(${config.icon})` }} />
              </Grid.Item>
            )}
            <Grid.Item align={showSidebar ? "center" : "left"} alignSelf={showSidebar ? "stretch" : "center"}>
              <div className="header__merchant">{config.businessName || " "}</div>
              {payoutMethodsEnabled && (
                <div className="header__detail">
                  {formatMessage({
                    id: "portal.setupPayoutMethod",
                    defaultMessage: "Setup your payout method",
                  })}
                </div>
              )}
              {!payoutMethodsEnabled && verificationEnabled && (
                <div className="header__detail">
                  {formatMessage({
                    id: "portal.completeVerification",
                    defaultMessage: "Complete verification",
                  })}
                </div>
              )}
              {!payoutMethodsEnabled && !verificationEnabled && usTaxEnabled && (
                <div className="header__detail">
                  {formatMessage({
                    id: "portal.completeTax",
                    defaultMessage: "Complete a tax form",
                  })}
                </div>
              )}
              {!payoutMethodsEnabled && !verificationEnabled && euTaxEnabled && (
                <div className="header__detail">Complete a tax profile</div>
              )}
            </Grid.Item>
          </Grid>
          {!showSidebar && <PortalLogoutButton className={styledPortalLogout} />}
        </div>
      </header>

      {showSidebar && (
        <div className="portal-steppers__steps">
          <div
            className={classnames("portal-steppers__steps__step", {
              "step-active": !step,
              "step-complete": isLoggedIn,
            })}
          >
            {formatMessage({
              id: "portal.confirmAccount",
              defaultMessage: "Confirm your account",
            })}
          </div>
          {payoutMethodsEnabled && (
            <div
              className={classnames("portal-steppers__steps__step", {
                "step-active":
                  history.location.pathname.includes(PATHS.INFO) || history.location.pathname.includes(PATHS.PAYOUT),
                "step-complete": isLoggedIn && completePayout,
              })}
            >
              {formatMessage({
                id: "portal.setupPayoutMethod",
                defaultMessage: "Setup your payout method",
              })}
            </div>
          )}
          {verificationEnabled && (
            <div
              className={classnames("portal-steppers__steps__step", {
                "step-active": !!trustVerificationInProgress || history.location.pathname.includes(PATHS.KYB_UPLOAD),
                "step-complete": isLoggedIn && completedIDV,
              })}
            >
              {formatMessage({
                id: "portal.completeVerification",
                defaultMessage: "Complete verification",
              })}
            </div>
          )}
          {usTaxEnabled && (
            <div
              className={classnames("portal-steppers__steps__step", {
                "step-active": history.location.pathname.includes(PATHS.US_TAX),
                "step-complete": isLoggedIn && completeUSTax,
              })}
            >
              {formatMessage({
                id: "portal.completeTax",
                defaultMessage: "Complete a tax form",
              })}
            </div>
          )}

          {euTaxEnabled && (
            <div
              className={classnames("portal-steppers__steps__step", {
                "step-active": history.location.pathname.includes(PATHS.WIZARD_TAXPROFILE),
                "step-complete": isLoggedIn && completeEUTax,
              })}
            >
              Complete a Tax Profile
            </div>
          )}

          <div
            className={classnames("portal-steppers__steps__step", {
              "step-active": history.location.pathname.includes("/complete"),
              "step-complete": isLoggedIn && complete,
            })}
          >
            {formatMessage({
              id: "portal.ready",
              defaultMessage: "Ready to get paid!",
            })}
          </div>
        </div>
      )}

      <div className="portal-content" ref={portalContent}>
        {props.children}
      </div>

      <Space justify="space-between" className="portal-steppers__footer" align="center">
        {formatMessage({
          id: "portal.poweredBy",
          defaultMessage: "Powered by Trolley",
        })}
        <LanguageSelector className={styledLanguageSelector} />
      </Space>
    </div>
  );
}

const useStyledLanguageSelector = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-select-selection-item {
      font-size: ${theme.fontSizeSM}px;
    }
  `(),
);

const useStyledPortalHeader = createUseStyle<{ showSidebar: boolean }>(({ theme, showSidebar }) =>
  css`
    z-index: 1;
    background-color: ${theme.colorBrand};
    transition: all 0.2s ease;
    min-height: 180px;
    height: 25vw;
    max-height: 300px;
    color: ${getContrastText(theme.colorBrand)};
    padding: 0 16px;
    position: relative;
    .header-content {
      position: relative;
      margin: 4vw auto 0;
    }

    ${theme.screenUp("md")} {
      .header-content {
        margin-top: min(4vw, 50px);
      }
    }

    ${showSidebar &&
    `
      ${theme.screenUp("md")} {
        height: 200px;
        min-height: 200px;
        display: flex;
        .header-content {
          margin-top: 24px;
        }
      }
      ${theme.screenUp("lg")} {
        height: 240px;
        min-height: 240px;
        .header-content {
          margin-top: 48px;
        }
      }
    `}

    .header__logo {
      background-color: rgba(255, 255, 255, 1);
      height: 75px;
      width: 75px;
      border-radius: 8px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    }
    .header__merchant {
      font-weight: bold;
      font-size: 18px;
      ${theme.screenUp("md")} {
        font-size: 20px;
      }
    }
    .header__detail {
      opacity: 0.85;
    }

    .white-wave {
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      top: 1px;
      background-image: url(${WhiteWave});
      background-position: bottom center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .wave {
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url(${Waves});
      background-position: bottom center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      transform: translate3d(0, -10px, 0);
    }
  `(),
);

const useStyledPortalSider = createUseStyle<{ showSidebar: boolean }>(({ theme, showSidebar }) => {
  return css`
    &.portal-steppers {
      background-color: #fff;
      transition: all 0.2s ease;
      overflow: hidden;
      min-height: 100vh;
      display: flex;
      flex-direction: column;

      .portal-steppers__steps {
        text-align: left;
        padding: 0 40px;
        flex-grow: 1;
        .portal-steppers__steps__step {
          color: ${theme.colorTextSecondary};
          height: 50px;
          position: relative;
          padding-left: 20px;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            color: ${theme.colorTextSecondary};
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            content: "\\f111"; //circle
            padding-right: 8px;
          }
          &:not(:first-child)::after {
            content: " ";
            height: 30px;
            display: block;
            width: 2px;
            background-color: ${theme.colorTextDisabled};
            position: absolute;
            left: 6px;
            bottom: 50px;
          }
          &.step-active {
            color: #555;
            &::before {
              color: ${theme.colorBrandActive};
            }
            &::after {
              background-color: ${theme.colorBrandActive};
            }
          }
          &.step-complete {
            color: ${theme.colorBrandActive};
            &::before {
              font-size: inherit;
              color: ${theme.colorBrandActive};
              content: "\\f058"; //check-circle
            }
            &::after {
              background-color: ${theme.colorBrandActive};
            }
          }
        }
      }
      .portal-steppers__footer {
        line-height: 4;
        background: #f9fbfd;
        color: #979797;
        font-size: 10px;
        padding: 0 16px;
        white-space: nowrap;
      }
      .portal-content {
        padding: 0;
        transition: all 0.2s ease;
        flex-grow: 1;
      }
      ${theme.screenUp("md")} {
        .portal-content {
          padding: 16px;
        }
      }
    }

    ${showSidebar &&
    `
      ${theme.screenUp("md")} {
        &.portal-steppers {
          width: 300px;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .portal-steppers__steps {
            margin-top: 8vh;
            display: flex;
            flex-direction: column;
          }
          .portal-content {
            &::before,
            &::after {
              content: "";
              width: 100%;
            }
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            min-height: 100%;
            overflow: auto;
            margin-left: 300px;
            padding: 60px 24px;
            background: #f9fbfd;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            box-shadow: inset 18px 0 20px -18px rgba(100, 100, 100, 0.2);

          }
        }
      }
      ${theme.screenUp("lg")} {
        &.portal-steppers {
          width: 420px;
          .portal-content {
            margin-left: 420px;
          }
        }
      }
      `}
  `();
});
