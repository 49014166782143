/* istanbul ignore file */
import { useState, useEffect } from "react";
import { useTheme } from "style/classname";

function getSize(mobileBreakpoint: number, supportsHover: boolean = false) {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: typeof window === "object" ? window.innerWidth < mobileBreakpoint : false,
    supportsHover,
  };
}

export default function useWindowSize() {
  const theme = useTheme();
  // const hoverMediaQuery = window.matchMedia && window.matchMedia("(hover: hover)");
  const [hoverMediaQuery, setHoverMediaQuery] = useState(window.matchMedia && window.matchMedia("(hover: hover)"));
  const [windowSize, setWindowSize] = useState(getSize(theme.screenMD, hoverMediaQuery && hoverMediaQuery.matches));

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize(theme.screenMD, hoverMediaQuery && hoverMediaQuery.matches));
    }

    function checkHoverSupport(e: MediaQueryListEvent) {
      setWindowSize(getSize(theme.screenMD, e && e.matches));
      // updating hoverMediaQuery for resize listener
      setHoverMediaQuery(window.matchMedia && window.matchMedia("(hover: hover)"));
    }

    window.addEventListener("resize", handleResize);

    if (hoverMediaQuery) {
      hoverMediaQuery.addEventListener("change", checkHoverSupport);
    }

    return () => {
      if (hoverMediaQuery) {
        hoverMediaQuery.removeEventListener("change", checkHoverSupport);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.screenMD]);

  return windowSize;
}
