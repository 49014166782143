import React from "react";

import { GreyBox, Grid, Modal, Text } from "components";
import { Recipient } from "store/actions/recipient";
import { Translator, useIntl } from "utils/context";
import { hasBank } from "@trolley/common-frontend";

export function reEscape(s: string) {
  return s.replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&");
}

export function isValidName(firstName: string, lastName: string, holderName: string) {
  const firstNameParts = (firstName ?? "").toLocaleLowerCase().split(/(?:\s|-)/);
  const lastNameParts = (lastName ?? "").toLocaleLowerCase().split(/(?:\s|-)/);
  const firstRe = `\\b(${firstNameParts.map(reEscape).join("|")})\\b`;
  const lastRe = `\\b(${lastNameParts.map(reEscape).join("|")})\\b`;
  const regex = new RegExp(`(${firstRe}.*${lastRe}|${lastRe}.*${firstRe})`, "i");

  return regex.test(holderName);
}

export function getHolderNameWarnings(
  formatMessage: Translator,
  recipient: Recipient,
  name: string = "",
  part?: "first" | "last",
) {
  const lowerCaseName = name.toLowerCase();

  const warnings = [];
  if (
    name &&
    recipient.type === "individual" &&
    !isValidName(part === "last" ? "" : recipient.firstName, part === "first" ? "" : recipient.lastName, name)
  ) {
    warnings.push({
      type: "nameMismatch",
      warningText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.nameMatchWarning",
      }),
      popupText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.popupText1",
      }),
    });
  }

  if (hasBank.test(lowerCaseName)) {
    warnings.push({
      type: "hasBank",
      warningText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.containsBank",
      }),
      popupText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.bankPopupText1",
      }),
    });
  }

  return warnings;
}

type NameWarningProps = {
  visible: boolean;
  onClose(): void;
  onOk(): Promise<void>;
  submitting?: boolean;
  accountHolderName?: string;
  recipient: Recipient;
  holderNameWarnings: ReturnType<typeof getHolderNameWarnings>;
};

export default function NameWarning({
  visible,
  onClose,
  onOk,
  submitting,
  holderNameWarnings,
  accountHolderName,
  recipient,
}: NameWarningProps) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={!!visible}
      onCancel={onClose}
      onOk={onOk}
      confirmLoading={submitting}
      cancelText="Back"
      okText="Save"
      title={formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.popupTitle",
      })}
    >
      <div>
        {holderNameWarnings.length > 1 ? (
          <ul style={{ paddingLeft: "16px" }}>
            {holderNameWarnings.map((w) => (
              <li key={w.type}>
                <Text size="small">{w.popupText}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text>{holderNameWarnings[0]?.popupText}</Text>
        )}

        <GreyBox margin="small">
          <Grid padding="medium">
            <Grid.Item xs={24} sm={holderNameWarnings.find((w) => w.type === "nameMismatch") ? 12 : 24}>
              <Text size="small">
                {formatMessage({
                  id: "containers.bankPayoutMethod.accountHolderName.holderName",
                })}
              </Text>
              <Text strong type="danger">
                {accountHolderName}
              </Text>
            </Grid.Item>
            {holderNameWarnings.find((w) => w.type === "nameMismatch") && (
              <Grid.Item xs={24} sm={12}>
                <Text size="small">
                  {formatMessage({
                    id: "containers.bankPayoutMethod.accountHolderName.profileName",
                  })}
                </Text>
                <Text strong>{`${recipient.firstName} ${recipient.lastName}`}</Text>
              </Grid.Item>
            )}
            <Grid.Item xs={24}>
              {formatMessage({
                id: "containers.bankPayoutMethod.accountHolderName.popupText2",
              })}
            </Grid.Item>
          </Grid>
        </GreyBox>
      </div>
    </Modal>
  );
}
