import { RecursivePartial } from "@trolley/common-frontend";
import { Layout as AntLayout, ThemeConfig } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import { Button, Container, Divider, Text } from "components";
import React, { ReactNode } from "react";
import { useTheme } from "style/classname";
import { useClientIntegration, useIntl } from "utils/context";

interface LayoutProps extends BasicProps {
  theme?: RecursivePartial<ThemeConfig>;
  header?: ReactNode;
  hideBackButton?: boolean;
  onBackClick?: () => void;
}

export default function OnboardingWrapper({
  header,
  hideBackButton,
  onBackClick,
  children,
  style,
  ...rest
}: LayoutProps) {
  const token = useTheme();
  const client = useClientIntegration();
  const { formatMessage } = useIntl();

  return (
    <AntLayout
      {...rest}
      style={{ minHeight: "100%", background: client === "portal" ? "#FFF" : token.colorBgLayout, ...style }}
    >
      {header && (
        <AntLayout.Header style={{ borderBottom: `1px solid ${token.colorBorder}`, lineHeight: "inherit" }}>
          <section
            style={{
              margin: "0 auto",
              maxWidth: "630px",
              height: "100%",
            }}
          >
            {header}
          </section>
        </AntLayout.Header>
      )}
      <AntLayout.Content style={{ padding: "16px" }}>
        <Container padding="none" style={{ margin: "0 auto", width: "100%", maxWidth: "630px" }}>
          {!hideBackButton && (
            <Button type="link" onClick={onBackClick} icon="angle-left">
              <Text underline italic inline>
                {formatMessage({ id: "common.back" })}
              </Text>
            </Button>
          )}
          <Divider size="large" transparent />
          {children}
        </Container>
      </AntLayout.Content>
    </AntLayout>
  );
}
