import { Tooltip as AntTooltip } from "antd";
import { TooltipPropsWithTitle } from "antd/lib/tooltip";
import Stop from "components/Stop";
import React from "react";
import { classnames, createUseStyle, css } from "style/classname";
import tinycolor from "tinycolor2";
import { useWindowSize } from "utils/hooks";

export default function Tooltip({
  title,
  className,
  placement = "top",
  destroyTooltipOnHide = true,
  mouseLeaveDelay = 0.4,
  trigger,
  ...rest
}: TooltipPropsWithTitle) {
  const { isMobile } = useWindowSize();
  const styledTooltip = useSyledTooltip();

  return (
    <AntTooltip
      {...rest}
      title={<Stop type="section">{title}</Stop>}
      rootClassName={classnames(styledTooltip, className)}
      trigger={trigger || (isMobile ? ["click", "hover"] : ["hover"])}
      placement={placement}
      destroyTooltipOnHide={destroyTooltipOnHide}
      mouseLeaveDelay={mouseLeaveDelay}
    />
  );
}

const useSyledTooltip = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-tooltip {
      max-width: 300px;
      .${theme.prefixCls}-tooltip-inner {
        padding: 0;
        & > * {
          padding: 8px;
          max-height: 60vh;
          overflow-y: auto;
        }
        .${theme.prefixCls}-btn-link, a {
          text-align: left;
          padding: 0;
          display: inline;
          white-space: normal;
          ${tinycolor(theme.colorBgBase).isLight() && // this is because the BG is too dark, and we need more contrast on links
          `
            color: ${theme.colorInfoBgHover};
            &:hover {
              color: ${theme.colorInfoBg};
              &:active {
                color: ${theme.colorInfoBorder};
              }
            }
            `}
        }
      }
    }
  `(),
);
