import { PayoutMethodType } from "@trolley/common-frontend";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Form2 as Form, Loader } from "components";
import { PATHS } from "pages/App/routes";
import { BitcoinAccountUpdate, RecipientAccount, addPayoutMethod, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";

interface Props {
  account: RecipientAccount | undefined;
}
export default function Bitcoin({ account }: Props) {
  const [form] = Form.useForm();
  const recipient = useRecipient();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  async function onFinish({ primary, ...values }: { wallet: string; primary?: boolean }) {
    if (!submitting) {
      setSubmitting(true);

      const data: BitcoinAccountUpdate = {
        primary,
        wallet: values.wallet,
        type: PayoutMethodType.BITCOIN,
      };

      try {
        if (recipient) {
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, omitMaskedValues(data));
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, data);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Form.Field
          name="wallet"
          label={intl.formatMessage({
            id: "containers.bitcoinPayoutMethod.wallet.title",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "containers.bitcoinPayoutMethod.wallet.required",
              }),
            },
          ]}
        >
          <Form.Input type="text" name="wallet" />
        </Form.Field>

        <PayoutFooter busy={submitting} account={account} />
      </Loader>
    </Form>
  );
}
