import { LOCALE_LABELS, SupportedLocales } from "@trolley/common-frontend";
import { Form2 } from "components";
import React from "react";
import { changeLocale } from "store/actions/params";
import { useUrlParams } from "store/hooks/params";
import { classnames, createUseStyle, css } from "style/classname";
import { ClientContext, useClientIntegration } from "utils/context";

const SUPPORTED_LOCALE_OPTIONS = Object.entries(LOCALE_LABELS).map(([value, label]: [SupportedLocales, string]) => ({
  label,
  value,
}));

async function handleSelect(value: string) {
  if (value) {
    await changeLocale(value);
  }
}

export default function LanguageSelector(props: { className?: string }) {
  const { className } = props;
  const client = useClientIntegration();
  const { locale: selected = SupportedLocales.EN } = useUrlParams();

  const styledSelector = useStyledPortalLanguageButton({ client });

  return (
    <Form2.Select
      bordered={false}
      size="small"
      showSearch
      className={classnames(styledSelector, className)}
      value={selected}
      options={SUPPORTED_LOCALE_OPTIONS}
      showArrow={false}
      placeholder="Language"
      onChange={handleSelect}
      popupMatchSelectWidth={false}
      placement={client === "widget" ? "bottomRight" : "topRight"}
    />
  );
}

const useStyledPortalLanguageButton = createUseStyle<{ client: ClientContext }>(({ theme, client }) =>
  css`
    .${theme.prefixCls}-select-selector {
      align-items: center;
      &::before {
        font-family: "Font Awesome 5 Pro";
        content: "\\f1ab";
        margin-right: 6px;
      }
      .${theme.prefixCls}-select-selection-search {
        margin-left: 24px;
      }
      .${theme.prefixCls}-select-selection-item {
        padding-inline: 0 !important;
      }
    }
  `(),
);
