/* istanbul ignore file */
import config from "config";
import React, { ReactNode, useContext } from "react";

export type ClientContext = "widget" | "portal";

export const client = config.CLIENT as ClientContext;

const clientContext = React.createContext<ClientContext>(client);

export function ClientProvider({ value = client, children }: { value?: ClientContext; children: ReactNode }) {
  return <clientContext.Provider value={value}>{children}</clientContext.Provider>;
}

export function useClientIntegration() {
  return useContext(clientContext);
}
