import { CountryCode, SupportedLocales } from "@trolley/common-frontend";
import { Container, Divider, Notification } from "components";
import { useIntl } from "utils/context";
import React from "react";
import { Recipient } from "store/actions/recipient";

interface Props {
  recipient: Recipient;
}
export default function BrazilWarnings({ recipient }: Props) {
  // show if no primary or primary is not paypal
  const showBrazilWarning =
    recipient.address.country === CountryCode.BR && !recipient.accounts.some((a) => a.type === "paypal" && a.primary);

  const { locale } = useIntl();

  return showBrazilWarning ? (
    <Container>
      <Divider transparent />
      <Notification
        closable
        type="warning"
        title={
          [SupportedLocales.PT, SupportedLocales.PT_BR].includes(locale as SupportedLocales)
            ? "Devido aos requisitos do governo brasileiro quanto a pagamentos internacionais, será necessário que você faça um cadastro com nosso provedor bancário brasileiro antes de receber seus pagamentos em BRL. Você receberá uma ligação ou um e-mail do nosso agente para completar o seu registo. Por favor, certifique-se de que o número de telefone e o e-mail fornecidos estão corretos a fim de evitar atrasos no envio do seu pagamento."
            : "Due to Brazilian government requirements related to international payments, you will be required to complete a one-time registration with our Brazilian banking provider prior to receiving your BRL payments. You will normally receive a phone call or email from our partner to complete the registration process. Please ensure the phone number and email you provided is accurate so they can reach you, to avoid delays in sending your payment to you."
        }
      />
    </Container>
  ) : null;
}

// uk trans: Через вимоги уряду Бразилії, пов’язані з міжнародними платежами, вам, ймовірно, доведеться зареєструватися у нашого бразильського банківського провайдера, перш ніж отримувати платежі на певну суму. Як правило, ви отримаєте телефонний дзвінок або електронне повідомлення від нашого партнера для завершення процесу реєстрації. Будь ласка, переконайтесь, що номер телефону та електронна пошта, які ви надали, точні, щоб вони могли зв’язатися з вами, щоб уникнути затримок з надсиланням вам платежу.
