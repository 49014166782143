import { Divider, GreyBox, Icon, Paragraph, Text } from "components";
import React from "react";

/**
 * Accessibility role term /defition
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/definition_role
 */

interface Props {
  term: string;
  definition: React.ReactNode;
}
export default function HelpText({ term: linkText, definition: mainText }: Props) {
  return (
    <Paragraph>
      <Text role="term" style={{ textDecoration: `underline dashed` }} inline>
        {linkText}
      </Text>
      <Icon type="circle-question" color="grey" theme="solid" right />

      <Divider transparent size="small" />

      <GreyBox role="definition" border={false}>
        {mainText}
      </GreyBox>
    </Paragraph>
  );
}
