import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadWithholdingTable } from "store/actions/withholdingTable";

const emptyObject = {};

export function useWithholdingTable() {
  useEffect(() => {
    loadWithholdingTable();
  }, []);

  return useShallowSelector((state) => {
    if (state.withholdingTable.fetchStatus.data === undefined) {
      loadWithholdingTable();
    }

    return {
      data: state.withholdingTable.entities.data || emptyObject,
      status: state.withholdingTable.fetchStatus.data,
      error: state.withholdingTable.errors.data,
    };
  });
}
