import { RecipientType, VerificationRejectedReasons } from "@trolley/common-frontend";
import { Divider, Notification, Typography } from "components";
import React from "react";
import { useIntl } from "utils/context";
import IdVerificationButton from "./IdVerificationButton";

interface Props {
  rejectedReason: VerificationRejectedReasons;
  reverificationAllowed: boolean;
  recipientType: RecipientType;
}

export default function RejectedNotification({ rejectedReason, reverificationAllowed, recipientType }: Props) {
  const { formatMessage } = useIntl();
  const Descriptions: { [key in VerificationRejectedReasons]: string | null } = {
    [VerificationRejectedReasons.FRAUDULENT]: formatMessage({
      id: `containers.trust.rejectedNotification.${recipientType}.fraudulent`,
    }),
    [VerificationRejectedReasons.INVALID]: formatMessage({
      id: `containers.trust.rejectedNotification.${recipientType}.invalid`,
    }),
    [VerificationRejectedReasons.MISSING_REQUIREMENTS]: formatMessage({
      id: `containers.trust.rejectedNotification.${recipientType}.missing_requirements`,
    }),
    [VerificationRejectedReasons.OTHER]: null,
    [VerificationRejectedReasons.UNPROCESSABLE]: formatMessage({
      id: `containers.trust.rejectedNotification.${recipientType}.unprocessable`,
    }),
  };

  if (!reverificationAllowed) return null;

  const description = Descriptions[rejectedReason];

  if (!description) return null;

  return (
    <Notification
      closable
      title={formatMessage({
        id: "common.important",
      })}
      type="warning"
      iconProps={{ type: "exclamation-triangle", theme: "solid" }}
      size="default"
    >
      <>
        <Typography.Title level={2}>
          {formatMessage({
            id: "containers.trust.rejectedNotification.title",
          })}
        </Typography.Title>
        <Typography.Paragraph>{description}</Typography.Paragraph>
        <Divider transparent />
        <IdVerificationButton reVerification linkType />
      </>
    </Notification>
  );
}
