import { allMappedCountries } from "@trolley/common-frontend";
import { FormatMessage, useIntl } from "utils/context";
import { NamePath, RuleRender } from "rc-field-form/lib/interface";
import React from "react";
import Select, { SelectProps } from "./Select";

interface Props extends Omit<SelectProps, "options"> {
  country: string | undefined;
  showEmptyOption?: boolean;
}

export function isRegionInCountryValidator(field: NamePath, formatMessage: FormatMessage): RuleRender {
  return ({ getFieldValue }) => ({
    async validator(rule, region) {
      // Changing country does not clear region now.
      // region cannot be cleared.
      // Make sure the select region is a region of the country.
      const country = getFieldValue(field);
      if (
        region &&
        country &&
        !allMappedCountries[country]?.regions.some((r) => r.shortCode === region || r.name === region)
      ) {
        throw new Error(formatMessage({ id: "common.invalidValue" }));
      }
    },
  });
}

export default function SelectRegion(props: Props) {
  const { country, showEmptyOption = true, ...rest } = props;
  const intl = useIntl();

  const regions = country
    ? allMappedCountries[country]?.regions.map((region) => ({
        label: region.name,
        value: region.shortCode || region.name,
      }))
    : [];

  return (
    <Select
      {...rest}
      placeholder={intl.formatMessage({ id: "components.formRegion.select" })}
      allowClear={showEmptyOption}
      options={regions}
    />
  );
}
