import { produce } from "immer";

import { NOTIFICATION_ADD, NOTIFICATION_RESET } from "store/actions/notifications";

type Notification = {
  type: "success" | "warning" | "error" | "info";
  message: string;
};

export type NotificationState = Notification[];
type NotificationAction = {
  type: string;
  data: Notification;
};

const initialState: Notification[] = [];

export default function notifications(state: Notification[] = initialState, action: NotificationAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case NOTIFICATION_ADD:
        draft.push(action.data);
        break;
      case NOTIFICATION_RESET:
        draft.splice(0, draft.length);
        break;
    }
  });
}
