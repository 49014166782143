import { stringifyQuery } from "utils/helpers";
import requestCore from "services/request/requestCore";

export interface RequestOptions {
  method?: "GET" | "POST" | "DELETE" | "PUT" | "PATCH" | "HEAD";
  query?: { [key: string]: any } | any;
  body?: any;
  deleteBody?: { [key: string]: any };
  headers?: Headers;
  asMerchantId?: string | number;
  blob?: boolean;
  isUpload?: boolean;
  noToken?: boolean;
}

export interface Response<T> {
  status: number;
  body: T;
  blob?: Blob;
}

export async function GET<T>(url: string, options: RequestOptions = {}): Promise<Response<T>> {
  const req: RequestOptions = {
    method: "GET",
    ...options,
  };

  return requestCore<T>(`${url}${stringifyQuery(options.query)}`, req);
}

export async function POST<T>(url: string, options: RequestOptions = {}): Promise<Response<T>> {
  const req: RequestOptions = {
    method: "POST",
    body: options.query ? JSON.stringify(options.query) : undefined,
    ...options,
  };

  return requestCore<T>(url, req);
}

export async function PATCH<T>(url: string, options: RequestOptions = {}): Promise<Response<T>> {
  const req: RequestOptions = {
    method: "PATCH",
    body: options.query ? JSON.stringify(options.query) : undefined,
    ...options,
  };

  return requestCore<T>(url, req);
}

export async function DELETE<T>(url: string, options: RequestOptions = {}): Promise<Response<T>> {
  const req: RequestOptions = {
    method: "DELETE",
    body: options.deleteBody ? JSON.stringify(options.deleteBody) : undefined,
    ...options,
  };

  return requestCore<T>(`${url}${stringifyQuery(options.query)}`, req);
}
