import { FormInstance } from "antd";
import { Container, Divider, Form2, Space, Typography } from "components";
import React from "react";
import { Link } from "react-router-dom";
import { TaxProfileWizard } from ".";
import CompletionNotification from "./CompletionNotification";
import { useIntl } from "utils/context";

interface Props {
  taxProfileWizard: TaxProfileWizard;
  onSubmitTaxProfile: (name: string, exit?: boolean) => void;
}

interface IFormData {
  name: string;
}

export default function OutOfScope({ taxProfileWizard, onSubmitTaxProfile }: Props) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    name: "",
  };

  function onSubmit({ name }: IFormData) {
    onSubmitTaxProfile(name, true);
  }

  return (
    <Container padding="none">
      <CompletionNotification>
        {formatMessage({ id: "containers.taxProfile.wizard.outOfScopeTitle" })}
      </CompletionNotification>
      <Divider size="large" transparent />
      {false && (
        <>
          {" "}
          <Space>
            <Typography.Text strong>Next Step: </Typography.Text>
            <Typography.Text strong>
              <Link to="" style={{ textDecoration: "underline" }}>
                complete tax profile
              </Link>
            </Typography.Text>
          </Space>
          <Divider transparent />
          <Typography.Paragraph>Please complete the feedback (takes 30 seconds).</Typography.Paragraph>
          <Divider size="large" transparent />
        </>
      )}{" "}
      <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
        <fieldset>
          <legend style={{ border: 0 }}>
            <Typography.Text type="label" size="base">
              {formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByFullName" })}
            </Typography.Text>
          </legend>
          <Form2.Item
            name="name"
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByRequiredMessage" }),
              },
            ]}
          >
            <Form2.Input type="text" />
          </Form2.Item>
        </fieldset>
        <Divider transparent />
        <Form2.Item dependencies={["name"]}>
          {(form: FormInstance<IFormData>) => {
            const name = form.getFieldValue("name");

            return (
              <Form2.SubmitButton
                disabled={!name}
                text={formatMessage({ id: "containers.taxProfile.wizard.taxProfileExit" })}
              />
            );
          }}
        </Form2.Item>
      </Form2>
    </Container>
  );
}
