import { OrganizationType, TaxW8LimitationTypes } from "@trolley/common-frontend";
import { Translator } from "utils/context";

export function getOrganizationTypes(
  translator: Translator,
): { label: string; value: "" | OrganizationType; disabled?: boolean }[] {
  return [
    {
      label: translator({ id: "containers.tax.w8.selectType" }),
      value: "",
      disabled: true,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.CORPORATION}`,
      }),
      value: OrganizationType.CORPORATION,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.PARTNERSHIP}`,
      }),
      value: OrganizationType.PARTNERSHIP,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.CENTRAL_BANK}`,
      }),
      value: OrganizationType.CENTRAL_BANK,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.COMPLEX_TRUST}`,
      }),
      value: OrganizationType.COMPLEX_TRUST,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.DISREGARDED_ENTITY}`,
      }),
      value: OrganizationType.DISREGARDED_ENTITY,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.ESTATE}`,
      }),
      value: OrganizationType.ESTATE,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.GOVERNMENT}`,
      }),
      value: OrganizationType.GOVERNMENT,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.GRANTOR_TRUST}`,
      }),
      value: OrganizationType.GRANTOR_TRUST,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.INTERNATIONAL_ORG}`,
      }),
      value: OrganizationType.INTERNATIONAL_ORG,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.PRIVATE_FOUNDATION}`,
      }),
      value: OrganizationType.PRIVATE_FOUNDATION,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.SIMPLE_TRUST}`,
      }),
      value: OrganizationType.SIMPLE_TRUST,
    },
    {
      label: translator({
        id: `containers.tax.w8.organizationTypes.${OrganizationType.TAX_EXEMPT_ORGANIZATION}`,
      }),
      value: OrganizationType.TAX_EXEMPT_ORGANIZATION,
    },
  ];
}

export function getLimitationTypes(
  translator: Translator,
): { label: string; value: "" | TaxW8LimitationTypes; disabled?: boolean }[] {
  return [
    {
      label: translator({ id: "containers.tax.w8.selectType" }),
      value: "",
      disabled: true,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.GOVERNMENT}`,
      }),
      value: TaxW8LimitationTypes.GOVERNMENT,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.TAX_EXEMPT_PENSION}`,
      }),
      value: TaxW8LimitationTypes.TAX_EXEMPT_PENSION,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.TAX_EXEMPT_ORGANIZATION}`,
      }),
      value: TaxW8LimitationTypes.TAX_EXEMPT_ORGANIZATION,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.PUBLICLY_TRADED_CORP}`,
      }),
      value: TaxW8LimitationTypes.PUBLICLY_TRADED_CORP,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.SUBSIDIARY_PUBLICLY_TRADED_CORP}`,
      }),
      value: TaxW8LimitationTypes.SUBSIDIARY_PUBLICLY_TRADED_CORP,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.MEETS_EROSION_TEST}`,
      }),
      value: TaxW8LimitationTypes.MEETS_EROSION_TEST,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.MEETS_BENEFITS_TEST}`,
      }),
      value: TaxW8LimitationTypes.MEETS_BENEFITS_TEST,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.MEETS_TRADE_TEST}`,
      }),
      value: TaxW8LimitationTypes.MEETS_TRADE_TEST,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.FAVORABLE_DISCRETIONARY}`,
      }),
      value: TaxW8LimitationTypes.FAVORABLE_DISCRETIONARY,
    },
    {
      label: translator({
        id: `containers.tax.w8.limitationTypes.${TaxW8LimitationTypes.OTHER}`,
      }),
      value: TaxW8LimitationTypes.OTHER,
    },
  ];
}
