import * as ACTIONS from "store/actions";
import { Store } from "store";

export function auth(state: Store["auth"] = {}, action: any) {
  switch (action && action.type) {
    case ACTIONS.REQUEST_AUTH:
      return { fetching: true };

    case ACTIONS.RECEIVE_AUTH:
      return action.data;

    case ACTIONS.ERROR_AUTH:
      return { errors: action.errors };

    default:
      return state;
  }
}
