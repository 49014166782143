import { IconName } from "@fortawesome/fontawesome-common-types";
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import debounce from "lodash.debounce";
import React, { MouseEvent, ReactNode, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import Icon, { IconProps } from "components/Icon";
import { classnames, createUseStyle, css } from "style/classname";
import { getContrastText } from "utils/helpers";

AntButton.displayName = "AntButton";

export interface BaseButtonProps extends Omit<AntButtonProps, "type" | "danger" | "children" | "color"> {
  type?: AntButtonProps["type"] | "success" | "danger";
  placement?: "right" | "center" | "left";
  icon?: IconName;
  iconProps?: Omit<IconProps, "type" | "left" | "right">;
}

export type ButtonProps = BaseButtonProps &
  (
    | { children: ReactNode }
    | {
        children?: never;
        "aria-label": string; // Accessibility when there's no children.
      }
  );

export default function Button(props: ButtonProps) {
  const { type, icon, shape, iconProps, loading, className, placement, onClick, children, htmlType, ...rest } = props;
  const styledButton = useStyledButton(props);
  const [debouncing, setDebouncing] = useState(false);
  const [delayedDebouncing] = useDebounce(debouncing, 1); // this is needed because button type "button" will not allow forms to be submited

  const { onDebounceClick, setDebouncingFalse } = useMemo(
    () => ({
      onDebounceClick: debounce(
        (e: MouseEvent<any, globalThis.MouseEvent>) => {
          onClick?.(e);
          setDebouncing(true);
        },
        400,
        { leading: true, trailing: false },
      ),
      setDebouncingFalse: debounce(() => {
        setDebouncing(false);
      }, 400),
    }),
    [onClick, setDebouncing],
  );

  const buttonProps: AntButtonProps = {
    ...rest,
    type: type === "success" ? "primary" : type !== "danger" ? type : "default",
    danger: type === "danger",
    shape: shape ?? (!children && icon ? "circle" : undefined),
    icon: icon ? <Icon type={icon} {...iconProps} /> : undefined,
    className: classnames(styledButton, className),
    onClick:
      loading || rest.disabled
        ? undefined
        : (e) => {
            onDebounceClick(e);
            setDebouncingFalse();
          },
    children: children ? <span className="content">{children}</span> : undefined,
    htmlType:
      "href" in rest
        ? undefined
        : delayedDebouncing || loading || rest.disabled
        ? "button" // this prevents forms from being submitted multiple times on doubleclck
        : htmlType,
    "aria-disabled": !!rest.disabled, // accessibility issue
  };

  return <AntButton {...buttonProps} />;
}

export const useStyledButton = createUseStyle<ButtonProps>(({ theme, animation, ...props }) =>
  css`
    &.${theme.prefixCls}-btn {
      position: relative;
      .content {
        visibility: ${props.loading ? "hidden" : "visible"};
      }
      transition-timing-function: ease-in-out;
      transition-duration: 0.125s;
      transition-property: color, border-color, background-color;
      display: ${props.block || props.placement ? "block" : "inline-block"};
      ${props.href &&
      props.placement &&
      `width: fit-content;`} /* Need this for <a> to be full and margin-left/right works */
      margin-left: ${props.placement && ["right", "center"].includes(props.placement) && "auto"};
      margin-right: ${props.placement && ["left", "center"].includes(props.placement) && "auto"};
      touch-action: manipulation;
      user-select: none;
      font-weight: 500;
      letter-spacing: ${props.type === "link" ? 0 : "0.5px"};
      ${props.shape !== "circle" &&
      props.type !== "link" &&
      props.size === "large" &&
      `
        min-width: 70px;
    `}
      ${props.type === "link" &&
      `
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        height: auto;
    `}
    &::after {
        transition-duration: 0s;
      }
      ${() =>
        props.loading &&
        `
        & > * {
          visibility: hidden;
        }
        &::after {
          font-family: "Font Awesome 5 Pro";
          content: "\\f3f4";
          animation: spinner 1s linear infinite normal forwards;
          font-size: 1.25em;
          position: absolute;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          pointer-events: none;
          opacity: 1;
        }
      `}
      &:not(:disabled) {
        ${props.type === "primary" &&
        `
        &.${theme.prefixCls}-btn-primary {
          color: ${getContrastText(theme.colorPrimary)};
        }
      `}
        ${props.type === "success" &&
        `
        &.${theme.prefixCls}-btn-primary {
          color: ${getContrastText(theme.colorSuccess)};
          background-color: ${theme.colorSuccess};
          &:hover {
            background-color: ${theme.colorSuccessHover};
          }
          &:active {
            background-color: ${theme.colorSuccessActive};
          }
        }
      `}
      ${props.type === "danger" &&
        `
        &.${theme.prefixCls}-btn-dangerous {
          &:hover {
            color: ${getContrastText(theme.colorError)};
            background-color: ${theme.colorError};
          }
          &:active {
            background-color: ${theme.colorErrorActive};
            color: ${getContrastText(theme.colorError)};
          }
        }
      `}
      }
    }
    @keyframes spinner {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  `(),
);
