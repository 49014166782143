import "@trolley/common-frontend/lib/icons/css/all.css";
import { IconName } from "@trolley/common-frontend/lib/icons/types";
import Tooltip from "components/Tooltip";
import css, { classnames, createUseStyle } from "style/classname";

import React, { forwardRef, HTMLAttributes, ReactNode } from "react";
import { omit } from "utils/helpers";

export { IconName };

const SOLID_THEME_ICONS = [
  "check-circle",
  "info-circle",
  "exclamation-circle",
  "exclamation-triangle",
  "question-circle",
  "times-circle",
];

const useStyledIcon = createUseStyle<Omit<IconProps, "theme">>(({ theme, ...props }) =>
  css`
    &.icon {
      margin-left: ${props.right ? "4px" : ""};
      margin-right: ${props.left ? "4px" : ""};
      color: ${() => {
        switch (props.color) {
          case "grey":
            return theme.colorTextTertiary;
          case "text":
            return theme.colorText;
          case "background":
            return theme.colorBgBase;
          case "primary":
            return theme.colorPrimary;
          case "success":
            return theme.colorSuccess;
          case "warning":
            return theme.colorWarning;
          case "info":
            return theme.colorInfo;
          case "error":
            return theme.colorError;
          default:
            return "inherit";
        }
      }};
    }
  `(),
);

export interface IconProps
  extends Omit<
    HTMLAttributes<HTMLSpanElement>,
    "role" | "onClick" // icon should not have onClick. Please wrap it around a Button
  > {
  type: IconName;
  theme?: "solid" | "regular" | "light" | "brands" | "duotone";
  size?: "xsmall" | "small" | "large" | "xlarge" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
  fixedWidth?: boolean;
  left?: boolean;
  right?: boolean;
  spin?: boolean;
  transform?: string;
  color?: "primary" | "text" | "background" | "grey" | "success" | "error" | "warning" | "info";
  /**
   * @deprecated:
   * for accessibility reason, we should not be using tooltips anymore
   */
  tooltip?: ReactNode;
  style?: React.CSSProperties;
}

export default forwardRef<HTMLSpanElement, IconProps>(function Icon(props, ref) {
  const {
    className,
    tooltip,
    type,
    theme: customTheme,
    size,
    fixedWidth = true,
    left,
    right,
    spin,
    color,
    ...rest
  } = props;
  const theme = customTheme ?? (SOLID_THEME_ICONS.includes(type) ? "solid" : "regular");
  const styledIcon = useStyledIcon(omit(props, ["theme"]));
  const icon = (
    <i
      {...rest}
      ref={ref}
      className={classnames("icon", styledIcon, className, `fa${String(theme).charAt(0)}`, `fa-${type}`, {
        [`fa-${size}`]: size && /x$/.test(size),
        "fa-xs": size === "xsmall",
        "fa-sm": size === "small",
        "fa-lg": size === "large",
        "fa-2x": size === "xlarge",
        "fa-fw": fixedWidth,
        "fa-spin": spin,
      })}
      aria-hidden // hide it for screen readers.
    />
  );

  return tooltip ? <Tooltip title={tooltip}>{icon}</Tooltip> : icon;
});
