import { GreyBox, Modal } from "components";
import { useIntl } from "utils/context";
import { PATHS } from "pages/App/routes";
import React from "react";
import { useHistory } from "react-router-dom";
import { TaxFormType } from "@trolley/common-frontend";

interface Props {
  onClose(): void;
  visible?: boolean;
}

export default function CompleteW9Popup({ visible, onClose }: Props) {
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={!!visible}
      onCancel={onClose}
      onOk={() => {
        history.replace(PATHS.US_TAX_NEW, { formType: TaxFormType.W9 });
      }}
      okText={formatMessage({ id: "containers.tax.w8.completeW9" })}
      title={formatMessage({ id: "containers.tax.w8.pleaseCompleteW9" })}
    >
      <GreyBox>
        <p>{formatMessage({ id: "containers.tax.w8.w9BasedOnResponse" })}</p>
        {formatMessage({ id: "containers.tax.w8.pressCancel" })}
      </GreyBox>
    </Modal>
  );
}
