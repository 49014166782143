import { Space as AntSpace, SpaceProps as Props } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React, { CSSProperties } from "react";
import { classnames, createUseStyle, css } from "style/classname";

const SIZES = {
  none: 0,
  xsmall: 2,
};
export interface SpaceProps extends Omit<Props, "direction" | "size" | "align"> {
  size?: SizeType | "xsmall" | "none";
  direction?: CSSProperties["flexDirection"];
  justify?: CSSProperties["justifyContent"];
  align?: Props["align"] | "stretch";
  inline?: boolean;
  compact?: boolean;
}

export default function Space(props: SpaceProps) {
  const {
    className,
    direction,
    justify,
    inline,
    align = "center",
    size = "small",
    wrap = true,
    compact,
    ...rest
  } = props;
  const styledSpace = useStyledSpace({ ...props, align, size, wrap });

  return !!compact ? (
    <AntSpace.Compact
      block
      className={classnames("space", styledSpace, className)}
      size={(size && SIZES[size]) ?? size}
      {...rest}
    />
  ) : (
    <AntSpace
      className={classnames("space", styledSpace, className)}
      size={(size && SIZES[size]) ?? size}
      wrap={wrap}
      {...rest}
    />
  );
}

const useStyledSpace = createUseStyle<SpaceProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-space {
      display: ${props.inline ? "inline-flex" : "flex"};
      ${props.justify && `justify-content: ${props.justify};`}
      ${props.align && `align-items: ${props.align};`}
      ${props.direction && `flex-direction: ${props.direction};`}
      ${props.direction && props.direction.includes("column") && `min-height: 100%;`};
    }
  `(),
);
