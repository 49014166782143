import React from "react";
import InputMask, { Props } from "react-input-mask";
import css, { classnames, createUseStyle } from "style/classname";
import Input, { InputProps } from "./Input";

export interface InputMaskProps extends InputProps, Omit<Props, "prefix" | "type"> {}

export default function FormMask(props: InputMaskProps) {
  const {
    mask,
    maskChar,
    formatChars = {
      // api returns masked values. we need to show them
      "9": "[0-9*]",
      a: "[A-Za-z*]",
      "*": "[A-Za-z0-9*]",
    },
    alwaysShowMask,
    inputRef,
    beforeMaskedValueChange,
    value,
    onChange,
    disabled,
    readOnly,
    className,
    ...inputProps
  } = props;
  const styledInputMask = useStyledInputMask(props);

  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      formatChars={formatChars}
      alwaysShowMask={alwaysShowMask}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      inputRef={inputRef}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
    >
      {(maskProps: any) => {
        return (
          <Input
            {...maskProps}
            {...inputProps}
            readOnly={readOnly}
            disabled={disabled}
            className={classnames("input-mask", styledInputMask, className)}
          />
        );
      }}
    </InputMask>
  );
}

const useStyledInputMask = createUseStyle<InputMaskProps>(({ theme, ...props }) =>
  css`
    font-family: monospace;
    letter-spacing: ${props.value ? 2 : 0}px;
  `(),
);
