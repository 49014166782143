import Form from "components/Form";
import React from "react";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export interface StandardInputProps extends InputPopupProps {
  name: string;
  label: string;
  value?: string;
}

export default function StandardInput(props: StandardInputProps) {
  const { submitted, name, label, value } = props;
  const recipient = useRecipient();

  async function onSubmit(value: string) {
    if (recipient) {
      props.onSubmit?.(value);
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  return (
    <LockedInput
      name={name}
      defaultValue={value}
      onSave={onSubmit}
      FieldComponent={Form.Input}
      label={label}
      submitted={submitted}
    />
  );
}
