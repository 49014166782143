import React from "react";
import { getRegionLabel, CountryCode } from "@trolley/common-frontend";
import { TitleBar, GreyBox, Loader, Text, LinkButton, Container } from "components";
import { PATHS } from "pages/App/routes";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { stringifyAddress } from "utils/helpers";
import { useUrlParams } from "store/hooks/params";

export default function Profile() {
  const recipient = useRecipient();
  const recipientCountry = (recipient?.address.country as CountryCode) || "";
  const addressAvailable = !!recipient?.address && !!recipientCountry;
  const { hideEmail } = useUrlParams();

  const { formatMessage } = useIntl();

  if (!recipient) {
    return <Loader />;
  }

  return (
    <Container>
      <GreyBox type="aside">
        <TitleBar
          level={2}
          align="center"
          extraActions={
            <LinkButton
              to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}
              icon={addressAvailable ? "pencil" : "plus"}
              size="middle"
            >
              {formatMessage({ id: addressAvailable ? "common.edit" : "containers.info.generalInfoAdd" })}
            </LinkButton>
          }
        >
          {formatMessage({ id: "containers.info.title" })}
        </TitleBar>
        {addressAvailable && (
          <>
            <Text type="secondary">
              {recipient.type === "business" && `${recipient.name}, `}
              {!!(recipient.firstName && recipient.lastName) && `${recipient.firstName} ${recipient.lastName}`}
              {!hideEmail && ` (${recipient.email})`}
            </Text>
            <Text type="secondary">
              <strong>
                {formatMessage({ id: "containers.bankPayoutMethod.address" })}
                :&nbsp;
              </strong>
              {stringifyAddress({
                ...recipient.address,
                region: getRegionLabel(recipient.address.region, recipientCountry),
                country: formatMessage({ id: `countries.${recipientCountry}` }), // since we have common-frontend keys locally... seems simpler
              })}
            </Text>
          </>
        )}
      </GreyBox>
    </Container>
  );
}
