import React from "react";
import InputMask, { Props as ReactInputMaskProps } from "react-input-mask";
import css, { classnames } from "style/classname";
import Input, { InputProps } from "./Input";

export interface InputMaskProps
  extends Omit<InputProps, "value">,
    Omit<ReactInputMaskProps, "type" | "size" | "prefix"> {}

export default function FormMask(props: InputMaskProps) {
  const {
    className,
    prefix,
    size,
    formatChars = {
      // api returns masked values. we need to show them
      "9": "[0-9*]",
      a: "[A-Za-z*]",
      "*": "[A-Za-z0-9*]",
    },
    ...rest
  } = props;

  return (
    <InputMask formatChars={formatChars} {...rest}>
      {({ disabled, ...inputProps }: any) => (
        <Input
          size={size}
          prefix={prefix}
          {...inputProps}
          disabled={disabled}
          className={classnames(styledInputMask, className)}
        />
      )}
    </InputMask>
  );
}

const styledInputMask = css`
  font-family: monospace;
`();
