import React, { ReactNode } from "react";

import { Country } from "@trolley/common-frontend";
import { Loader, Notification } from "components";
import { useCurrencies } from "store/hooks/currencies";
import { BaseStatus, Mapped } from "store/reducers/standardReducer";
import { useIntl } from "utils/context";

type AllProps = {
  children: (data: Mapped<Country.CountryCurrency[]>) => ReactNode;
};

export default function BankTransferWrapper({ children }: AllProps) {
  const { data: currencies, status: currenciesStatus, error } = useCurrencies();
  const { formatMessage } = useIntl();

  return (
    <Loader spinning={currenciesStatus === BaseStatus.LOADING}>
      {currenciesStatus === BaseStatus.LOADED && children(currencies)}
      {currenciesStatus === BaseStatus.ERROR && (
        <Notification
          size="large"
          type="error"
          title={formatMessage({ id: "containers.mainContainer.somethingWentWrong" })}
        >
          {(typeof error === "string" ? error : (error as any)?.message || error?.find?.((e) => e.message)?.message) ||
            formatMessage({ id: "containers.mainContainer.contactAdmin" })}
        </Notification>
      )}
    </Loader>
  );
}
