import React, { HTMLAttributes } from "react";
import { classnames, createUseStyle, css } from "style/classname";

export interface ValueDisplayProps extends HTMLAttributes<HTMLDListElement> {
  items: (HTMLAttributes<HTMLDivElement> & { label: string; "data-testid"?: string })[];
}

export default function DescriptionList({ items, className, ...dlProps }: ValueDisplayProps) {
  const styledList = useStyledList();

  return (
    <dl {...dlProps} className={classnames(styledList, className)}>
      {items.map(({ label, children, ...restDiv }) => (
        <div key={label} {...restDiv}>
          <dt>{label}</dt>
          <dd>{children}</dd>
        </div>
      ))}
    </dl>
  );
}

const useStyledList = createUseStyle(({ theme }) =>
  css`
    & > div:not(:first-child) {
      margin-top: ${theme.margin}px;
    }
    dt {
      color: ${theme.colorTextLabel};
    }
  `(),
);
