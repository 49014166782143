import { TaxFormType } from "@trolley/common-frontend";
import dayjs from "dayjs";
import React, { useState } from "react";
import Helmet from "react-helmet";

import { Container, Divider, Form, Grid, Paragraph, Text, Title } from "components";
import { useForm } from "components/Form";
import SignatureBox from "pages/TaxSection/SignatureBox";
import TaxFooter from "pages/TaxSection/TaxFooter";
import { getCertificationTrueRequired, getCertificationValueRequired, getIrsRule } from "pages/TaxSection/variables";
import { TaxDataW8BENE, createTaxForm, updateTaxForm } from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues } from "utils/helpers";
import CompleteW9Popup from "../CompleteW9Popup";

interface Props {
  taxId: string | undefined;
  taxData: TaxDataW8BENE | undefined;
  loading?: boolean;
  pageIndex: number;
  pageTotal: number;
  onComplete(id: string): void;
}

export const FORM_VERSION = "Oct2021";

export default function NonUsCertification(props: Props) {
  const [form] = useForm();
  const { formatMessage } = useIntl();
  const { taxId, taxData = {}, loading, pageIndex, pageTotal } = props;
  const merchant = useMerchant();
  const [showWrongForm, setShowWrongForm] = useState(false);

  async function onFinish(values: Partial<TaxDataW8BENE>) {
    if (taxId && !form.isFieldsTouched()) {
      props.onComplete(taxId);
    } else if (!values.isNotUsPerson && !showWrongForm) {
      setShowWrongForm(true);
    } else {
      try {
        const formattedData = {
          kind: TaxFormType.W8BENE,
          formVersion: FORM_VERSION,
          data: omitMaskedValues(values),
        };
        const tf = taxId ? await updateTaxForm(taxId, formattedData) : await createTaxForm(formattedData);
        props.onComplete(tf.id);
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Form form={form} initialValues={taxData} onFinish={onFinish}>
      <Container>
        <Helmet>
          <title>
            {formatMessage({
              id: "containers.tax.w8.certificationNoUs",
            })}
          </title>
        </Helmet>
        <Title>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN-E" })}</Title>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({
                id: "containers.tax.w8.certificationNoUs",
              }),
              step: pageIndex + 1,
              totalSteps: pageTotal,
            },
          )}
        </Title>
        <Form.Field name="isNotUsPerson">
          <Form.Checkbox name="isNotUsPerson">
            {formatMessage({ id: "containers.tax.w8.isNotUsPerson.certify" })}
            <ul>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsPerson.citizenE",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsPerson.residentE",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsPerson.dualCitizenE",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "containers.tax.w8.isNotUsPerson.subjectE",
                })}
              </li>
            </ul>
          </Form.Checkbox>
        </Form.Field>

        {merchant?.features?.forceSelfCertificationNonUS ? (
          <Form.Field name="noUsCertification" rules={[getCertificationTrueRequired(formatMessage)]}>
            <Form.Checkbox name="noUsCertification">
              <Text strong>{formatMessage({ id: "containers.tax.w8.provideOutsideUsE" })}</Text>
              <Text type="secondary" size="small">
                {formatMessage({ id: "containers.tax.w8.provideOutsideUsSub" })}
              </Text>
            </Form.Checkbox>
          </Form.Field>
        ) : (
          <Form.Field
            name="noUsCertification"
            label={formatMessage({ id: "containers.tax.w8.noUsCertification" })}
            rules={[
              ({ getFieldValue }) =>
                getFieldValue("isNotUsPerson") ? getCertificationValueRequired(formatMessage) : { required: false },
            ]}
            normalize={(v: string | undefined) => v === "true"}
          >
            <Form.Radio
              options={[
                {
                  label: (
                    <>
                      {formatMessage({
                        id: "containers.tax.w8.provideOutsideUsE",
                      })}
                      <Text type="secondary" size="small" strong={false}>
                        {formatMessage({
                          id: "containers.tax.w8.provideOutsideUsSub",
                        })}
                      </Text>
                    </>
                  ),
                  value: "true",
                },
                {
                  label: (
                    <>
                      {formatMessage({
                        id: "containers.tax.w8.provideInsideUsE",
                      })}
                      <Text type="secondary" size="small" strong={false}>
                        {formatMessage({
                          id: "containers.tax.w8.provideInsideUsSub",
                        })}
                      </Text>
                    </>
                  ),
                  value: "false",
                },
              ]}
              name="noUsCertification"
            />
          </Form.Field>
        )}

        <Divider transparent size="xlarge" />
        <Paragraph>
          {formatMessage(
            { id: "containers.tax.w8.nameCertification" },
            {
              theCompany: merchant?.name || formatMessage({ id: "containers.tax.w8.theCompany" }),
            },
          )}
        </Paragraph>
      </Container>
      <SignatureBox>
        <Grid>
          <Grid.Item xs={24} sm={16}>
            <Form.Field
              name="noUsSignature"
              label={formatMessage({
                id: "containers.tax.signatureWithFullName",
              })}
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue("isNotUsPerson"),
                  message: formatMessage({
                    id: "containers.tax.signatureRequired",
                  }),
                }),
                getIrsRule(formatMessage),
              ]}
            >
              <Form.Input name="noUsSignature" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={8}>
            <Form.Field name="certificationDate" label="Date">
              {({ value }) => <Form.Input readOnly name="certificationDate" value={dayjs(value).format("LL")} />}
            </Form.Field>
          </Grid.Item>
        </Grid>
      </SignatureBox>
      <Container>
        <TaxFooter loading={loading} okText={formatMessage({ id: "containers.tax.w8.certify" })} />
      </Container>
      <CompleteW9Popup
        visible={showWrongForm}
        onClose={() => {
          setShowWrongForm(false);
        }}
      />
    </Form>
  );
}
