import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, ButtonProps } from "components";
import { LocationDescriptor } from "history";

type Props = ButtonProps & {
  to: LocationDescriptor;
};

export default function LinkButton(props: Props) {
  const { to, role = "link", ...rest } = props;
  const history = useHistory();

  return (
    <Link
      component={Button}
      role={role}
      {...rest}
      to={to}
      onClick={(e) => {
        e?.preventDefault?.();
        history.push(to);
      }}
    />
  );
}
