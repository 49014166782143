import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import common from "locale/en/common.json";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import React from "react";
import { useIntl } from "utils/context";

dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

type Props = {
  value: string | Date | null | undefined;
  date?: string;
  time?: string | false;
  showUTC?: boolean;
  emptyText?: string;
};

export default function DateDisplay({ value = "", date = "ll", time = "LT", showUTC, emptyText = " - " }: Props) {
  const { formatMessage } = useIntl();
  if (!value) {
    return <span>{emptyText}</span>;
  }

  const val = showUTC ? dayjs.utc(value) : dayjs(value);

  return (
    <span style={{ whiteSpace: "nowrap", marginRight: "6px" }}>
      {val.isValid() && new Date(value).toString() !== "Invalid Date" ? (
        <>
          {date && val.format(date)}
          {time && <span style={{ fontSize: "0.86em", color: "#888", marginLeft: "8px" }}>{val.format(time)}</span>}
        </>
      ) : (
        formatMessage({
          id: "common.invalidDate",
          defaultMessage: common.invalidDate,
        })
      )}
    </span>
  );
}
