import * as ACTIONS from "store/actions";
import { Store } from "store";

export function merchant(state: Store["merchant"], action: any) {
  switch (action && action.type) {
    case ACTIONS.RECEIVE_MERCHANT:
      return action.data;
    default:
      return state || null;
  }
}
