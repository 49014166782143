import { BankCodesData, CountryCode, CurrencyCode } from "@trolley/common-frontend";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { computeID, isLoaded } from "./actionUtils";

export type BankCodes = BankCodesData["rows"];

export interface BankCodesParameters {
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
}

export function loadBankCodes(requestBody: BankCodesParameters, force?: boolean) {
  const id = computeID(requestBody);
  const { bankCodes } = store.getState();

  if (force || !isLoaded(bankCodes.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "bankCodes", { id });

    POST<BankCodesData>(`/v1/bank/codes`, { query: { ...requestBody, pageSize: 1000 } })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "bankCodes", {
          id,
          data: body.rows,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "bankCodes", { id, errors });
      });
  }

  return id;
}
