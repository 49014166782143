import { BackButton, Button, Form, Grid, Tooltip } from "components";
import { PATHS } from "pages/App/routes";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "utils/context";
import { useHistory } from "react-router-dom";
import { RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { AccountVerification } from "store/actions/bankInfo";
import { AccVerifResultEnum } from "pages/PayoutMethod2/BankTransfer/AccountVerificationResultAlert";
import AccountVerificationSubmitModal from "pages/PayoutMethod2/BankTransfer/AccountVerificationSubmitModal";

interface Props {
  account: RecipientAccount | undefined;
  disabled?: boolean;
  disabledTooltip?: string;
  busy: boolean;
  setBusy: Dispatch<SetStateAction<boolean>>;
  onSave?(): void;
  onSaveActivate?(): void;
  accountVerificationResult?: AccountVerification;
  submitCall?: () => void;
}

export default function PayoutFooter({
  account,
  disabled,
  disabledTooltip,
  busy,
  setBusy,
  onSave,
  onSaveActivate,
  accountVerificationResult,
  submitCall,
}: Props) {
  const recipient = useRecipient();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [activate, setActivate] = useState(false);
  const submitLabel = formatMessage({
    id: account && account.id ? "common.save" : "common.add",
  });
  const showAddAndActivate = !account?.primary && recipient?.accounts.some((a) => !account || account.id !== a.id);

  async function onActivate() {
    if (recipient && account) {
      setBusy(true);
      try {
        await updatePayoutMethod(recipient.id, account.recipientAccountId, {
          primary: true,
        });
        history.push(PATHS.HOME);
      } catch (errors) {}
      setBusy(false);
    }
  }

  function onClickSave() {
    if (accountVerificationResult && accountVerificationResult?.reasonCode !== AccVerifResultEnum.AV100) {
      setOpenModal(true);
      setActivate(false);
    }
  }

  function onClickAddAndActivate() {
    if (accountVerificationResult && accountVerificationResult?.reasonCode !== AccVerifResultEnum.AV100) {
      setOpenModal(true);
      setActivate(true);
    }
  }

  const footer = (
    <>
      <Grid direction="row-reverse" padding="small" style={{ marginTop: "16px" }} justify="space-between">
        <Form.Control shouldUpdate>
          {(control, meta, { isFieldsTouched }) => {
            return (
              <>
                {showAddAndActivate && (
                  <Grid.Item>
                    {account && !isFieldsTouched() ? (
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await onActivate();
                        }}
                        size="large"
                        loading={busy}
                      >
                        {formatMessage({
                          id: "containers.accountSummary.buttons.makeActive",
                        })}
                      </Button>
                    ) : (
                      <Button
                        htmlType={
                          !accountVerificationResult ||
                          accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                            ? "submit"
                            : "button"
                        }
                        type="primary"
                        onClick={
                          !accountVerificationResult ||
                          accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                            ? onSaveActivate
                            : onClickAddAndActivate
                        }
                        size="large"
                        loading={busy}
                        disabled={disabled}
                      >
                        {submitLabel}{" "}
                        {formatMessage({
                          id: "containers.paypalPayoutMethod.andActivate",
                        })}
                      </Button>
                    )}
                  </Grid.Item>
                )}

                <Grid.Item>
                  <Button
                    size="large"
                    htmlType={
                      !accountVerificationResult || accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                        ? "submit"
                        : "button"
                    }
                    type={showAddAndActivate ? "default" : "primary"}
                    loading={busy}
                    onClick={
                      !accountVerificationResult || accountVerificationResult.reasonCode === AccVerifResultEnum.AV100
                        ? onSave
                        : onClickSave
                    }
                    disabled={disabled}
                  >
                    {submitLabel}
                  </Button>
                </Grid.Item>
              </>
            );
          }}
        </Form.Control>

        <Grid.Item flex="1">
          <BackButton />
        </Grid.Item>
      </Grid>
      <AccountVerificationSubmitModal
        result={accountVerificationResult}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onActivate={() => {
          if (activate && onSaveActivate) {
            onSaveActivate();
          }
          if (!activate && onSave) {
            onSave();
          }
        }}
        submitCall={submitCall}
      />
    </>
  );

  return disabled && disabledTooltip ? (
    <Tooltip placement="topRight" title={disabledTooltip} trigger={["hover"]}>
      {footer}
    </Tooltip>
  ) : (
    footer
  );
}
