import { useNewStyle } from "pages/App";
import React, { CSSProperties, ReactNode } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useWindowSize } from "utils/hooks";

type Size = "none" | "small" | "medium" | "large" | "xlarge";
const PADDING_MAP = {
  none: "none",
  small: "paddingXS",
  medium: "padding",
  large: "paddingLG",
  xlarge: "paddingXL",
};

export type Props = {
  padding?: Size | [Size, Size];
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

export default function Container({ children, style, className, padding }: Props) {
  const newStyle = useNewStyle();
  const { isMobile } = useWindowSize();
  const styledContainer = useStyledContainer({
    padding: padding ?? (!newStyle || isMobile ? "medium" : "xlarge"),
  });

  return (
    <section style={style} className={classnames("container", styledContainer, className)}>
      {children}
    </section>
  );
}

const useStyledContainer = createUseStyle<Props>(({ theme, animation, ...props }) =>
  css`
    animation: ${animation.fadeInDown} 0.375s ease;
    width: 100%;
    padding: ${props.padding
      ? typeof props.padding === "string"
        ? `${theme[PADDING_MAP[props.padding]] || 0}px`
        : props.padding.map((p) => `${theme[PADDING_MAP[p]] || 0}px`).join(" ")
      : ""};
  `(),
);
