import { Input as AntInput, InputProps as AntInputProps } from "antd";
import { LiteralUnion } from "antd/lib/_util/type";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import { Icon } from "components";
import FormItem from "components/Form2/Item";
import React, { ForwardRefExoticComponent, PropsWithoutRef, Ref, forwardRef, useContext } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";

export interface InputProps extends Omit<AntInputProps, "allowClear" | "type"> {
  /** for accessibility issue */
  type: LiteralUnion<
    // | "button"
    // | "checkbox"
    // | "color"
    // | "date"
    // | "datetime-local"
    | "email"
    // | "file"
    | "hidden"
    // | "image"
    // | "month"
    | "number"
    | "one-time-code"
    | "password"
    // | "radio"
    // | "range"
    // | "reset"
    // | "search"
    // | "submit"
    | "tel"
    | "text"
    // | "time"
    | "url"
    // | "week"
  >;
  allowClear?: boolean;
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ onFocus, allowClear, className, readOnly, disabled: customDisabled, onChange, ...rest }, ref: any) => {
    const { formatMessage } = useIntl();
    const { label } = FormItem.useFormItemInstance();
    const disabledContext = useContext(DisabledContext);
    const disabled = customDisabled ?? disabledContext;
    const styledInput = useStyledInput({ disabled, readOnly });

    return (
      <AntInput
        onFocus={
          onFocus ??
          ((e) => {
            if (!readOnly && !disabled) {
              e?.target?.select?.();
            }
          })
        }
        ref={ref}
        allowClear={
          disabled
            ? undefined
            : allowClear && {
                clearIcon: (
                  <Icon
                    type="circle-xmark"
                    tooltip={formatMessage({ id: "common.delete" })}
                    color="grey"
                    theme="solid"
                  />
                ),
              }
        }
        className={classnames(styledInput, className)}
        readOnly={readOnly || disabled}
        // disabled={disabled} // do not use this field for accessibility reason. "disabled" will skip over focus for keyboard TAB navigation
        aria-disabled={readOnly || disabled} // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-disabled
        aria-label={label} // Accessibility BUFIX until title is removed from label  https://github.com/ant-design/ant-design/issues/31766
        onChange={(e) => {
          if (!readOnly && !disabled) {
            onChange?.(e);
          }
        }}
        {...rest}
      />
    );
  },
) as ForwardRefExoticComponent<PropsWithoutRef<InputProps> & { ref?: Ref<HTMLInputElement> }> & {
  Group: typeof AntInput.Group;
  TextArea: typeof AntInput.TextArea;
  Search: typeof AntInput.Search;
  Password: typeof AntInput.Password;
};

Input.displayName = "Input";
Input.Group = AntInput.Group;
Input.TextArea = AntInput.TextArea;
Input.Search = AntInput.Search;
Input.Password = AntInput.Password;
export default Input;

const useStyledInput = createUseStyle<{ disabled: boolean | undefined; readOnly: boolean | undefined }>(
  ({ theme, disabled, readOnly }) =>
    css`
      ${readOnly &&
      !disabled &&
      `
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        border: none;
    `}
      ${disabled &&
      `
        &.${theme.prefixCls}-input-group-wrapper,
        .${theme.prefixCls}-input-group-wrapper,
        &.${theme.prefixCls}-input-affix-wrapper,
        .${theme.prefixCls}-input-affix-wrapper,
        &.${theme.prefixCls}-input {
          cursor: not-allowed;
          color: ${theme.colorTextDisabled};
          background-color: ${theme.colorBgContainerDisabled};
          border-color: ${theme.colorBorder} !important;
          * {
            color: ${theme.colorTextDisabled};
            background-color: transparent;
            border-color: transparent;
          }
        }
      `}


      // ant style bug fix
      border-radius: ${theme.borderRadius}px;
      & > input {
        //SOME style fixing if there's a border-radius, the inner border radius should still stay square
        border-radius: 0 !important;
      }
    `(),
);
