import React from "react";

import { Divider, Icon, Loader, Text } from "components";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { VerificationStatus, KybStatus, RecipientType } from "@trolley/common-frontend";
import IdVerificationButton from "./IdVerificationButton";

export { default as KybUpload } from "./KybUpload";

export default function FirstVerification() {
  const recipient = useRecipient();
  const merchant = useMerchant();

  const showIdV = !!merchant?.features?.idv && merchant?.trust?.requireIDV;

  const showKyb =
    !!merchant?.features?.dsa && merchant?.trust?.requireDSA && recipient?.type === RecipientType.BUSINESS;
  const profileIncompleteForKYB =
    showKyb &&
    (!recipient.brn ||
      !recipient.name ||
      !recipient.address.region ||
      !recipient.address.street1 ||
      !recipient.address.country);

  // TODO: Below logic will be problem if recipient type is changed after idv

  const isIndividualVerificationComplete =
    showIdV &&
    recipient?.idvStatus &&
    recipient?.idvStatus === VerificationStatus.SUBMITTED &&
    (!showKyb || recipient.type === RecipientType.INDIVIDUAL); // for trust standard, business recipients go through individual verification

  const isBusinessVerificationComplete =
    showKyb &&
    recipient.kybStatus &&
    recipient?.kybStatus === KybStatus.SUBMITTED &&
    recipient.type === RecipientType.BUSINESS;

  const verificationComplete = isIndividualVerificationComplete || isBusinessVerificationComplete;

  const { formatMessage } = useIntl();

  if (!recipient) {
    return <Loader />;
  }

  return (
    <>
      {(showIdV || showKyb) && (
        <div style={{ textAlign: "center", margin: "60px 0" }}>
          <Text padded>
            <Icon size="xlarge" theme="light" type="address-card" />
          </Text>
          {!verificationComplete ? (
            <>
              <Text strong padded>
                {formatMessage({
                  id: "containers.accountSummary.verification.1",
                })}
              </Text>
              <Text padded>
                {formatMessage({
                  id: profileIncompleteForKYB
                    ? "containers.accountSummary.verification.incompleteProfile"
                    : "containers.accountSummary.verification.2",
                })}
              </Text>
              <Divider transparent />
              <IdVerificationButton />
            </>
          ) : (
            <Text strong padded>
              {formatMessage({
                id: "containers.accountSummary.verification.done",
              })}
            </Text>
          )}
        </div>
      )}
    </>
  );
}
