import { CountryCode, formatCountry, getRegionLabel } from "@trolley/common-frontend";
import { default as checkCA } from "assets/images/CA_Check.png";
import { default as checkUS } from "assets/images/US_Check.png";
import { Button, DescriptionList, Divider, Icon, Loader, Notification, Paragraph, Text } from "components";
import BankLogo from "features/BankLogo";
import { useNewStyle } from "pages/App";
import React, { useState } from "react";
import { BankInfo } from "store/actions/bankInfo";
import { RecipientAccount } from "store/actions/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import css, { classnames, createUseStyle } from "style/classname";
import { useIntl } from "utils/context";
import { stringifyAddress } from "utils/helpers";

type Props = {
  value?: BankInfo | undefined;
  status?: BaseStatus;
  recipientAccount: RecipientAccount | undefined;
  /**
   * Typically when doing a SWIFTBIC bank lookup, we get the HQ address.
   * Hide address for swiftbic account because we want to get the specific branch address
   */
  showAddress: boolean;
};

function isBankInfo(account: RecipientAccount | BankInfo): account is BankInfo {
  return !(account as RecipientAccount).id;
}

export default function BankInfoDisplay(props: Props) {
  const { showAddress, value, status, recipientAccount } = props;
  const newStyle = useNewStyle();
  const { formatMessage } = useIntl();
  const styledUSCheck = useCheckStyle({ image: checkUS });
  const styledCACheck = useCheckStyle({ image: checkCA });
  const [showCheck, setShowCheck] = useState(false);

  const bankCheckImages = {
    [CountryCode.US]: (
      <div className={classnames("check-image", styledUSCheck)}>
        <Button
          type="text"
          icon="times"
          size="small"
          onClick={onToggleCheck}
          aria-label={formatMessage({ id: "common.close" })}
        />
      </div>
    ),
    [CountryCode.CA]: (
      <div className={classnames("check-image", styledCACheck)}>
        <Button
          type="text"
          icon="times"
          size="small"
          onClick={onToggleCheck}
          aria-label={formatMessage({ id: "common.close" })}
        />
      </div>
    ),
  };

  function onToggleCheck() {
    setShowCheck((state) => !state);
  }

  const country = value && typeof value === "object" ? value.bankCountryCode : recipientAccount?.country;
  const bankImg = country && bankCheckImages[country];
  const bankDetails = value && typeof value === "object" ? value : recipientAccount;

  return (
    <Loader spinning={status === BaseStatus.LOADING} style={{ height: "auto" }}>
      {status === BaseStatus.ERROR ? (
        <Notification
          closable={false}
          data-testid="error-notif"
          title={formatMessage({
            id: "containers.bankPayoutMethod.noLookupAlertTitle",
          })}
          type="error"
          iconProps={{ type: "exclamation-triangle", theme: "solid" }}
        >
          {formatMessage({
            id: "containers.bankPayoutMethod.noLookupAlert",
          })}
        </Notification>
      ) : (
        bankDetails && (
          <Notification
            showIcon={newStyle}
            closable={false}
            type="info"
            aria-live="polite"
            aria-atomic
            title={
              <>
                <DescriptionList
                  items={[
                    {
                      label: formatMessage({
                        id: "containers.bankPayoutMethod.bankName",
                      }),
                      children: bankDetails.bankName,
                      "data-testid": "bankName",
                    },
                    ...(showAddress
                      ? [
                          {
                            label: formatMessage({
                              id: "containers.bankPayoutMethod.address",
                            }),
                            "data-testid": "address",
                            children: (
                              <address>
                                {stringifyAddress({
                                  ...bankDetails,
                                  region: getRegionLabel(
                                    isBankInfo(bankDetails) ? bankDetails.bankRegion : bankDetails.bankRegionCode,
                                    isBankInfo(bankDetails) ? bankDetails.bankCountryCode : bankDetails.country,
                                  ),
                                  country: formatCountry(
                                    isBankInfo(bankDetails) ? bankDetails.bankCountryCode : bankDetails.country,
                                    formatMessage,
                                  ),
                                })}
                              </address>
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
                <Paragraph size="small" type="secondary">
                  {formatMessage({
                    id: "containers.bankPayoutMethod.bankInfoTooltip",
                  })}
                </Paragraph>
              </>
            }
            action={<BankLogo bankInfo={isBankInfo(bankDetails) ? bankDetails : undefined} size="3x" />}
          />
        )
      )}

      {bankImg && (
        <div>
          <Text size="small">
            <a onClick={onToggleCheck} role="button" tabIndex={0}>
              {formatMessage({
                id: `containers.bankPayoutMethod.needHelp.${country === CountryCode.US ? "US" : "default"}`,
              })}
              <Icon theme="solid" type={showCheck ? "chevron-up" : "chevron-down"} />
            </a>
          </Text>
          <Divider transparent />
          {showCheck && bankImg}
        </div>
      )}
    </Loader>
  );
}

const useCheckStyle = createUseStyle<{ image: string }>(({ theme, animation, ...props }) =>
  css`
    animation: ${animation.fadeInDown} 0.275s ease;
    background: url(${props.image}) center no-repeat #ffffff;
    width: 100%;
    height: 250px;
    background-size: 340px;
    border-radius: ${theme.borderRadius}px;
    border: 1px solid ${theme.colorBorder};
    button {
      float: right;
      margin: 8px;
    }
  `(),
);
