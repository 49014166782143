import React from "react";
import { useIntl } from "utils/context";

import { Divider, LinkButton, Paragraph, Text } from "components";
import { PATHS } from "pages/App/routes";
import { CompletedIcon, IncompleteIcon } from "pages/PayoutComplete";
import { useMerchant } from "store/hooks/merchant";

interface Props {
  enabled: boolean;
  completed: boolean;
}

export default function RequireUSTax({ enabled, completed }: Props) {
  const { formatMessage } = useIntl();
  const merchant = useMerchant();
  const usTaxFormRequired = merchant?.tax?.paymentBehaviour === "block";

  return enabled ? (
    <>
      {completed ? (
        <>
          <Text type="success" key="tax/complete" padded data-test="taxFormCompleted">
            <CompletedIcon />
            {formatMessage({
              id: "containers.payoutComplete.taxFormCompleted",
            })}
          </Text>
          <Divider transparent size="large" />

          <Paragraph limitWordPerLine style={{ marginLeft: "auto", marginRight: "auto" }}>
            {formatMessage(
              { id: "containers.payoutComplete.reviewDetails" },
              { done: <b>{formatMessage({ id: "common.done" })}</b> },
            )}
          </Paragraph>

          <LinkButton size="large" type="primary" to={PATHS.HOME}>
            {formatMessage({ id: "common.done" })}
          </LinkButton>
        </>
      ) : (
        <>
          <Text padded type="secondary" key="tax-incomplete" data-test="taxFormNotCompleted">
            <IncompleteIcon />
            {formatMessage({
              id: "containers.payoutComplete.taxFormNotCompleted",
            })}
          </Text>
          {usTaxFormRequired && (
            <>
              <Divider transparent />
              <Paragraph limitWordPerLine style={{ marginLeft: "auto", marginRight: "auto" }}>
                {formatMessage({ id: "containers.payoutComplete.taxRequired" })}
              </Paragraph>
            </>
          )}

          <Divider transparent size="large" />

          <LinkButton size="large" type="primary" to={PATHS.US_TAX}>
            {formatMessage({ id: "containers.payoutComplete.submitTax" })}
          </LinkButton>
          <Divider transparent />
          <LinkButton size="large" to={PATHS.HOME}>
            {formatMessage({ id: "common.done" })}
          </LinkButton>
        </>
      )}
    </>
  ) : null;
}
