import BigNumber from "bignumber.js";
import { Icon } from "components";
import React from "react";
import { classnames, createUseStyle, css } from "style/classname";

const useStyledProgress = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    padding: 16px 8px;
    margin: 0px 16px;
    text-align: center;
    z-index: 999;
    .c-progress-meter {
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;
      height: 100%;
      .c-progress-meter__step-last,
      .c-progress-meter__step {
        width: ${new BigNumber(80).dividedBy(props.steps - 1).toFixed()}%;
        display: inline-block;
        .check_container {
          width: 32px;
          display: inline-block;
          height: 100%;
          text-align: center;
        }
        .bar_container {
          height: 2px;
          width: calc(100% - 32px);
          margin-bottom: 3px;
          background: ${theme.colorBorder};
          display: inline-block;
          .bar {
            width: 0%;
            height: 100%;
            background: ${theme.colorPrimary};
            border-radius: 0px;
            display: block;
            transition: all 0.3s ease;
          }
          .checked {
            width: 100%;
          }
        }
      }
      .c-progress-meter__step-last {
        width: 32px;
        min-width: 19px;
      }
    }
  `(),
);

type Props = {
  steps: number;
  stepIndex: number;
};

export default function Stepper({ stepIndex, steps }: Props) {
  const renderedSteps = [];
  const styledProgress = useStyledProgress({ stepIndex, steps });

  for (let i = 0; i < steps - 1; i += 1) {
    renderedSteps.push(
      <div key={`step-${i}`} className="c-progress-meter__step">
        <div className="check_container">
          <Icon
            type={stepIndex > i ? "check-circle" : "circle"}
            color={stepIndex >= i ? "primary" : "grey"}
            theme={stepIndex > i ? "solid" : "regular"}
          />
        </div>
        <div className="bar_container">
          <div className={classnames("bar", { checked: stepIndex > i })} />
        </div>
      </div>,
    );
  }
  renderedSteps.push(
    <div key="step-last" className="c-progress-meter__step-last">
      <Icon type="circle" color={stepIndex === steps - 1 ? "primary" : "grey"} />
    </div>,
  );

  return (
    <div className={styledProgress}>
      <div className="c-progress-meter">{renderedSteps}</div>
    </div>
  );
}
