import BigNumber from "bignumber.js";
import React, { useContext } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import GridContext, { GridContextState } from "./GridContext";
import { makeGap } from "./index";

const GRID_COL = 24;

type FlexType = number | "none" | "auto" | string;

function parseFlex(flex: FlexType | undefined): string | undefined {
  if (flex !== undefined) {
    if (typeof flex === "number") {
      return `${flex} ${flex} auto`;
    }

    if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
      return `0 0 ${flex}`;
    }
  }

  return flex ?? ``;
}

type ColSpanType = number;
export interface ColSize {
  flex?: FlexType;
  span?: ColSpanType;
  order?: ColSpanType;
  // offset?: ColSpanType;
  push?: ColSpanType;
  pull?: ColSpanType;
}

export interface GridItemProps extends React.HTMLAttributes<HTMLDivElement>, ColSize {
  xs?: ColSpanType | ColSize;
  sm?: ColSpanType | ColSize;
  md?: ColSpanType | ColSize;
  lg?: ColSpanType | ColSize;
  xl?: ColSpanType | ColSize;
  align?: "left" | "center" | "right";
  alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
}

export default function Item(props: GridItemProps) {
  const { className, alignSelf, align, xs, sm, md, lg, xl, span, flex, order, push, pull, ...rest } = props;
  const gridContext = useContext(GridContext);

  return <div {...rest} className={classnames("grid-item", styledCol({ ...props, ...gridContext }), className)} />;
}

const styledCol = createUseStyle<GridItemProps & GridContextState>(({ theme, ...props }) =>
  css`
    position: relative;
    max-width: 100%;
    align-self: ${props.alignSelf};
    text-align: ${props.align};
    /* Prevent columns from collapsing when empty */
    min-height: 1px;

    ${makeGap(theme, props.padding, "item")}

    ${makeGrid({
      span: props.span,
      flex: props.flex,
      order: props.order,
      push: props.push,
      pull: props.pull,
    })}

    ${makeGrid(props.xs)}

    ${theme.screenUp("sm")} {
      ${makeGrid(props.sm)}
    }

    ${theme.screenUp("md")} {
      ${makeGrid(props.md)}
    }

    ${theme.screenUp("lg")} {
      ${makeGrid(props.lg)}
    }

    ${theme.screenUp("xl")} {
      ${makeGrid(props.xl)}
    }
  `(),
);

function makeGrid(colSize: ColSize | ColSpanType | undefined) {
  if (colSize === undefined) {
    return ``;
  }

  if (!colSize || typeof colSize === "number") {
    return parseColSizeNumber(colSize);
  }

  return `
    ${parseColSizeNumber(colSize.span)}
    flex: ${parseFlex(colSize.flex)};
    order: ${colSize.order || ""};
    left: ${calculatePercentage(colSize.push)};
    right: ${calculatePercentage(colSize.pull)};
  `;
}

function parseColSizeNumber(size: number | undefined) {
  if (size === undefined) {
    return ``;
  }

  return `
    display: ${size > 0 ? "block" : "none"};
    width: ${calculatePercentage(size)};
    max-width: ${calculatePercentage(size)};
  `;
}

function calculatePercentage(size: number | undefined) {
  if (size === undefined) {
    return ``;
  }

  return size > 0 ? `${new BigNumber(size).dividedBy(GRID_COL).times(100).toNumber()}%` : "auto";
}
