import { GreyBox, Modal, Text } from "components";
import { useIntl } from "utils/context";
import React from "react";

interface Props {
  label: string;
  address: string[];
  visible: boolean;
  onClose(): void;
  onOk(): void;
}

export default function CopyProfileAddress(props: Props) {
  const { formatMessage } = useIntl();
  const { label = formatMessage({ id: "containers.info.street1.title" }), address, visible, onClose, onOk } = props;

  return address.some((line) => line) ? (
    <Modal
      size="small"
      open={visible}
      onCancel={onClose}
      onOk={onOk}
      okText={formatMessage({ id: "containers.tax.copyAddress.ok" })}
      title={formatMessage({ id: "containers.tax.copyAddress.title" })}
    >
      <GreyBox>
        <Text strong type="label">
          {label}
        </Text>
        {address.map((line) => (
          <Text key={line}>{line}</Text>
        ))}
      </GreyBox>
    </Modal>
  ) : null;
}
