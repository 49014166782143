import { CountryCode, CurrencyCode, PayoutMethodType } from "@trolley/common-frontend";
import { Form2 as Form, Grid, Loader } from "components";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { InteracAccountUpdate, RecipientAccount, addPayoutMethod, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";

export default function Interac({ account }: { account: RecipientAccount | undefined }) {
  const [form] = Form.useForm();
  const recipient = useRecipient();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  async function onFinish(values: any) {
    if (!submitting && recipient) {
      setSubmitting(true);

      const update: InteracAccountUpdate = {
        ...values,
        type: PayoutMethodType.INTERAC,
        currency: CurrencyCode.CAD,
        country: CountryCode.CA,
      };
      try {
        if (recipient) {
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, update);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, update);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Grid justify="space-between">
          <Grid.Item style={{ flex: "10 1 400px" }}>
            <Form.Field
              name="emailAddress"
              label={intl.formatMessage({
                id: "containers.interacPayoutMethod.email.title",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "containers.paypalPayoutMethod.email.required",
                  }),
                },
                {
                  type: "email",
                  message: intl.formatMessage({
                    id: "containers.paypalPayoutMethod.email.valid",
                  }),
                },
              ]}
            >
              <Form.Input type="email" name="emailAddress" />
            </Form.Field>

            <Form.Field
              name="reEmailAddress"
              label={intl.formatMessage({
                id: "containers.interacPayoutMethod.reEmail.title",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "containers.paypalPayoutMethod.reEmail.required",
                  }),
                },
                {
                  type: "email",
                  message: intl.formatMessage({
                    id: "containers.paypalPayoutMethod.reEmail.valid",
                  }),
                },
                ({ getFieldValue }) => {
                  return {
                    async validator(_: any, value: string) {
                      if (value !== getFieldValue("emailAddress")) {
                        throw new Error(
                          intl.formatMessage({
                            id: "containers.paypalPayoutMethod.emailMustBeSame",
                          }),
                        );
                      }
                    },
                  };
                },
              ]}
            >
              <Form.Input type="email" name="reEmailAddress" />
            </Form.Field>
          </Grid.Item>
        </Grid>

        <PayoutFooter busy={submitting} account={account} />
      </Loader>
    </Form>
  );
}
