import { CountryCode, formatCountry, PayoutMethodType, UsTeritories } from "@trolley/common-frontend";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Divider, Form2 as Form, Grid, Loader, Notification, Text, Title } from "components";
import { isRegionInCountryValidator } from "components/Form2/SelectRegion";
import { PATHS } from "pages/App/routes";
import { POST } from "services/request";
import { addPayoutMethod, CheckAccountUpdate, RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors } from "utils/helpers";

import config from "config";
import { isValidUsPostalCode } from "../Info2";
import PayoutFooter from "./PayoutFooter";

export const checkCountries = UsTeritories;

export default function Check({ account }: { account: RecipientAccount | undefined }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const recipient = useRecipient();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const [validAddress, setValidAddress] = useState<"deliverable" | "undeliverable" | undefined>();
  const country = Form.useWatch("country", form);

  async function onFinish({ primary, ...values }: any) {
    if (!submitting && recipient) {
      setSubmitting(true);
      try {
        const { body } = await POST<any>("/v1/address/lookup", {
          query: values,
        });
        setValidAddress(body?.addressData?.deliverability || "undeliverable");
        if (body?.addressData?.deliverability === "deliverable") {
          const postData: CheckAccountUpdate = {
            primary,
            type: PayoutMethodType.CHECK,
            mailing: values,
          };

          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, postData);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, postData);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        setValidAddress(undefined);
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form
      form={form}
      initialValues={account?.mailing ?? { country: recipient?.address.country }}
      onFieldsChange={() => {
        setValidAddress(undefined);
      }}
      onFinish={onFinish}
    >
      <Loader spinning={submitting}>
        <Form.Field
          name="name"
          label={intl.formatMessage({
            id: "containers.checkPayoutMethod.name.title",
          })}
          extra={intl.formatMessage({
            id: "containers.checkPayoutMethod.name.hint",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "containers.checkPayoutMethod.name.required",
              }),
            },
          ]}
        >
          <Form.Input type="text" name="name" />
        </Form.Field>

        <Divider />

        <Title level={2}>
          {intl.formatMessage({
            id: "containers.checkPayoutMethod.mailingAddress",
          })}
        </Title>

        <Form.Field
          name="country"
          label={intl.formatMessage({ id: "containers.info.country.title" })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "containers.info.country.required",
              }),
            },
          ]}
        >
          <Form.SelectCountry
            includes={checkCountries}
            type="address"
            onChange={() => {
              form.setFieldValue("region", undefined);
            }}
          />
        </Form.Field>

        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="street1"
              label={intl.formatMessage({
                id: "containers.checkPayoutMethod.street1.title",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "containers.checkPayoutMethod.street1.required",
                  }),
                },
                (_form) => ({
                  async validator(rule: any, value: string) {
                    const fullAddress = [value, _form.getFieldValue("street2") || ""].join("");
                    if (fullAddress.length > 50) {
                      throw intl.formatMessage({
                        id: "containers.checkPayoutMethod.street1.exceed50",
                      });
                    }
                  },
                }),
              ]}
            >
              <Form.Input type="text" name="street1" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="street2"
              initialValue=""
              label={intl.formatMessage({
                id: "containers.checkPayoutMethod.street2.title",
              })}
            >
              <Form.Input type="text" name="street2" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="city"
              label={intl.formatMessage({ id: "containers.info.city.title" })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "containers.info.city.required",
                  }),
                },
              ]}
            >
              <Form.Input type="text" name="city" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="region"
              label={intl.formatMessage({
                id: country === CountryCode.US ? "containers.info.region.US.title" : "containers.info.region.title",
              })}
              rules={[
                {
                  required: true,
                  message:
                    country === CountryCode.US
                      ? intl.formatMessage({
                          id: "containers.info.region.US.required",
                        })
                      : intl.formatMessage({
                          id: "containers.info.region.required",
                        }),
                },
                isRegionInCountryValidator("country", intl.formatMessage),
              ]}
            >
              <Form.SelectRegion country={country} />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="postal"
              label={intl.formatMessage({
                id: "containers.info.postalCode.US.title",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "containers.info.postalCode.US.required",
                  }),
                },
                {
                  async validator(_: any, value: string) {
                    if (value && config.ENV === "production" && !isValidUsPostalCode(value)) {
                      throw intl.formatMessage({
                        id: "containers.info.postalCode.US.valid",
                      });
                    }
                  },
                },
              ]}
            >
              <Form.Input type="text" name="postal" />
            </Form.Field>
          </Grid.Item>
        </Grid>

        <Notification
          type={validAddress === "undeliverable" ? "error" : "info"}
          iconProps={{
            type: validAddress === "undeliverable" ? "map-marker-exclamation" : "map-marker-alt",
            theme: "solid",
          }}
          title={intl.formatMessage({
            id:
              validAddress === "undeliverable"
                ? "containers.checkPayoutMethod.invalidAddress"
                : "containers.checkPayoutMethod.addressVerification",
          })}
        >
          {intl.formatMessage({
            id:
              validAddress === "undeliverable"
                ? "containers.checkPayoutMethod.invalidAddressDescription"
                : "containers.checkPayoutMethod.addressVerificationDescription",
          })}

          <Form.Control shouldUpdate>
            {({ getFieldsValue }) => {
              const { name, street1, street2, city, region, postal, country } = getFieldsValue();

              return (
                <Text strong padded>
                  {name && <div>{name}</div>}
                  {street1 && (
                    <div>
                      {street1} {street2}
                    </div>
                  )}
                  {(city || region) && (
                    <div>
                      {[city, region].filter((v) => v).join(", ")} {postal}
                    </div>
                  )}
                  {formatCountry(country, intl.formatMessage)}
                </Text>
              );
            }}
          </Form.Control>
        </Notification>
        <PayoutFooter busy={submitting} account={account} />
      </Loader>
    </Form>
  );
}
