import { useShallowSelector } from ".";
import { SessionMode } from "store/actions/auth";

export function useAuth() {
  return useShallowSelector((state) => {
    const { auth } = state;

    return {
      isLoggedIn: !!state.recipient?.entities?.data?.id && auth.state === SessionMode.ACTIVE,
      auth,
    };
  });
}
