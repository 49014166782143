import { getBankRuleProps } from "@trolley/common-frontend";
import React, { useState } from "react";

import { Form } from "components";
import NameWarning, { getHolderNameWarnings } from "pages/PayoutMethod2/BankTransfer/NameWarning";
import { updatePayoutMethod } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { pick } from "utils/helpers";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function AccountHolderNameInput({ submitted }: InputPopupProps) {
  const recipient = useRecipient();
  const [submitting, setSubmitting] = useState(false);
  const [holderNamePopup, setHolderNamePopup] = useState<{
    show: boolean;
    name?: string;
    holderNameWarnings?: typeof getHolderNameWarnings;
  }>({ show: false });

  const { formatMessage } = useIntl();

  const primaryAccount = recipient?.accounts.find((a) => a.primary);

  if (!recipient || !primaryAccount || !primaryAccount.country || !primaryAccount.currency) {
    return null;
  }

  const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, holderNamePopup.name);

  const accountHolderNameRules = getBankRuleProps(
    "accountHolderName",
    undefined,
    {
      country: primaryAccount.country,
      currency: primaryAccount.currency,
    },
    formatMessage,
  );

  async function onSubmit(accountHolderName?: string) {
    if (recipient && primaryAccount) {
      if (
        !holderNamePopup.show &&
        accountHolderName &&
        getHolderNameWarnings(formatMessage, recipient, accountHolderName).length > 0
      ) {
        setHolderNamePopup({ show: true, name: accountHolderName });
        throw new Error(); // prevent LockedInput from finish saving
      } else {
        setSubmitting(true);
        await updatePayoutMethod(recipient.id, primaryAccount.id, {
          ...pick(primaryAccount, ["currency", "country"]),
          accountHolderName: holderNamePopup.show ? holderNamePopup.name : accountHolderName,
        });
        setSubmitting(false);
        loadTicketsWithDelay(recipient.id, true);
        setHolderNamePopup({ show: false });
      }
    }
  }

  return (
    <>
      <LockedInput
        submitted={submitted}
        defaultValue={primaryAccount.accountHolderName}
        onSave={onSubmit}
        name="accountHolderName"
        label={
          accountHolderNameRules?.label ||
          formatMessage({
            id: "containers.bankPayoutMethod.accountHolderName.title",
          })
        }
        tooltip={accountHolderNameRules?.tooltip || formatMessage({ id: "containers.bankPayoutMethod.correctName" })}
        validateFirst
        normalize={accountHolderNameRules?.normalize}
        rules={accountHolderNameRules?.rules}
        FieldComponent={Form.Input}
        fieldComponentProps={{
          placeholder: "eg. John Smith",
        }}
      />
      <NameWarning
        visible={!!holderNamePopup.show}
        onClose={() => {
          setHolderNamePopup({ show: false });
        }}
        onOk={onSubmit}
        submitting={submitting}
        holderNameWarnings={holderNameWarnings}
        accountHolderName={holderNamePopup.name}
        recipient={recipient}
      />
    </>
  );
}
