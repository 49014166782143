import { Container, Divider, Form, Grid, Loader, Text } from "components";
import { CountryCode as PhoneCountryCode } from "libphonenumber-js";
import { useForm } from "components/Form";
import InputPhone, { countryPhoneValidator, parsePhoneNumber } from "components/Form/InputPhone";
import { useIntl } from "utils/context";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  addPayoutMethod,
  isVenmoSettings,
  RecipientAccount,
  updatePayoutMethod,
  VenmoAccountUpdate,
} from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { handleFormErrors } from "utils/helpers";
import { CountryCode, CurrencyCode, PayoutMethodType } from "@trolley/common-frontend";
import PayoutFooter from "./PayoutFooter";
import { useMerchant } from "store/hooks/merchant";

type Props = {
  account: RecipientAccount | undefined;
};

export default function Venmo({ account }: Props) {
  const [form] = useForm();
  const recipient = useRecipient();
  const intl = useIntl();
  const [activate, setActivate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const merchantSettings = useMerchant();
  const venmoPayout = merchantSettings?.enabledPayoutMethods.find((pm) => pm.integration === PayoutMethodType.VENMO);
  const venmoAllowedCountries = isVenmoSettings(venmoPayout?.integration, venmoPayout?.settings)
    ? venmoPayout?.settings.allowedCountries
    : [CountryCode.US];
  const selectedCountry = recipient?.address.country || CountryCode.US;
  const deliveryEstimate = account?.deliveryBusinessDaysEstimate || venmoPayout?.deliveryBusinessDaysEstimate || 0;

  interface FormFields {
    phoneNumber: string;
    phonNumberConfirmation: string;
  }

  async function onFinish(values: FormFields) {
    if (!submitting && recipient) {
      setSubmitting(true);
      const parsedNumber = parsePhoneNumber(values.phoneNumber, selectedCountry as PhoneCountryCode);
      const update: VenmoAccountUpdate = {
        phoneNumber: parsedNumber.internationalFormat,
        type: PayoutMethodType.VENMO,
        currency: CurrencyCode.USD,
        country: parsedNumber.country as CountryCode,
      };
      try {
        if (recipient) {
          if (activate) {
            update.primary = true;
          }
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, update);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, update);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setActivate(false);
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Container>
          <>
            <Text size="small">
              {intl.formatMessage({
                id: "containers.bankPayoutMethod.processingTimes.title",
              })}
              <b>
                {deliveryEstimate < 1
                  ? intl.formatMessage({ id: "containers.payoutMethod.processingTimes.sameDay" })
                  : intl.formatMessage(
                      {
                        id: "containers.payoutMethod.processingTimes.bussinessDaysEstimate",
                      },
                      { from: deliveryEstimate, to: deliveryEstimate + 1 },
                    )}
              </b>{" "}
            </Text>
          </>
          <Divider transparent />
          <Grid padding="medium" direction="column">
            <Grid.Item md={12}>
              <Form.Field
                initialValue={account?.phoneNumber}
                name="phoneNumber"
                label={intl.formatMessage({
                  id: "containers.venmoPayoutMethod.phone.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.info.phone.required",
                    }),
                  },
                  countryPhoneValidator(intl.formatMessage, venmoAllowedCountries),
                ]}
              >
                <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phone" />
              </Form.Field>
            </Grid.Item>
            <Grid.Item md={12}>
              <Form.Field
                name="phoneNumberConfirmed"
                validateTrigger="onChange"
                label={intl.formatMessage({
                  id: "containers.venmoPayoutMethod.phoneConfirm.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.info.phone.required",
                    }),
                  },
                  (form) => ({
                    async validator(rule, value) {
                      if (value !== form.getFieldValue("phoneNumber")) {
                        throw intl.formatMessage({
                          id: "containers.venmoPayoutMethod.phoneConfirm.valid",
                        });
                      }
                    },
                  }),
                ]}
              >
                <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phone" />
              </Form.Field>
            </Grid.Item>
          </Grid>
          <PayoutFooter
            setBusy={setSubmitting}
            busy={submitting}
            account={account}
            onSave={() => {
              setActivate(false);
            }}
            onSaveActivate={() => {
              setActivate(true);
            }}
          />
        </Container>
      </Loader>
    </Form>
  );
}
