import React, { useState } from "react";
import css, { createUseStyle } from "style/classname";
import ListFooter from "./ListFooter";

export type BaseProps<T> = {
  defaultRecordCount?: number;
  showMoreText?: React.ReactNode;
  showLessText?: React.ReactNode;
  footer?: React.ReactNode;
  items: T[];
  itemKey?: string;
};

interface ListProps<T> extends BaseProps<T> {
  size?: "small" | "default";
  renderItem: (item: T) => React.ReactNode;
}

export default function List<T>(props: ListProps<T>) {
  const {
    defaultRecordCount = 3,
    items,
    showMoreText,
    showLessText,
    size = "default",
    footer,
    renderItem,
    itemKey = "id",
  } = props;
  const styledListItem = useStyledListItem();
  const styledListContainer = useStyledListContainer();
  const [showMore, setShowMore] = useState(false);

  function renderItems() {
    return items
      .filter((item, index) => showMore || index < defaultRecordCount)
      .map((item) => (
        <div className={styledListItem} key={item[itemKey]} style={{ minHeight: size === "small" ? "46px" : "70px" }}>
          {renderItem(item)}
        </div>
      ));
  }

  return (
    <div className={styledListContainer}>
      {renderItems()}
      {(items.length > defaultRecordCount || footer) && (
        <ListFooter
          footer={footer}
          showMore={showMore}
          showMoreText={showMoreText}
          showLessText={showLessText}
          onShowMore={setShowMore}
        />
      )}
    </div>
  );
}

const useStyledListItem = createUseStyle(({ theme, animation }) =>
  css`
    padding: 0px 8px;
    display: grid;
    border-top: 1px solid ${theme.colorBorder};
    &:first-of-type {
      border-top: none;
    }
    animation: ${animation.fadeInDown} 0.175s ease;
    transform-origin: top;
  `(),
);

const useStyledListContainer = createUseStyle(({ theme }) =>
  css`
    border: 1px solid ${theme.colorBorder};
    border-radius: ${theme.borderRadius}px;
    min-height: auto;
  `(),
);
