export default function capitalize(text: string) {
  return text
    .split(" ")
    .map((word, index) => {
      if (index > 0 && ["the", "a", "of", "by", "to", "this", "that", "those", "these"].includes(word)) {
        return word;
      }

      return `${word.charAt(0).toLocaleUpperCase()}${word.substring(1)}`;
    })
    .join(" ");
}
