import React, { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer } from "react";

export enum ActionType {
  PAYOUT = "payout",
  TAX_US = "taxUs",
  TAX_EU = "taxEu",
  TRUST = "trust",
}
interface StepperAction {
  type: ActionType;
  /**
   * percentage completed
   * use `number` to represent a step in progress,
   * use `null` when not in progress. it will either show a completed icon or the step number
   */
  percent: number | null;
}

const initialSteppers: Record<ActionType, number | null> = {
  [ActionType.PAYOUT]: null,
  [ActionType.TAX_US]: null,
  [ActionType.TAX_EU]: null,
  [ActionType.TRUST]: null,
};

const StepperContext = createContext(initialSteppers);
const StepperDispatchContext = createContext<Dispatch<StepperAction>>(() => {});

export function StepperProvider({ children }: { children: ReactNode }) {
  const [steps, dispatch] = useReducer(stepperReducer, initialSteppers);

  return (
    <StepperContext.Provider value={steps}>
      <StepperDispatchContext.Provider value={dispatch}>{children}</StepperDispatchContext.Provider>
    </StepperContext.Provider>
  );
}

export function stepperReducer(steps: any, action: StepperAction) {
  return {
    ...steps,
    [action.type]: action.percent,
  };
}

export function useStepper() {
  return useContext(StepperContext);
}

export function useStepperEffect(type: ActionType, percent: number) {
  const stepperDipatch = useContext(StepperDispatchContext);

  useEffect(() => {
    stepperDipatch({ type, percent });

    return () => {
      stepperDipatch({ type, percent: null });
    };
  }, [percent]);
}
