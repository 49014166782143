import { uniqueList } from "@trolley/common-frontend";
import qs from "qs";

export interface JsonArray extends Array<Json> {}
export interface JsonObject {
  [property: string]: Json | undefined;
}
export type Json = string | number | boolean | null | JsonObject | JsonArray;
export type JsonNoQuery = JsonObject & { query?: never };

type StringKeyOfT<T> = Extract<keyof T, string>;

export interface Query<T = any> extends JsonObject {
  page?: number;
  pageSize?: number;
  orderBy?: StringKeyOfT<T>[];
  sortBy?: ("asc" | "desc")[];

  dateColumn?: null | StringKeyOfT<T>;
  startDate?: string;
  endDate?: string;
  query?: never;
}

export default function parseQuery(
  queryStr: string,
  options?: {
    arrayKeys?: string[]; // query fields that are suppose to be an array
    numberKeys?: string[]; // query fields that are suppose to be numbers
  },
) {
  const query = qs.parse(queryStr, {
    comma: true,
    ignoreQueryPrefix: true,
    allowDots: true, // allows params like "colors.primary" to be converted to objects
  }) as Query;
  const arrayKeys = uniqueList(["sortBy", "orderBy", "status", ...(options?.arrayKeys || [])]);
  const numberKeys = uniqueList(["page", "pageSize", ...(options?.numberKeys || [])]);

  return Object.fromEntries(
    Object.entries(query).map(([k, v]) => {
      if (v === "") {
        return [k, undefined]; // critical for overwriting DEFAULT_QUERY
      } else if (arrayKeys.includes(k) && !Array.isArray(v)) {
        return [k, [String(v)]];
      } else if (["true", "false"].includes(String(v))) {
        return [k, v === "true"];
      } else if (numberKeys.includes(k)) {
        return [k, Number(v)];
      }

      return [k, v];
    }),
  );
}
