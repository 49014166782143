/* istanbul ignore file */
const safeCrypto = window.crypto || (window as any).msCrypto;

export default function getRandomBytes(len: number) {
  const a = new Uint8Array(len);

  safeCrypto.getRandomValues(a);

  return a;
}
