import {
  addressMappedCountries,
  allMappedCountries,
  bankingMappedCountries,
  CountryCode,
  formatCountry,
  MappedCountry,
} from "@trolley/common-frontend";
import { Flag, Icon } from "components";
import React from "react";
import { FormatMessage, useIntl } from "utils/context";
import Select, { SelectProps } from "./Select";

export function isCountryCode(countryCode: string | null | undefined): countryCode is CountryCode {
  return !!(countryCode && CountryCode[countryCode]);
}

interface ExtraProps {
  type: "all" | "address" | "banking";
  selectableCountries?: CountryCode[];
  excludes?: CountryCode[];
}

interface Props extends ExtraProps, Omit<SelectProps, "options" | "value"> {
  value?: CountryCode;
}

export default function SelectCountry(props: Props) {
  const { type, excludes, selectableCountries, ...selectProps } = props;
  const selectedCountry =
    typeof selectProps.value === "string" && isCountryCode(selectProps.value) ? selectProps.value : undefined;

  const { formatMessage } = useIntl();

  return (
    <Select
      {...selectProps}
      placeholder={formatMessage({ id: "components.formCountry.select" })}
      options={sortCountries(getCountrySet({ type, selectableCountries, excludes }), formatMessage)}
      prefix={selectedCountry ? <Flag code={selectedCountry} /> : <Icon type="globe" />}
    />
  );
}

function sortCountries(areas: MappedCountry, formatMessage?: FormatMessage): { label: string; value: CountryCode }[] {
  const entries = Object.entries(areas);
  if (!entries.length) {
    return [];
  }
  try {
    const sortedArray = entries.map(([code, area]) => ({
      label: formatCountry(code, formatMessage) || area.name,
      value: code as CountryCode,
    }));

    sortedArray.sort((a, b) => {
      const nameA = a.label.toLocaleUpperCase();
      const nameB = b.label.toLocaleUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  } catch {
    return [];
  }
}

function getCountrySet({ type, selectableCountries, excludes }: ExtraProps) {
  let countrySet: MappedCountry;

  switch (type) {
    case "all":
      countrySet = allMappedCountries;
      break;
    case "address":
      countrySet = addressMappedCountries;
      break;
    default:
      countrySet = bankingMappedCountries;
      break;
  }

  if (selectableCountries || excludes) {
    countrySet = Object.fromEntries(
      Object.entries(countrySet).filter(
        ([k, v]) =>
          (!selectableCountries?.length || selectableCountries.includes(v.code)) &&
          (!excludes?.length || !excludes.includes(v.code)),
      ),
    );
  }

  return countrySet;
}
