import store from "store";
import { BaseError } from "@trolley/common-frontend";

export const NOTIFICATION_ADD = "notification/ADD";
export const NOTIFICATION_RESET = "notification/RESET";

export type NotifyOptions = {
  type: "success" | "warning" | "error" | "info";
};

function parseErrorsMessage(e?: BaseError[]) {
  return e?.find?.((err) => err.message)?.message;
}

export function notifySuccess(message: React.ReactNode) {
  notify(message, { type: "success" });
}

export function notifyError(defaultMessage: React.ReactNode, options?: { errors: BaseError[] }) {
  notify(parseErrorsMessage(options?.errors) || defaultMessage, {
    type: "error",
  });
}

export function notify(message: React.ReactNode, options: NotifyOptions = { type: "success" }) {
  store.dispatch({
    type: NOTIFICATION_ADD,
    data: {
      message,
      type: options.type,
    },
  });
}

export function resetNotification() {
  store.dispatch({
    type: NOTIFICATION_RESET,
  });
}
