import { FTINType, RecipientType } from "@trolley/common-frontend";
import React, { useMemo } from "react";

import { useIntl } from "utils/context";
import Select, { SelectOption, SelectProps } from "./Select";
import { filterFtinsByRecipientType, getFilteredFtinsByCountry } from "utils/helpers";

interface Props extends Omit<SelectProps, "options"> {
  country: string | undefined | null;
  addOtherOption?: boolean;
  showEmptyOption?: boolean;
  recipientType: RecipientType;
  onOptionsLoaded?: (options: SelectOption[]) => void;
}

export default function SelectFTINType(props: Props) {
  const { formatMessage } = useIntl();
  const { country, addOtherOption, showEmptyOption = true, recipientType, onOptionsLoaded, ...rest } = props;
  const options = useMemo(() => {
    const otherLabel = formatMessage({ id: "components.formTinTypes.other" });
    const fTinsByCountry: Record<string, FTINType> = getFilteredFtinsByCountry();
    const otherOption: SelectOption = {
      label: otherLabel,
      value: "other",
      "data-search": otherLabel,
      "aria-label": otherLabel,
    };
    if (!country) {
      return [];
    }

    const fTins: FTINType = fTinsByCountry[country]
      ? filterFtinsByRecipientType(fTinsByCountry[country], recipientType)
      : {};

    const ftinOptions: SelectOption[] = [
      ...Object.entries(fTins).map(
        ([code, fTinData]) =>
          ({
            label: fTinData?.localName ?? fTinData?.name ?? "",
            value: code,
            "aria-label": fTinData?.name ?? "",
          } as SelectOption),
      ),
      ...(!!addOtherOption ? [otherOption] : []),
    ];
    if (onOptionsLoaded) {
      onOptionsLoaded(ftinOptions);
    }

    return ftinOptions;
  }, [country]);

  return (
    <Select
      {...rest}
      placeholder={formatMessage({ id: "components.formTinTypes.select" })}
      options={options}
      allowClear={showEmptyOption && options.length > (addOtherOption ? 1 : 0)}
      aria-label={formatMessage({ id: "components.formTinTypes.select" })}
      {...(addOtherOption && options.length === 1 ? { value: "other" } : {})}
    />
  );
}
