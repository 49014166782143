import { Grid } from "components";
import Form from "components/Form";
import React from "react";
import { useIntl } from "utils/context";
import { updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function NameInput(props: InputPopupProps) {
  const { submitted } = props;
  const { formatMessage } = useIntl();
  const recipient = useRecipient();

  async function onSubmit(values: { firstName: string; lastName: string }) {
    if (recipient) {
      await updateRecipient(recipient.id, values);
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  return (
    <LockedInput
      name="name"
      defaultValue={recipient}
      onSave={onSubmit}
      label={`${formatMessage({
        id: "containers.info.firstName.title",
      })} / ${formatMessage({
        id: "containers.info.lastName.title",
      })}`}
      FieldComponent={NameFieldsInput}
      rules={[
        {
          async validator(_: any, values: { firstName: string; lastName: string }) {
            if (!values.firstName) {
              throw new Error(formatMessage({ id: "containers.info.firstName.required" }));
            }
            if (!values.lastName) {
              throw new Error(formatMessage({ id: "containers.info.lastName.required" }));
            }
          },
        },
      ]}
      submitted={submitted}
    />
  );
}

type Props = {
  disabled: boolean;
  onChange: (e: any) => void;
  value?: {
    firstName: string;
    lastName: string;
  };
};

function NameFieldsInput({ disabled, value, onChange }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Grid padding="small">
      <Grid.Item xs={24} md={12}>
        <Form.Input
          disabled={disabled}
          name="firstName"
          value={value?.firstName}
          placeholder={formatMessage({ id: "containers.info.firstName.title" })}
          onChange={(e) => {
            onChange?.({
              firstName: e.target.value,
              lastName: value?.lastName,
            });
          }}
        />
      </Grid.Item>

      <Grid.Item xs={24} md={12}>
        <Form.Input
          disabled={disabled}
          name="lastName"
          placeholder={formatMessage({ id: "containers.info.lastName.title" })}
          value={value?.lastName}
          onChange={(e) => {
            onChange?.({
              firstName: value?.firstName,
              lastName: e.target.value,
            });
          }}
        />
      </Grid.Item>
    </Grid>
  );
}
