import { createContext, Context } from "react";
import { Breakpoints, GridPadding } from "./index";

export interface GridContextState {
  padding?: GridPadding | Partial<Record<Breakpoints, GridPadding>>;
}

const GridContext: Context<GridContextState> = createContext({});

export default GridContext;
