import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadCurrencies } from "store/actions/currencies";

export function useCurrencies(optionalLoad: boolean = true) {
  useEffect(() => {
    loadCurrencies();
  }, [optionalLoad]);

  return useShallowSelector((state) => {
    if (optionalLoad && state.currencies.fetchStatus.data === undefined) {
      loadCurrencies();
    }

    return {
      data: state.currencies.entities,
      status: state.currencies.fetchStatus.data,
      error: state.currencies.errors.data,
    };
  });
}
