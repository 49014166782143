import { Button, Icon } from "components";
import { useIntl } from "utils/context";

import React from "react";
import { authLogout } from "store/actions/authPortal";
import { useAuth } from "store/hooks/auth";
import { useWindowSize } from "utils/hooks";
import { useNewStyle } from "pages/App";

export default function PortalLogoutButton(props: { className?: string }) {
  const { className } = props;
  const { isLoggedIn } = useAuth();
  const { isMobile } = useWindowSize();
  const { formatMessage } = useIntl();
  const newLayout = useNewStyle();

  return isLoggedIn ? (
    <Button size={newLayout ? "small" : "middle"} onClick={authLogout} type="text" className={className}>
      {!isMobile ? formatMessage({ id: "containers.mainContainer.logout" }) : undefined}
      <Icon right={!isMobile} type="sign-out" />
    </Button>
  ) : null;
}
