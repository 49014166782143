import React, { useState } from "react";
import OnboardingHeader, { OnboardingStatus } from "./OnboardingHeader";
import OnboardingWrapper from "./OnboardingWrapper";

import {
  Button,
  Divider,
  Form2,
  Grid,
  Highlight,
  Icon,
  Modal,
  Paragraph,
  Text,
  TitleBar,
  Typography,
} from "components";
import Radio from "components/Form2/Radio";

import { notify } from "store/actions/notifications";

export default function OnboardingShowRoom() {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("category3");
  const [options, setOptions] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);

  function onClick() {
    if (!loading) {
      setLoading(true);
      window.setTimeout(() => {
        setLoading(false);
        notify("Button Clicked", { type: "info" });
      }, 1000);
    }
  }

  return (
    <OnboardingWrapper header={<OnboardingHeader status={OnboardingStatus.IN_PROGRESS} />}>
      <Form2 initialValues={{ input: "input text" }} validateTrigger="onChange" requiredMark={false}>
        {/* <Notifier />

        <Tooltip overlay="Successful Notification" placement="topLeft" mouseLeaveDelay={0}>
          <div>
            <Notification type="success" closable={false} title="Completed">
              You've completed something!
            </Notification>
          </div>
        </Tooltip>

        <Tooltip overlay="Warning Notification" placement="topLeft" mouseLeaveDelay={0}>
          <div>
            <Notification type="warning" closable={false} title="Recipient Incomplete">
              <ul style={{ paddingLeft: "22px", marginBottom: "0px" }}>
                <li>Your address information is incomplete.</li>
              </ul>
            </Notification>
          </div>
        </Tooltip>

        <Tooltip overlay="Error Notification" placement="topLeft" mouseLeaveDelay={0}>
          <div>
            <Notification type="error" closable={false} title="Server Error">
              Something went wrong!
            </Notification>
          </div>
        </Tooltip>

        <Highlight tooltip="Divider">
          <Divider />
        </Highlight> */}

        <Highlight tooltip="Radio Button">
          <Typography.Text type="label">Select a category</Typography.Text>
          <Radio.Group
            optionType="card"
            direction="vertical"
            value={category}
            options={[
              {
                label: "Category 1",
                value: "category1",
              },
              {
                label: "Category 2",
                value: "category2",
              },
              {
                label: "Category 3",
                value: "category3",
                disabled: true,
              },
            ]}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          />
        </Highlight>

        <Highlight tooltip="Checkbox group">
          <Typography.Text type="label">Select all the options that apply</Typography.Text>
          <Form2.Checkbox.Group
            value={options}
            options={[
              {
                label: "Option 1",
                value: "option1",
              },
              {
                label: "Option 2",
                value: "option2",
              },
              {
                label: "Option 3",
                value: "option3",
              },
              {
                label: "Option 4",
                value: "option4",
              },
            ]}
            onChange={(opt: string[]) => {
              setOptions(opt);
            }}
          />
        </Highlight>

        <Highlight tooltip="Buttons">
          <Grid padding="small">
            <Grid.Item>
              <Button type="primary" onClick={onClick} loading={loading}>
                Primary
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button onClick={onClick} loading={loading}>
                Default
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="success" onClick={onClick} loading={loading}>
                Success
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="danger" onClick={onClick} loading={loading}>
                Critical
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button disabled onClick={onClick} loading={loading}>
                Disabled
              </Button>
            </Grid.Item>
          </Grid>
          <Divider transparent />
          <Grid padding="small">
            <Grid.Item>
              <Button
                type="primary"
                onClick={onClick}
                loading={loading}
                icon="star"
                iconProps={{ theme: "solid" }}
                shape="circle"
                aria-label="star"
              />
            </Grid.Item>
            <Grid.Item>
              <Button onClick={onClick} loading={loading} icon="pencil" shape="circle" aria-label="Edit" />
            </Grid.Item>
            <Grid.Item>
              <Button
                type="success"
                onClick={onClick}
                loading={loading}
                icon="check"
                shape="circle"
                aria-label="Success"
              />
            </Grid.Item>
            <Grid.Item>
              <Button
                type="danger"
                onClick={onClick}
                loading={loading}
                icon="trash-alt"
                shape="circle"
                aria-label="Delete"
              />
            </Grid.Item>
            <Grid.Item>
              <Button disabled onClick={onClick} loading={loading} icon="ban" shape="circle" aria-label="ban" />
            </Grid.Item>
          </Grid>
        </Highlight>

        <Highlight tooltip="Links and actions color">
          <Button
            type="link"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Open Modal Window
          </Button>
        </Highlight>

        <Highlight tooltip="Heading Color">
          <TitleBar>Lorem ipsum dolor sit amet...</TitleBar>
        </Highlight>

        <Highlight tooltip="Default Text">
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Success Status">
          <Text type="success">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Info Status">
          <Text type="info">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Warning Color">
          <Text type="warning">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Error Color">
          <Text type="danger">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Subtext">
          <Text type="secondary" size="small">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Form Input">
          <Form2.Item
            label="Form Input Label"
            name="input"
            tooltip="Tooltip message"
            rules={[{ required: true, message: "Sample error message" }]}
            validateTrigger="onChange"
          >
            <Form2.Input
              type="text"
              placeholder="placeholder"
              suffix={<Icon type="exclamation-triangle" color="warning" tooltip="Warning message" />}
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Checkbox Input">
          <Form2.Item name="checkbox" valuePropName="checked">
            <Form2.Checkbox
              name="checkbox"
              checkedChildren="Yes, it's checked"
              uncheckedChildren="No, it's not checked"
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Radio Input">
          <Form2.Item name="radio" label="I prefer...">
            <Form2.Radio.Group
              name="radio"
              options={[
                { label: "Choice A", value: "a" },
                { label: "Choice B", value: "b" },
              ]}
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Paragraph size small">
          <Paragraph size="small">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Highlight tooltip="Paragraph size large">
          <Paragraph size="large">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Highlight tooltip="Paragraph size xlarge">
          <Paragraph size="xlarge">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Modal
          open={showModal}
          title="Modal Window Title"
          onCancel={() => {
            setShowModal(false);
          }}
          onOk={() => {
            setShowModal(false);
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Modal>
      </Form2>
    </OnboardingWrapper>
  );
}
