import { CountryCode, PaymentCategory } from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import { default as royalties } from "data/withholding/royalties_withholding.json";
import { default as services } from "data/withholding/services_withholding.json";
import { getPaymentCategoryLabels } from "data/withholding/variables";
import React from "react";
import { TaxForm } from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";

interface Props {
  country: CountryCode | undefined;
  calculatedWithholdings: TaxForm["calculatedWithholdings"];
}

export default function TreatyTable({ country, calculatedWithholdings }: Props) {
  const merchant = useMerchant();
  const paymentCategories = merchant?.payment?.categories;
  const styledIncomeTable = useStyledIncomeTable();
  const { formatMessage } = useIntl();
  const paymentCategoryLabels = getPaymentCategoryLabels(formatMessage);
  const paymentCategories1099K = [PaymentCategory.SERVICES_MARKETPLACE];

  return !country || !paymentCategories ? null : (
    <table className={styledIncomeTable}>
      <thead>
        <tr>
          <th>{formatMessage({ id: "containers.tax.treatyTable.incomeCode" })}</th>
          <th>
            {formatMessage({
              id: "containers.tax.treatyTable.withholdingRate",
            })}
          </th>
          <th>{formatMessage({ id: "containers.tax.treatyTable.treatyArticle" })}</th>
        </tr>
      </thead>
      <tbody>
        {paymentCategories &&
          Object.keys(paymentCategories)
            .filter(
              (key: PaymentCategory) =>
                !!paymentCategories[key] &&
                (paymentCategoryLabels[key]?.incomeCode || paymentCategories1099K.includes(key)),
            )
            .map(
              (key: PaymentCategory) =>
                country && (
                  <tr key={key}>
                    <th>{paymentCategoryLabels[key].name}:</th>
                    <td>
                      {new BigNumber(
                        calculatedWithholdings?.[paymentCategoryLabels[key]?.incomeCode || ""]?.withholdingPercentage ||
                          "0",
                      ).toFixed(1)}
                      %
                    </td>
                    <td>
                      {key === PaymentCategory.SERVICES && services[country]?.treatyArticle}
                      {[PaymentCategory.ROYALTIES, PaymentCategory.ROYALTIES_FILM].includes(key) &&
                        royalties[country]?.treatyArticle}
                      {key === PaymentCategory.SERVICES_MARKETPLACE && "N/A"}
                    </td>
                  </tr>
                ),
            )}
      </tbody>
    </table>
  );
}

const useStyledIncomeTable = createUseStyle(({ theme }) =>
  css`
    width: 100%;
    thead {
      th {
        padding-bottom: 5px;
        border-bottom: 1px solid ${theme.colorBorder};
        &:nth-of-type(n + 2) {
          text-align: right;
        }
      }
    }
    tbody {
      th,
      td {
        padding: 4px;
      }
      td {
        text-align: right;
      }
      th {
        padding-left: 8px;
      }
    }
  `(),
);
