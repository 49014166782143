import React from "react";
import { Button, Divider, Modal, Space, Typography } from "components";
import { AccountVerification } from "store/actions/bankInfo";
import { useIntl } from "utils/context";
import { AccVerifResultEnum } from "./AccountVerificationResultAlert";

interface Props {
  result?: AccountVerification;
  submitCall?: () => void;
  open: boolean;
  onClose: () => void;
  onActivate?: () => void;
}

export default function AccountVerificationSubmitButton({ result, submitCall, open, onClose, onActivate }: Props) {
  const { formatMessage } = useIntl();

  function submit() {
    if (submitCall) {
      submitCall();
    }
  }

  function getModalTitle() {
    return (
      <Typography.Text strong>
        {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.dialog.title" })}
      </Typography.Text>
    );
  }

  function getModalBody() {
    return formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.dialog.body" });
  }

  function getOnOkPrimaryButtonLabel() {
    return formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.dialog.okPrimaryButton" });
  }

  function getOnCancelButtonLabel() {
    return formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.dialog.cancelButton" });
  }

  function onOkPrimaryModalButton() {
    if (onActivate) {
      onActivate();
    }
    submit();
    onClose();
  }

  function onCancelModalButton() {
    onClose();
  }

  function renderCancelButtonModal() {
    return (
      <Button onClick={onCancelModalButton} aria-label={getOnCancelButtonLabel()}>
        {getOnCancelButtonLabel()}
      </Button>
    );
  }

  function renderPrimaryButtonModal() {
    return (
      <Button type="primary" onClick={onOkPrimaryModalButton} aria-label={getOnOkPrimaryButtonLabel()}>
        {getOnOkPrimaryButtonLabel()}
      </Button>
    );
  }

  function getModalButtons() {
    return (
      <Space justify="right">
        {renderCancelButtonModal()}
        {renderPrimaryButtonModal()}
      </Space>
    );
  }

  return (
    <Modal
      size="small"
      open={open && (!result || result?.reasonCode !== AccVerifResultEnum.AV100)}
      closable
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
    >
      {getModalTitle()}
      <Divider transparent />
      {getModalBody()}
      <Divider transparent />
      {getModalButtons()}
    </Modal>
  );
}
