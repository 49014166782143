import { Button, ButtonProps } from "components";
import config from "config";
import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "utils/context";

interface Props extends Omit<ButtonProps, "onClick" | "href"> {
  title?: string;
}
export default function BackButton(props: Props) {
  const { title, size = "large", ...rest } = props;
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    config.CLIENT === "portal" // use const CLIENT instead of context client because the memoryHistory/browserHistory is tied to the config
      ? history.location.key // on the first page, there is no key in browserHistory
      : (history as any).index > 0
  ) ? (
    // in widget memHistory has index
    <Button onClick={history.goBack} size={size} {...rest}>
      {title ?? formatMessage({ id: "common.back" })}
    </Button>
  ) : null;
}
