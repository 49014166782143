import { Ticket, TicketDocumentType, TicketListResponse } from "@trolley/common-frontend";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { createFormData, emitEvent, Query, WidgetEvent } from "utils/helpers";
import { isLoaded } from "./actionUtils";
import { RcFile } from "rc-upload/lib/interface";

export interface TicketsQuery extends Query<Ticket> {}

export const TICKET_RESOLUTION_DELAY = 3000;

export function loadTicketsWithDelay(recipientId: string, force?: true) {
  const id = "data";
  const { tickets } = store.getState();

  if (force || !isLoaded(tickets.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "tickets", { id });
    /*
     When we provide data for the ticket reason, the ticket itself is not updated right away.
     This is a "hack" as we expect ticket to get updated within TICKET_RESOLUTION_DELAY.
     It does not always work, but it is the best we can do at the moment without reworking how tickets work in the backend.
     Only applicable to ticket reasons. Ticket document request are updated right away.
    */
    window.setTimeout(() => loadTickets(recipientId), force ? TICKET_RESOLUTION_DELAY : 0);
  }
}

export async function loadTickets(recipientId: string) {
  const id = "data";
  const { merchant } = store.getState();

  try {
    standardDispatch(OpCode.LOADING, "tickets", { id });
    const response = await request.POST<TicketListResponse>("/v1/ticket/search", {
      query: {
        recipientId,
        pageSize: 1000,
        includeSubmerchantTicket: !!merchant?.features?.syncRecipients,
      },
    });

    standardDispatch(OpCode.DATA, "tickets", {
      id,
      data: {
        records: response.body.tickets,
        meta: response.body.meta,
      },
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "tickets", { id, errors });
  }
}

export async function uploadTicketDocuments(
  ticket: Ticket,
  documentType: TicketDocumentType,
  files: RcFile[],
  recipientId: string,
) {
  try {
    standardDispatch(OpCode.LOADING, "tickets");
    for (const file of files) {
      const formData = createFormData([file], { ticketId: ticket.id, documentType, recipientId });
      await request.POST("/v1/ticket/documents", { isUpload: true, body: formData });
    }
    await loadTickets(recipientId);
    standardDispatch(OpCode.LOADING, "tickets", { loading: false });

    emitEvent({
      event: WidgetEvent.TICKET_UPDATED,
      ticket: {
        id: ticket.id,
      },
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "tickets", {
      errors,
    });
    throw errors;
  }
}
