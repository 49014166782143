import {
  BankAccountType,
  BaseError,
  Country,
  CountryCode,
  CurrencyCode,
  PayoutMethodType,
  Rules,
  formatBankRulesLabel,
  formatCountry,
  formatCurrency,
  getBankRuleProps,
  getBankRules,
  isValidIbanSwiftAccountCountry,
} from "@trolley/common-frontend";
import deepEqual from "fast-deep-equal";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { Divider, Form2 as Form, Loader, Notification } from "components";
import { PATHS } from "pages/App/routes";
import { AccountVerificationQuery, BankInfo } from "store/actions/bankInfo";
import {
  BankTransferAccountUpdate,
  Recipient,
  RecipientAccount,
  addPayoutMethod,
  updatePayoutMethod,
} from "store/actions/recipient";
import { useBankCodes } from "store/hooks/bankCodes";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { IntlMessageKeys, Translator, useIntl } from "utils/context";
import { calculateJaccardSimilarity, handleFormErrors, omitMaskedValues, pickDiff } from "utils/helpers";
import PayoutFooter from "../PayoutFooter";
import AccountHolderNameFormField from "./AccountHolderNameFormField";
import BankInfoFormField from "./BankInfoFormField";
import AccountVerificationFormField from "./AccountVerificationFormField";
import BankRegionCodeFormField from "./BankRegionCodeFormField";
import ImportantNotification from "./ImportantNotification";
import NameWarning, { getHolderNameWarnings } from "./NameWarning";
import { loadAsyncRecipientAccountConfig } from "store/actions/recipientAccountConfig";

export type FormFields = {
  /**
   * bank country. For new account, it is defaulted to the recipient's country.
   * Once created it should not be editable.
   * A country change will have the following effect:
   * - the list of available currencies may change
   * - it should reset the country rules index to 0
   */
  country: CountryCode;

  /**
   * currency of the bank account. The available currencies depends on the selected country
   * A currency change will have the following impact:
   * - it should reset the country rules index to 0
   */
  currency: CurrencyCode;

  bankAccountType?: BankAccountType; // check or saving account

  iban?: string;
  swiftBic?: string;
  // bankName?: string; // this will not appear in the Rules. and there's no need to submit, api will use the BankInfo's bank Name
  bankAddress?: string; // only necessary for SWIFTBIC, as it is often the HQ's address, this address is specify their branch address
  bankCity?: string; // only necessary for SWIFTBIC, as it is often the HQ's address, this address is specify their branch address
  bankRegionCode?: string; // only necessary for SWIFTBIC, as it is often the HQ's address, this address is specify their branch address
  bankCodeMappingId?: string; // should be number, but <Select> transforms it to string.
  bankId?: string;
  branchId?: string;
  accountNum?: string;

  accountHolderName: string; // always required

  accountHolderFirstName?: string;
  accountHolderLastName?: string;

  bankInfo?: BankInfo | BaseStatus;

  primary?: boolean;
};

type Props = {
  account: RecipientAccount | undefined;
  currencies: Partial<Record<CountryCode, Country.CountryCurrency[]>>;
  onCurrencyChange?: (currency: CurrencyCode) => void;
};

export default function BankTransfer({ account, currencies, onCurrencyChange }: Props) {
  const [form] = Form.useForm<FormFields>();
  const recipient = useRecipient();
  const merchant = useMerchant();
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setError] = useState("");
  const [showHolderNamePopup, setShowHolderNamePopup] = useState(false);
  const [bankRulesIndex, setBankRulesIndex] = useState(0);
  const payoutCountry = Form.useWatch("country", form) || account?.country; // we can fallback because country is non-editable when account exists. this prevents multiple useEffect trigger in STEP 1
  const currency = Form.useWatch("currency", form) || account?.currency;
  const { data: bankCodes } = useBankCodes(payoutCountry, currency);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const accountVerifStatus = Form.useWatch("accountVerifStatus", form);
  const accountVerif = Form.useWatch("accountVerif", form);
  const requiresAccountVerification = Form.useWatch("requiresAccountVerification", form);
  const bankInfoStatus = Form.useWatch("bankInfoStatus", form);

  const [allRules, setAllRules] = useState<
    {
      label: string;
      bankFields: Rules;
    }[]
  >(
    account
      ? getDefaultBankRule(account.country, account.currency)
      : getDefaultBankRule(recipient?.address.country, getMainCurrency(currencies[recipient?.address.country || ""])),
  );

  const countryRule = useMemo(() => allRules[bankRulesIndex]?.bankFields || [], [allRules, bankRulesIndex]);

  /**
   * when a country or currency changes, we fetch the new bank rules (BankFieldType[])
   * */
  useEffect(() => {
    if (payoutCountry && currency && recipient) {
      loadAsyncRecipientAccountConfig({
        recipientId: recipient.id,
        accountType: PayoutMethodType.BANKTRANSFER,
        countryCode: payoutCountry,
        currencyCode: currency,
      })
        .then((recipientAccountConfig) => {
          updateAllRules(
            recipientAccountConfig?.requiredFields.length
              ? recipientAccountConfig?.requiredFields
              : getDefaultBankRule(payoutCountry, currency),
          );
        })
        .catch(() => {
          updateAllRules(getDefaultBankRule(payoutCountry, currency));
        });
    }
  }, [payoutCountry, currency]);

  useEffect(() => {
    if (onCurrencyChange) {
      onCurrencyChange(currency);
    }
  }, [currency]);

  function updateAllRules(allNewRules: ReturnType<typeof getBankRules>) {
    setAllRules(allNewRules);

    if (!deepEqual(allNewRules, allRules)) {
      setAllRules(allNewRules);
    }

    if (account) {
      const ruleIndex = getBankFieldRulesIndex(allNewRules, account);
      setBankRulesIndex(ruleIndex > 0 ? ruleIndex : 0);
    } else {
      setBankRulesIndex(0);
    }
  }

  if (!recipient) {
    return null;
  }

  async function onSave({
    bankInfo, // only used to make sure the bank exist,
    bankCodeMappingId, // should be saved in number
    ...formValues
  }: FormFields) {
    if (recipient) {
      setSubmitting(true);
      const values: BankTransferAccountUpdate = {
        ...formValues,
        bankCodeMappingId: bankCodeMappingId ? parseInt(bankCodeMappingId, 10) : undefined,
        type: PayoutMethodType.BANKTRANSFER,
        ...(formValues.accountHolderFirstName && formValues.accountHolderFirstName
          ? { accountHolderName: `${formValues.accountHolderFirstName} ${formValues.accountHolderLastName}` }
          : {}),
      };

      const updates = account ? pickDiff(omitMaskedValues(values), account) : values;
      try {
        if (account?.recipientAccountId) {
          if (updates) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, updates);
          }
          history.push(PATHS.HOME);
        } else {
          if (updates) {
            await addPayoutMethod(recipient.id, updates);
          }
          history.push(PATHS.PAYOUT_COMPLETE);
        }
      } catch (errors) {
        if (errors?.some?.((err: BaseError) => "field" in err)) {
          handleFormErrors(
            errors?.map?.((error: BaseError) => {
              if (error?.field === "currency" && error.code === "invalid_field") {
                // Overriding currency error as we know the cause of it.
                // This also allows us to use translation.
                return {
                  ...error,
                  message: formatMessage(
                    { id: "containers.bankPayoutMethod.currency.invalid_for_recipientType" },
                    {
                      recipientType: formatMessage({
                        id:
                          recipient.type === "indvidual"
                            ? "containers.info.type.individual"
                            : "containers.info.type.business",
                      }),
                      currencyCode: formatCurrency(values.currency, formatMessage),
                      country: formatCountry(values.country, formatMessage),
                    },
                  ),
                };
              }

              return error;
            }) ?? errors,
            form,
          );
        } else {
          setError(
            typeof errors === "string"
              ? errors
              : errors?.find((err: BaseError) => err.message)?.message ||
                  errors?.message ||
                  formatMessage({ id: "containers.mainContainer.somethingWentWrong" }),
          );
        }
      }
      setSubmitting(false);
    }
  }

  function submitCall() {
    form.submit();
  }

  return (
    <Loader spinning={submitting}>
      <Form<FormFields>
        form={form}
        initialValues={getInitialValues(account, recipient, currencies)}
        requiredMark={account ? false : "optional"}
        onFinish={async (formValues: FormFields) => {
          if (recipient && !submitting) {
            if (
              !showHolderNamePopup &&
              getHolderNameWarnings(formatMessage, recipient, formValues.accountHolderName).length > 0
            ) {
              // Give warning that account holder name is not the same as profile name
              setShowHolderNamePopup(true);
            } else {
              setShowHolderNamePopup(false);
              await onSave(formValues);
            }
          }
        }}
        validateTrigger={["onSubmit", "onChange"]} // onChange is necessary. we look for bankInfo only if there are no errors. The fields needs to be validated as the user types
      >
        {!countryRule.includes("bankCodeMappingId") && (
          <ImportantNotification payoutCountry={payoutCountry} currency={currency} />
        )}

        {!account && merchant?.features?.allowUSBankTransferAccount && recipient.address.country !== CountryCode.US ? (
          <Form.Field
            name="country"
            label={formatMessage({
              id: "containers.bankPayoutMethod.country.title",
            })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "containers.bankPayoutMethod.country.required",
                }),
              },
            ]}
          >
            <Form.SelectCountry
              disabled={!!account}
              type="banking"
              includes={[CountryCode.US, recipient.address.country as CountryCode]}
              onChange={(country: CountryCode) => {
                if (!account) {
                  const payoutCountryCurrencies = (country && currencies[country]) || [];
                  form.setFieldsValue({
                    currency: getMainCurrency(payoutCountryCurrencies),
                  });
                }
              }}
            />
          </Form.Field>
        ) : (
          <Form.Field name="country" hidden />
        )}

        <Form.Field
          name="currency"
          label={formatMessage({
            id: "containers.bankPayoutMethod.currency.title",
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.bankPayoutMethod.currency.required",
              }),
            },
          ]}
        >
          <Form.Select options={buildDownCurrencies(currencies[payoutCountry], formatMessage)} disabled={!!account} />
        </Form.Field>

        {!account && allRules.length > 1 && (
          <Form.Item>
            <Form.Radio.Group
              optionType="card"
              value={bankRulesIndex}
              options={allRules.map((type, index) => ({
                label: formatBankRulesLabel(type.bankFields, payoutCountry, formatMessage),
                value: index,
              }))}
              onChange={(e) => {
                setBankRulesIndex(Number(e.target.value));
              }}
            />
          </Form.Item>
        )}

        {countryRule.map((field, fieldIndex) => (
          <Form.Control key={field} dependencies={[field, "bankId"]}>
            {({ getFieldsValue }) => {
              const { [field]: value, bankId } = getFieldsValue();
              const inputProps = getBankRuleProps(
                field,
                value,
                {
                  country: payoutCountry,
                  currency,
                  bankId,
                },
                formatMessage,
              );

              const allowInputEditing =
                !account || !FIELDS_REQUIRING_BANK_LOOKUP.includes(field) || field === "accountNum";

              if (inputProps) {
                return (
                  <React.Fragment key={field}>
                    <Form.Field
                      name={field}
                      dependencies={FIELDS_REQUIRING_BANK_LOOKUP}
                      label={inputProps.label}
                      validateFirst
                      normalize={inputProps.normalize}
                      extra={inputProps.tooltip || inputProps.hint}
                      rules={
                        allowInputEditing
                          ? [
                              ...(inputProps.rules || []),

                              /**
                               * Custom validation for IBAN/SWIFT account
                               * when currency is in EUR, and the field is swiftBIC/IBAN,
                               * we test if the payout country supports the swiftBIC/IBAN from a different country
                               * */
                              ({ getFieldValue }) => ({
                                async validator(_: any, value: string) {
                                  if (["iban", "swiftBic"].includes(field) && value && !value.includes("*")) {
                                    const accountCountryStr = String(value || "")
                                      .substr(field === "iban" ? 0 : 4, 2)
                                      .toLocaleUpperCase();
                                    const accountCountry =
                                      accountCountryStr in CountryCode ? (accountCountryStr as CountryCode) : undefined;

                                    if (
                                      accountCountry &&
                                      !isValidIbanSwiftAccountCountry({
                                        payoutCountry: getFieldValue("country") as CountryCode,
                                        payoutCurrency: getFieldValue("currency") as CurrencyCode,
                                        accountCountry,
                                        accountCountryAcceptedCurrencies:
                                          currencies[accountCountry]?.map((v) => v.currency) || [],
                                      })
                                    ) {
                                      throw formatMessage(
                                        {
                                          id: `containers.bankPayoutMethod.${field}Country` as IntlMessageKeys,
                                        },
                                        {
                                          country: formatCountry(getFieldValue("country"), formatMessage),
                                        },
                                      );
                                    }
                                  }
                                },
                              }),

                              /**
                               * This rule mainly applies for Canada, for each bankId/currency pair, we know the format of some account number.
                               * if the format doesn't match, we give them a warning
                               *
                               * eg. TD Bank (bankId 004), USD account numbers starts with 7.
                               *     Thus, if the user is using CAD, and account starts with 7, we give them a warning
                               *     Thus, if the user is using USD, and account DOES NOT starts with 7, we give them a warning
                               *  */
                              {
                                warningOnly: true,
                                async validator(rule, value) {
                                  if (inputProps.warning) {
                                    throw new Error(inputProps.warning);
                                  }
                                },
                              },
                            ]
                          : []
                      }
                    >
                      {inputProps.options ? (
                        <Form.Select
                          placeholder={inputProps.placeholder}
                          readOnly={!allowInputEditing}
                          options={
                            field === "bankCodeMappingId"
                              ? bankCodes.map((code) => ({
                                  label: code.bankName,
                                  value: code.id,
                                }))
                              : inputProps.options
                          }
                        />
                      ) : (
                        <Form.Input
                          type="text"
                          name={field}
                          readOnly={!allowInputEditing}
                          placeholder={inputProps.placeholder}
                        />
                      )}
                    </Form.Field>

                    {
                      // show "bankRegionCode" after "bankCity", as it is never returned in the countryRules
                      field === "bankCity" && <BankRegionCodeFormField />
                    }
                  </React.Fragment>
                );
              }

              return null;
            }}
          </Form.Control>
        ))}

        <BankInfoFormField
          recipientAccount={account}
          countryRule={countryRule}
          requiresAccountVerification={requiresAccountVerification}
        />

        <Divider transparent />

        <AccountHolderNameFormField />

        <Divider transparent />

        {!!merchant?.features?.accountVerificationUk && recipient?.address.country === CountryCode.GB && (
          <AccountVerificationFormField countryRule={countryRule} account={account} />
        )}

        <Divider transparent />

        {apiError && (
          <Notification type="error" title={formatMessage({ id: "containers.mainContainer.somethingWentWrong" })}>
            {apiError}
          </Notification>
        )}

        <PayoutFooter
          busy={submitting || bankInfoStatus === BaseStatus.LOADING || accountVerifStatus === BaseStatus.LOADING}
          account={account}
          disableOtherSubmit={requiresAccountVerification && !account}
          accountVerificationResult={accountVerif}
          submitCall={submitCall}
        />

        <Form.Control dependencies={["accountHolderName"]}>
          {({ getFieldValue }) => {
            const accountHolderName = getFieldValue("accountHolderName");
            const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, accountHolderName);

            return (
              holderNameWarnings.length > 0 && (
                <NameWarning
                  visible={showHolderNamePopup}
                  onClose={() => {
                    setShowHolderNamePopup(false);
                  }}
                  onOk={async () => {
                    form.submit();
                  }}
                  submitting={submitting}
                  holderNameWarnings={holderNameWarnings}
                  accountHolderName={accountHolderName}
                  recipient={recipient}
                />
              )
            );
          }}
        </Form.Control>
      </Form>
    </Loader>
  );
}

export function getBankFieldRulesIndex(rules: { label: string; bankFields: Rules }[], account: RecipientAccount) {
  const cleanedAccount = Object.entries(account) // Removes undefined and empty values
    .filter(([_, v]) => v)
    .map(([k, _]) => k);

  let closestIndex = 0;
  let closestSimilarity = 0;

  rules.forEach((raConfig, index) => {
    const similarity = calculateJaccardSimilarity(raConfig.bankFields, cleanedAccount);
    if (similarity > closestSimilarity) {
      closestIndex = index;
      closestSimilarity = similarity;
    }
  });

  return closestIndex;
}

export const FIELDS_REQUIRING_BANK_LOOKUP = [
  "country",
  "currency",
  "iban",
  "swiftBic",
  "branchId",
  "bankId",
  "accountNum",
];

export const FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP = [
  "accountHolderName",
  "accountHolderFirstName",
  "accountHolderLastName",
  "currency",
  "iban",
  "branchId",
  "accountNum",
];

export function mapBankAccountQuery(
  fields: any,
  recipientId: string,
  country: CountryCode,
  recipientAccountId?: string | null,
) {
  type BankAccountLookUpFields =
    | "accountHolderName"
    | "accountHolderFirstName"
    | "accountHolderLastName"
    | "iban"
    | "branchId"
    | "accountNum"
    | "currency";
  const mappedFields: Record<BankAccountLookUpFields, string> = {
    accountHolderName: "name",
    accountHolderFirstName: "firstName",
    accountHolderLastName: "lastName",
    iban: "iban",
    branchId: "sortCode",
    accountNum: "accountNumber",
    currency: "currency",
  };

  if (
    fields.currency &&
    (fields.accountHolderName || (fields.accountHolderFirstName && fields.accountHolderLastName)) &&
    (fields.iban || (fields.branchId && fields.accountNum))
  ) {
    return FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP.reduce(
      (query, requiredField: BankAccountLookUpFields) => {
        if (fields[requiredField]) {
          return {
            ...query,
            [mappedFields[requiredField]]: fields[requiredField],
          };
        }

        return query;
      },
      {
        recipientId,
        country,
        ...(recipientAccountId ? { recipientAccountId } : {}),
      },
    ) as AccountVerificationQuery;
  }

  return null;
}

export function getInitialValues(
  recipientAccount: RecipientAccount | undefined,
  recipient: Recipient | undefined,
  currencies: Props["currencies"],
) {
  const accountHolderNameTokens = recipientAccount?.accountHolderName?.split(" ");

  return recipientAccount
    ? {
        ...recipientAccount,
        accountHolderFirstName:
          accountHolderNameTokens && accountHolderNameTokens.length > 0 && accountHolderNameTokens[0],
        accountHolderLastName:
          accountHolderNameTokens && accountHolderNameTokens.length > 1 && accountHolderNameTokens.slice(1).join(" "),
      }
    : {
        country: recipient?.address.country,
        currency: recipient?.address.country && getMainCurrency(currencies[recipient.address.country]),
      };
}

export function getMainCurrency(countryCurrencies: Country.CountryCurrency[] | undefined) {
  return countryCurrencies?.find((c) => c.isPrimary)?.currency || countryCurrencies?.[0]?.currency;
}

function buildDownCurrencies(
  countryCurrencies: Country.CountryCurrency[] | undefined,
  formatMessage: Translator,
): { label: string; value: string }[] {
  const options: { label: string; value: string }[] = [];

  (countryCurrencies ?? []).forEach((item) => {
    const currencyLabel = formatCurrency(item.currency, formatMessage);
    const optionItem = {
      label: currencyLabel ? `${currencyLabel} (${item.currency})` : item.currency,
      value: item.currency,
    };

    if (item.isPrimary) {
      options.unshift(optionItem);
    } else {
      options.push(optionItem);
    }
  });

  return options;
}

export function getDefaultBankRule(
  payoutCountry: CountryCode | null | undefined,
  currency: CurrencyCode | null | undefined | "",
) {
  return getBankRules(payoutCountry, currency).filter((rule) => !rule.bankFields.includes("bankCodeMappingId"));
}
