import { BackButton, Button, ButtonProps, Footer } from "components";
import React from "react";
import { useIntl } from "utils/context";

interface Props {
  loading?: boolean;
  okText?: string;
  onClick?: ButtonProps["onClick"];
}

export default function TaxFooter({ loading, okText, onClick }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Footer
      main={
        <Button type="primary" htmlType="submit" size="large" loading={loading} onClick={onClick}>
          {okText || formatMessage({ id: "common.continue" })}
        </Button>
      }
      extra={<BackButton />}
    />
  );
}
