import { Checkbox as CheckboxAnt } from "antd";
import { CheckboxGroupProps as AntCheckboxGroupProps } from "antd/lib/checkbox";
import React from "react";

interface CheckboxGroupProps extends AntCheckboxGroupProps {}

export default function CheckboxGroup(props: CheckboxGroupProps) {
  const { className, ...rest } = props;

  return <CheckboxAnt.Group {...rest} className={className} />;
}
