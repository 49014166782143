import { getSupportedLocaleCode, IFrameAuthResponse, SupportedLocales } from "@trolley/common-frontend";
import { client, initLocale } from "utils/context";

import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import * as ACTIONS from "store/actions";
import { BaseError, OpCode } from "store/reducers/standardReducer";
import { authTokenGet, authTokenSet, getDeviceId } from "utils/helpers";
import { IFrameConfig } from "store/hooks/config";
import { standardDispatch } from "store/dispatcher";

export type SessionBody = IFrameAuthResponse;

export enum SessionMode {
  ACTIVE = "active",
  EXPIRED = "expired",
  ENDED = "ended",
}

export type AuthType = {
  fetching?: true;
  errors?: BaseError[];
  state?: SessionMode;
};

export async function loadAuthToken(raw: string) {
  try {
    const token = authTokenGet();

    if (token) {
      const { body } = await request.POST<SessionBody>("/v1/iframe/authToken", {
        query: { raw },
      });

      await initStore(body);
    } else {
      throw new Error("token mismatch");
    }
  } catch (errors) {
    store.dispatch({
      type: ACTIONS.ERROR_AUTH,
      errors: [errors],
    });

    throw errors;
  }
}

export async function loadAuth(raw: string) {
  const deviceId = getDeviceId();

  store.dispatch({
    type: ACTIONS.REQUEST_AUTH,
  });

  try {
    const { body } = await request.POST<SessionBody>("/v1/iframe/authorize", {
      query: { raw, deviceId },
      noToken: true,
    });

    await initStore(body);
  } catch (errors) {
    store.dispatch({
      type: ACTIONS.ERROR_AUTH,
      errors,
    });

    throw errors;
  }
}

export async function initStore(session: SessionBody) {
  const { auth, params, config, merchant, recipient } = session;
  let locale: SupportedLocales;

  if (params) {
    if (params.locale) {
      if (params.locale === "in") {
        // "in" is deprecated. it's not a locale. id is the ISO locale for Indonesian
        locale = SupportedLocales.ID;
      }
    }
    locale = getSupportedLocaleCode(params.locale || recipient?.language) || SupportedLocales.EN;

    await initLocale(locale);
  }

  if (auth?.token) {
    // this is null when requesting session through /v1/iframe/authToken.
    // eg. when one refresh the page. token is in the browser's sessionStorage
    authTokenSet(auth.token);
  }

  batch(() => {
    if (params && ((client === "portal" && recipient) || !store.getState().params)) {
      // dont' update if alreayd set, or only update on portal if the recipient is present (not before auth)
      // params.locale is updated locally
      store.dispatch({
        type: ACTIONS.RECEIVE_PARAMS,
        data: {
          ...params,
          locale,
        },
      });
    }

    const iframeConfig = config as IFrameConfig;
    store.dispatch({
      type: ACTIONS.RECEIVE_CONFIG,
      data: {
        ...iframeConfig,
        colors: {
          ...iframeConfig.colors,
          info: iframeConfig.colors?.info || iframeConfig.colors?.primary,
        },
      },
    });

    // auth.token is null when requesting data through /v1/iframe/authToken
    // or when API is requesting TFA code
    // so get token from sessionStorage
    const token = authTokenGet();

    if (token) {
      store.dispatch({
        type: ACTIONS.RECEIVE_AUTH,
        data: {
          token,
          state: SessionMode.ACTIVE,
        },
      });
    }

    if (merchant) {
      store.dispatch({
        type: ACTIONS.RECEIVE_MERCHANT,
        data: merchant,
      });
    }

    if (recipient) {
      standardDispatch(OpCode.DATA, "recipient", {
        data: recipient,
      });
    }
  });
}
