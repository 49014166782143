import { TaxEntityTypeUS } from "@trolley/common-frontend";

const taxTypeLabels: Record<TaxEntityTypeUS, string> = {
  [TaxEntityTypeUS.INDIVIDUAL]: "Individual",
  [TaxEntityTypeUS.SOLE_PROP]: "Sole Proprietor",
  [TaxEntityTypeUS.LLC_SINGLE]: "LLC - Single Member",
  [TaxEntityTypeUS.LLC_C_CORP]: "LLC - C Corporation",
  [TaxEntityTypeUS.LLC_S_CORP]: "LLC - S Corporation",
  [TaxEntityTypeUS.LLC_PARTNER]: "LLC Partnership",
  [TaxEntityTypeUS.C_CORP]: "C Corporation",
  [TaxEntityTypeUS.S_CORP]: "S Corporation",
  [TaxEntityTypeUS.PARTNERSHIP]: "Partnership",
  [TaxEntityTypeUS.TRUST]: "Trust",
  [TaxEntityTypeUS.OTHER]: "Other",
};

export const taxTypes: {
  label: string;
  value: "" | TaxEntityTypeUS;
  disabled?: boolean;
}[] = [
  // todo
  { label: "Select a Classification", value: "", disabled: true },
  ...Object.entries(taxTypeLabels).map(([value, label]: [TaxEntityTypeUS, string]) => ({
    label,
    value,
  })),
];

export const idTypes = [
  { label: "Select a Tax Identifier Type", value: "", disabled: true },
  { label: "Social Security Number (SSN)", value: "ssn" },
  { label: "Employer Identification Number (EIN)", value: "tin" },
];

export const payeeCodes = [
  { label: "Select a code", value: "" },
  { label: "Code 1", value: "1" },
  { label: "Code 2", value: "2" },
  { label: "Code 3", value: "3" },
  { label: "Code 4", value: "4" },
  { label: "Code 5", value: "5" },
  { label: "Code 6", value: "6" },
  { label: "Code 7", value: "7" },
  { label: "Code 8", value: "8" },
  { label: "Code 9", value: "9" },
  { label: "Code 10", value: "10" },
  { label: "Code 11", value: "11" },
  { label: "Code 12", value: "12" },
  { label: "Code 13", value: "13" },
];

export const reportingCodes = [
  { label: "Select a code", value: "" },
  { label: "Code A", value: "A" },
  { label: "Code B", value: "B" },
  { label: "Code C", value: "C" },
  { label: "Code D", value: "D" },
  { label: "Code E", value: "E" },
  { label: "Code F", value: "F" },
  { label: "Code G", value: "G" },
  { label: "Code H", value: "H" },
  { label: "Code I", value: "I" },
  { label: "Code J", value: "J" },
  { label: "Code K", value: "K" },
  { label: "Code L", value: "L" },
  { label: "Code M", value: "M" },
];

export function getUSTaxEntityTinType(taxType: TaxEntityTypeUS | undefined): ("ssn" | "tin" | undefined)[] {
  switch (taxType) {
    case TaxEntityTypeUS.INDIVIDUAL:
      return ["ssn"];
    case TaxEntityTypeUS.LLC_C_CORP:
    case TaxEntityTypeUS.LLC_S_CORP:
    case TaxEntityTypeUS.LLC_PARTNER:
    case TaxEntityTypeUS.C_CORP:
    case TaxEntityTypeUS.S_CORP:
    case TaxEntityTypeUS.PARTNERSHIP:
    case TaxEntityTypeUS.TRUST:
    case TaxEntityTypeUS.OTHER:
      return ["tin"];
    case TaxEntityTypeUS.LLC_SINGLE:
    case TaxEntityTypeUS.SOLE_PROP:
      return ["ssn", "tin"];
    default:
      return [];
  }
}

export const TIN_FORMAT = {
  ssn: "999-99-9999",
  tin: "99-9999999",
};
