import { Store } from "store";
import { useSelector } from "react-redux";

export function customShallowEqual<T>(objA: T, objB: T): boolean {
  if (Object.is(objA, objB)) return true;

  if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i += 1) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i])) {
      return false;
    }
    if (!Object.is(objA[keysA[i]], objB[keysA[i]])) {
      if (["data", "records", "meta"].includes(keysA[i])) {
        if (!customShallowEqual(objA[keysA[i]], objB[keysA[i]])) {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  return true;
}

export function useShallowSelector<TSelected>(selector: (state: Store) => TSelected) {
  return useSelector<Store, TSelected>(selector, customShallowEqual);
}
