import { useEffect, useState } from "react";
import { useShallowSelector } from ".";
import { loadBankInfo, BankQuery, AccountVerificationQuery, loadAccountVerification } from "store/actions/bankInfo";
import { RecipientType } from "@trolley/common-frontend";

export function useBankInfo(query: BankQuery | undefined) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query) {
      setFetchId(loadBankInfo(query));
    } else {
      setFetchId("");
    }
  }, [query]);

  return useShallowSelector((state) => {
    if (fetchId && query && state.bankInfo.fetchStatus[fetchId] === undefined) {
      loadBankInfo(query);
    }

    return {
      data: state.bankInfo.entities[fetchId],
      status: state.bankInfo.fetchStatus[fetchId],
      error: state.bankInfo.errors[fetchId],
    };
  });
}

export function useAccountVerification(query: AccountVerificationQuery | undefined, recipientType?: RecipientType) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query) {
      setFetchId(loadAccountVerification(query, recipientType));
    } else {
      setFetchId("");
    }
  }, [query, recipientType]);

  return useShallowSelector((state) => {
    if (fetchId && query && state.accountVerification.fetchStatus[fetchId] === undefined) {
      loadAccountVerification(query, recipientType);
    }

    return {
      data: state.accountVerification.entities[fetchId],
      status: state.accountVerification.fetchStatus[fetchId],
      error: state.accountVerification.errors[fetchId],
    };
  });
}
