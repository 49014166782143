import { Button, Container, Divider, Icon, Typography } from "components";
import React from "react";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useIntl } from "utils/context";
import { WizardPageProps } from ".";

export default function GDPRMessage({ onContinue }: WizardPageProps) {
  const merchant = useMerchant();
  const config = useIframeConfig();
  const { formatMessage } = useIntl();

  return (
    <Container padding="none">
      <Typography.Title style={{ marginBottom: "18px" }}>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageTitle" })}
      </Typography.Title>
      <Typography.Paragraph>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageParagraph1" }, { merchantName: merchant?.name })}
        <Divider transparent />
        <ul style={{ listStyleType: "disc", marginLeft: "10px" }}>
          {merchant?.payment?.categories?.goods && (
            <li>{formatMessage({ id: "containers.taxProfile.wizard.gdprMessageSellingGoods" })}</li>
          )}
          {merchant?.payment?.categories?.services && (
            <li>{formatMessage({ id: "containers.taxProfile.wizard.gdprMessageSellingServices" })}</li>
          )}
        </ul>
      </Typography.Paragraph>
      <Divider size="large" transparent />
      <Typography.Title level={2} style={{ marginBottom: "18px" }}>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageQuestion1" })}
      </Typography.Title>
      <Typography.Paragraph>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageResponse1" })}
      </Typography.Paragraph>
      <Divider size="large" transparent />
      <Typography.Title level={2} style={{ marginBottom: "18px" }}>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageQuestion2" })}
      </Typography.Title>
      <Typography.Paragraph>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageResponse2" })}
      </Typography.Paragraph>
      <Divider size="large" transparent />
      <Typography.Title level={2} style={{ marginBottom: "18px" }}>
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageQuestion3" })}
      </Typography.Title>
      <Typography.Paragraph>
        <ul style={{ listStyleType: "disc", marginLeft: "10px" }}>
          <li>{formatMessage({ id: "containers.taxProfile.wizard.gdprMessageResponse31" })}</li>
          <li>{formatMessage({ id: "containers.taxProfile.wizard.gdprMessageResponse32" })}</li>
        </ul>
      </Typography.Paragraph>
      <Divider size="xlarge" transparent />
      <Button
        type="primary"
        block
        onClick={() => {
          onContinue();
        }}
      >
        {formatMessage({ id: "containers.taxProfile.wizard.gdprMessageBegin" })} <Icon type="arrow-right" />
      </Button>
      <Divider size="large" transparent />
      {config.privacyLink && (
        <Typography.Paragraph type="secondary">
          {formatMessage(
            {
              id: "containers.taxProfile.wizard.gdprMessagePrivacyLink",
            },
            {
              link: (text: string) => (
                <Button
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    /^(https|http)?:\/\//.test(config.privacyLink ?? "")
                      ? config.privacyLink
                      : `https://${config.privacyLink}`
                  }
                >
                  {text}
                </Button>
              ),
            },
          )}
        </Typography.Paragraph>
      )}
    </Container>
  );
}
