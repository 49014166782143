const colors = {
  primary: "#0095FF",
  blue: "#0044cc",
  green: "#009c4b",
  orange: "#fa8100",
  red: "#fa0021",
  dark: "#000000",
  grey: "#b5b5b5",
  light: "#ffffff",
};

type Colors = typeof colors;

export { colors, Colors };

export default colors;
