import { Container, Divider, LinkButton, Typography } from "components";
import { PATHS } from "pages/App/routes";
import React from "react";
import { Link } from "react-router-dom";
import CompletionNotification from "./CompletionNotification";
import { useIntl } from "utils/context";

export default function TaxProfileComplete() {
  const { formatMessage } = useIntl();

  return (
    <Container padding="none">
      <CompletionNotification>
        {formatMessage({ id: "containers.taxProfile.wizard.taxProfileCompleteTitle" })}
      </CompletionNotification>
      <Divider size="large" transparent />
      {false && (
        <>
          <Typography.Paragraph size="large">
            Your next step would be to
            <Link to=""> complete the tax profile</Link>
          </Typography.Paragraph>
          <Divider transparent size="small" />
          <Typography.Paragraph size="large">
            <Link to="">Fill in a survey (30 seconds)</Link>
          </Typography.Paragraph>
          <Divider size="large" transparent />
        </>
      )}
      <LinkButton block type="primary" to={PATHS.HOME}>
        {formatMessage({ id: "containers.taxProfile.wizard.taxProfileExit" })}
      </LinkButton>
    </Container>
  );
}
