import { useNewStyle } from "pages/App";
import { default as React } from "react";
import css, { createUseStyle } from "style/classname";

type Props = {
  children: React.ReactNode;
};

export default function SignatureBox(props: Props) {
  const newLayout = useNewStyle();
  const styledContainer = useStyle({ ...props, newLayout });

  return <section className={styledContainer}>{props.children}</section>;
}

const useStyle = createUseStyle<Props & { newLayout: boolean }>(({ theme, ...props }) =>
  css`
    width: 100%;
    background: ${theme.colorFillSecondary};
    padding: ${theme.padding}px 16px;

    ${props.newLayout
      ? `
      border-radius: ${theme.borderRadius}px;
      `
      : `
      border-top: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
      border-bottom: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};

    `}
  `(),
);
