import { CurrencyCode, PayoutMethodType, RecursivePartial } from "@trolley/common-frontend";
import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Container,
  Divider,
  Form2,
  Grid,
  Highlight,
  Icon,
  List,
  Modal,
  Notification,
  Paragraph,
  Space,
  Text,
  TitleBar,
  Tooltip,
} from "components";
import { RecipientAccountDisplay } from "features";
import { globalTheme } from "pages/App/MainContainer";
import Notifier from "pages/Notifier";
import { notify } from "store/actions/notifications";
import { UrlParams } from "store/actions/params";
import { RecipientAccount } from "store/actions/recipient";
import css, { createUseStyle, useTheme } from "style/classname";
import { ConnectedIntlProvider, ThemeProvider, useIntl } from "utils/context";
import { parseQuery, pick } from "utils/helpers";

const recipientAccounts: Partial<RecipientAccount>[] = [
  {
    id: "AC-1",
    bankName: "Bank Name",
    iban: "123456789",
    accountHolderName: "John Smith",
    currency: CurrencyCode.USD,
    type: PayoutMethodType.BANKTRANSFER,
  },
  {
    id: "AC-2",
    currency: CurrencyCode.USD,
    emailAddress: "paypal_test@paymentrails.com",
    type: PayoutMethodType.PAYPAL,
  },
];
export default function ShowRoomWrapper() {
  const urlQuery = pick(parseQuery(window.location.search), ["colors", "style"]) as RecursivePartial<
    Pick<UrlParams, "colors" | "style">
  >;

  return (
    <ThemeProvider
      iframeConfig={{
        colors: urlQuery.colors,
        style: urlQuery.style,
      }}
      prefixCls="showroom"
    >
      <ConnectedIntlProvider>
        <ShowRoom />
      </ConnectedIntlProvider>
    </ThemeProvider>
  );
}

function ShowRoom() {
  const styledWrapper = useWrapper();
  const [loading, setLoading] = useState(false);
  const body = useRef<null | HTMLElement>(null);
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!body.current) {
      const root = document.getElementById("root");
      if (root) {
        body.current = root;
      }
    }
    if (body.current) {
      body.current.className = globalTheme(theme);
    }
  }, [theme]);

  function onClick() {
    if (!loading) {
      setLoading(true);
      window.setTimeout(() => {
        setLoading(false);
        notify("Button Clicked", { type: "info" });
      }, 1000);
    }
  }

  function renderPayoutMethod(account: RecipientAccount) {
    return (
      <Grid justify="space-between" alignItems="middle" wrap={false} padding="none">
        <Grid.Item flex={1}>
          <RecipientAccountDisplay account={account} />
        </Grid.Item>
        <Grid.Item>
          <Grid wrap={false} alignItems="middle" padding="none">
            <Grid.Item style={{ paddingRight: "6px" }}>
              <Tooltip
                title={<span style={{ textTransform: "capitalize" }}>{formatMessage({ id: "common.edit" })}</span>}
                mouseLeaveDelay={0}
              >
                <Button icon="pencil" shape="circle" aria-label="Edit" />
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip title={formatMessage({ id: "common.delete" })} mouseLeaveDelay={0}>
                <Button icon="trash-alt" shape="circle" type="danger" aria-label="Delete" />
              </Tooltip>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    );
  }

  return (
    <Form2 initialValues={{ input: "input text" }} validateTrigger="onChange" requiredMark={false}>
      <Container className={styledWrapper}>
        <Notifier />

        <Tooltip title="Successful Notification" placement="topLeft" mouseLeaveDelay={0}>
          <Notification type="success" title="Completed">
            You've completed something!
          </Notification>
        </Tooltip>

        <Tooltip title="Warning Notification" placement="topLeft" mouseLeaveDelay={0}>
          <Notification type="warning" title="Recipient Incomplete">
            <ul style={{ paddingLeft: "22px", marginBottom: "0px" }}>
              <li>Your address information is incomplete.</li>
            </ul>
          </Notification>
        </Tooltip>

        <Tooltip title="Error Notification" placement="topLeft" mouseLeaveDelay={0}>
          <Notification type="error" title="Server Error">
            Something went wrong!
          </Notification>
        </Tooltip>

        <Tooltip title="Info Notification" placement="topLeft" mouseLeaveDelay={0}>
          <Notification type="info" title="Just hello">
            Hello!
          </Notification>
        </Tooltip>

        <Highlight tooltip="Buttons">
          <Space>
            <Button size="large" type="primary" onClick={onClick} loading={loading}>
              Primary
            </Button>
            <Button size="large" onClick={onClick} loading={loading}>
              Default
            </Button>
            <Button size="large" type="success" onClick={onClick} loading={loading}>
              Success
            </Button>
            <Button size="large" type="danger" onClick={onClick} loading={loading}>
              Critical
            </Button>
            <Button size="large" disabled onClick={onClick} loading={loading}>
              Disabled
            </Button>
          </Space>

          <Divider transparent />

          <Space>
            <Button size="small" type="primary" onClick={onClick} loading={loading}>
              Primary
            </Button>
            <Button size="small" onClick={onClick} loading={loading}>
              Default
            </Button>
            <Button size="small" type="success" onClick={onClick} loading={loading}>
              Success
            </Button>
            <Button size="small" type="danger" onClick={onClick} loading={loading}>
              Critical
            </Button>
            <Button size="small" disabled onClick={onClick} loading={loading}>
              Disabled
            </Button>
          </Space>

          <Divider transparent />

          <Space>
            <Button
              type="primary"
              onClick={onClick}
              loading={loading}
              icon="star"
              iconProps={{ theme: "solid" }}
              shape="circle"
              aria-label="star"
            />
            <Button onClick={onClick} loading={loading} icon="pencil" shape="circle" aria-label="pencil" />
            <Button type="success" onClick={onClick} loading={loading} icon="check" shape="circle" aria-label="check" />
            <Button
              type="danger"
              onClick={onClick}
              loading={loading}
              icon="trash-alt"
              shape="circle"
              aria-label="delete"
            />
            <Button disabled onClick={onClick} loading={loading} icon="ban" shape="circle" aria-label="ban" />
          </Space>
        </Highlight>

        <Highlight tooltip="Links and actions color">
          <Button
            onClick={() => {
              setShowModal(true);
            }}
          >
            Open Dialog
          </Button>
        </Highlight>

        <Highlight tooltip="Heading Color">
          <TitleBar>Lorem ipsum dolor sit amet...</TitleBar>
        </Highlight>

        <Highlight tooltip="Default Text">
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Success Status">
          <Text type="success">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        {/* <Text type="info">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.
      </Text> */}

        <Highlight tooltip="Warning Color">
          <Text type="warning">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Error Color">
          <Text type="danger">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="Subtext">
          <Text type="secondary" size="small">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </Text>
        </Highlight>

        <Highlight tooltip="List View">
          <List
            items={recipientAccounts}
            renderItem={renderPayoutMethod}
            showMoreText={formatMessage({
              id: "containers.accountSummary.buttons.seeMorePayoutMethods",
            })}
            showLessText={formatMessage({
              id: "containers.accountSummary.buttons.seeLessPayoutMethods",
            })}
          />
        </Highlight>

        <Highlight tooltip="Form Input">
          <Form2.Item
            label="Form Input Label"
            name="input"
            tooltip="Tooltip message"
            rules={[{ required: true, message: "Sample error message" }]}
            validateTrigger="onChange"
          >
            <Form2.Input
              type="text"
              placeholder="placeholder"
              suffix={<Icon type="exclamation-triangle" color="warning" tooltip="Warning message" />}
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Checkbox Input">
          <Form2.Item name="checkbox" valuePropName="checked">
            <Form2.Checkbox
              name="checkbox"
              checkedChildren="Yes, it's checked"
              uncheckedChildren="No, it's not checked"
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Radio Input">
          <Form2.Item name="radio" label="I prefer...">
            <Form2.Radio.Group
              name="radio"
              options={[
                { label: "Choice A", value: "a" },
                { label: "Choice B", value: "b" },
              ]}
            />
          </Form2.Item>
        </Highlight>

        <Highlight tooltip="Paragraph size small">
          <Paragraph size="small">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Highlight tooltip="Paragraph size large">
          <Paragraph size="large">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Highlight tooltip="Paragraph size xlarge">
          <Paragraph size="xlarge">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum
          </Paragraph>
        </Highlight>

        <Modal
          open={showModal}
          title="Modal Window Title"
          onCancel={() => {
            setShowModal(false);
          }}
          onOk={() => {
            setShowModal(false);
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Modal>
      </Container>
    </Form2>
  );
}

const useWrapper = createUseStyle(({ theme }) =>
  css`
    padding-top: 64px;
    padding-bottom: 32px;
    background-color: ${theme.colorBgLayout};
  `(),
);
