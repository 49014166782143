/* istanbul ignore file */
import React, { ReactNode, useContext } from "react";

export enum PRODUCT_MODULES {
  TAX = "tax",
  PAY = "pay",
  TRUST = "trust",
}

export type ProductsContext = {
  queryProducts: PRODUCT_MODULES[];
  entitledProducts: PRODUCT_MODULES[];
};

export const defaultProducts = {
  queryProducts: [],
  entitledProducts: [],
};

const productsContext = React.createContext<ProductsContext>(defaultProducts);

export function ProductsProvider({
  value = defaultProducts,
  children,
}: {
  value?: ProductsContext;
  children: ReactNode;
}) {
  return <productsContext.Provider value={value}>{children}</productsContext.Provider>;
}

export function useProducts() {
  return useContext(productsContext);
}
