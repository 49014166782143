import * as request from "services/request";
import { OpCode, standardDispatch } from "store/dispatcher";
import store from "store";
import { Country } from "@trolley/common-frontend";

export interface CountryCurrency extends Country.CountryCurrency {}

export function loadCurrencies(force?: boolean) {
  const { currencies } = store.getState();

  if (force || !["loading", "loaded"].includes(currencies.fetchStatus.data || "")) {
    standardDispatch(OpCode.LOADING, "currencies");
    request
      .GET<Country.ListResult>("/v1/geography/countries")
      .then((res) => {
        standardDispatch(OpCode.DATA, "currencies", {
          bulk: Object.values(res.body.countries).reduce((acc: any, country: Country.Country) => {
            acc[country.code] = country.currencies;

            return acc;
          }, {} as any),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "currencies", {
          errors,
        });
      });
  }
}
