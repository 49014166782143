import React, { HTMLAttributes } from "react";
import css, { Theme, classnames, createUseStyle } from "style/classname";
import GridContext, { GridContextState } from "./GridContext";
import Item from "./Item";

export type SpaceSize = "none" | "xsmall" | "small" | "medium" | "large";
export type GridPadding = SpaceSize | [SpaceSize, SpaceSize]; // [YPadding,XPadding]
export type Breakpoints = "xs" | "sm" | "md" | "lg" | "xl";
export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  padding?: GridPadding | Partial<Record<Breakpoints, GridPadding>>;
  justify?: "start" | "center" | "space-between" | "space-around" | "end";
  alignItems?: "top" | "middle" | "stretch" | "bottom";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  wrap?: boolean;
}

export default function Grid({
  padding = ["none", "medium"],
  justify,
  alignItems,
  direction,
  className,
  wrap = true,
  ...rest
}: GridProps) {
  const styledGrid = useStyledGrid({
    padding,
    justify,
    alignItems,
    direction,
    className,
    wrap,
    ...rest,
  });

  return (
    <GridContext.Provider value={{ padding }}>
      <div {...rest} className={classnames("grid", styledGrid, className)} />
    </GridContext.Provider>
  );
}

Grid.Item = Item;

const useStyledGrid = createUseStyle<GridProps>(({ theme, animation, ...props }) =>
  css`
    &::before,
    &::after {
      display: flex;
    }
    display: flex;
    flex-wrap: ${props.wrap ? "wrap" : "nowrap"};
    flex-direction: ${props.direction};
    ${props.direction?.includes("column") && "height: 100%;"}

    ${makeGap(theme, props.padding, "grid")}

    justify-content: ${() => {
      switch (props.justify) {
        case "start":
          return "flex-start";
        case "end":
          return "flex-end";
        default:
          return props.justify;
      }
    }};

    align-items: ${() => {
      switch (props.alignItems) {
        case "top":
          return "flex-start";
        case "bottom":
          return "flex-end";
        case "middle":
          return "center";
        default:
          return props.alignItems;
      }
    }};
  `(),
);

export function makeGap(theme: Theme, gap: GridContextState["padding"] | undefined, type: "grid" | "item") {
  function getSpace(space: SpaceSize) {
    let val = type === "grid" ? "-" : "";
    switch (space) {
      case "xsmall":
        val += `2px`;
        break;
      case "small":
        val += `4px`;
        break;
      case "medium":
        val += `8px`;
        break;
      case "large":
        val += `12px`;
        break;
      default:
        val = "0px";
    }

    return val;
  }

  function parseSpacing(p: GridPadding | undefined) {
    if (p === undefined) {
      return ``;
    }

    return `${type === "grid" ? "margin" : "padding"}: ${
      Array.isArray(p) ? `${getSpace(p[0])} ${getSpace(p[1])}` : getSpace(p)
    };`;
  }

  if (gap) {
    if (Array.isArray(gap) || typeof gap === "string") {
      return parseSpacing(gap);
    }

    return `
      ${parseSpacing(gap.xs)}

      ${theme.screenUp("sm")} {
        ${parseSpacing(gap.sm)}
      }
      ${theme.screenUp("md")} {
        ${parseSpacing(gap.md)}
      }
      ${theme.screenUp("lg")} {
        ${parseSpacing(gap.lg)}
      }
      ${theme.screenUp("xl")} {
        ${parseSpacing(gap.xl)}
      }
    `;
  }

  return ``;
}
