import { PayoutMethodType } from "@trolley/common-frontend";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Form2 as Form, Loader, Notification } from "components";
import { PATHS } from "pages/App/routes";
import { RecipientAccount, addPayoutMethod, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";

type FormFields = {
  emailAddress: string;
  reEmailAddress: string;
  primary?: boolean;
};

export default function Paypal({ account }: { account: RecipientAccount | undefined }) {
  const [form] = Form.useForm();
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(values: FormFields) {
    if (!submitting && recipient) {
      setSubmitting(true);
      try {
        const data: any = { ...values, type: PayoutMethodType.PAYPAL };
        if (account?.recipientAccountId) {
          await updatePayoutMethod(recipient.id, account.recipientAccountId, data);
          history.push(PATHS.HOME);
        } else {
          data.country = recipient.address.country;
          await addPayoutMethod(recipient.id, data);
          history.push(PATHS.PAYOUT_COMPLETE);
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onSubmit}>
      <Loader spinning={submitting}>
        <Notification
          title={formatMessage({
            id: "containers.paypalPayoutMethod.important",
          })}
          size="large"
          type="warning"
        >
          <ul>
            <li>
              {formatMessage({
                id: "containers.paypalPayoutMethod.ensureEmailIsCorrect",
              })}
            </li>
            <li>
              {formatMessage({
                id: "containers.paypalPayoutMethod.paypalWithdrawalFees",
              })}
            </li>
          </ul>
        </Notification>

        <Form.Field
          style={{ maxWidth: "400px" }}
          name="emailAddress"
          label={formatMessage({
            id: "containers.paypalPayoutMethod.email.title",
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.paypalPayoutMethod.email.required",
              }),
            },
            {
              type: "email",
              message: formatMessage({
                id: "containers.paypalPayoutMethod.email.valid",
              }),
            },
          ]}
        >
          <Form.Input type="email" name="emailAddress" />
        </Form.Field>

        <Form.Field
          style={{ maxWidth: "400px" }}
          name="reEmailAddress"
          label={formatMessage({
            id: "containers.paypalPayoutMethod.reEmail.title",
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.paypalPayoutMethod.reEmail.required",
              }),
            },
            ({ getFieldValue }) => ({
              async validator(_: any, value: string) {
                if (value !== getFieldValue("emailAddress")) {
                  throw new Error(
                    formatMessage({
                      id: "containers.paypalPayoutMethod.emailMustBeSame",
                    }),
                  );
                }
              },
            }),
          ]}
        >
          <Form.Input type="email" name="reEmailAddress" />
        </Form.Field>

        <PayoutFooter busy={submitting} account={account} />
      </Loader>
    </Form>
  );
}
