import config from "config";
import { authTimeout } from "store/actions/authPortal";
import { notifyError } from "store/actions/notifications";
import { authTokenGet } from "utils/helpers";
import { RequestOptions, Response } from ".";
import handleErrors from "./handleErrors";

const baseURI = config.SERVER_ADDRESS;

export default async function requestCore<T>(url: string, optionsIn: RequestOptions = {}): Promise<Response<T>> {
  const sessionToken = authTokenGet();

  const headers = new Headers({
    "Trolley-Source": `recipient_${config.CLIENT}`,
  });

  if (!optionsIn.isUpload) {
    headers.set("Content-Type", "application/json");
  }

  if (sessionToken && optionsIn.noToken !== true) {
    headers.set("Authorization", `prwidget ${sessionToken}`);
  }

  const options: RequestOptions = {
    headers,
    ...optionsIn,
  };

  try {
    const res = await fetch(`${baseURI}${url}`, options);

    if (res.status !== 200) {
      const errorBody = await res.json();

      if (errorBody && errorBody.ok === false && errorBody.errors) {
        throw {
          status: res.status,
          body: { ok: false, errors: errorBody.errors },
        };
      } else {
        throw res;
      }
    }

    const body = optionsIn.blob ? {} : await res.json();

    if (options.blob) {
      return {
        status: res.status,
        body: {} as any,
        blob: await res.blob(),
      };
    }

    return {
      status: res.status,
      body,
    };
  } catch (error) {
    if (error.body && typeof error.status === "number") {
      // Not a fan of dispatch in this library, but it needs to be cenetralized
      // All 401 errors except for authentication related or missing_TFA will log you out
      if (error.status === 401) {
        authTimeout();
      } else if (/^5/.test(error.status)) {
        notifyError("Internal Server Error", { errors: error.body.errors });
        if (!Array.isArray(error.body.errors)) {
          throw new Error("Internal Server Error");
        }
      }

      throw handleErrors(error);
    }

    notifyError("Network Error");
    throw new Error("Network Error");
  }
}
