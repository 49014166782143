import * as ACTIONS from "store/actions";
import { Store } from "store";

export function config(state: Store["config"] = null, action: any) {
  switch (action && action.type) {
    case ACTIONS.RECEIVE_CONFIG:
      return action.data;

    default:
      return state;
  }
}
