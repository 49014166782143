import store from "store";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { PaymentsQuery } from "./payments";
import { computeID } from "./actionUtils";
import { standardDispatch, OpCode } from "store/dispatcher";
import { CurrencyCode } from "@trolley/common-frontend";

export interface OfflinePayment extends types.PaymentTax {
  amount: string;
  currency: CurrencyCode;
}

export function loadOfflinePayments(recipientId: string, query: PaymentsQuery, force?: boolean) {
  const { offlinePayments } = store.getState();
  const id = computeID(query);
  if (force || !["loading", "loaded"].includes(offlinePayments.fetchStatus[id] || "")) {
    standardDispatch(OpCode.LOADING, "offlinePayments", { id });
    request
      .GET<types.PaymentTaxListResponse>(`/v1/recipients/${recipientId}/offlinePayments`, { query })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "offlinePayments", {
          id,
          data: {
            records: body.offlinePayments,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "offlinePayments", { errors, id });
      });
  }

  return id;
}
