import { RecipientStatus, RecipientType, TaxStatus } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { showProduct } from "utils/helpers";

import { Container, Space } from "components";
import { PATHS } from "pages/App/routes";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForms } from "store/hooks/taxForms";
import { useTaxProfiles } from "store/hooks/taxProfiles";
import { useTheme } from "style/classname";
import { useStepper, PRODUCT_MODULES, useProducts } from "utils/context";
import StepperIcon from "./StepperIcon";

export default function ReadyStepper() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const merchant = useMerchant();
  const recipient = useRecipient();
  const iframeConfig = useIframeConfig();
  const { enabled: usTaxEnabled, data: taxForms } = useTaxForms();
  const { enabled: euTaxEnabled, data: taxProfiles } = useTaxProfiles();
  const stepperProgress = useStepper();
  const steps: { label: string; percent: number | null; completed: boolean; link?: string }[] = [];
  const [visible, setVisible] = useState(recipient?.status !== RecipientStatus.ACTIVE);
  const { queryProducts, entitledProducts } = useProducts();
  const showTaxProduct = showProduct(PRODUCT_MODULES.TAX, queryProducts, entitledProducts);
  const showPayProduct = showProduct(PRODUCT_MODULES.PAY, queryProducts, entitledProducts);
  const showTrustProduct = showProduct(PRODUCT_MODULES.TRUST, queryProducts, entitledProducts);

  /** ======================== PAYOUT METHODS ======================= */
  const showPayoutMethods = showPayProduct && !!iframeConfig.showPayoutMethods;
  const completePayout = !!recipient?.accounts?.some(
    (account) =>
      account.status === "primary" && merchant?.enabledPayoutMethods.find((pm) => pm.integration === account.type),
  );

  if (showPayoutMethods) {
    steps.push({
      label: formatMessage({
        id: "portal.setupPayoutMethod",
        defaultMessage: "Setup your payout method",
      }),
      completed: completePayout,
      percent: stepperProgress.payout,
      link:
        completePayout || [PATHS.INFO, PATHS.PAYOUT].includes(history.location.pathname as any)
          ? undefined
          : PATHS.INFO,
    });
  }

  /** ======================== Verification ======================= */

  const verificationEnabled =
    showTrustProduct &&
    ((recipient?.type === RecipientType.INDIVIDUAL && !!merchant?.features?.idv && merchant?.trust?.requireIDV) ||
      (recipient?.type === RecipientType.BUSINESS && !!merchant?.features?.dsa && merchant?.trust?.requireDSA)) &&
    merchant?.trust?.requireVerificationToBeActive;

  const trustVerificationInProgress =
    verificationEnabled &&
    ((recipient?.type === RecipientType.INDIVIDUAL && recipient?.idvStatus) ||
      (recipient?.type === RecipientType.BUSINESS && recipient?.kybStatus));

  const completedIDV = !!trustVerificationInProgress && !recipient?.inactiveReasons?.approvedIdVerification;

  if (verificationEnabled) {
    steps.push({
      completed: completedIDV,
      percent: !!trustVerificationInProgress
        ? !!recipient?.inactiveReasons?.approvedIdVerification
          ? 0.5
          : stepperProgress.trust
        : 0,
      label: formatMessage({
        id: "portal.completeVerification",
        defaultMessage: "Complete verification",
      }),
    });
  }

  /** ======================== US TAX COMPLIANCE ======================= */

  const showUsTax = showTaxProduct && usTaxEnabled;
  const usTaxFormRequired = merchant?.tax?.paymentBehaviour === "block";
  const completeUSTax = taxForms.records.some((t) =>
    [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(t.status as TaxStatus),
  );
  if (showUsTax) {
    steps.push({
      completed: completeUSTax,
      percent: stepperProgress.taxUs, // history.location.pathname.includes(PATHS.US_TAX) ? 1 / 2 : null,
      label: [
        formatMessage({
          id: "portal.completeTax",
          defaultMessage: `Complete a tax form`,
        }),
        usTaxFormRequired ? null : `(${formatMessage({ id: "common.optional" })})`,
      ]
        .filter((v) => v)
        .join(" "),
      link:
        (showPayoutMethods && !completePayout) || // If payout shown, it must be completed before completing the tax compliance
        completeUSTax
          ? undefined
          : PATHS.US_TAX,
    });
  }

  /** ======================== EU TAX COMPLIANCE ======================= */

  const showEuTax = showTaxProduct && euTaxEnabled;
  const completeEUTax = taxProfiles.records.some((t) =>
    [TaxStatus.SUBMITTED, TaxStatus.REVIEWED].includes(t.status as TaxStatus),
  );
  if (showEuTax) {
    steps.push({
      completed: completeEUTax,
      percent: stepperProgress.taxEu,
      label: "Complete a Tax Profile",
    });
  }

  /** ======================== READY ======================= */
  const complete =
    (!showPayoutMethods || completePayout) &&
    (!showUsTax || !usTaxFormRequired || completeUSTax) &&
    (!showEuTax || completeEUTax) &&
    (!verificationEnabled || completedIDV);

  if (showPayProduct) {
    steps.push({
      completed: complete,
      percent: null,
      label: formatMessage({
        id: "portal.ready",
        defaultMessage: "Ready to get paid!",
      }),
    });
  }

  useEffect(() => {
    if (!visible && !complete) {
      // if recipient changes this profile to make it incomplete,
      // eg. when someone removes all payout methods
      // it should make the notification reappear
      setVisible(true);
    }
  }, [complete]);

  return visible ? (
    <Container padding={["small", "medium"]}>
      <Container
        style={{
          position: "relative",
          backgroundColor: theme.colorBgContainerDisabled,
          borderRadius: `${theme.borderRadius}px`,
        }}
        padding="large"
      >
        {steps.map((step, index) => (
          <Space align="center" wrap={false} key={step.label} style={{ margin: `${theme.marginXS}px 0` }}>
            <StepperIcon order={index + 1} percent={step.percent} completed={step.completed} />
            {step.link ? <NavLink to={step.link}>{step.label}</NavLink> : step.label}
          </Space>
        ))}
      </Container>
    </Container>
  ) : null;
}
