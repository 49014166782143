/* eslint-disable react/no-unused-prop-types */
import { Icon } from "components";
import React, { useEffect, useState } from "react";
import { BankInfo } from "store/actions/bankInfo";
import css, { createUseStyle } from "style/classname";

enum SIZE_MAP {
  "10x" = "10em",
  "7x" = "7em",
  "5x" = "5em",
  "3x" = "3em",
  "xlarge" = "2em",
  "large" = "1.33333em",
  "small" = "0.875em",
  "xsmall" = "0.75em",
}

const useStyle = createUseStyle<Props>(
  css`
    height: ${(props) => SIZE_MAP[props.size || "xlarge"]};
    display: inline-block;
    position: relative;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  `,
);

interface Props {
  bankInfo: BankInfo | undefined;
  size?: keyof typeof SIZE_MAP;
}

export default function BankLogo(props: Props) {
  const { bankInfo } = props;
  const styledBankLogo = useStyle(props);
  const [useFallback, setFallback] = useState<boolean>(false);

  useEffect(() => {
    if (bankInfo) {
      // RESET fallback when new bankInfo
      setFallback(false);
    }
  }, [bankInfo]);

  function onShowFallBackIcon() {
    setFallback(true);
  }

  return (
    <div className={styledBankLogo}>
      {bankInfo?.logoURL && !useFallback ? (
        <img src={bankInfo.logoURL} onError={onShowFallBackIcon} alt="bank-logo" />
      ) : (
        <Icon type="university" size={props.size} fixedWidth={false} />
      )}
    </div>
  );
}
