import store from "store";
import * as request from "services/request";

import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";
import { standardDispatch, OpCode } from "store/dispatcher";
import { Query } from "utils/helpers";

export interface Payment extends types.Payment {}
export interface PaymentsQuery extends Query<Payment> {
  search?: string;

  status?: string | string[];
  country?: string | string[];
  paymentMethod?: string | string[];
  sourceCurrency?: string | string[];
  targetCurrency?: string | string[];
  // complianceStatus?: string | string[];
}

export function loadPayments(recipientId: string, query: PaymentsQuery, force?: boolean) {
  const { payments } = store.getState();
  const id = computeID(query);
  if (force || !isLoaded(payments.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "payments", { id });
    request
      .GET<types.PaymentListResult>(`/v1/recipients/${recipientId}/payments`, {
        query,
      })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "payments", {
          id,
          data: {
            records: body.payments,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "payments", { errors, id });
      });
  }

  return id;
}
