import {
  CurrencyCode,
  PayoutMethod,
  PayoutMethodType,
  formatCountry,
  formatPayoutMethod,
} from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";

import { default as interac } from "assets/images/interac.svg";
import { default as VenmoLogo } from "assets/images/venmo.svg";
import { BackButton, Divider, Form2, Icon, Notification, Paragraph, Text, TitleBar } from "components";
import { RecipientAccount } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useRecipient } from "store/hooks/recipient";
import { createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";
import PayoutEstimatedDeliveryDate from "./PayoutEstimatedDeliveryDate";
import PayoutEstimatedFees from "./PayoutEstimatedFees";
import NotificationDisabledCountry from "./NotificationDisabledCountry";
import { Fees, loadAsyncBankTransferFees } from "store/actions/fees";
import { useMerchant } from "store/hooks/merchant";

const ICON_ATTR: any = {
  size: "xlarge",
};

function getPayoutMethodIcon(type: PayoutMethodType) {
  switch (type) {
    case PayoutMethodType.BANKTRANSFER:
      return <Icon type="university" {...ICON_ATTR} />;
    case PayoutMethodType.PAYPAL:
      return <Icon theme="brands" type="paypal" {...ICON_ATTR} />;
    case PayoutMethodType.BITCOIN:
      return <Icon theme="brands" type="bitcoin" {...ICON_ATTR} />;
    case PayoutMethodType.INTERAC:
      return <img src={interac} alt="interac-icon" style={{ width: "2em" }} />;
    case PayoutMethodType.CHECK:
      return <Icon type="money-check-edit-alt" {...ICON_ATTR} />;
    case PayoutMethodType.VENMO:
      return <img src={VenmoLogo} alt="venmo-icon" style={{ width: "2em" }} />;
    default:
      return null;
  }
}

interface Props {
  account: RecipientAccount | undefined; // the account the user is currently editing
  enabledPayoutMethods: PayoutMethod.PayoutMethodPublic[] | undefined;
  selectedMethod: PayoutMethodType | undefined;
  onSelect: (val: PayoutMethodType) => void;
  currency?: CurrencyCode;
}

export default function PayoutMethodPicker(props: Props) {
  const recipient = useRecipient();
  const merchant = useMerchant();
  const config = useIframeConfig();
  const { account, enabledPayoutMethods, selectedMethod, onSelect } = props;
  const { formatMessage } = useIntl();
  const [bankTransferEstimatedFees, setBankTransferEstimatedFees] = useState<Fees["bankTransfer"] | null>(null);

  const styledPayoutMethod = useStyledPayoutMethod();

  useEffect(() => {
    if (!recipient?.id || !recipient?.address?.country || !merchant) {
      return;
    }

    loadAsyncBankTransferFees({
      country: account?.country ?? recipient.address.country,
      sourceCurrency: merchant.primaryCurrency ?? undefined,
      targetCurrency: props.currency,
    })
      .then((bankTransfer) => {
        if (!!bankTransfer) {
          setBankTransferEstimatedFees(bankTransfer);
        }
      })
      .catch(() => {});
  }, [props.currency, recipient, merchant, account]);

  if (!enabledPayoutMethods?.length) {
    return (
      <>
        <Divider transparent size="xlarge" />
        <Notification
          type="warning"
          title={formatMessage(
            { id: "containers.payoutMethod.noPayoutMethods" },
            {
              country: <strong>{formatCountry(recipient?.address.country, formatMessage)}</strong>,
            },
          )}
        />
        <Divider transparent size="xlarge" />
        <BackButton />
      </>
    );
  }

  return (
    <>
      {!account ? (
        <>
          <TitleBar level={2}>{formatMessage({ id: "containers.payoutMethod.selectPayoutMethod" })}</TitleBar>
          <Paragraph>
            {formatMessage(
              { id: "containers.payoutMethod.description" },
              {
                country: formatCountry(recipient?.address.country, formatMessage),
              },
            )}
          </Paragraph>
        </>
      ) : (
        <TitleBar>{formatMessage({ id: "containers.payoutMethod.headers.payoutMethod" })}</TitleBar>
      )}

      <NotificationDisabledCountry
        account={account}
        payoutMethod={enabledPayoutMethods.find((pm) => pm.integration === selectedMethod)}
        size="default"
      />

      <Form2.Radio.Group
        optionType="card"
        value={selectedMethod}
        className={styledPayoutMethod}
        onChange={(e) => {
          onSelect(e.target.value);
        }}
        options={enabledPayoutMethods.map((pm) => ({
          value: pm.integration,
          disabled: account && account.type !== pm.integration,
          label: (
            <>
              {getPayoutMethodIcon(pm.integration)}
              <Text strong padded>
                {formatPayoutMethod(pm.integration, formatMessage)}
              </Text>

              <PayoutEstimatedDeliveryDate
                account={account?.type === pm.integration ? account : undefined}
                payoutMethod={pm}
              />

              {config.showFees && (
                <PayoutEstimatedFees
                  // account={account}
                  type={pm.integration}
                  bankTransferFees={bankTransferEstimatedFees ?? undefined}
                />
              )}
            </>
          ),
        }))}
      />
      <Divider transparent size="large" />
    </>
  );
}

const useStyledPayoutMethod = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-radio-group {
      margin: ${theme.padding}px 0;
      .${theme.prefixCls}-space-item {
        flex: 1 0 50%;
        --fifty: calc((100% - 8px) / 2);
        max-width: var(--fifty);
        .${theme.prefixCls}-radio-wrapper {
          width: 100%;
          height: 100%;
          padding: ${theme.padding}px;
          & > span {
            padding: 0;
          }
          &-checked {
            .icon {
              color: ${theme.colorPrimary};
            }
          }
          .${theme.prefixCls}-radio {
            position: absolute;
            margin: 0;
            display: inline;
            top: ${theme.paddingSM}px;
            right: ${theme.paddingSM}px;
          }
        }
        ${theme.screenUp("md")} {
          flex: 0 1 50%;
          .${theme.prefixCls}-radio-wrapper {
            padding: ${theme.paddingLG}px;
          }
        }
        ${theme.screenUp("lg")} {
          max-width: min(360px, var(--fifty));
        }
      }
    }
  `(),
);
