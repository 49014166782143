import { POST } from "services/request";
import * as types from "@trolley/common-frontend";

import store from "store";
import { standardDispatch, OpCode } from "store/dispatcher";
import { isLoaded } from "./actionUtils";
import { EoyTaxReportStatus } from "@trolley/common-frontend";

export interface DAC7EoyTaxForm extends types.EndOfYear.DAC7RecipientEoyTax {}
export function loadDac7EoyTaxForms(force?: true) {
  const id = "data";
  const { dac7EoyTaxForms } = store.getState();
  if (force || !isLoaded(dac7EoyTaxForms.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms", { id });

    POST<types.EndOfYear.ListDAC7RecipientEoyTax>("/v1/recipients/dac7-eoy/search", {
      query: {
        pageSize: 100,
        status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
      },
    })
      .then((res) => {
        standardDispatch(OpCode.DATA, "dac7EoyTaxForms", {
          id,
          data: {
            records: res.body.dac7RecipientEoyTaxes,
            meta: res.body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", { id, errors });
      });
  }
}
