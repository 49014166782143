import {
  CountryCode,
  RecipientType,
  SupportedTaxRegions,
  formatCountry,
  getRegionLabel,
} from "@trolley/common-frontend";
import { FormInstance } from "antd";
import { Button, Card, Container, DateDisplay, Divider, Flag, Form2, Grid, Space, Typography } from "components";
import React, { useMemo } from "react";
import { useTheme } from "style/classname";
import { useClientIntegration, useIntl } from "utils/context";
import { stringifyAddress, getTinTypeLabel } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import { TaxProfileWizard, WIZARD_SECTIONS, WizardPages } from ".";
import { isOnlyEnabledRegion } from "..";
import { useMerchant } from "store/hooks/merchant";

interface Props {
  taxProfileWizard: TaxProfileWizard;
  path: WizardPages[];
  onSubmitTaxProfile: (name: string, exit?: boolean) => void;
  onSummaryEdit: (section: WIZARD_SECTIONS) => void;
}

interface IFormData {
  name: string;
}

export default function Summary({ onSubmitTaxProfile, onSummaryEdit, taxProfileWizard, path }: Props) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    name: "",
  };
  const { isMobile } = useWindowSize();

  function getTaxIDValue(type: string, country?: CountryCode, value?: string) {
    return {
      label: type,
      value: (
        <Space size={isMobile ? "xsmall" : "small"}>
          {country && value && (
            <>
              <Flag code={country} style={{ verticalAlign: "top" }} />
              {value}
            </>
          )}
        </Space>
      ),
    };
  }
  function getTaxIDListValue(country: CountryCode, value: string, type: string) {
    return {
      label: `${type}`,
      value: (
        <Space size={isMobile ? "xsmall" : "small"}>
          <Flag code={country} style={{ verticalAlign: "top" }} />
          {value}
        </Space>
      ),
    };
  }

  function isInPath(pages: WizardPages[]) {
    return pages.some((p) => path.includes(p));
  }

  function onSubmit({ name }: IFormData) {
    onSubmitTaxProfile(name);
  }

  const showTins =
    isInPath([WizardPages.ENTER_TIN_NUMBER]) && taxProfileWizard.tinNumbers && taxProfileWizard.tinNumbers?.length > 0;
  const showVat =
    isInPath([WizardPages.CONFIRM_VAT_NUMBER, WizardPages.ENTER_VAT_NUMBER]) &&
    taxProfileWizard.confirmVATNumber === "yes";
  const showBRN = isInPath([WizardPages.ENTER_BUSINESS_NUMBER, WizardPages.ENTER_ABN]);
  const showTaxIdentificationSection = showTins || showVat || showBRN;

  const showBirthPlace = isInPath([WizardPages.CONFIRM_BIRTHPLACE]);
  const showDOB = isInPath([WizardPages.CONFIRM_DATE_OF_BIRTH]);
  const showIndividualDetailsSection = showBirthPlace || showDOB;

  return (
    <Container padding="none">
      <Typography.Title>{formatMessage({ id: "containers.taxProfile.wizard.summaryTitle" })}</Typography.Title>
      <Divider size="large" transparent />
      <SummarySectionWrapper
        title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.title" })}
        rows={[
          {
            label: formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.taxProfileType" }),
            value:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.info.type.individual" })
                : formatMessage({ id: "containers.info.type.business" }),
          },
          {
            label:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.individualName" })
                : formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.businessName" }),
            value:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}`
                : taxProfileWizard.businessName,
          },
          {
            label: formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.addressLabel" }),
            value: stringifyAddress({
              ...taxProfileWizard.address,
              country: formatCountry(taxProfileWizard.address?.country),
              region: getRegionLabel(taxProfileWizard.address?.region, taxProfileWizard.address?.country),
            }),
            shouldRender: isInPath([WizardPages.CONFIRM_PRIMARY_ADDRESS, WizardPages.UPDATE_PRIMARY_ADDRESS]),
          },
        ]}
        onEditSection={() => {
          onSummaryEdit(WIZARD_SECTIONS.TAX_PROFILE);
        }}
        recipientType={taxProfileWizard.confirmedRecipientType}
      />
      <Divider size="large" transparent />
      {showTaxIdentificationSection && (
        <>
          <SummarySectionWrapper
            title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.title" })}
            rows={[
              {
                ...getTaxIDValue(
                  formatMessage({
                    id: "containers.taxProfile.wizard.summarySection.taxIdentification.vatLabel",
                  }),
                  taxProfileWizard.vatCountry as CountryCode,
                  taxProfileWizard.vatNumber,
                ),
                shouldRender: showVat,
              },
              ...(taxProfileWizard.tinNumbers?.map(({ tinCountry, tinNumber, tinType }, index) => ({
                ...getTaxIDListValue(
                  tinCountry as CountryCode,
                  tinNumber,
                  tinType
                    ? getTinTypeLabel(tinCountry, tinType)
                    : formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.tinLabel" }),
                ),
                shouldRender: showTins,
              })) ?? []),
              ...(taxProfileWizard.confirmedRecipientType === RecipientType.BUSINESS
                ? [
                    {
                      ...getTaxIDValue(
                        formatMessage({
                          id: "containers.taxProfile.wizard.summarySection.taxIdentification.businessRNLabel",
                        }),
                        taxProfileWizard.businessCountry as CountryCode,
                        taxProfileWizard.businessNumber,
                      ),
                      shouldRender: showBRN,
                    },
                  ]
                : []),
            ]}
            onEditSection={() => {
              onSummaryEdit(WIZARD_SECTIONS.TAX_IDENTIFICATION);
            }}
            recipientType={taxProfileWizard.confirmedRecipientType}
          />
          <Divider size="large" transparent />
        </>
      )}
      {showIndividualDetailsSection && (
        <SummarySectionWrapper
          title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.details.individualTitle" })}
          rows={[
            {
              label: formatMessage({
                id: "containers.taxProfile.wizard.summarySection.details.placeOfBirthLabel",
              }),
              value: `${taxProfileWizard.birthplace?.city}, ${formatCountry(taxProfileWizard.birthplace?.country)}`,
              shouldRender: showBirthPlace,
            },
            {
              label: formatMessage({
                id: "containers.taxProfile.wizard.summarySection.details.dateOfBirthLabel",
              }),
              value: <DateDisplay value={taxProfileWizard.dob} time={false} />,
              shouldRender: showDOB,
            },
          ]}
          onEditSection={() => {
            onSummaryEdit(WIZARD_SECTIONS.DETAILS);
          }}
          recipientType={taxProfileWizard.confirmedRecipientType}
        />
      )}
      <Divider size="large" transparent />
      <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
        <fieldset>
          <legend style={{ border: 0 }}>
            <Typography.Text type="label" size="base">
              {taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL &&
              !!taxProfileWizard.recipientFirstName &&
              !!taxProfileWizard.recipientLastName
                ? formatMessage(
                    { id: "containers.taxProfile.wizard.summaryCertifiedBy" },
                    { name: `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}` },
                  )
                : formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByFullName" })}
            </Typography.Text>
          </legend>
          <Form2.Item
            name="name"
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByRequiredMessage" }),
              },
              {
                async validator(_: any, v: any) {
                  if (
                    taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL &&
                    v?.trim() !== `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}`
                  ) {
                    throw new Error(
                      formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByMustMatchMessage" }),
                    );
                  }
                },
              },
            ]}
          >
            <Form2.Input type="text" />
          </Form2.Item>
        </fieldset>
        <Divider transparent />
        <Form2.Item dependencies={["name"]}>
          {(form: FormInstance<IFormData>) => {
            const name = form.getFieldValue("name");

            return <Form2.SubmitButton disabled={!name} text={formatMessage({ id: "common.save" })} />;
          }}
        </Form2.Item>
      </Form2>
    </Container>
  );
}

interface SummarySectionWrapperProps {
  onEditSection: () => void;
  title: string;
  rows: { label: string; value: React.ReactNode; shouldRender?: boolean }[];
  recipientType: RecipientType | undefined;
}

function SummarySectionWrapper({ onEditSection, title, rows, recipientType }: SummarySectionWrapperProps) {
  const token = useTheme();
  const { formatMessage } = useIntl();
  const client = useClientIntegration();
  const merchant = useMerchant();
  const hideEdit = useMemo(() => {
    return isOnlyEnabledRegion(SupportedTaxRegions.AU, merchant) && recipientType === RecipientType.BUSINESS;
  }, [recipientType, merchant]);

  return (
    <Card
      type="inner"
      title={<Typography.Text>{title}</Typography.Text>}
      extra={
        !hideEdit ? (
          client === "portal" ? (
            <Button
              type="text"
              icon="pen-to-square"
              aria-label={`${title} - ${formatMessage({ id: "common.edit" })}`}
              size="small"
              onClick={() => onEditSection()}
            />
          ) : (
            <Button
              style={{
                marginRight: "12px",
                background: token.colorFillTertiary,
                border: `1px solid ${token.colorBorder}`,
              }}
              onClick={() => onEditSection()}
            >
              {formatMessage({ id: "common.edit" })}
            </Button>
          )
        ) : undefined
      }
    >
      <dl>
        <Grid padding="medium">
          {rows.map(
            ({ label, value, shouldRender = true }) =>
              shouldRender && (
                <React.Fragment key={label}>
                  <Grid.Item xs={11}>
                    <dt>
                      <Typography.Text type="label">{label}</Typography.Text>
                    </dt>
                  </Grid.Item>
                  <Grid.Item xs={13}>
                    <dd>{value}</dd>
                  </Grid.Item>
                </React.Fragment>
              ),
          )}
        </Grid>
      </dl>
    </Card>
  );
}
