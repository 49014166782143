import { Container, Divider, Form, Grid, Loader, Text } from "components";
import { useForm } from "components/Form";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useIntl } from "utils/context";
import { useHistory } from "react-router-dom";
import { addPayoutMethod, InteracAccountUpdate, RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useIframeConfig } from "store/hooks/config";
import { useRecipient } from "store/hooks/recipient";
import { handleFormErrors } from "utils/helpers";
import { CountryCode, CurrencyCode, PayoutMethodType } from "@trolley/common-frontend";
import PayoutFooter from "./PayoutFooter";

export default function Interac({ account }: { account: RecipientAccount | undefined }) {
  const [form] = useForm();
  const config = useIframeConfig();
  const recipient = useRecipient();
  const intl = useIntl();
  const [activate, setActivate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  async function onFinish(values: any) {
    if (!submitting && recipient) {
      setSubmitting(true);

      const update: InteracAccountUpdate = {
        ...values,
        type: PayoutMethodType.INTERAC,
        currency: CurrencyCode.CAD,
        country: CountryCode.CA,
      };
      try {
        if (recipient) {
          if (activate) {
            update.primary = true;
          }
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, update);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, update);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setActivate(false);
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Container>
          {config.showFees && (
            <Text size="small">
              {intl.formatMessage({
                id: "containers.bankPayoutMethod.processingTimes.title",
              })}
              <b>60 minutes</b>{" "}
            </Text>
          )}
          <Divider transparent />

          <Grid justify="space-between">
            <Grid.Item style={{ flex: "10 1 400px" }}>
              <Form.Field
                name="emailAddress"
                label={intl.formatMessage({
                  id: "containers.interacPayoutMethod.email.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.paypalPayoutMethod.email.required",
                    }),
                  },
                  {
                    type: "email",
                    message: intl.formatMessage({
                      id: "containers.paypalPayoutMethod.email.valid",
                    }),
                  },
                ]}
              >
                <Form.Input name="emailAddress" />
              </Form.Field>

              <Form.Field
                name="reEmailAddress"
                label={intl.formatMessage({
                  id: "containers.interacPayoutMethod.reEmail.title",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "containers.paypalPayoutMethod.reEmail.required",
                    }),
                  },
                  {
                    type: "email",
                    message: intl.formatMessage({
                      id: "containers.paypalPayoutMethod.reEmail.valid",
                    }),
                  },
                  ({ getFieldValue }) => {
                    return {
                      async validator(_: any, value: string) {
                        if (value !== getFieldValue("emailAddress")) {
                          throw new Error(
                            intl.formatMessage({
                              id: "containers.paypalPayoutMethod.emailMustBeSame",
                            }),
                          );
                        }
                      },
                    };
                  },
                ]}
              >
                <Form.Input name="reEmailAddress" />
              </Form.Field>
            </Grid.Item>
          </Grid>

          <PayoutFooter
            setBusy={setSubmitting}
            busy={submitting}
            account={account}
            onSave={() => {
              setActivate(false);
            }}
            onSaveActivate={() => {
              setActivate(true);
            }}
          />
        </Container>
      </Loader>
    </Form>
  );
}
