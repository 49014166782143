import { BaseError } from "@trolley/common-frontend";
import { Container, Icon } from "components";
import config from "config";
import { styledErrorBox } from "pages/App/ErrorBoundary";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DocumentFailedEventType, emitEvent, mapDocumentFailedEvent } from "utils/helpers";

export default function PageNotFound() {
  const { state } = useLocation<{ errors: BaseError[] }>();

  useEffect(() => {
    const error = state?.errors?.find?.((e: BaseError) => e.message);
    if (error) {
      const comment = document.createComment(`ERRORS: ${error.message}`);
      document.body.appendChild(comment);

      const documentFailedEvent: DocumentFailedEventType = mapDocumentFailedEvent(error);
      emitEvent(documentFailedEvent);
    }
  }, [state]);

  return (
    <Container padding="large">
      <div className={styledErrorBox({ border: config.CLIENT === "widget" })}>
        <Icon type="exclamation-square" color="error" size="3x" theme="solid" />
        <div style={{ marginTop: "16px" }}>
          <strong>{state?.errors?.[0]?.message || "Page Not Found"}</strong>
        </div>
      </div>
    </Container>
  );
}
