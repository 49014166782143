import { RadioGroupProps as AntRadioGroupProps, RadioProps as AntRadioProps, Radio as RadioAnt } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import { Form2 } from "components";
import Space from "components/Space";
import React from "react";
import { classnames, createUseStyle, css } from "style/classname";

export declare type CustomRadioValueType = string | number | boolean | undefined; // custom because we should accept undefined

interface RadioProps extends Omit<AntRadioProps, "value"> {
  value: CustomRadioValueType;
}

export default function Radio(props: RadioProps) {
  return <RadioAnt {...props} />;
}
Radio.__ANT_RADIO = true;

export interface RadioOptionType extends Omit<CheckboxOptionType, "value"> {
  value: CustomRadioValueType;
  "aria-label"?: string;
}

export interface GroupProps extends Omit<AntRadioGroupProps, "options" | "buttonStyle" | "optionType" | "children"> {
  options?: Array<RadioOptionType | string | number>;
  optionType?: "default" | "button" | "card";

  /**
   * Style of card radios
   * inline: (default) each card is as wide as its content
   * horizontal: all cards will stretch to fit the entire row
   * vertical. each card is stacked vertically, 100% width
   */
  direction?: "inline" | "horizontal" | "vertical";

  "aria-label"?: string;
}

export function RadioGroup(props: GroupProps) {
  const { "aria-label": ariaLabel, className, options, optionType, direction, ...rest } = props;
  const styledBigButton = useStyledRadioGroup(props);
  const { label: itemLabel } = Form2.Item.useFormItemInstance();
  const label = ariaLabel ?? itemLabel;

  const radioGroup = options?.map((option) => {
    if (typeof option === "object") {
      const { label, "aria-label": ariaLabel, ...optionRest } = option;

      return (
        <Radio {...optionRest} key={String(optionRest.value)} aria-label={ariaLabel ?? label}>
          {label}
        </Radio>
      );
    }

    return (
      <Radio value={option} key={option}>
        {String(option)}
      </Radio>
    );
  });

  const groupContent =
    optionType !== "button" && radioGroup ? (
      <Space size="small" direction={direction === "vertical" ? "column" : "row"} align="stretch">
        {radioGroup}
      </Space>
    ) : (
      radioGroup
    );

  return (
    <RadioAnt.Group
      {...rest}
      optionType={optionType === "card" ? "default" : optionType}
      className={classnames(styledBigButton, className)}
    >
      {label ? (
        <fieldset>
          {label && <legend className="sr-only">{label}</legend>}
          {groupContent}
        </fieldset>
      ) : (
        groupContent
      )}
    </RadioAnt.Group>
  );
}

const useStyledRadioGroup = createUseStyle<GroupProps>(({ theme, optionType, direction, ...props }) =>
  css`
    &.${theme.prefixCls}-radio-group {
      display: ${optionType === "button" && direction === "horizontal" ? "flex" : "block"};
      .${theme.prefixCls}-radio-button-wrapper {
        ${direction === "horizontal" &&
        `
          flex: 1;
          width: 100%;
        `}
      }
      .${theme.prefixCls}-space-item, .${theme.prefixCls}-radio-wrapper {
        margin: 0;
        ${(direction === "horizontal" || direction === "vertical") &&
        `
          flex: 1;
          width: 100%;
        `}
      }
      .${theme.prefixCls}-radio-wrapper {
        position: relative;
        .${theme.prefixCls}-radio {
          align-self: flex-start;
          margin: 4px 0;
          &:last-child {
            width: 100%;
            padding-right: 0px;
          }
        }
        ${optionType === "card" &&
        `
          ${(theme.Radio as any)?.fontSize ? `font-size: ${(theme.Radio as any)?.fontSize}px;` : ``}
          padding: ${(theme.Radio as any)?.padding || theme.paddingSM}px;
          border-radius: ${theme.borderRadius}px;
          border: ${theme.lineType} ${theme.lineWidth}px ${theme.colorBorder};
          background-color: ${getAlphaColor(theme.colorBgContainer, 0.5)};
          &.${theme.prefixCls}-radio-wrapper-checked {
            background-color: ${theme.colorPrimaryBg};
            border-color: ${theme.colorPrimaryBorder};
            &.${theme.prefixCls}-radio-wrapper-disabled {
              border-color: ${theme.colorBorder};
              background-color: ${theme.colorBgContainerDisabled};
            }
          }
          &.${theme.prefixCls}-radio-wrapper-disabled {
            background-color: ${theme.colorBgContainerDisabled};
          }
          &:not(.${theme.prefixCls}-radio-wrapper-disabled) {
            &:hover {
              border-color: ${theme.colorPrimaryHover};
              &:active {
                border-color: ${theme.colorPrimaryActive};
                background-color: ${theme.controlItemBgActive};
              }
            }
          }
        `}
      }
    }
  `(),
);

Radio.Group = RadioGroup;
