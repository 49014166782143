import Tooltip from "components/Tooltip";
import React, { Fragment, HTMLAttributes } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { getContrastText } from "utils/helpers";

type Props = Omit<HTMLAttributes<HTMLSpanElement>, "prefix"> & {
  type?: "info" | "success" | "warning" | "critical" | "default";
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  tooltip?: React.ReactNode;
  children: string;
};

export default function Status(props: Props) {
  const { type, className, children, prefix, suffix, tooltip, ...rest } = props;
  const styledLabel = useStyledLabel(props);

  const item = (
    <span className={classnames("c-status", styledLabel, className)} {...rest}>
      <Fragment key="prefix">{prefix}</Fragment>
      {children}
      <Fragment key="suffix">{suffix}</Fragment>
    </span>
  );

  return tooltip ? <Tooltip title={tooltip}>{item}</Tooltip> : item;
}

const useStyledLabel = createUseStyle<Props>(({ theme, animation, ...props }) => {
  const fontSize = theme.fontSize - 4;

  return css`
    font-size: ${fontSize}px;
    line-height: ${(fontSize + 8) / fontSize};
    margin-left: ${theme.marginXXS}px;
    vertical-align: text-bottom;
    display: inline-block;
    padding: 0px 0.6em;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    border-radius: 4px;
    background-color: ${() => {
      switch (props.type) {
        case "info":
          return theme.colorInfo;
        case "success":
          return theme.colorSuccess;
        case "warning":
          return theme.colorWarning;
        case "critical":
          return theme.colorError;
        default:
          return theme.grey3;
      }
    }};
    color: ${() => {
      switch (props.type) {
        case "info":
          return getContrastText(theme.colorInfo);
        case "success":
          return getContrastText(theme.colorSuccess);
        case "warning":
          return getContrastText(theme.colorWarning);
        case "critical":
          return getContrastText(theme.colorError);
        default:
          return getContrastText(theme.grey3);
      }
    }};

    @media print {
      border: 1px solid #000;
    }
  `();
});
