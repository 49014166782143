import React, { HTMLAttributes, forwardRef } from "react";
import css, { classnames, createUseStyle } from "style/classname";

type Props = HTMLAttributes<HTMLDivElement> & {
  spinning?: boolean;
};
export default forwardRef<HTMLDivElement, Props>(function Loader(
  { className, spinning = true, children, ...rest },
  ref,
) {
  const styledContainer = useStyledContainer({ spinning, children });

  return (
    <div {...rest} className={classnames(styledContainer, className)} ref={ref}>
      <div className="glass">{children}</div>
      <div className="loader" />
    </div>
  );
});

const useStyledContainer = createUseStyle<Props>(
  css`
    width: 100%;
    position: relative;
    ${(props) =>
      props.spinning &&
      props.children &&
      `
      min-height: 80px;
      .glass {
        filter: blur(1px);
        height: 100%;
        opacity: 0.8;
      }
    `}
    & > .loader {
      display: ${(props) => (props.spinning ? "block" : "none")};
      position: absolute;
      z-index: 1;
      border: 3px solid transparent;
      border-top: 3px solid ${({ theme }) => theme.colorPrimary};
      border-radius: 50%;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
      width: 60px;
      height: 60px;
      animation: ${({ animation }) => animation.spin} 0.8s linear infinite;
    }
  `,
);
