import {
  CountryCode,
  CurrencyCode,
  RecipientType,
  formatCurrency,
  isRequiredToActivate,
} from "@trolley/common-frontend";
import { Notification } from "components";
import React from "react";
import { useRecipient } from "store/hooks/recipient";
import { Translator, useIntl } from "utils/context";

interface Props {
  payoutCountry: CountryCode | null | undefined;
  currency: CurrencyCode | undefined;
}

export default function ImportantNotification(props: Props) {
  const recipient = useRecipient();
  const { payoutCountry, currency } = props;
  const { formatMessage } = useIntl();

  return (
    <Notification
      size="large"
      closable
      title={formatMessage({
        id: "containers.bankPayoutMethod.important",
      })}
      type="warning"
    >
      <ul>
        {payoutCountry && getImportantMessages(formatMessage)[payoutCountry]}
        {isRequiredToActivate("govId", payoutCountry, recipient?.type as RecipientType) && (
          <li>
            {formatMessage({
              id: "containers.bankPayoutMethod.govIdCorrect",
            })}
          </li>
        )}
        <li>
          {formatMessage({
            id: "containers.bankPayoutMethod.correctName",
          })}
        </li>
        {currency && (
          <li>
            {formatMessage(
              {
                id: "containers.bankPayoutMethod.correctCurrency",
              },
              {
                currency: (
                  <b>
                    {formatCurrency(currency, formatMessage)} {currency}
                  </b>
                ),
              },
            )}
          </li>
        )}
      </ul>
    </Notification>
  );
}

function getImportantMessages(translator: Translator) {
  return {
    US: [<li key="mustProvideACH">{translator({ id: "containers.bankPayoutMethod.mustProvideACH" })}</li>],
    RU: [
      <li key="ruImportant1">{translator({ id: "containers.bankPayoutMethod.ruImportant1" })}</li>,
      <li key="ruImportant2">{translator({ id: "containers.bankPayoutMethod.ruImportant2" })}</li>,
    ],
  };
}
