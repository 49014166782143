import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadEoyTaxForms } from "store/actions/eoyTaxForms";
import { emptyList } from "store/reducers/standardReducer";

export function useEoyTaxForms() {
  useEffect(() => {
    loadEoyTaxForms();
  }, []);

  return useShallowSelector((state) => {
    if (state.eoyTaxForms.fetchStatus.data === undefined) {
      loadEoyTaxForms();
    }

    return {
      data: state.eoyTaxForms.entities.data || emptyList,
      status: state.eoyTaxForms.fetchStatus.data,
      error: state.eoyTaxForms.errors.data,
    };
  });
}
