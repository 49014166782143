import Form from "components/Form";
import { isLegalAgeDateValidator } from "components/Form/DatePicker";
import React from "react";
import { useIntl } from "utils/context";
import { updateRecipient } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useRecipient } from "store/hooks/recipient";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function DOBInput({ submitted }: InputPopupProps) {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();

  async function onSubmit(dob: string) {
    if (recipient) {
      await updateRecipient(recipient.id, { dob });
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  if (!recipient) {
    return null;
  }

  return (
    <LockedInput
      name="dob"
      defaultValue={recipient.dob}
      onSave={onSubmit}
      FieldComponent={Form.DatePicker}
      rules={[
        {
          required: true,
          message: formatMessage({ id: "containers.info.dob.required" }),
        },
        isLegalAgeDateValidator(formatMessage),
      ]}
      label={formatMessage({ id: "containers.info.dob.title" })}
      submitted={submitted}
    />
  );
}
