import { Typography as AntTypography } from "antd";
import { BaseType } from "antd/lib/typography/Base";
import { TextProps } from "antd/lib/typography/Text";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";

interface Props extends Omit<TextProps, "type"> {
  size?: "base" | "large" | "small";
  align?: "left" | "center" | "right" | "justify" | "inherit";
  type?: BaseType | "label" | "info";
  inline?: boolean;
  padded?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  htmlFor?: string;
  medium?: boolean; // font weight 500. strong is 600 for "semi-bold"
}

export default function Text(props: Props) {
  const { children, padded, strong, medium, className, size, inline, uppercase, capitalize, type, ...rest } = props;
  const styledText = useStyledText(props);

  return (
    <AntTypography.Text
      className={classnames("text", type, className, styledText)}
      type={type === "label" || type === "info" ? undefined : type}
      strong={strong || medium || type === "label"}
      {...rest}
    >
      {children}
    </AntTypography.Text>
  );
}

const useStyledText = createUseStyle<Props>(({ theme, animation, ...props }) =>
  css`
    &.${theme.prefixCls}-typography {
      word-break: normal;
      ${props.medium &&
      `
        & > b,
        & > strong {
          font-weight: 500;
        }
      `}
      ${() => {
        if (props.type) {
          switch (props.type) {
            case "label":
              return `color: ${(theme.Form as any)?.colorTextHeading || theme.colorTextHeading};`;
            case "info":
              return `color: ${theme.colorInfoText};`;
            default:
              return "";
          }
        } else {
          return `color: inherit;`;
        }
      }}
    ${!props.inline || props.padded ? `display: block;` : ""}
    ${props.padded &&
      `
      margin-top: ${theme.marginXXS}px;
      margin-bottom: ${theme.marginXXS}px;
    `}
    ${props.capitalize && `text-transform: capitalize;`}
    ${props.uppercase && `text-transform: uppercase;`}
    ${props.align && `text-align: ${props.align};`}
    ${() => {
        switch (props.size) {
          case "base":
            return `
            font-size: ${theme.fontSize}px;
            ${(!props.inline || props.padded) && `line-height: ${theme.lineHeight};`}
          `;
          case "large":
            return `
            font-size: ${theme.fontSizeLG}px;
            ${(!props.inline || props.padded) && `line-height: ${theme.lineHeightLG};`}
          `;
          case "small":
            return `
            font-size: ${theme.fontSizeSM}px;
            ${(!props.inline || props.padded) && `line-height: ${theme.lineHeightSM};`}
          `;
          default:
            return "font-size: inherit;";
        }
      }}
    }
  `(),
);
