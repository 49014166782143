import {
  CountryCode,
  CurrencyCode,
  formatCountry,
  getBankRuleProps,
  getBankRules,
  isValidIbanSwiftAccountCountry,
  PayoutMethodType,
} from "@trolley/common-frontend";
import { Icon } from "components";
import Form from "components/Form";
import { InputProps } from "components/Form/Input";
import { getBankFieldRulesIndex } from "pages/PayoutMethod2/BankTransfer";
import React from "react";
import { updatePayoutMethod } from "store/actions/recipient";
import { loadTicketsWithDelay } from "store/actions/tickets";
import { useCurrencies } from "store/hooks/currencies";
import { useRecipient } from "store/hooks/recipient";
import { useRecipientAccountConfig } from "store/hooks/recipientAccountConfig";
import { useIntl } from "utils/context";
import { pick } from "utils/helpers";
import { InputPopupProps } from ".";
import LockedInput from "./LockedInput";

export default function AccountNumberInput({ submitted }: InputPopupProps) {
  const recipient = useRecipient();
  const { data: currencies } = useCurrencies();
  const { formatMessage } = useIntl();

  const primaryAccount = recipient?.accounts.find((a) => a.primary && a.type === PayoutMethodType.BANKTRANSFER);
  const { data: recipientAccountConfig } = useRecipientAccountConfig(
    primaryAccount?.type,
    primaryAccount?.country,
    primaryAccount?.currency,
  );

  if (!recipient || !primaryAccount || !primaryAccount.country || !primaryAccount.currency) {
    return null;
  }

  const allRules =
    (recipientAccountConfig?.requiredFields.length && recipientAccountConfig?.requiredFields) ||
    getBankRules(primaryAccount.country, primaryAccount.currency);
  const countryRuleIndex = getBankFieldRulesIndex(allRules, primaryAccount);
  const countryRule = allRules[countryRuleIndex];
  const field = countryRule?.bankFields.includes("iban") ? "iban" : "accountNum";

  const inputProps = getBankRuleProps(
    field,
    undefined,
    {
      country: primaryAccount.country,
      currency: primaryAccount.currency,
      bankId: primaryAccount.bankId,
    },
    formatMessage,
  );

  if (!inputProps) {
    return null;
  }

  async function onSubmit(value: string) {
    if (recipient && primaryAccount) {
      await updatePayoutMethod(recipient.id, primaryAccount?.id, {
        ...pick(primaryAccount, ["currency", "country"]),
        [field]: value,
      });
      loadTicketsWithDelay(recipient.id, true);
    }
  }

  return (
    <LockedInput<InputProps>
      submitted={submitted}
      defaultValue={primaryAccount[field]}
      onSave={onSubmit}
      name={field}
      label={inputProps.label}
      tooltip={inputProps.tooltip}
      validateFirst
      normalize={inputProps.normalize}
      hint={inputProps.hint}
      rules={[
        ...(inputProps.rules || []),
        ({ getFieldValue }) => ({
          async validator(_: any, value: string) {
            // custom validation for IBAN/SWIFT account
            if (["iban", "swiftBic"].includes(field) && value && !value.includes("*")) {
              const ssIndex = field === "iban" ? 0 : 4;
              const accountCountryStr = String(value || "")
                .substring(ssIndex, ssIndex + 2)
                .toLocaleUpperCase();
              const accountCountry = accountCountryStr in CountryCode ? (accountCountryStr as CountryCode) : undefined;

              if (
                accountCountry &&
                !isValidIbanSwiftAccountCountry({
                  payoutCountry: getFieldValue("country") as CountryCode,
                  payoutCurrency: getFieldValue("currency") as CurrencyCode,
                  accountCountry,
                  accountCountryAcceptedCurrencies: currencies[accountCountry]?.map((v) => v.currency) || [],
                })
              ) {
                throw new Error(
                  formatMessage(
                    { id: `containers.bankPayoutMethod.${field}Country` },
                    {
                      country: formatCountry(getFieldValue("country"), formatMessage),
                    },
                  ),
                );
              }
            }
          },
        }),
      ]}
      fieldComponentProps={{
        placeholder: inputProps.placeholder,
        suffix: inputProps.warning ? (
          <div>
            <Icon type="exclamation-triangle" color="warning" tooltip={inputProps.warning} />
          </div>
        ) : (
          <span />
        ),
      }}
      FieldComponent={Form.Input}
    />
  );
}
