import { Divider as AntDivider, DividerProps } from "antd";
import React from "react";
import css, { classnames } from "style/classname";

interface Props extends DividerProps {
  size?: "small" | "large" | "xlarge";
  transparent?: boolean;
}

export default function Divider(props: Props) {
  const { transparent, className, size, style, ...rest } = props;

  return transparent ? (
    <hr aria-hidden className={classnames("divider", styledDivider(props), className)} style={style} />
  ) : (
    <AntDivider className={classnames("divider", styledDivider(props), className)} style={style} {...rest} />
  );
}

const styledDivider = css<Props>`
  ${(props) => props.transparent && `visibility: hidden;`}
  &.divider {
    margin: ${(props) => {
      switch (props.size) {
        case "small":
          return "4px 0";
        case "large":
          return "16px 0";
        case "xlarge":
          return "24px 0";
        default:
          return "8px 0";
      }
    }};
  }
`;
