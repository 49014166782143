import { Stepper } from "components";
import { PATHS } from "pages/App/routes";
import PageNotFound from "pages/PageNotFound";
import { getLatestSubmission } from "pages/TaxSection/variables";
import { default as React } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { FormW9, TaxForm } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForms } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { TaxDeliveryType, TaxFormType } from "@trolley/common-frontend";
import Certification from "./Certification";
import TaxInformation from "./TaxInformation";
import { useNewStyle } from "pages/App";
import { ActionType, useStepperEffect } from "utils/context";

export function isW9Form(form: TaxForm | undefined): form is FormW9 {
  return form?.kind === TaxFormType.W9;
}

enum PAGES {
  IDENTIFICATION = "",
  CERTIFICATION = "certification",
}

export default function W9({ taxForm, taxFormStatus }: { taxForm?: FormW9; taxFormStatus?: BaseStatus }) {
  const match = useRouteMatch();
  const recipient = useRecipient();
  const newLayout = useNewStyle();
  const { data: taxForms } = useTaxForms();
  const history = useHistory();
  const latestSubmission = getLatestSubmission(taxForms);
  const taxData = taxForm?.data;
  const page = (history.location.pathname.split("/")[3] || "") as PAGES;
  const pages = Object.values(PAGES);
  const pageIndex = pages.indexOf(page);

  useStepperEffect(ActionType.TAX_US, (pageIndex + 1) / pages.length);

  function getPath(page: PAGES) {
    if (!page) {
      return match.path;
    }

    return [match.path, page].join("/");
  }

  const taxInfo = (
    <TaxInformation
      taxId={taxForm?.id}
      loading={taxFormStatus === BaseStatus.LOADING}
      taxData={taxData}
      onComplete={(tf) => {
        history.replace(`/tax/${tf.id}`);
        history.push(`/tax/${tf.id}/${PAGES.CERTIFICATION}`);
      }}
    />
  );

  return (
    <>
      {newLayout ? null : <Stepper steps={pages.length} stepIndex={pageIndex} />}
      {!taxForm || !taxData ? (
        taxInfo
      ) : (
        <Switch>
          <Route path={getPath(PAGES.IDENTIFICATION)} exact render={() => taxInfo} />
          <Route
            path={getPath(PAGES.CERTIFICATION)}
            render={() => (
              <Certification
                taxId={taxForm.id}
                taxData={taxData}
                loading={taxFormStatus === BaseStatus.LOADING}
                onComplete={() => {
                  if (recipient) {
                    const isFirstForm = !latestSubmission;

                    if (isFirstForm || recipient.taxDeliveryType === TaxDeliveryType.MAIL) {
                      history.push(PATHS.US_TAX_DELIVERY);
                    } else {
                      history.push(PATHS.US_TAX_COMPLETE);
                    }
                  }
                }}
              />
            )}
          />
          <Route component={PageNotFound} />
        </Switch>
      )}
    </>
  );
}
