import { Container, Form, Loader } from "components";
import { useForm } from "components/Form";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import { useIntl } from "utils/context";
import { useHistory } from "react-router-dom";
import { addPayoutMethod, BitcoinAccountUpdate, RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { omitMaskedValues, handleFormErrors } from "utils/helpers";
import { PayoutMethodType } from "@trolley/common-frontend";
import PayoutFooter from "./PayoutFooter";

interface Props {
  account: RecipientAccount | undefined;
}
export default function Bitcoin({ account }: Props) {
  const [form] = useForm();
  const recipient = useRecipient();
  const intl = useIntl();
  const [activate, setActivate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  async function onFinish(values: { wallet: string }) {
    if (!submitting) {
      setSubmitting(true);

      const data: BitcoinAccountUpdate = {
        wallet: values.wallet,
        type: PayoutMethodType.BITCOIN,
      };
      if (activate) {
        data.primary = true;
      }

      try {
        if (recipient) {
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, omitMaskedValues(data));
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, data);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        }
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setActivate(false);
      setSubmitting(false);
    }
  }

  return (
    <Form form={form} initialValues={account} onFinish={onFinish}>
      <Loader spinning={submitting}>
        <Container>
          <Form.Field
            name="wallet"
            label={intl.formatMessage({
              id: "containers.bitcoinPayoutMethod.wallet.title",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "containers.bitcoinPayoutMethod.wallet.required",
                }),
              },
            ]}
          >
            <Form.Input name="wallet" />
          </Form.Field>

          <PayoutFooter
            setBusy={setSubmitting}
            busy={submitting}
            account={account}
            onSave={() => {
              setActivate(false);
            }}
            onSaveActivate={() => {
              setActivate(true);
            }}
          />
        </Container>
      </Loader>
    </Form>
  );
}
