import { Form as AntForm, FormProps } from "antd";
import React from "react";

import Checkbox from "./Checkbox";
import Control from "./Control";
import DatePicker from "./DatePicker";
import GovernmentIds from "./GovernmentIds";
import Input from "./Input";
import InputMask from "./InputMask";
import InputPhone from "./InputPhone";
import InputPhoneButton from "./InputPhoneButton";
import InputTextPhone from "./InputTextPhone";
import Item from "./Item";
import Radio from "./Radio";
import Select from "./Select";
import SelectCountry from "./SelectCountry";
import SelectFTINType from "./SelectFTINType";
import SelectRegion from "./SelectRegion";
import SubmitButton from "./SubmitButton";

const { useForm, useWatch, useFormInstance, List } = AntForm;

interface Props<T> extends Omit<FormProps<T>, "layout"> {}

export default function Form<T>({ validateTrigger = "onSubmit", onSubmitCapture, ...props }: Props<T>) {
  return (
    <AntForm
      layout="vertical"
      requiredMark="optional"
      {...props}
      validateTrigger={validateTrigger}
      onSubmitCapture={(e) => {
        e.preventDefault();
        onSubmitCapture?.(e);
      }}
    />
  );
}
Form.useWatch = useWatch;
Form.useForm = useForm;
Form.useFormInstance = useFormInstance;
Form.Control = Control;
Form.Field = Item; // SAME AS ITEM. to be renamed when
Form.Item = Item;
Form.Input = Input;
Form.InputMask = InputMask;
Form.Select = Select;
Form.Checkbox = Checkbox;
Form.Radio = Radio;
Form.DatePicker = DatePicker;
Form.SelectCountry = SelectCountry;
Form.SelectFTINType = SelectFTINType;
Form.SelectRegion = SelectRegion;
Form.GovernmentIds = GovernmentIds;
Form.InputPhone = InputPhone;
Form.InputPhoneButton = InputPhoneButton;
Form.InputTextPhone = InputTextPhone;
Form.SubmitButton = SubmitButton;
Form.List = List;
