import { Form as AntForm, FormItemProps } from "antd";
import React, { Context, ReactNode, createContext, useContext } from "react";

import { Icon, Space } from "components";
import { classnames, createUseStyle, css } from "style/classname";
import { useNewStyle } from "pages/App";
import { MAX_CHARACTERS_PER_LINE } from "components/Typography";

export interface ItemProps<V = any> extends Omit<FormItemProps<V>, "tooltip" | "label"> {
  label?: string;
  extraLabel?: ReactNode;
  /**
   * @deprecated
   * For accessibility reasons, we should not be using tooltips anymore. Use `extra` prop instead
   */
  tooltip?: ReactNode;
}

export default function FormItem<V = any>(props: ItemProps<V>) {
  const { tooltip, validateFirst = true, className, extraLabel, label, ...rest } = props;
  const newStyle = useNewStyle();
  const styledFormItem = useStyledFormItem({ ...props, newStyle });

  return (
    <FormItemContext.Provider value={{ label }}>
      <AntForm.Item
        {...rest}
        validateFirst={validateFirst}
        tooltip={
          tooltip && {
            title: tooltip,
            icon: <Icon type="circle-question" theme="solid" />,
          }
        }
        className={classnames(styledFormItem, className)}
        label={
          extraLabel ? (
            <Space>
              {label}
              {extraLabel}
            </Space>
          ) : (
            label
          )
        }
      />
    </FormItemContext.Provider>
  );
}

FormItem.useStatus = AntForm.Item.useStatus;

const useStyledFormItem = createUseStyle<ItemProps & { newStyle?: boolean }>(({ theme, newStyle }) =>
  css`
    &.${theme.prefixCls}-form-item {
      .${theme.prefixCls}-form-item-label {
        font-weight: bold;
        & > label {
          height: auto;
        }
      }

      .${theme.prefixCls}-select, .${theme.prefixCls}-input-affix-wrapper, .${theme.prefixCls}-input {
        &:focus-within {
          /**
             * accessibility requirement:
             * https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance-minimum
             */
          border-radius: ${theme.borderRadius}px;
          outline: 4px solid ${theme.colorPrimaryBorder};
          & > .${theme.prefixCls}-input {
            outline: none;
          }
        }
      }
      .${theme.prefixCls}-form-item-extra {
        min-height: ${theme.controlHeightXS}px;
      }

      ${newStyle
        ? `
        .${theme.prefixCls}-form-item-control {
          display: flex;
          .${theme.prefixCls}-form-item-extra {
            max-width: ${MAX_CHARACTERS_PER_LINE}ch;
            margin-top: -${((theme.Form as any)?.paddingXS || 12) - 4}px;
            margin-bottom: ${(theme.Form as any)?.paddingXS || 12}px;
            order: 0; // we want to order the hint/extra field right after the label
          }
          .${theme.prefixCls}-form-item-control-input {
            order: 1;
            & + :not(.${theme.prefixCls}-form-item-extra) {
              order: 2;
            }
          }
        }

      `
        : ``}
    }
  `(),
);

FormItem.useFormItemInstance = useFormItemInstance;

interface FormItemContextState {
  label?: string;
}
export const FormItemContext: Context<FormItemContextState> = createContext({});

function useFormItemInstance() {
  return useContext(FormItemContext);
}
