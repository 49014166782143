import { useEffect, useState } from "react";
import { loadRecipientAccountConfig } from "store/actions/recipientAccountConfig";
import { useShallowSelector } from ".";
import { useRecipient } from "store/hooks/recipient";
import { CountryCode, CurrencyCode, PayoutMethodType } from "@trolley/common-frontend";

export function useRecipientAccountConfig(
  accountType: PayoutMethodType | undefined,
  countryCode: CountryCode | undefined,
  currencyCode: CurrencyCode | "" | null | undefined,
) {
  const recipient = useRecipient();
  const recipientId = recipient?.id || "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (recipientId && accountType && countryCode && currencyCode) {
      setFetchId(loadRecipientAccountConfig({ recipientId, accountType, countryCode, currencyCode }));
    } else {
      setFetchId("");
    }
  }, [accountType, countryCode, currencyCode]);

  return useShallowSelector((state) => {
    if (
      recipientId &&
      accountType &&
      countryCode &&
      currencyCode &&
      state.recipientAccountConfig.fetchStatus[fetchId] === undefined
    ) {
      loadRecipientAccountConfig({ recipientId, accountType, countryCode, currencyCode });
    }

    return {
      data: state.recipientAccountConfig.entities[fetchId],
      status: state.recipientAccountConfig.fetchStatus[fetchId],
      error: state.recipientAccountConfig.errors[fetchId],
    };
  });
}
