import {
  allMappedCountries as countries,
  formatCountry,
  getRegionLabel,
  PaymentCategory,
} from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import { Container, Divider, Grid, Text, Title, TitleBar } from "components";
import React, { useState } from "react";
import Helmet from "react-helmet";

import { getPaymentCategoryLabels } from "data/withholding/variables";
import { getPostalLocaleId, getRegionLocaleId } from "pages/Info";
import { TaxDataW8BEN, TaxForm } from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import { useWithholdingTable } from "store/hooks/withholdingTable";
import css from "style/classname";
import { useIntl } from "utils/context";
import TaxFooter from "../TaxFooter";
import WithholdingWarningPopup from "../WithholdingWarningPopup";

const styledTable = css`
  width: 100%;
  th {
    padding-bottom: 10px;
  }
  td {
    padding-bottom: 4px;
    vertical-align: top;
    &:first-of-type {
      min-width: 110px;
      padding-right: 10px;
    }
  }
`();

interface Props {
  taxData: TaxDataW8BEN;
  calculatedWithholdings: TaxForm["calculatedWithholdings"];
  loading?: boolean;
  onComplete(): void;
}

export default function Review(props: Props) {
  const { taxData, calculatedWithholdings, loading } = props;
  const { formatMessage } = useIntl();
  const [showWithholdingWarning, setShowWithholdingWarning] = useState(false);
  const merchant = useMerchant();
  const { data: withholdingTable } = useWithholdingTable();
  const paymentCategoryLabels = getPaymentCategoryLabels(formatMessage);
  const categories = merchant?.payment?.categories;

  if (!merchant || !categories) {
    return null;
  }

  const enabledCategories =
    merchant.payment &&
    (Object.keys(categories).filter(
      (key) => !!categories[key] && paymentCategoryLabels[key]?.incomeCode,
    ) as PaymentCategory[]);

  const has30Percent = !!enabledCategories?.find((c) =>
    new BigNumber(calculatedWithholdings?.[paymentCategoryLabels[c].incomeCode || ""]?.withholdingPercentage || "0").eq(
      30,
    ),
  );

  const withholdingWarningEnabled =
    merchant?.tax?.displayW8WithholdingWarning && taxData.noUsCertification && taxData.noTaxId;
  const rules = has30Percent
    ? {
        noUsCertification: taxData.noUsCertification === false,
        noTreatyCountry: taxData.residenceCountry && !withholdingTable[taxData.residenceCountry],
        noTaxId: withholdingWarningEnabled,
      }
    : {};

  function onSubmit() {
    const values = Object.values(rules);
    if (values.some((v) => v) && !showWithholdingWarning) {
      setShowWithholdingWarning(true);
    } else {
      props.onComplete();
    }
  }

  return (
    <>
      <Container>
        <Helmet>
          <title>{formatMessage({ id: "containers.tax.w8.review" })}</title>
        </Helmet>

        <TitleBar>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN" })}</TitleBar>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({ id: "containers.tax.w8.review" }),
              step: 3,
              totalSteps: 4,
            },
          )}
        </Title>
        {Object.values(rules).find((value) => value) && (
          <Text data-testid="withhold-30">
            <span>{formatMessage({ id: "containers.tax.w8.withholdingNote" })}</span>
            <ul>
              {rules.noUsCertification && <li>{formatMessage({ id: "containers.tax.w8.notCertified" })}</li>}
              {rules.noTaxId && <li data-testid="no-tax-id">{formatMessage({ id: "containers.tax.w8.noTin" })}</li>}
              {rules.noTreatyCountry && <li>{formatMessage({ id: "containers.tax.w8.noTreatyCountry" })}</li>}
            </ul>
          </Text>
        )}
        <Divider />
        <dl className={styledTable} data-testid="w8table">
          <Grid>
            <Grid.Item xs={24}>
              <TitleBar level={3}>
                {formatMessage({
                  id: "containers.tax.w8.idOfBeneficialOwner",
                })}
              </TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.tax.w8.nameOfIndividual" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>
                {taxData.firstName} {taxData.lastName}
              </dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>
                {formatMessage({
                  id: "containers.tax.w8.citizenshipCountry",
                })}
              </dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{formatCountry(taxData.organizationCountry, formatMessage)}</dd>
            </Grid.Item>
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={3}>
                {formatMessage({
                  id: "containers.tax.w8.permanentResidence",
                })}
              </TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.country.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{formatCountry(taxData.residenceCountry, formatMessage)}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.street1.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.residenceAddress}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.city.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.residenceCity}</dd>
            </Grid.Item>
            {taxData.residenceRegion && (
              <>
                <Grid.Item xs={12}>
                  <dt>
                    {formatMessage({
                      id: getRegionLocaleId(taxData.residenceCountry, "title"),
                      defaultMessage: "State / Province",
                    })}
                  </dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd>{getRegionLabel(taxData.residenceRegion, taxData.residenceCountry)}</dd>
                </Grid.Item>
              </>
            )}
            {taxData.residencePostalCode && (
              <>
                <Grid.Item xs={12}>
                  <dt>
                    {formatMessage({
                      id: getPostalLocaleId(taxData.residenceCountry, "title"),
                      defaultMessage: "Postal Code",
                    })}
                  </dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd>{taxData.residencePostalCode}</dd>
                </Grid.Item>
              </>
            )}
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={3}>{formatMessage({ id: "containers.tax.mailingAddress" })}</TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.country.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{formatCountry(taxData.mailingCountry, formatMessage)}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.street1.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.mailingAddress}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt>{formatMessage({ id: "containers.info.city.title" })}</dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.mailingCity}</dd>
            </Grid.Item>
            {taxData.mailingRegion && (
              <>
                <Grid.Item xs={12}>
                  <dt>
                    {formatMessage({
                      id: getRegionLocaleId(taxData.mailingCountry, "title"),
                      defaultMessage: "State / Province",
                    })}
                  </dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd>{getRegionLabel(taxData.mailingRegion, taxData.mailingCountry)}</dd>
                </Grid.Item>
              </>
            )}
            {taxData.mailingPostalCode && (
              <>
                <Grid.Item xs={12}>
                  <dt>
                    {formatMessage({
                      id: getPostalLocaleId(taxData.mailingCountry, "title"),
                      defaultMessage: "Postal Code",
                    })}
                  </dt>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <dd>{taxData.mailingPostalCode}</dd>
                </Grid.Item>
              </>
            )}
            <Divider />
            <Grid.Item xs={24}>
              <TitleBar level={3}>
                {rules.noTreatyCountry
                  ? formatMessage({ id: "containers.tax.w8.nonResident" })
                  : formatMessage({
                      id: "containers.tax.w8.claimOfTreatyBenefits",
                    })}
              </TitleBar>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt data-testid="country-header">
                {rules.noTreatyCountry
                  ? formatMessage({
                      id: "containers.tax.w8.countryOfResidence",
                    })
                  : formatMessage({ id: "containers.tax.w8.treatyCountry" })}
              </dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>{taxData.residenceCountry && countries[taxData.residenceCountry].name}</dd>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dt style={{ verticalAlign: "top" }}>
                {rules.noTreatyCountry
                  ? formatMessage({ id: "containers.tax.w8.nraWithholding" })
                  : formatMessage({
                      id: "containers.tax.w8.specialWithholding",
                    })}
              </dt>
            </Grid.Item>
            <Grid.Item xs={12}>
              <dd>
                <ul style={{ paddingLeft: "16px" }}>
                  {enabledCategories?.map((key) => (
                    <li key={key}>
                      {paymentCategoryLabels[key].name} (
                      {taxData.residenceCountry &&
                        new BigNumber(
                          calculatedWithholdings?.[paymentCategoryLabels[key]?.incomeCode || ""]
                            ?.withholdingPercentage || "0",
                        ).toFixed(1)}
                      %)
                    </li>
                  ))}
                </ul>
              </dd>
            </Grid.Item>
          </Grid>
        </dl>
        <TaxFooter loading={loading} onClick={onSubmit} />
      </Container>

      <WithholdingWarningPopup
        visible={showWithholdingWarning}
        onClose={() => {
          setShowWithholdingWarning(false);
        }}
        onOk={props.onComplete}
        rules={rules}
      />
    </>
  );
}
