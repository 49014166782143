import { client } from "utils/context";
import { storageFactory } from "utils/factories";
import parseQuery from "utils/helpers/formatter/parseQuery";
import getRandomBytes from "utils/helpers/getRandomBytes";

const TOKEN = "widget-authentication";
const DEVICEID = "deviceId";

const deviceStore = storageFactory(() => localStorage);
const authStore = storageFactory(() => sessionStorage);

const authTokenId = ((w) => {
  try {
    const raw = w.location.search;
    const query = parseQuery(raw);

    return client === "portal" ? "portal" : `${query.email}/${query.refid}/${query.key}`;
  } catch (error) {
    return w.location.search;
  }
})(window || globalThis);

// kept as function incase we change the tokenkey makeup in the future
function getTokenKey() {
  return `${TOKEN}:${authTokenId}`;
}

export function authTokenSet(value?: string | undefined) {
  if (value === undefined) {
    authStore.removeItem(getTokenKey());
  } else {
    authStore.setItem(getTokenKey(), value);
  }
}

export function authTokenGet() {
  return authStore.getItem(getTokenKey());
}

export function getDeviceId() {
  let deviceId = deviceStore.getItem(DEVICEID);
  if (deviceId) {
    return deviceId;
  }

  const found = document.cookie
    .split(";")
    .map((v) => v.trim())
    .filter((v) => v.startsWith(`${DEVICEID}=`));
  if (found.length !== 0) {
    deviceId = decodeURIComponent(found[0].substring(DEVICEID.length + 1));

    if (deviceId) {
      return deviceId;
    }
  }

  const array = getRandomBytes(20);

  deviceId = btoa(String.fromCharCode.apply(null, array));
  deviceStore.setItem(DEVICEID, deviceId);
  try {
    document.cookie = `${DEVICEID}=${encodeURIComponent(deviceId)}; max-age=31536000; samesite=strict`;
  } catch (err) {
    // nothing
  }

  return deviceId;
}

export function authTokenClear() {
  authTokenSet();
}
