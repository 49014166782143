import { formatCountry, TaxW8LimitationTypes } from "@trolley/common-frontend";
import { Button, Container, Divider, Form, GreyBox, Text, Title } from "components";
import { useForm } from "components/Form";
import TreatyTable from "pages/TaxSection/TreatyTable";
import { getCertificationTrueRequired } from "pages/TaxSection/variables";
import { default as React } from "react";
import Helmet from "react-helmet";
import { TaxDataW8BENE, TaxForm, updateTaxForm } from "store/actions/taxForms";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues } from "utils/helpers";
import TaxFooter from "../TaxFooter";
import { getLimitationTypes } from "./variables";

interface Props {
  taxId: string;
  taxData: TaxDataW8BENE;
  calculatedWithholdings: TaxForm["calculatedWithholdings"];
  loading?: boolean;
  pageIndex: number;
  pageTotal: number;
  onComplete(): void;
}

export default function TreatyBenefits(props: Props) {
  const [form] = useForm();
  const { formatMessage } = useIntl();
  const { taxId, taxData, calculatedWithholdings, loading, pageIndex, pageTotal } = props;
  const certificationTrueRequired = getCertificationTrueRequired(formatMessage);
  const limitationTypes = getLimitationTypes(formatMessage);

  async function onFinish({
    otherBeneficialOwnerLimitationType,
    ...values
  }: Partial<TaxDataW8BENE & { otherBeneficialOwnerLimitationType: string }>) {
    if (form.isFieldsTouched()) {
      const formattedValues: Partial<TaxDataW8BENE> = omitMaskedValues(values);

      if (otherBeneficialOwnerLimitationType) {
        formattedValues.beneficialOwnerLimitationType = otherBeneficialOwnerLimitationType;
      }

      try {
        await updateTaxForm(taxId, { data: formattedValues });
        props.onComplete();
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    } else {
      props.onComplete();
    }
  }

  return (
    <Form
      form={form}
      initialValues={{
        ...taxData,
        isBeneficialOwnerCertified: taxData.isBeneficialOwnerCertified ?? false,
        beneficialOwnerLimitationType: taxData.beneficialOwnerLimitationType
          ? ((limitationTypes.find((type) => type.value && type.value === taxData.beneficialOwnerLimitationType)
              ?.value || TaxW8LimitationTypes.OTHER) as string)
          : undefined,
        otherBeneficialOwnerLimitationType:
          taxData.beneficialOwnerLimitationType &&
          !limitationTypes.some((type) => type.value && type.value === taxData.beneficialOwnerLimitationType)
            ? taxData.beneficialOwnerLimitationType
            : undefined,
      }}
      onFinish={onFinish}
    >
      <Container>
        <Helmet>
          <title>
            {formatMessage({
              id: "containers.tax.w8.claimOfTreatyBenefits",
            })}
          </title>
        </Helmet>
        <Title>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN-E" })}</Title>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({
                id: "containers.tax.w8.claimOfTreatyBenefits",
              }),
              step: pageIndex + 1,
              totalSteps: pageTotal,
            },
          )}
        </Title>
        <GreyBox margin="small">
          <Text padded>I certify that:</Text>
          <Form.Field name="isBeneficialOwnerCertified" rules={[certificationTrueRequired]}>
            <Form.Checkbox name="isBeneficialOwnerCertified">
              {formatMessage(
                { id: "containers.tax.w8.ownerIsResident" },
                {
                  country: (
                    <Text inline strong underline>
                      {formatCountry(taxData.residenceCountry, formatMessage)}
                    </Text>
                  ),
                },
              )}
            </Form.Checkbox>
          </Form.Field>

          <Form.Field name="beneficialOwnerHasLimitations" rules={[certificationTrueRequired]}>
            <Form.Checkbox name="beneficialOwnerHasLimitations">
              {formatMessage({
                id: "containers.tax.w8.beneficialOwnerHasLimitations",
              })}
            </Form.Checkbox>
          </Form.Field>

          <div style={{ paddingLeft: "24px" }}>
            <Form.Field
              name="beneficialOwnerLimitationType"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.tax.w8.limitationRequired",
                  }),
                },
              ]}
              hint={
                <Button type="link" href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target="_blank" rel="noreferrer">
                  {formatMessage({
                    id: "containers.tax.w8.seeIrsInstructions",
                  })}
                </Button>
              }
            >
              <Form.Select name="beneficialOwnerLimitationType" options={limitationTypes} />
            </Form.Field>

            <Form.Control dependencies={["beneficialOwnerLimitationType"]}>
              {({ value: { beneficialOwnerLimitationType } }) =>
                beneficialOwnerLimitationType === TaxW8LimitationTypes.OTHER ? (
                  <Form.Field
                    name="otherBeneficialOwnerLimitationType"
                    label={formatMessage({ id: "containers.tax.w8.otherType" })}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: "containers.tax.w8.limitationRequired",
                        }),
                      },
                    ]}
                  >
                    <Form.Input name="otherBeneficialOwnerLimitationType" />
                  </Form.Field>
                ) : null
              }
            </Form.Control>
          </div>

          <Divider transparent />
          <Title level={2}>{formatMessage({ id: "containers.tax.w8.specialRates2" })}</Title>
          <TreatyTable country={taxData.residenceCountry} calculatedWithholdings={calculatedWithholdings} />
        </GreyBox>
        <TaxFooter loading={loading} />
      </Container>
    </Form>
  );
}
