import { Icon } from "components";
import RCForm from "rc-field-form";
import { FieldProps } from "rc-field-form/es/Field";
import { NamePath } from "rc-field-form/es/interface";
import React, { CSSProperties, ReactElement, ReactNode } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";

import { CHECKBOX_WRAPPER_NAME } from "components/Form/Checkbox";
import { RADIO_GROUP_NAME } from "components/Form/Radio";
import Error, { FIELD_ERROR_NAME } from "./Error";
import Hint from "./Hint";

export interface FormFieldProps<T> extends FieldProps<T> {
  name: NamePath;
  label?: string;
  tooltip?: ReactNode;
  hint?: ReactNode;
  optional?: boolean;
  showEmptyError?: boolean;
  className?: string;
  style?: CSSProperties;
  extraLabel?: ReactNode;
}

const FORMFIELD_NAME = "form-field";
const FORMFIELD_LABEL_NAME = "form-item__label";

export default function Field<T>(props: FormFieldProps<T>) {
  const {
    name,
    label,
    extraLabel,
    tooltip,
    hint,
    optional,
    showEmptyError,
    children,
    className,
    style,
    ...formFieldProps
  } = props;
  const styledFormItem = useStyledFormItem(props);
  const styledOptional = useStyledOptional();
  const { formatMessage } = useIntl();

  return (
    <RCForm.Field<T> name={name} {...formFieldProps}>
      {(control, meta, form) => {
        if (!children) {
          return null;
        }
        const id = Array.isArray(name) ? name.join(".") : typeof name === "number" ? String(name) : name; // could also be undefined
        const childNode =
          typeof children === "function"
            ? children(control, meta, form)
            : React.cloneElement(children as ReactElement, {
                ...(children as ReactElement).props,
                id,
                value: (children as ReactElement).props?.value ?? control.value,
                onChange: (e: any) => {
                  // this allows user to add additional onChange behavior instead of replacing it
                  control.onChange?.(e); // form control needs to change first.
                  (children as ReactElement).props?.onChange?.(e); // then trigger onChange event
                },
              });

        return (
          <div
            className={classnames(FORMFIELD_NAME, className, styledFormItem, {
              "has-error": !!name && meta.errors.length > 0,
            })}
            style={style}
          >
            {label && (
              <label className={FORMFIELD_LABEL_NAME} title={label} htmlFor={id}>
                {optional && <span className={styledOptional}> {formatMessage({ id: "common.optional" })}</span>}
                {label}
                {tooltip && <Icon type="question-circle" theme="solid" color="grey" right tooltip={tooltip} />}
                {extraLabel}
              </label>
            )}
            {childNode}
            <Hint value={hint} />
            <Error errors={meta.errors} showEmpty={showEmptyError} />
          </div>
        );
      }}
    </RCForm.Field>
  );
}

const useStyledFormItem = createUseStyle<FormFieldProps<any>>(({ theme }) =>
  css`
    &.${FORMFIELD_NAME} {
      margin-bottom: ${theme.marginSM}px;
      .${FORMFIELD_LABEL_NAME} {
        display: block;
        font-weight: bold;
        color: ${(theme.Form as any)?.colorTextHeading || theme.colorTextHeading};
        margin-bottom: ${theme.marginXS}px;
      }
      &.has-error {
        .${FORMFIELD_LABEL_NAME} {
          color: ${theme.colorErrorText};
        }
        input,
        select {
          border-color: ${theme.colorError};
        }
        .${RADIO_GROUP_NAME}, .${CHECKBOX_WRAPPER_NAME} {
          & ~ .${FIELD_ERROR_NAME} {
            padding-left: 24px;
          }
        }
      }
    }
  `(),
);

const useStyledOptional = createUseStyle(({ theme }) =>
  css`
    float: right;
    line-height: 2.5;
    color: ${theme.colorTextSecondary};
    font-size: ${theme.fontSize - 4}px;
    text-transform: uppercase;
  `(),
);
