import { PayoutMethod } from "@trolley/common-frontend";
import { Text } from "components";
import React from "react";
import { RecipientAccount } from "store/actions/recipient";
import { useIntl } from "utils/context";

interface Props {
  account: RecipientAccount | undefined;
  payoutMethod: PayoutMethod.PayoutMethodPublic;
}

export default function PayoutEstimatedDeliveryDate(props: Props) {
  const { formatMessage } = useIntl();
  const { account, payoutMethod } = props;
  const deliveryEstimate = account?.deliveryBusinessDaysEstimate || payoutMethod.deliveryBusinessDaysEstimate || 0;

  return (
    <Text>
      {formatMessage({ id: "containers.payoutMethod.headers.processingTime" })}:{" "}
      <Text inline medium>
        {deliveryEstimate < 1
          ? formatMessage({ id: "containers.payoutMethod.processingTimes.sameDay" })
          : formatMessage(
              {
                id: "containers.payoutMethod.processingTimes.bussinessDaysEstimate",
              },
              { from: deliveryEstimate, to: deliveryEstimate + 1 },
            )}
      </Text>
    </Text>
  );
}
