import { Container, Divider, Icon, Paragraph, Text } from "components";
import config from "config";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { datadog } from "services/thirdParty";
import { css } from "style/classname";
import { client } from "utils/context";

export const styledErrorBox = css<{ border: boolean }>`
  width: 50%;
  max-width: 420px;
  min-width: 300px;
  text-align: center;
  display: block;
  padding: 40px 10px 60px;
  font-size: 16px;
  color: #7a7a7a;
  border: ${(props) => (props.border ? "1px solid #e9e9e9" : "none")};
  border-radius: 8px;
  margin: 0 auto;
  background: transparent;
`;

interface States {
  hasError?: { message?: string; stack?: string };
}

type AllProps = RouteComponentProps;

class ErrorBoundary extends React.Component<AllProps, States> {
  state: States = {};

  componentDidUpdate(prevProps: AllProps) {
    if (this.state.hasError && prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: undefined });
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: error,
    });

    datadog.error(error);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Container padding="large">
          <div className={styledErrorBox({ border: client === "widget" })}>
            <Icon type="dizzy" theme="solid" color="error" style={{ margin: "0px auto 24px" }} size="3x" />
            <Text strong>Oops... Something went wrong.</Text>
            {config.ENV !== "production" && hasError.message && <Paragraph type="danger">{hasError.message}</Paragraph>}
            <Divider transparent size="large" />
            <Text>We've been notif.ied of this error. In the meantime please reload the page. </Text>
            {hasError.stack && config.ENV !== "production" && (
              <code style={{ fontSize: "12px" }}>{JSON.stringify(hasError.stack)}</code>
            )}
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
